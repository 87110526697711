import {
  useRef,
  useEffect,
  useState,
  useCallback,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";

/**
 * 判断组件是否被销毁，从而防止在销毁后仍然访问组件
 */
export const useAlive = () => {
  const alive = useRef(false);
  useEffect(() => {
    alive.current = true;
    return () => {
      alive.current = false;
    };
  }, [alive]);
  return alive;
};

export function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef() as React.MutableRefObject<Function>;

  // 保存新回调
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, savedCallback]);
}

/**
 * 标记下一帧刷新该组件。
 * 可以在useEffect中使用。
 */
export const useUpdate = () => {
  const [key, setUpdateKey] = useState(0);
  const update = useCallback(() => {
    setUpdateKey(key + 1);
  }, [key, setUpdateKey]);

  const updateRef = useRef({ update, key });
  useEffect(() => {
    updateRef.current = { update, key };
  }, [update, key]);

  return updateRef.current as UpdateObj;
};

/**
 * 立即刷新该组件。
 * 注意：**在useEffect中请使用useUpdate**
 */
export const useUpdateI = () => {
  const [key, setUpdateKey] = useState(0);
  const update = useCallback(() => {
    setUpdateKey(key + 1);
  }, [key, setUpdateKey]);
  const ref = useRef(update);
  ref.current = update;
  const ret = useMemo(() => {
    return { update, key, ref } as UpdateObj;
  }, [update, key, ref]);
  return ret;
};

export interface UpdateObj {
  key: number;
  update: () => void;
  ref: MutableRefObject<() => void>;
}

export function useStateful<T>(defaultValue: T): Stateful<T> {
  const [value, set] = useState<T>(defaultValue);
  const ret = useMemo(() => {
    return {
      value,
      set,
    };
  }, [value, set]);
  return ret;
}

export interface Stateful<T> {
  value: T;
  set: Dispatch<SetStateAction<T>>;
}

export function useCustomState<T, N>(
  defaultValue: T,
  whenSet: (value: N) => T
) {
  const _value = useStateful(defaultValue);
  const set = useCallback(
    (value: N) => {
      _value.set(whenSet(value));
    },
    [_value.set]
  );
  return { value: _value.value, set: set };
}

export function useFirst() {
  const first = useRef(true);
  useEffect(() => {
    return () => {
      first.current = false;
    };
  }, []);
  return first;
}
