import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  Fragment,
} from "react";
import {
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Card,
  Container,
  Grid,
  Fab,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CardActions,
  Fade,
  Backdrop,
  CircularProgress,
  Collapse,
  Box,
} from "@material-ui/core";
import { drawerHeight, ThemeContext } from "../../App";
import { BlockEditor } from "../../elements/BlockEditor";
import { emptyValueData } from "../../Init/initValue";
import uuid from "uuid";
import { Node } from "slate";
import AddIcon from "@material-ui/icons/Add";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  StoreName,
  getObjectStore,
  downloadMarkdown,
} from "../../lib/Database/Database";
import { PageRawData } from "../../lib/Database/DataInterface";
import {
  useUpdateI,
  UpdateObj,
  useInterval,
  useStateful,
} from "../../lib/CommonHook";
import md5 from "blueimp-md5";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Details from "@material-ui/icons/Details";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import { ScrollMem } from "../../lib/Coms/ScrollMem";
import { NBox } from "../../Neumorphism/NBox";
import { Button } from "antd";
import { EditOutlined, QuestionOutlined } from "@ant-design/icons";
import { DragablePanel } from "../../AppCom/DragablePanel";
import { BoxWithCloseButton } from "../../AppCom/BoxWithCloseButton";
import { NotePanel } from "../Learn/Knowledge/NotePanel";
import { InputHelper } from "../../AppCom/InputHelper";
export enum NoteCardType {
  card = "card",
  collection = "collection",
}

export const CardPage = () => {
  const { cardid } = useParams();
  const style = useStyle();
  const mtheme = useContext(ThemeContext);
  const update = useUpdateI();
  const { data } = useRequest(
    async () => {
      let db = await getDatabase();
      let root: NoteCardRecord = await (
        await getObjectStore(db, StoreName.cards)
      ).get(cardid || "undefind");
      if (!root && cardid === "root") {
        await (await getObjectStore(db, StoreName.cards, "readwrite")).put({
          uuid: "root",
          children: [],
          type: NoteCardType.collection,
        });
        root = await (await getObjectStore(db, StoreName.cards)).get(cardid);
      }
      return root;
    },
    { refreshDeps: [update.key, cardid] }
  );

  const deleteCard = useCallback(
    (s: string) => {
      const r = async () => {
        if (data) {
          let db = await getDatabase();
          data.children = data?.children?.filter((v) => v !== s) || [];
          await (await getObjectStore(db, StoreName.cards, "readwrite")).put(
            data
          );
          update.update();
        }
      };
      r();
    },
    [data, update]
  );
  const helper_open = useStateful(false);
  const note_open = useStateful(false);
  return (
    <Paper
      className={style.body}
      style={{ backgroundColor: mtheme.background }}
    >
      <ScrollMem />
      <Grid container className={style.grid} justify="center">
        {data &&
          data.children
            ?.filter((s) => true)
            .reverse()
            .map((v, i) => (
              <Fragment key={v}>
                <Grid item key={v} sm={12}>
                  <NoteCard
                    uuid={v}
                    update={update}
                    deleteC={deleteCard}
                    parent={data}
                  />
                </Grid>
                <Grid item key={v + "_i" + i} sm={12}>
                  <DropInsert myid={v} />
                </Grid>
              </Fragment>
            ))}
      </Grid>
      <Fab
        className={style.fab}
        color="secondary"
        onClick={async () => {
          if (!data) return;
          let db = await getDatabase();
          let d = {
            document: emptyValueData(),
            uuid: uuid.v4(),
            lastWriteTime: new Date(),
            md5: "",
            tags: [],
          };
          await (await getObjectStore(db, StoreName.cards, "readwrite")).put({
            doc: d,
            uuid: d.uuid,
            type: NoteCardType.card,
            children: [],
          });
          data.children.push(d.uuid);
          if (data)
            await (await getObjectStore(db, StoreName.cards, "readwrite")).put(
              data
            );
          update.update();
        }}
      >
        <AddIcon color="primary" />
      </Fab>
      <Fade in={!note_open.value}>
        <Button
          shape="circle"
          onClick={() => note_open.set(true)}
          style={{ position: "fixed", right: 90, bottom: 30 }}
          size="large"
        >
          <EditOutlined style={{ fontSize: 24 }} />
        </Button>
      </Fade>
      <Fade in={note_open.value}>
        <Box>
          <DragablePanel right={10} bottom={10}>
            <BoxWithCloseButton onClose={() => note_open.set(false)}>
              <NotePanel />
            </BoxWithCloseButton>
          </DragablePanel>
        </Box>
      </Fade>
      <Fade in={!helper_open.value}>
        <Button
          shape="circle"
          onClick={() => helper_open.set(true)}
          style={{ position: "fixed", right: 30, bottom: 30 }}
          size="large"
        >
          <QuestionOutlined style={{ fontSize: 24 }} />
        </Button>
      </Fade>
      <Fade in={helper_open.value}>
        <Box>
          <DragablePanel right={10} bottom={10}>
            <BoxWithCloseButton onClose={() => helper_open.set(false)}>
              <InputHelper />
            </BoxWithCloseButton>
          </DragablePanel>
        </Box>
      </Fade>
    </Paper>
  );
};

export interface NoteCardRecord {
  doc: PageRawData;
  children: string[];
  uuid: string;
  type: NoteCardType;
}

interface DDData {
  id: string;
  parent: NoteCardRecord;
  update: () => void;
  type: string;
}

const DropInsert = ({ myid }: { myid: string }) => {
  const style = useStyle();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "card",
    drop: async (item: DDData) => {
      let c = item.parent.children.filter((v: string) => v !== item.id);
      let myind = c.indexOf(myid);
      c.splice(myind, 0, item.id);
      item.parent.children = c;
      const db = await getDatabase();
      await (await getObjectStore(db, StoreName.cards, "readwrite")).put(
        item.parent
      );
      item.update();
    },
    collect: (mon) => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop(),
    }),
    canDrop: (item: DDData) => {
      return item.id !== myid;
    },
  });
  return (
    <Container>
      <div
        ref={drop}
        className={
          style.insertCard + " " + (isOver && canDrop && style.over) || ""
        }
      >
        <Fade in={isOver && canDrop}>
          <Container>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <TrendingDownIcon color="primary" fontSize="large" />
              </Grid>
            </Grid>
          </Container>
        </Fade>
      </div>
    </Container>
  );
};

const HoverMove = ({ dir }: { dir: string }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "card",
    drop: async (item: DDData) => {},
    collect: (mon) => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop(),
    }),
    canDrop: (item: DDData) => {
      return false;
    },
  });
  useInterval(() => {
    if (isOver) {
      if (dir === "up") window.scrollBy(0, -4);
      if (dir === "down") window.scrollBy(0, 4);
    }
  }, 20);

  const style = useStyle();

  return (
    <div
      ref={drop}
      style={{
        position: "fixed",
        left: 0,
        height: 100,
        width: "100%",
        zIndex: 10000,
      }}
      className={dir === "up" ? style.hover_up : style.hover_down}
    ></div>
  );
};

const NoteCard = ({
  uuid,
  update,
  deleteC,
  parent,
}: {
  uuid: string;
  update: UpdateObj;
  deleteC: (n: string) => void;
  parent: NoteCardRecord;
}) => {
  const style = useStyle();
  const inupdate = useUpdateI();
  const [mvalue, setMValue]: [
    PageRawData | undefined,
    (x: PageRawData) => void
  ] = useState();
  const [doc, setDoc]: [
    NoteCardRecord | undefined,
    (n: NoteCardRecord) => void
  ] = useState();

  const { data } = useRequest(async () => {
    const db = await getDatabase();
    let docc: NoteCardRecord = await (
      await getObjectStore(db, StoreName.cards)
    ).get(uuid);
    setMValue(docc.doc as any);
    setDoc(docc as any);
    return docc;
  });

  const [id, setID] = useState(uuid);
  const [back_open, setBackOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setBackOpen(false);
  };

  let mmd5 = mvalue?.md5 || "";
  useEffect(() => {
    const save = async () => {
      let db = await getDatabase();
      await (await getObjectStore(db, StoreName.cards, "readwrite")).put({
        ...doc,
        doc: mvalue,
      });
    };
    if (!!mvalue && !!doc) save();
  }, [mmd5, inupdate.key, doc, mvalue]);

  // ------------------
  //     drag drop
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "card",
      id: uuid,
      parent: parent,
      update: update.update,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "card",
    drop: async (item: DDData) => {
      let db = await getDatabase();
      if (data) {
        parent.children = parent.children.filter((v) => v !== item.id);
        await (await getObjectStore(db, StoreName.cards, "readwrite")).put(
          parent
        );
        data.children.push(item.id);
        await (await getObjectStore(db, StoreName.cards, "readwrite")).put(
          data
        );
        update.update();
      }
      //
    },
    collect: (mon) => {
      return {
        isOver: !!mon.isOver(),
        canDrop: !!mon.canDrop(),
      };
    },
    canDrop: (item: DDData) => {
      return item.id !== id;
    },
  });

  // ------------------
  return (
    <Container>
      {isDragging && <HoverMove dir="up" />}
      <Card className={style.card} style={{ position: "relative" }}>
        <div
          ref={drag}
          style={{
            position: "absolute",
            top: 0,
            left: 30,
            height: 50,
            width: 400,
          }}
        ></div>
        <CardContent style={{ position: "absolute", right: -5, top: -10 }}>
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        </CardContent>
        <CardContent className={style.editor}>
          {mvalue && (
            <BlockEditor
              key={id}
              mvalue={mvalue}
              setValue={(v: any) => {
                let mm = md5(JSON.stringify(v.doc));
                if (mm !== mvalue.md5) {
                  setMValue({
                    document: v.doc,
                    uuid: id,
                    lastWriteTime: new Date(),
                    md5: mm,
                    tags: v.tags,
                  });
                }
              }}
            />
          )}
        </CardContent>
        <CardContent style={{ paddingBottom: 5, paddingTop: 5 }}>
          <Typography color="textSecondary">
            {mvalue && mvalue.lastWriteTime.toLocaleString()}
          </Typography>
        </CardContent>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>取消</MenuItem>
          <MenuItem
            onClick={async () => {
              let db = await getDatabase();
              await (
                await getObjectStore(db, StoreName.cards, "readwrite")
              ).delete(uuid);
              deleteC(uuid);
              //update.update();
            }}
          >
            删除
          </MenuItem>
        </Menu>
      </Card>
      {isDragging && <HoverMove dir="down" />}
      <Backdrop open={back_open} className={style.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const cardW = 600;
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      minHeight: 1080,
      marginTop: drawerHeight,
      [theme.breakpoints.up("sm")]: {
        padding: 20,
      },
    },
    insertCard: {
      minHeight: 20,
      [theme.breakpoints.up("xs")]: {
        width: cardW,
      },
      [theme.breakpoints.down("xs")]: {
        width: 280,
      },
      marginLeft: "auto",
      marginRight: "auto",
    },
    card: {
      //minHeight: 200,
      [theme.breakpoints.up("xs")]: {
        width: cardW,
      },
      [theme.breakpoints.down("xs")]: {
        width: 280,
      },
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 20,
      paddingRight: 30,
      paddingBottom: 20,
      borderRadius: 20,
    },
    cardtitle: {
      fontSize: 14,
    },
    linkbutton: {
      textDecoration: "none",
    },
    fab: {
      position: "fixed",
      top: 160,
      left: 30,
    },
    editor: {
      minHeight: 140,
    },
    grid: {
      marginBottom: 30,
    },
    time: {},
    over: {
      // backgroundColor: "#eeeeee",
    },
    col_over: {
      borderColor: "#eeeeee",
      borderWidth: 5,
      borderStyle: "dashed",
      borderRadius: 20,
    },
    hover_up: {
      top: 51,
      background: "linear-gradient(rgba(210,210,210,1),rgba(255,255,255,0))",
    },
    hover_down: {
      bottom: 0,
      background: "linear-gradient(rgba(255,255,255,0),rgba(200,200,200,1))",
    },
    backdrop: {
      zIndex: 999999,
      color: "#fff",
    },
  })
);
