import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core";

export const LinkButton = ({ children, to }: { children: any; to: string }) => {
  const style = useStyle();
  return (
    <Link to={to} className={style.linkbutton}>
      {children}
    </Link>
  );
};
const useStyle = makeStyles(() =>
  createStyles({
    linkbutton: {
      textDecoration: "none"
    }
  })
);
