import React, {
  useState,
  Fragment,
  useReducer,
  useRef,
  useEffect,
  useContext,
} from "react";
import "./css/App.css";
import MenuIcon from "@material-ui/icons/Menu";
import ListAlt from "@material-ui/icons/ListAlt";
import Settings from "@material-ui/icons/Settings";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import FaceIcon from "@material-ui/icons/Face";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  AppBar,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Theme,
  createStyles,
  Toolbar,
  IconButton,
  Grid,
  Container,
  ListItemIcon,
  Fab,
  ThemeProvider,
} from "@material-ui/core";
import { EditorArea, HelpEditorPage } from "./AppPages/Editor/EditorPage";
import { PagesList } from "./AppPages/PagesList/PagesList";
import { css } from "emotion";
import { Route, Switch, Link, useLocation, Redirect } from "react-router-dom";
import { SharePage } from "./AppPages/SharePage";
import { SettingPage } from "./AppPages/SettingPage/SettingPage";
import { TestCenterPage } from "./AppPages/Quizs/TestCenterPage";
import { QuizCollectionDetail } from "./AppPages/Quizs/QuizCollectionDetail";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useDivices } from "./lib/hooks/useDevices";
import { useMTheme } from "./lib/MTheme";
import { useAutoSync } from "./lib/hooks/useAutoSync";
import { EditorAppbar } from "./AppPages/Editor/AppBar";
import { useBlockEditor } from "./AppPages/Editor/useBlockEditor";
import { QuizCollectionPractice } from "./AppPages/Quizs/QuizCollectionPractice";
import { CardPage } from "./AppPages/Cards/CardPage";
import { cloneDeep } from "lodash";
import { LearnRoot, LearnAppBar } from "./AppPages/Learn/LearnRoot";
import {
  LearnAppBarProp,
  useLearnAppBar,
} from "./AppPages/Learn/useLearnAppState";
import { KnowledgeExplore } from "./AppPages/Learn/Explore/KnowledgeExplore";
import { Read } from "./AppPages/Read/Read";

import { CloseCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import { EnglishWordPage } from "./AppPages/EnglishWord/EnglishWordPage";
import ReceiptIcon from '@material-ui/icons/Receipt';

export const AppContext = React.createContext(
  {} as {
    refreashTheme: Function;
    routeScroll: React.MutableRefObject<Map<string, number>>;
    learn: LearnAppBarProp;
    clientWidth: number;
  }
);

export const useApp = () => {
  return useContext(AppContext);
};

export const ThemeContext = React.createContext(
  {} as {
    theme: Theme;
    background: string;
    background_dark: string;
    background_drawer: string;
    headtheme: Theme;
  }
);

const App: React.FC = () => {
  const style = useStyles();

  const editorRuntime = useBlockEditor();

  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  useAutoSync();

  const [doc_list_open_in_mobile, set_doc_list_open] = useState(false);

  const location = useLocation();

  const mtheme = useMTheme();

  const { whenDesktop, whenPad, whenMobile } = useDivices();

  const [clientWidth, setClientWidth] = useState(
    window.document.body.clientWidth
  );
  useEffect(() => {
    window.onresize = () => {
      setClientWidth(window.document.body.clientWidth);
    };
  }, [setClientWidth]);

  const page_lists = (
    <Fragment>
      {whenDesktop && (
        <Drawer
          variant="permanent"
          className={style.drawer}
          classes={{ paper: style.drawerPaper }}
        >
          <List
            style={{
              backgroundColor: mtheme.theme.background_drawer,
              height: "100%",
            }}
          >
            <div className={style.toolbar}></div>
            <PagesList runtime={editorRuntime} />
          </List>
        </Drawer>
      )}
      {(whenMobile || whenPad) && (
        <Fragment>
          <Fab
            className={style.doc_list_button}
            onClick={() => set_doc_list_open(true)}
          >
            <FormatListBulletedIcon />
          </Fab>
          <Drawer
            className={style.drawer}
            classes={{ paper: style.drawerPaper }}
            open={doc_list_open_in_mobile}
            onClose={() => set_doc_list_open(false)}
          >
            <List
              style={{
                backgroundColor: mtheme.theme.background_drawer,
                height: "100%",
              }}
            >
              <div className={style.toolbar}></div>
              <PagesList
                runtime={editorRuntime}
                onSelectDocCallback={() => {
                  //set_doc_list_open(false);
                }}
              />
            </List>
          </Drawer>
        </Fragment>
      )}
      <Route exact path="/edit/:id">
        <EditorArea classes={style} runtime={editorRuntime} />
      </Route>
      <Route exact path="/doc">
        <HelpEditorPage />
      </Route>
    </Fragment>
  );

  const routeScroll = useRef(new Map<string, number>());
  const learnAppBar = useLearnAppBar();

  if (location.pathname === "/") {
    // ! CONFIG: HomePage
    return <Redirect to="/learn" />;
  }
  const { myIpcRenderer } = window;
  return (
    <Fragment>
      <ThemeProvider theme={mtheme.theme.theme}>
        <ThemeContext.Provider value={mtheme.theme}>
          <AppContext.Provider
            value={{
              refreashTheme: mtheme.refreash,
              routeScroll: routeScroll,
              learn: learnAppBar,
              clientWidth: clientWidth,
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <div className={style.root}>
                <Switch>
                  <Route path="/">
                    <Fragment>
                      <ThemeProvider theme={mtheme.theme.headtheme}>
                        <AppBar
                          className={style.appBar}
                          position="fixed"
                          style={{
                            backgroundColor: mtheme.theme.background_dark,
                          }}
                        >
                          {myIpcRenderer && (
                            <div
                              className={"dragwindow"}
                              style={{
                                position: "fixed",
                                width: "100%",
                                height: 20,
                              }}
                            ></div>
                          )}
                          <Toolbar variant="dense">
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                            >
                              <Grid item sm={1}>
                                <IconButton
                                  edge="start"
                                  color="primary"
                                  aria-label="menu"
                                  onClick={() => {
                                    setMenuDrawerOpen(!menuDrawerOpen);
                                  }}
                                >
                                  <MenuIcon />
                                </IconButton>
                              </Grid>
                              <Route exact path="/share/:id">
                                <Grid item sm={11}>
                                  <EditorAppbar runtime={editorRuntime} />
                                </Grid>
                              </Route>
                              <Route exact path="/edit/:id">
                                <Grid item sm={11}>
                                  <EditorAppbar
                                    runtime={editorRuntime}
                                    editable
                                  />
                                </Grid>
                              </Route>
                              <Route exact path="/learn">
                                <Grid item sm={11}>
                                  <LearnAppBar />
                                </Grid>
                              </Route>
                            </Grid>
                          </Toolbar>
                          {myIpcRenderer && (
                            <Fragment>
                              <IconButton
                                className="nodrag"
                                style={{
                                  position: "fixed",
                                  right: 35,
                                  top: 5,
                                }}
                                onClick={() => {
                                  myIpcRenderer.send("APP_min");
                                }}
                                size="small"
                              >
                                <MinusCircleTwoTone
                                  style={{
                                    fontSize: "24px",
                                  }}
                                  twoToneColor="#bebebe"
                                />
                              </IconButton>
                              <IconButton
                                className="nodrag"
                                style={{
                                  position: "fixed",
                                  right: 5,
                                  top: 5,
                                }}
                                onClick={() => {
                                  myIpcRenderer.send("APP_close");
                                }}
                                size="small"
                              >
                                <CloseCircleTwoTone
                                  style={{
                                    fontSize: "24px",
                                  }}
                                  twoToneColor="#eb2f96"
                                />
                              </IconButton>
                            </Fragment>
                          )}
                        </AppBar>
                      </ThemeProvider>
                      <Route exact path="/share/:id">
                        <SharePage runtime={editorRuntime} classes={style} />
                      </Route>
                      {(location.pathname === "/doc" ||
                        location.pathname.indexOf("/edit/") === 0) &&
                        page_lists}

                      <Route path="/setting">
                        <SettingPage />
                      </Route>

                      <Route exact path="/quiz/:uuid/edit">
                        <QuizCollectionDetail />
                      </Route>
                      <Route exact path="/quiz/:uuid/practice">
                        <QuizCollectionPractice />
                      </Route>
                      <Route exact path="/quiz">
                        <TestCenterPage />
                      </Route>

                      <Route exact path="/cards/:cardid">
                        <CardPage />
                      </Route>

                      <Route exact path="/learn">
                        <LearnRoot />
                      </Route>
                      <Route exact path="/read/:path">
                        <Read />
                      </Route>

                      <Route exact path="/wordswall">
                        <EnglishWordPage />
                      </Route>

                      <Drawer
                        anchor="left"
                        open={menuDrawerOpen}
                        className={style.drawer + " drawer-link"}
                        classes={{ paper: style.drawerPaper }}
                        onClose={() => setMenuDrawerOpen(false)}
                      >
                        <List>
                          <div className={style.toolbar}></div>
                          <Link to="/doc">
                            <ListItem
                              button
                              key={"文档"}
                              onClick={() => setMenuDrawerOpen(false)}
                            >
                              <ListItemIcon>
                                <ListAlt></ListAlt>
                              </ListItemIcon>
                              <ListItemText primary={"文档"} />
                            </ListItem>
                          </Link>
                          <Link to="/learn">
                            <ListItem
                              button
                              key={"知识"}
                              onClick={() => setMenuDrawerOpen(false)}
                            >
                              <ListItemIcon>
                                <HourglassEmptyIcon></HourglassEmptyIcon>
                              </ListItemIcon>
                              <ListItemText primary={"知识"} />
                            </ListItem>
                          </Link>
                          <Link to="/wordswall">
                            <ListItem
                              button
                              key={"单词"}
                              onClick={() => setMenuDrawerOpen(false)}
                            >
                              <ListItemIcon>
                                <ReceiptIcon />
                              </ListItemIcon>
                              <ListItemText primary={"单词"} />
                            </ListItem>
                          </Link>
                          <Link to="/cards/root">
                            <ListItem
                              button
                              key={"灵感"}
                              onClick={() => setMenuDrawerOpen(false)}
                            >
                              <ListItemIcon>
                                <FaceIcon></FaceIcon>
                              </ListItemIcon>
                              <ListItemText primary={"灵感"} />
                            </ListItem>
                          </Link>
                          <Link to="/setting">
                            <ListItem
                              button
                              key={"设置"}
                              onClick={() => setMenuDrawerOpen(false)}
                            >
                              <ListItemIcon>
                                <Settings></Settings>
                              </ListItemIcon>
                              <ListItemText primary={"设置"} />
                            </ListItem>
                          </Link>
                        </List>
                      </Drawer>
                    </Fragment>
                  </Route>
                </Switch>
              </div>
              <Foot />
            </DndProvider>
          </AppContext.Provider>
        </ThemeContext.Provider>
      </ThemeProvider>
    </Fragment>
  );
};
export const drawerWidth = 240;
export const drawerHeight = 48;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //display: "flex"
    },
    appBar: {
      zIndex: 1298,
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      paddingLeft: drawerWidth,
    },
    mobile_context: {},
    toolbar: { minHeight: drawerHeight },
    doc_list_button: {
      bottom: theme.spacing(10),
      right: theme.spacing(2),
      position: "fixed",
      zIndex: 2001,
    },
  })
);
export default App;

const Foot = () => {
  return (
    <Grid
      container
      id="foot"
      alignItems="center"
      justify="center"
      className={css`
        padding: 30px 0px 0px 0px;
      `}
    >
      <Grid item>
        <Container>
          <div
            className={css`
              color: #939393;
              text-align: center;
            `}
          ><a href="https://beian.miit.gov.cn/" target="_blank" className={css`
          color: #939393;
        `}>浙ICP备20003979号-1</a>
          </div>
        </Container>
        <Container>
          <div
            className={css`
              width: 300px;
              margin: 0 auto;
              padding: 20px 0;
              text-align: center;
            `}
          >
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202001616"
              className={css`
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              `}
            >
              <img
                src="./备案图标.png"
                className={css`
                  float: left;
                `}
              />
              <p
                className={css`
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: #939393;
                `}
              >
                浙公网安备 33010202001616号
              </p>
            </a>
          </div>
        </Container>
        <Container>
          <div
            className={css`
              color: #939393;
              text-align: center;
            `}
          >
            support@zhishudali.ink
          </div>
        </Container>
        <Container>
          <div
            className={css`
              color: #939393;
              text-align: center;
            `}
          >
            © 2020 - 2022 zhishudali.ink. All Rights Reserved
          </div>
        </Container>
        <Container>
          <div
            className={css`
              color: #939393;
              text-align: center;
              height: 30px;
            `}
          ></div>
        </Container>
      </Grid>
    </Grid>
  );
};
