import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { TopicMNode } from "./TopicMNode";
import "./css/MindMap.css";
import { useInterval, useUpdate, useUpdateI } from "../../../lib/CommonHook";

interface MindMapProp {
  rootUUID: string;
  selectKnowledge: string;
  setSelectKnowledge: (s: string) => void;
}

interface ITMContext {
  addLine: (k: Symbol, v: any) => void;
  removeLine: (k: any) => void;
  selectKnowledge: string;
  setSelectKnowledge: (s: string) => void;
}
const TopicMindMapContext = React.createContext<ITMContext>({} as any);
export const useTopicMindMap = () => {
  return useContext(TopicMindMapContext);
};
export const TopicMindMap: React.FC<MindMapProp> = ({
  rootUUID,
  selectKnowledge,
  setSelectKnowledge,
}) => {
  // 保存组件-线的映射
  const lines = useRef(new Map());

  // 储存线的列表
  let lines_paths = useRef([] as any[]);

  // 因为线的更新导致渲染刷新标记
  const refreachOfLine = useRef(true);

  const update = useUpdate();

  // 更新线
  const addLine = useCallback(
    (k: Symbol, v: any) => {
      lines.current.set(k, v);
      refreachOfLine.current = true;
      update.update();
    },
    [update]
  );

  // 删除线
  const removeLine = useCallback(
    (k: any) => {
      lines.current.delete(k);
      refreachOfLine.current = true;
      update.update();
    },
    [update]
  );

  const updateI = useUpdateI();

  useInterval(() => {
    if (refreachOfLine.current) {
      updateI.update();
    }
  }, 200);

  // 将线的映射转化成列表
  if (refreachOfLine.current) {
    let newpaths: any[] = [];
    lines.current.forEach((v, k) => {
      newpaths.push({ key: k, value: v });
    });
    lines_paths.current = newpaths;
    refreachOfLine.current = false;
  }

  //style={{ overflow: "auto", position: "relative" }}
  return (
    <div className="t-main-mind-master" style={{ position: "relative" }}>
      <div>
        <TopicMindMapContext.Provider
          value={{ addLine, removeLine, selectKnowledge, setSelectKnowledge }}
        >
          <TopicMNode knowledgeUUID={rootUUID} depth={0} />
        </TopicMindMapContext.Provider>
      </div>
      <TpoicMindMapCanvas lines_paths={lines_paths}></TpoicMindMapCanvas>
    </div>
  );
};

// 绘制线条的Canvas
export const TpoicMindMapCanvas = ({ lines_paths }: { lines_paths: any }) => {
  // 储存画布对象
  const can = useRef() as React.MutableRefObject<HTMLCanvasElement>;
  const [can_width, setCanW] = useState(0);
  const [can_height, setCanH] = useState(0);
  // 时刻更新
  useInterval(() => {
    if (can.current !== undefined && can.current !== null) {
      let max_x = 0,
        max_y = 0;
      lines_paths.current.forEach((rv: any) => {
        const v = rv.value;
        //绘制3次贝塞尔曲线，若子或父无效则不绘制
        if (
          v.s_v ||
          v.e_v ||
          v.startref === undefined ||
          v.endref === undefined ||
          v.startref.current === null ||
          v.endref.current === null
        ) {
        } else {
          let s = v.endref.current;
          const cp = {
            x: s.offsetLeft,
            y: s.offsetTop + s.offsetHeight / 2,
          };
          if (cp.x > max_x) max_x = cp.x;
          if (cp.y > max_y) max_y = cp.y;
        }
      });

      // 更新画布，随着node的大小改变而改变
      can.current.width = Math.max(can.current.offsetWidth, max_x);
      can.current.height = Math.max(can.current.offsetHeight, max_y);
      if (can_width !== can.current.width) setCanW(can.current.width);
      if (can_height !== can.current.height) setCanH(can.current.height);
      const ctx = can.current.getContext("2d") as CanvasRenderingContext2D;
      lines_paths.current.forEach((rv: any) => {
        const v = rv.value;
        //绘制3次贝塞尔曲线，若子或父无效则不绘制
        if (
          v.s_v ||
          v.e_v ||
          v.startref === undefined ||
          v.endref === undefined
        ) {
          return null;
        } else {
          let p = v.startref.current;
          if (p === null) return;
          const pp = {
            x: p.offsetLeft + p.offsetWidth,
            y: p.offsetTop + p.offsetHeight / 2,
          };
          let s = v.endref.current;
          if (s === null) return;
          const cp = {
            x: s.offsetLeft,
            y: s.offsetTop + s.offsetHeight / 2,
          };

          ctx.beginPath();
          ctx.moveTo(pp.x, pp.y);
          ctx.bezierCurveTo(pp.x + 20, pp.y, cp.x - 20, cp.y, cp.x, cp.y);

          // * 在此设定绘制样式
          ctx.strokeStyle = "rgb(160, 160, 160)";
          ctx.lineWidth = 2;
          ctx.stroke();
          ctx.closePath();
        }
      });
    }
  }, 50);

  return (
    <canvas
      className="t-mind-map-canvas"
      ref={can}
      style={{ width: can_width, height: can_height }}
    ></canvas>
  );
};
