import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import {
  useLearnApp,
  changeLearnStep,
  LearnStep,
  setTopicRoot,
} from "../useLearnAppState";
import { TopicMindMap } from "../mindmap/TopicMindMap";
import { Fab, Fade } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

export const LearnChooseTopic = () => {
  const learnApp = useLearnApp();
  const rootID =
    learnApp.plan.planList[learnApp.state.currentSubjectIndex]
      .subjectKnowledgeCollectionUUID;
  const [selectKnowledge, setSelectKnowledge] = useState("");

  return (
    <Box>
      {rootID && (
        <TopicMindMap
          rootUUID={rootID}
          selectKnowledge={selectKnowledge}
          setSelectKnowledge={setSelectKnowledge}
        />
      )}
      <Fade in={selectKnowledge !== "" || rootID === undefined}>
        <Fab
          color="primary"
          style={{ position: "fixed", bottom: 50, left: "50%", zIndex: 3000 }}
          onClick={() => {
            if (selectKnowledge === "") {
                learnApp.func.changeState?.(changeLearnStep(LearnStep.Practice));
            } else {
              learnApp.func.changeState?.(
                setTopicRoot(
                  selectKnowledge
                )
              );
              learnApp.func.changeState?.(changeLearnStep(LearnStep.Learning));
            }
          }}
        >
          <DoneIcon />
        </Fab>
      </Fade>
    </Box>
  );
};
