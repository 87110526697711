import React, { Fragment } from "react";
import {
  Paper,
  Typography,
  Container,
  TextField,
  CircularProgress,
  Grid
} from "@material-ui/core";
import { BlockEditor, useBlockEditorStyle } from "../elements/BlockEditor";
import { PageDataToMindMap } from "./Editor/EditorPage";
import { useParams } from "react-router-dom";
import { useRequest } from "@umijs/hooks";
import { TabPanel } from "../AppCom/TabPanel";
import { MindMap } from "../elements/mindmap/MindMap";
import { BlockEditorRuntime } from "./Editor/useBlockEditor";
import {
  getObjectStore,
  getDatabase,
  StoreName
} from "../lib/Database/Database";

export const SharePage = ({
  runtime,
  classes
}: {
  runtime: BlockEditorRuntime;
  classes: any;
}) => {
  let { id } = useParams();
  const { data, loading, error } = useRequest(async () => {
    let doc = await getDoc(id);
    runtime.setTitle(doc.fcb.title);
    runtime.setCurrentDoc(doc.page);
    runtime.set_current_uuid(doc.page.uuid);

    const db = await getDatabase();
    if (
      !(await (await getObjectStore(db, StoreName.fcb_pages)).get(doc.fcb.uuid))
    ) {
      await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).put(
        doc.fcb
      );
      await (await getObjectStore(db, StoreName.pages, "readwrite")).put(
        doc.page
      );
    }
    //let missMedia = [];
    //if (doc.media) {
    //  for (let i = 0; i < doc.media.length; i++) {
    //    const id = doc.media[i];
    //    if (!(await (await getObjectStore(db, StoreName.media)).get(id))) {
    //      missMedia.push(id);
    //    }
    //  }
    //}
    //if (missMedia.length > 0) {
    //  let getMedia = JSON.stringify({
    //    media: missMedia
    //  });
    //
    //  let response = await fetch("https://zhishudali.ink/api/getmedia", {
    //    method: "post",
    //    headers: {
    //      "Content-Type": "application/json"
    //    },
    //    body: getMedia
    //  });
    //  if (response) {
    //    let re = await response.json();
    //    if (re) {
    //      for (let i = 0; i < re.body.media.length; i++) {
    //        const m = re.body.media[i];
    //        await (await getObjectStore(db, StoreName.media, "readwrite")).put(
    //          m
    //        );
    //      }
    //    }
    //  }
    //}

    return doc;
  });
  const style = useBlockEditorStyle();
  if (loading) {
    return (
      <Paper className={style.paper} style={{ backgroundColor: "#f0f0f5" }}>
        <Grid
          container
          className={style.paper}
          alignContent="center"
          justify="center"
        >
          <Grid item>
            <Container>
              <CircularProgress />
            </Container>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  if (error) {
    return (
      <Paper className={style.paper} style={{ backgroundColor: "#f0f0f5" }}>
        <Grid
          container
          className={style.paper}
          alignContent="center"
          justify="center"
        >
          <Grid item>
            <Container>
              <Typography>404</Typography>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return (
    <Paper>
      <Fragment>
        <TabPanel value={runtime.editor_tab_value} index={0}>
          <div className={classes.toolbar}></div>
          <Paper>
            <div className={style.editor}>
              <BlockEditor
                mvalue={data.page}
                setValue={() => {}}
                readonly
                share
              />
            </div>
          </Paper>
        </TabPanel>
        <TabPanel value={runtime.editor_tab_value} index={1}>
          <div className={classes.toolbar}></div>
          <Paper className={`mind-map-paper`}>
            <div className="mind-map-top">
              <Container>
                <MindMap
                  value={PageDataToMindMap(data.page.document)}
                  title={data.fcb.title}
                  docuuid={runtime.current_doc_uuid}
                />
              </Container>
            </div>
          </Paper>
        </TabPanel>
      </Fragment>
    </Paper>
  );
};

async function getDoc(id: string | undefined) {
  if (!id) throw "unknown page";
  let getdata = await fetch(`https://zhishudali.ink/api/getshare?doc=${id}`);
  let strdataStr = await getdata.json();

  if (strdataStr && strdataStr.body) {
    let data = JSON.parse(strdataStr.body);
    return data;
  }
  throw "error page";
}

export const ShareAppBar = ({ runtime }: { runtime: BlockEditorRuntime }) => {
  return (
    <TextField
      label="标题"
      defaultValue={runtime.title}
      InputProps={{
        readOnly: true
      }}
      variant="filled"
    />
  );
};
