import { useState, useCallback } from "react";
import { useRequest } from "@umijs/hooks";

export function useLocalStorage<T>(name: string, defaultValue: T) {
  const [state, _setState] = useState(() => {
    let g = window.localStorage.getItem(name);
    let res = defaultValue;
    if (g !== null && g !== "") res = JSON.parse(g);
    return res;
  });
  const { run: saveToLocal } = useRequest(
    async (value: T) => {
      window.localStorage.setItem(name, JSON.stringify(value));
    },
    {
      throttleInterval: 1000,
      manual: true,
    }
  );
  const setState = useCallback(
    (value: T) => {
      _setState(value);
      saveToLocal(value);
    },
    [_setState, saveToLocal]
  );
  return { state, setState };
}
