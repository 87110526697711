import { Transforms, Editor, Range, Point } from "slate";
import { isTitle, isElementStart } from "./withBlockDefault";

// 定义简写
const SHORTCUTS: { [key: string]: string } = {
  "*": "list-item",
  "-": "list-item",
  "+": "check-list-item",
  ">": "block-quote",
  "#": "H1",
  "##": "H2",
  "###": "H3",
  "####": "H4",
  "#####": "H5",
  "######": "H6",
  "#1": "H1",
  "#2": "H2",
  "#3": "H3",
  "#4": "H4",
  "#5": "H5",
  "#6": "H6",
  "```": "code-area",
  //$$: "math-line",
  //"￥￥": "math-line",
};

export const withShortcuts = (editor: Editor) => {
  const { deleteBackward, insertText, insertBreak, normalizeNode } = editor;

  editor.insertText = (text: string) => {
    const { selection } = editor;
    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      });
      if (block) {
        const path = block ? block[1] : [];
        const start = Editor.start(editor, path);
        const range = { anchor, focus: start };
        const beforeText = Editor.string(editor, range);
        const type = SHORTCUTS[beforeText];

        if (type) {
          Transforms.delete(editor, { at: range });
          Transforms.setNodes(
            editor,
            { type: type },
            { match: (n) => Editor.isBlock(editor, n) }
          );

          if (type === "list-item") {
            const list = {
              type: beforeText === "*" ? "bulleted-list" : "ol",
              children: [],
            };
            Transforms.wrapNodes(editor, list, {
              match: (n) => n.type === "list-item",
            });
          }

          return;
        }
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (block.type !== "ph" && Point.equals(selection.anchor, start)) {
          if (block.type === "list-item") {
            //Transforms.delete(editor, { distance: 1, unit: "character" });
            //return;
            const [list] = Editor.nodes(editor, {
              at: path,
              match: (n) => n.type === "ol" || n.type === "bulleted-list",
            });
            if (list) {
              if (list[0].children.length === 1) {
                Transforms.delete(editor, {
                  at: list[1],
                });
                return;
              }
            }
          }
          if (block.type === "math-line") {
            Transforms.unwrapNodes(editor, {
              at: path,
              match: (n) => n.type === "math-line",
            });
            return;
          }
        }

        if (
          isTitle(block.type) &&
          editor.selection &&
          isElementStart(editor.selection.anchor)
        ) {
          //Transforms.setNodes(editor, { ...block, type: "ph" });
          //return;
        }
      }
    }
    deleteBackward(...args);
  };

  editor.insertBreak = () => {
    if (editor.selection && Range.isCollapsed(editor.selection)) {
      let [node] = Editor.nodes(editor, {
        match: (n) =>
          n.type === "list-item" &&
          n.children.length === 1 &&
          n.children[0].text === "",
      });
      if (node) {
        let path = node[1];
        if (path.length > 2) {
          // 对子列表
          let gparent = Editor.node(editor, path.slice(0, path.length - 2));
          if (gparent && gparent[0].type === "list-item") {
            let parent = Editor.node(editor, path.slice(0, path.length - 1));
            if (
              parent &&
              parent[0].children.length - 1 === path[path.length - 1]
            ) {
              let lastone = node[0];
              Transforms.delete(editor, { unit: "line", reverse: true });
              let p = gparent[1].slice(0, gparent[1].length);
              p[p.length - 1]++;
              Transforms.insertNodes(
                editor,
                { type: "list-item", children: lastone.children },
                { at: p }
              );
              Transforms.move(editor, { distance: 1, unit: "offset" });

              return;
            }
          }
        } else {
          // 对于最外层列表
          let list = Editor.node(editor, path.slice(0, 1));
          if (list) {
            let me = node[0];
            if (path[path.length - 1] + 1 === list[0].children.length) {
              let nd = me;
              Transforms.delete(editor, { unit: "line", reverse: true });
              let p = path.slice(0, path.length - 1);
              p[p.length - 1]++;
              Transforms.insertNodes(
                editor,
                { children: nd.children, type: "ph" },
                {
                  at: p,
                }
              );
              Transforms.move(editor, { distance: 1, unit: "offset" });
              return;
            }
          }
        }
      }
    }
    insertBreak();
  };

  editor.normalizeNode = (entry) => {
    if (entry[0].type === "list-item") {
      if (entry[0].children.length > 0 && entry[0].children[0].type === "ph") {
        Transforms.unwrapNodes(editor, { at: [...entry[1], 0] });
        return;
      }
    }
    //if (entry[0].type === "list-item") {
    //  const [list] = Editor.nodes(editor, {
    //    at: entry[1],
    //    match: n => n.type === "ol" || n.type === "bulleted-list"
    //  });
    //  if (!list) {
    //    Transforms.unwrapNodes(editor, {
    //      at: entry[1],
    //      match: n => n.type === "list-item"
    //    });
    //    return;
    //  }
    //}
    if (entry[0].type === "math-line") {
      let p = [...entry[1]];
      p[0] = p[0] + 1;
      let [nextline] = Editor.nodes(editor, {
        at: p,
        match: (n) => n.type === "ph",
      });
      if (!nextline) {
        Transforms.insertNodes(
          editor,
          { type: "ph", children: [{ text: "" }] },
          { at: p }
        );
        return;
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

const INLINE_SHORTCUTS: { [key: string]: string } = {
  $$: "math-inline",
  '￥￥': "math-inline",
};

export const withInlineShortcuts = (editor: Editor) => {
  const { insertText, deleteBackward } = editor;

  editor.insertText = (text: string) => {
    const { selection } = editor;
    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      if (anchor.offset >= 2) {
        const ns = Editor.nodes(editor, {
          match: (n) => n.type === "math-line" || n.type === "math-inline",
          at: Editor.path(editor, anchor),
        });

        // 检查是否有数学，禁止在其中插入
        let flagOfBreak = false;
        for (const n of ns) {
          const na = n as any;

          if (na[0].type === "math-line" || na[0].type === "math-inline") {
            flagOfBreak = true;
            break;
          }
        }

        if (!flagOfBreak) {
          const before = Editor.before(editor, anchor, {
            distance: 2,
          }) as Point;
          const range = { anchor, focus: before };
          const beforeText = Editor.string(editor, range);
          const type = INLINE_SHORTCUTS[beforeText];
          if (type) {
            Transforms.select(editor, range);
            Transforms.delete(editor);
            Transforms.insertNodes(editor, {
              type: type,
              children: [{ text: " " }],
            });
            const a2 = editor.selection?.anchor;
            if(type === "math-inline"){
              const { myIpcRenderer } = window;
              myIpcRenderer?.send("APP_MATH_BEGIN")
            }
            if (a2 !== null) {
              const before2 = Editor.before(
                editor,
                editor.selection?.anchor as Point,
                {
                  distance: 1,
                }
              ) as Point;
              const range2 = { anchor: before2, focus: before2 };
              Transforms.select(editor, range2);
              return;
            }
          }
        }
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isInline(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);
        if (
          block.type === "math-inline" &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: "ph" });
          Transforms.unwrapNodes(editor, {
            match: (n) => n.type === "math-inline",
          });
          return;
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};
