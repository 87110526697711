import { Editor, Transforms } from "slate";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Input,
} from "@material-ui/core";
import React, { useState } from "react";
import { cloneDeep } from "lodash";
import { ISelectListAction } from "./withSelectInsert";
import { MediaAsset } from "../../lib/Database/DataInterface";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import { css } from "emotion";
import md5 from "blueimp-md5";

export const ImagePlugin = {
  insertImage: async (
    editor: Editor,
    url: string,
    urlType: string,
    position: any
  ) => {
    const text = { text: "" };
    const asset = {
      uuid: md5(url),
      url: url,
      type: "img",
      origin: urlType,
    } as MediaAsset;

    const image = {
      type: "image",
      assetID: md5(url),
      children: [text],
    };

    const db = await getDatabase();
    await (await getObjectStore(db, StoreName.media, "readwrite")).put(asset);

    Transforms.insertNodes(
      editor,
      [image, { type: "ph", children: [{ text: "" }] }],
      { at: position }
    );
  },
  insertImageHelper: (editor: Editor, addition: any): ISelectListAction => {
    let add = cloneDeep(addition);
    add.dialogOpen = true;
    let res = { type: "image", newAddition: add };
    return res;
  },
  openInsertUrlDialog: () => {},
};

export const ImageURLDialog = ({
  open,
  handleClose,
  confirm,
}: {
  open: boolean;
  handleClose: any;
  confirm: Function;
}) => {
  const [url, setUrl] = useState("");
  const [blob, setBlob] = useState();
  const [urlType, setURLType] = useState("remote");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>插入图片</DialogTitle>
      <DialogContent>
        <DialogContentText>请输入图片链接</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="url"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setUrl(e.target.value);
            setURLType("remote");
          }}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>或使用本地图片</DialogContentText>
        <Button variant="outlined" color="default">
          <Input
            className={css`
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0
              backgroung-color: transparent;
            `}
            type="file"
            onChange={(e: any) => {
              let file = e.target.files[0];
              if (!!file) {
                let reader = new FileReader();
                reader.onload = async (e) => {
                  let data = e.target?.result as string;
                  if (!!data) {
                    setUrl(data);
                  }
                };
                reader.readAsDataURL(file);
                setBlob(file);
                setURLType("local");
              }
            }}
          />
          {blob ? "已选择本地图片" : "选择本地图片"}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          取消
        </Button>
        <Button onClick={() => confirm(url, urlType)} color="primary">
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useImage = () => {
  const [showImageURLDialog, setShowImageURLDialog] = useState(false);

  return {
    showImageURLDialog,
    setShowImageURLDialog,
  };
};
