import { Paper, Tab, Tabs, Typography, Box } from "@material-ui/core";
import React, { useContext, Fragment, useMemo, useEffect } from "react";
import { usePageStyle } from "../PageStyle";
import { ThemeContext, AppContext } from "../../App";
import {
  LearnAppContext,
  useLearnAppState,
  useLearnAppPlan,
  TabStep,
  LearnAppFunc,
  getLearnFunc,
  LearnStep,
} from "./useLearnAppState";
import { TabPanel, If } from "../../AppCom/TabPanel";
import { LearnProc } from "./LearnProc";
import { KnowledgePage } from "./Knowledge/KnowledgePage";
import { TestCenterPage } from "../Quizs/TestCenterPage";
import { LearnPlanPage } from "./LearnPlanPage";

export const LearnAppBar = () => {
  const ctx = useContext(AppContext);
  // ! debug
  // useEffect(() => {
  //     ctx.learn.setCurrentTab(TabStep.Knowledge)
  // }, [])
  return (
    <Tabs
      value={ctx.learn.currentTab}
      onChange={(_, nv) => ctx.learn.setCurrentTab(nv)}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="知识" value={TabStep.Knowledge} />
      {
        /**
          <Tab label="学习" value={TabStep.Learn} />
          <Tab label="题集" value={TabStep.Quiz} />
          <Tab label="计划" value={TabStep.Plan} />
         */
      }
   
    </Tabs>
  );
};

export const LearnRoot = () => {
  const paper_style = usePageStyle();
  const mtheme = useContext(ThemeContext);
  const learnCtx = useLearnAppState();
  const learnPlan = useLearnAppPlan();
  const appCtx = useContext(AppContext);
  const func = useMemo(() => {
    let a = getLearnFunc();
    a.changeState = learnCtx.dispatch;
    a.changePlan = learnPlan.dispatch;
    return a;
  }, [learnCtx.dispatch, learnPlan.dispatch]);

  const valid = learnCtx.data !== undefined && learnPlan.data !== undefined;
  return (
    <Box
      className={paper_style.body}
      style={{ backgroundColor: mtheme.background }}
    >
      {learnCtx.data && learnPlan.data && (
        <LearnAppContext.Provider
          value={{
            valid: valid,
            state: learnCtx.data,
            plan: learnPlan.data,
            func: func,
          }}
        >
          <If exp={valid}>
            <TabPanel index={TabStep.Learn} value={appCtx.learn.currentTab}>
              <LearnProc />
            </TabPanel>
            <TabPanel index={TabStep.Knowledge} value={appCtx.learn.currentTab}>
              {learnCtx.data && <KnowledgePage />}
            </TabPanel>
            <TabPanel index={TabStep.Quiz} value={appCtx.learn.currentTab}>
              <TestCenterPage />
            </TabPanel>
            <TabPanel index={TabStep.Plan} value={appCtx.learn.currentTab}>
              <LearnPlanPage />
            </TabPanel>
          </If>
        </LearnAppContext.Provider>
      )}
    </Box>
  );
};
