import { useRef, useEffect } from "react";
import { useSlate, ReactEditor } from "slate-react";
import { Editor, Range } from "slate";
import React from "react";
import { css } from "emotion";
import { Button, Icon, Menu, Portal } from "./components.js";
import { EditorMethods } from "./EditorMethods";
import "./css/toolbar.css";
import { KeyWordPlugin } from "./plugins/withKeyWord";

export const HoveringToolbar = () => {
  const ref = useRef();
  const editor = useSlate();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection?.getRangeAt(0);
    const rect = domRange?.getBoundingClientRect();
    if (!!rect) {
      el.style.opacity = 1;
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${rect.left +
        window.pageXOffset -
        el.offsetWidth / 2 +
        rect.width / 2}px`;
    }
  });

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 10000;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: #222;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
      >
        <FormatButton format="bold" icon=" B " />
        <FormatButton format="italic" icon=" I " />
        <FormatButton format="underlined" icon=" U " />
        <ElementButton
          icon="KL"
          active={KeyWordPlugin.isKeyLine}
          toggle={KeyWordPlugin.toggleKeyLine}
        />
        <ElementButton
          icon="K1"
          active={e => KeyWordPlugin.isKeyWord(e, 1)}
          toggle={e => KeyWordPlugin.toggleKeyWord(e, editor,  1)}
        />
        <ElementButton
          icon="K2"
          active={e => KeyWordPlugin.isKeyWord(e, 2)}
          toggle={e => KeyWordPlugin.toggleKeyWord(e, editor, 2)}
        />
      </Menu>
    </Portal>
  );
};

const FormatButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      reversed
      active={EditorMethods.isFormatActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        EditorMethods.toggleFormat(editor, format);
      }}
    >
      <Icon className="tool-bar-button">{icon}</Icon>
    </Button>
  );
};

const ElementButton = ({ icon, active, toggle }) => {
  const editor = useSlate();
  return (
    <Button
      reversed
      active={active(editor)}
      onMouseDown={event => {
        event.preventDefault();
        toggle(editor);
      }}
    >
      <Icon className="tool-bar-button">{icon}</Icon>
    </Button>
  );
};
