import { Node } from "slate";

export const testValueData = [
  { type: "H1", children: [{ text: "This is Title" }] },
  {
    type: "ph",
    children: [{ text: "A newwwww line of text in a ph." }],
  },
  { type: "H1", children: [{ text: "This is next Title" }] },
  { type: "H2", children: [{ text: "This is p2" }] },
  { type: "H2", children: [{ text: "This is p2 2" }] },
  { type: "H3", children: [{ text: "This is p3" }] },
  { type: "H3", children: [{ text: "This is p3 2" }] },
  {
    type: "ph",
    children: [{ text: "helloworld." }],
  },
  { type: "H2", children: [{ text: "This is p2 3" }] },
];

export const emptyValue = () => {
  return JSON.parse(
    JSON.stringify([
      { type: "H1", children: [{ text: "新的文档" }] },
      { type: "ph", children: [{ text: "" }] },
    ])
  );
};
export const initValueData = (): Node[] => [
  { type: "H1", children: [{ text: "" }] },
];

export const emptyValueData = (): Node[] => [
  { type: "ph", children: [{ text: "" }] },
];
