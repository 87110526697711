import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  TextField,
  makeStyles,
  createStyles,
  Typography,
  Theme,
  Divider,
  Link,
  Input,
  Tabs,
  Tab,
} from "@material-ui/core";
import { ElementProp } from "../common/eleCommon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Editor, Transforms } from "slate";
import { css } from "emotion";
import md5 from "blueimp-md5";
import { MediaAsset } from "../../lib/Database/DataInterface";
import {
  getObjectStore,
  getDatabase,
  StoreName,
  FetchMedia,
} from "../../lib/Database/Database";
import { TabPanel } from "../../AppCom/TabPanel";
import { useAlive } from "../../lib/CommonHook";
import {
  ArrayBuffer2String,
  String2ArrayBuffer,
} from "../../AppPages/SettingPage/Common";
import Pdf from "@mikecousins/react-pdf";
import { useDivices } from "../../lib/hooks/useDevices";
import { useSelected, useFocused } from "slate-react";
import { DocContext } from "../BlockEditor";
import { useAlwaysUseBrowserPdfView } from "../../AppPages/SettingPage/PreferenceCard";
import { useRequest } from "@umijs/hooks";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    nonEditable: {
      userSelect: "none",
    },
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

export const LinkCardElement = ({
  children,
  element,
  attributes,
}: ElementProp) => {
  const [open, setOpen] = useState(false);
  const style = useStyle();
  const [asset, setAsset]: [any, Function] = useState();
  const divice = useDivices();
  const alive = useAlive();
  const doc = useContext(DocContext);
  const getting = useRef(false);
  const alwaysUseBrowserPdfView = useAlwaysUseBrowserPdfView();
  const { data } = useRequest(
    async () => {
      getting.current = true;
      const db = await getDatabase();
      let pdf: MediaAsset = await (
        await getObjectStore(db, StoreName.media)
      ).get(element.assetID);

      if (!pdf && doc.share) {
        await FetchMedia(element.assetID);
      }
      getting.current = false;
      if (open && !getting.current && !asset && element.link_type === "pdf") {
        const getPdf = async () => {
          getting.current = true;
          try {
            const db = await getDatabase();
            let pdf: MediaAsset = await (
              await getObjectStore(db, StoreName.media)
            ).get(element.assetID);

            if (pdf.data) {
              let b = new Blob([String2ArrayBuffer(pdf.data)], {
                type: "application/pdf",
              });
              pdf.url = window.URL.createObjectURL(b);
            }
            getting.current = false;
            if (alive.current) setAsset(pdf);
            return pdf;
          } catch (error) {
            getting.current = false;
          }
        };
        return await getPdf();
      }
    },
    { refreshDeps: [open] }
  );

  const selected = useSelected();
  const focused = useFocused();
  return (
    <div
      {...attributes}
      style={{
        position: "relative",
        boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
      }}
      data-slate-editor
      contentEditable={false}
    >
      <ExpansionPanel
        expanded={open}
        onChange={() => setOpen(!open)}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={style.heading}>{element.summary}</Typography>
          <Typography className={style.secondaryHeading}>
            {element.link_type === "link" && (
              <Link href={element.link}>{element.link}</Link>
            )}
          </Typography>
          {children}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Divider />
          <Grid container>
            <Grid item>
              <Typography>{element.detail}</Typography>
            </Grid>
            <Grid item xs={12}>
              {element.link_type === "link" && (
                <div>
                  <iframe
                    src={element.link}
                    width="100%"
                    height="800px"
                    style={{ minWidth: 800, userSelect: "none" }}
                    contentEditable={false}
                  ></iframe>
                </div>
              )}
              {!getting.current ? (
                element.link_type === "pdf" && asset ? (
                  <div>
                    {divice.whenDesktop || alwaysUseBrowserPdfView.value ? (
                      <embed
                        src={asset.url + "#view=FitH"}
                        width="100%"
                        height="900px"
                      ></embed>
                    ) : (
                      <PdfViewer pdf_path={asset.url} />
                    )}
                  </div>
                ) : (
                  "下载资源中"
                )
              ) : (
                "?"
              )}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
//

export const LinkCardPlugin = {
  Insert: async (
    editor: Editor,
    summary: string,
    link: string,
    detail: string,
    type: "link" | "pdf" | string,
    position: any,
    blob?: ArrayBuffer
  ) => {
    switch (type) {
      case "link":
        if (!link.startsWith("http://") && !link.startsWith("https://"))
          link = "http://" + link;
        Transforms.insertNodes(
          editor,
          [
            {
              type: "link-card",
              summary: summary,
              link: link,
              detail: detail,
              link_type: type,
              children: [{ text: "" }],
            },
            { type: "ph", children: [{ text: "" }] },
          ],
          { at: position }
        );
        break;
      case "pdf":
        let data = ArrayBuffer2String(blob || new ArrayBuffer(0));
        if (blob) {
          let u8 = new Uint8Array(blob);
          let u82 = new Uint8Array(String2ArrayBuffer(data));
          for (let i = 0; i < u8.length; i++) {
            const element = u8[i];
            if (element !== u82[i]) console.log(`i=${i} v=${element}`);
            break;
          }
          console.log("complete");
        }

        let asset: MediaAsset = {
          uuid: md5(blob ? data : link),
          url: link,
          type: "pdf",
          origin: "local",
          data: data,
        };

        await (
          await getObjectStore(
            await getDatabase(),
            StoreName.media,
            "readwrite"
          )
        ).put(asset);
        Transforms.insertNodes(
          editor,
          [
            {
              type: "link-card",
              summary: summary,
              assetID: asset.uuid,
              detail: detail,
              link_type: type,
              children: [{ text: "" }],
            },
            { type: "ph", children: [{ text: "" }] },
          ],
          { at: position }
        );
        break;
      default:
        break;
    }
  },
};

interface AddLinkCardDialogProp {
  open: boolean;
  onCancel: () => void;
  onConfirm: (
    summary: string,
    link: string,
    detail: string,
    type: string,
    blob?: ArrayBuffer
  ) => void;
}
export const AddLinkCardDialog = (prop: AddLinkCardDialogProp) => {
  const [sum, setSum] = useState("");
  const [link, setLink] = useState("");
  const [detail, setDetail] = useState("");
  const [type, setType] = useState("link");
  const [tab, setTab] = useState("link");
  const onCancel = useCallback(() => {
    prop.onCancel();
  }, [prop]);

  const [blob, setBlob] = useState();

  const onConfirm = useCallback(
    (summary: string, link: string, detail: string, ttype: string) => {
      prop.onConfirm(summary, link, detail, ttype, blob);
    },
    [prop, blob]
  );

  return (
    <Dialog
      open={prop.open}
      onClose={() => {
        onCancel();
      }}
    >
      <DialogTitle>插入链接卡片</DialogTitle>
      <DialogActions>
        <Grid container spacing={2} justify="space-evenly">
          <Grid item>
            <TextField
              variant="outlined"
              label="简称"
              onChange={(e) => setSum(e.target.value)}
              value={sum}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              multiline
              label="详细信息"
              onChange={(e) => setDetail(e.target.value)}
              value={detail}
            />
          </Grid>
        </Grid>
      </DialogActions>
      <DialogContent>
        <Tabs
          value={tab}
          onChange={(e, nv) => {
            setTab(nv);
          }}
        >
          <Tab value="link" label="引用网页" />
          <Tab value="pdf" label="引用PDF" />
        </Tabs>
        <TabPanel value={tab} index="link">
          <TextField
            variant="outlined"
            label="链接"
            onChange={(e) => {
              setType("link");
              setLink(e.target.value);
            }}
            value={link}
          />
        </TabPanel>
        <TabPanel value={tab} index="pdf">
          <Button variant="outlined" color="default">
            <Input
              className={css`
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0
              backgroung-color: transparent;
            `}
              type="file"
              onChange={(e: any) => {
                let file = e.target.files[0];
                if (!!file) {
                  let reader = new FileReader();
                  reader.onload = async (e) => {
                    let data = e.target?.result as ArrayBuffer;
                    if (!!data) {
                      setLink("");
                      setBlob(data as any);
                      setType("pdf");
                    }
                  };
                  reader.readAsArrayBuffer(file);
                }
              }}
            />
            {blob ? "已选择本地PDF" : "选择本地PDF"}
          </Button>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-evenly">
          <Grid item>
            <Button variant="outlined" onClick={() => onCancel()}>
              取消
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onConfirm(sum, link, detail, type)}
            >
              确认
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogContent></DialogContent>
    </Dialog>
  );
};

const PdfViewer = ({ pdf_path }: { pdf_path: string }) => {
  const [page, setPage] = useState(1);

  return (
    <Pdf file={pdf_path} page={page}>
      {({ pdfDocument, canvas }) => (
        <>
          {!pdfDocument && <span>加载中...</span>}
          {canvas}
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <Grid container spacing={3} justify="space-evenly">
              <Grid item>
                <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
                  上一页
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    var eleLink = document.createElement("a");
                    eleLink.download = "pdf";
                    eleLink.style.display = "none";

                    eleLink.href = pdf_path;
                    // 触发点击
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    // 然后移除
                    document.body.removeChild(eleLink);
                  }}
                >
                  下载PDF
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={page === pdfDocument.numPages}
                  onClick={() => setPage(page + 1)}
                >
                  下一页
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Pdf>
  );
};
