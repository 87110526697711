import React from "react";
import { CardProp } from "./QuizCommon";
import { Card, CardContent } from "@material-ui/core";

export const QuizTrueOrFalseCardElement = (prop: CardProp) => {
  return (
    <Card>
      <CardContent>TrueOrFalseCard...</CardContent>
    </Card>
  );
};
