import React, {
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  createStyles,
  makeStyles,
  Box,
  Slider,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
} from "@material-ui/core";
import { Editor, Transforms } from "slate";
import { css } from "emotion";
import { useSelected, useFocused, useEditor, ReactEditor } from "slate-react";
import { cloneDeep } from "lodash";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";
import { CirclePicker } from "react-color";
import { useDivices } from "../../lib/hooks/useDevices";
export const CanvasPlguin = {
  insertCanvasHelper: (editor: Editor) => {
    const text = { text: " " };
    const node = { type: "canvas", children: [text] };
    Transforms.insertNodes(editor, node);
  },
};

// x, y均为0~1之间的值
interface DrawPoint {
  x: number;
  y: number;
}

interface DrawPath {
  color: string;
  path: DrawPoint[];
  width: number;
}

export const CanvasElement = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: any;
  element: any;
}) => {
  const selected = useSelected();
  const focused = useFocused();
  const editor = useEditor();
  const style = useStyle();
  const [isMDown, setMD] = useState(false);
  const ref: MutableRefObject<HTMLCanvasElement | null> = useRef(null);
  const lastPoint = useRef(null as any);
  const lastPath = useRef({ color: "#000000", path: [], width: 2 } as DrawPath);

  const paths = useRef((element.paths as DrawPath[]) || ([] as DrawPath[]));
  const W = 1000;
  const H = 1000;

  useEffect(() => {
    if (ref.current) {
      let ctx = ref.current.getContext("2d");

      paths.current.forEach((v) => {
        if (ctx !== null && v.path.length > 1) {
          ctx.beginPath();

          // ! --------------------------
          //let p1 = v.path[0];
          //let p2 = v.path[1];
          //
          //ctx.beginPath();
          //ctx.moveTo(p1.x * W, p1.y * H);
          //
          //for (let i = 1, len = v.path.length; i < len; i++) {
          //  // we pick the point between pi+1 & pi+2 as the
          //  // end point and p1 as our control point
          //  let midPoint = midPointBtw(p1, p2);
          //  ctx.quadraticCurveTo(
          //    p1.x * W,
          //    p1.y * H,
          //    midPoint.x * W,
          //    midPoint.y * H
          //  );
          //  p1 = v.path[i];
          //  p2 = v.path[i + 1];
          //}
          //// Draw last line as a straight line while
          //// we wait for the next point to be able to calculate
          //// the bezier control point
          //ctx.lineTo(p1.x * W, p1.y * H);

          // ! ----------------------
          ctx.moveTo(v.path[0].x * W, v.path[0].y * H);
          for (let i = 1; i < v.path.length; i++) {
            const point = v.path[i];
            ctx.lineTo(point.x * W, point.y * H);
          }
          ctx.lineWidth = v.width || 2;
          ctx.strokeStyle = v.color;
          ctx.stroke();
          ctx.closePath();
        }
      });
    }
  }, []);

  const [width_value, setWV]: [number | number[], any] = useState(2);
  const handleChange = (event: any, newValue: number | number[]) => {
    setWV(newValue);
  };
  const saveToElement = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
      editor,
      { ...element, paths: cloneDeep(paths.current) },
      { at: path }
    );
  }, [editor, element, paths]);
  const endDraw = useCallback(() => {
    if (!isMDown) return;
    setMD(false);
    lastPoint.current = null;

    paths.current.push(lastPath.current);
    lastPath.current = { color: "#000000", path: [], width: 2 };
    saveToElement();
  }, [isMDown, setMD, lastPoint, paths, lastPath, saveToElement]);

  const [selectColor, setSelectColor] = useState("black");
  const [deleteDialogOpen, setDeleteDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const colorList = useMemo(
    () => ["black", "#efefef", "yellow", "red", "green", "grey"],
    []
  );
  const divice = useDivices();
  return (
    <Box>
      <div style={{ position: "absolute", display: "none" }}>{children}</div>

      <Box
        {...attributes}
        contentEditable={false}
        style={{ userSelect: "none" }}
        className={
          css`
            display: block;
            margin-left: auto;
            margin-right: auto;
            box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
          ` +
          " " +
          style.canvasParent
        }
      >
        <canvas
          ref={ref}
          width={W}
          height={H}
          className={style.canvas + (expanded ? " " + style.canvasDraw : "")}
          onPointerDown={() => {
            if (!expanded) return;
            setMD(true);
            lastPath.current.width = width_value;
            lastPath.current.color = selectColor;
          }}
          onPointerOut={() => {
            endDraw();
          }}
          onPointerUp={() => {
            endDraw();
          }}
          onPointerMove={(e) => {
            if (isMDown && !!ref.current) {
              let x = e.clientX - ref.current.getBoundingClientRect().x;
              let y = e.clientY - ref.current.getBoundingClientRect().y;
              let ctx = ref.current.getContext("2d");
              let w = ref.current.offsetWidth;
              let h = ref.current.offsetHeight;
              x /= w;
              y /= h;

              if (lastPoint.current !== null) {
                let lx = lastPoint.current.x;
                let ly = lastPoint.current.y;

                if (
                  Math.sqrt((lx * W - x * W) ** 2 + (ly * H - y * H) ** 2) > 4
                ) {
                  if (lastPoint.current !== null && ctx) {
                    ctx.beginPath();
                    ctx.moveTo(
                      lastPath.current.path[0].x * W,
                      lastPath.current.path[0].y * H
                    );
                    for (let i = 1; i < lastPath.current.path.length; i++) {
                      const point = lastPath.current.path[i];
                      ctx.lineTo(point.x * W, point.y * H);
                    }
                    ctx.lineWidth = lastPath.current.width || 2;
                    ctx.strokeStyle = lastPath.current.color;
                    ctx.stroke();
                    ctx.closePath();
                  }
                  lastPoint.current = { x, y };
                  lastPath.current.path.push({ x, y });
                }
              } else {
                lastPoint.current = { x, y };
                lastPath.current.path.push({ x, y });
              }
            }
          }}
        ></canvas>
        <ExpansionPanel
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
        >
          <ExpansionPanelSummary
            style={{
              backgroundColor: expanded
                ? "rgb(253,253,253)"
                : "rgb(250,250,250)",
            }}
          >
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Edit />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{}}>
            <Box
              style={{
                padding: 10,
                width: "100%",
              }}
            >
              <Box className={style.fieldBox}>
                <Typography className={style.fieldText}>笔刷宽度</Typography>
                <Slider
                  value={width_value}
                  min={1}
                  max={20}
                  defaultValue={2}
                  valueLabelDisplay="auto"
                  onChange={handleChange}
                ></Slider>
              </Box>
              <Divider variant="middle" />
              <Box className={style.fieldBox}>
                <Typography className={style.fieldText}>笔刷颜色</Typography>
                <Grid container justify="center">
                  <Grid item>
                    <CirclePicker
                      width={divice.select([
                        [divice.whenDesktop, "400px"],
                        [divice.whenPad, "400px"],
                        [divice.whenMobile, "200px"],
                      ])}
                      circleSpacing={20}
                      onChange={(prop) => {
                        setSelectColor(prop.hex);
                      }}
                      colors={colorList}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box className={style.fieldBox}>
                <Typography className={style.fieldText}>画布工具</Typography>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setDeleteDialog(true);
                      }}
                      style={{ width: 45, height: 45 }}
                    >
                      <DeleteForeverIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Dialog open={deleteDialogOpen}>
          <DialogTitle>
            <Typography>是否清空画布？</Typography>{" "}
          </DialogTitle>
          <DialogActions>
            <Grid container justify="center">
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    let ctx = ref.current?.getContext("2d");
                    if (ctx && ref.current) {
                      ctx.clearRect(
                        0,
                        0,
                        ref.current.width,
                        ref.current.height
                      );
                    }
                    lastPoint.current = null;
                    lastPath.current = { color: "#000000", path: [], width: 2 };
                    paths.current = [];
                    saveToElement();
                    setDeleteDialog(false);
                  }}
                >
                  确认
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => setDeleteDialog(false)}
                >
                  取消
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

const useStyle = makeStyles(() =>
  createStyles({
    canvasParent: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    canvas: {
      width: "100%",
      borderWidth: 2,
      borderColor: "#eeeeee",
      borderStyle: "solid",
    },
    canvasDraw: {
      touchAction: "none",
    },
    colorField: {
      width: 45,
      height: 45,
      boxSizing: "inherit",
    },
    colorSelected: {
      borderStyle: "solid",
      borderWidth: 2,
      borderColor: "#cccccc",
    },
    whiteFrame: {
      borderColor: "#cccccc",
    },
    blackFrame: {
      borderColor: "#cccccc",
    },
    otherFrame: { borderColor: "#bbbbbbb" },
    fieldBox: {
      marginBottom: 20,
      marginTop: 5,
    },
    fieldText: {
      margin: 10,
    },
  })
);
