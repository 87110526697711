import { searchResult, findSearchResult } from "./Common";
import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import {
    ListItem,
    Grid,
    ListItemIcon,
    ListItemText,
    Collapse,
    List,
    Menu,
    MenuItem,
    Box,
} from "@material-ui/core";
import { BlockEditorRuntime } from "../Editor/useBlockEditor";
import { PageFCB } from "../../lib/Database/DataInterface";
import { useDrag, useDrop } from "react-dnd";
import {
    getDatabase,
    getObjectStore,
    StoreName,
    DirtDatabaseVer,
} from "../../lib/Database/Database";
import { useUpdate, useAlive, useInterval } from "../../lib/CommonHook";
import { ArrayNotEqual } from "../../lib/ArrayUtil";
import { useDeleteDialog, DeleteDialog } from "../../AppCom/DeleteDialog";
import { useHistory } from "react-router-dom";
import React from "react";
import InsertDriveFileTwoToneIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export interface PageListItemRT {
    lockWhenDragging: React.MutableRefObject<boolean>;
    searchResult: React.MutableRefObject<searchResult[] | undefined>;
    onSelectDocCallback?: () => void;
}
export const usePageListItem = (onSelectDocCallback?: () => void) => {
    const lockWhenDragging = useRef(false);
    const searchResult = useRef(undefined);
    return {
        lockWhenDragging,
        searchResult,
        onSelectDocCallback,
    };
};

const ItemType = {
    DocItem: "DocItem",
};

export const PageListItem = ({
    v,
    setCurrentDoc,
    select,
    createDocCallback,
    deepth,
    runtime,
    listRT,
    parent,
    trace,
}: {
    v: string;
    setCurrentDoc: Function;
    select: { selectIndex: string; setSelectIndex: Function };
    createDocCallback: Function;
    deepth: number;
    runtime: BlockEditorRuntime;
    listRT: PageListItemRT;
    parent: string;
    trace: string[];
}) => {
    const [open, setOpen] = useState(false);
    const [ins, setIns] = useState() as [PageFCB, Function];

    const saveFCB = useCallback(async () => {
        let db = await getDatabase();
        await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).put(ins);
    }, [ins]);

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: ItemType.DocItem,
            doc: v,
            parent: parent,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemType.DocItem,
        drop: async (item: any) => {
            let newI = trace.indexOf(item.doc);
            if (item.doc !== v && newI === -1) {
                let db = await getDatabase();
                let parentFCB: PageFCB = await (
                    await getObjectStore(db, StoreName.fcb_pages)
                ).get(item.parent);

                parentFCB.children = parentFCB.children.filter(
                    (pv) => pv !== item.doc
                );
                await (
                    await getObjectStore(db, StoreName.fcb_pages, "readwrite")
                ).put(parentFCB);

                let newParent = await (
                    await getObjectStore(db, StoreName.fcb_pages)
                ).get(parent);

                let ind = newParent.children.indexOf(v);
                newParent.children.splice(ind, 0, item.doc);

                await (
                    await getObjectStore(db, StoreName.fcb_pages, "readwrite")
                ).put(newParent);
            }

            listRT.lockWhenDragging.current = false;
        },
        collect: (mon) => ({
            isOver: !!mon.isOver(),
            canDrop: !!mon.canDrop(),
        }),
        canDrop: (item) => {
            return item.doc !== v && trace.indexOf(item.doc) === -1;
        },
    });

    const [clickState, setClickState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);

    const handleClose = useCallback(() => {
        setClickState(initialState);
    }, [setClickState]);

    const update = useUpdate();
    const [dirtVersion, setDirtVersion] = useState(0);
    const alive = useAlive();
    useEffect(() => {
        async function getDataList(uuid: string) {
            const db = await getDatabase();
            const objstore = await getObjectStore(
                db,
                StoreName.fcb_pages,
                "readonly"
            );
            const newfcb: PageFCB = await objstore.get(uuid);
            if (
                !!newfcb &&
                (!!!ins ||
                    ins.title !== newfcb.title ||
                    ArrayNotEqual(newfcb.children, ins.children))
            ) {
                if (alive.current) {
                    setIns(newfcb);
                    setOpen(!!newfcb.open);
                }
            }
        }
        if (!listRT.lockWhenDragging.current) {
            getDataList(v);
        }
    }, [listRT.lockWhenDragging, update.key, ins, setIns, v, alive]);

    useEffect(() => {
        if (isDragging) listRT.lockWhenDragging.current = true;
    }, [listRT.lockWhenDragging, isDragging]);

    useInterval(() => {
        if (dirtVersion !== DirtDatabaseVer() && alive.current) {
            setDirtVersion(DirtDatabaseVer());
            update.update();
        }
    }, 200);

    useInterval(() => {
        update.update();
    }, 5000);

    const deleteDialogControl = useDeleteDialog(v, parent);
    const history = useHistory();
    const t = ins?.title;
    const displayTitle = useMemo(() => {
        if (t) {
            //let w = 0;
            //for (let i = 0; i < t.length; i ++) {
            //  if(t.)
            //}
            //return t.slice(0, 5);
            return t;
        } else {
            return "";
        }
    }, [t]);
    return (
        <div ref={drag} style={{ position: "relative" }}>
            <ListItem
                ref={drop}
                button
                key={v}
                onContextMenu={(e) => {
                    e.preventDefault();
                    setClickState({
                        mouseX: e.clientX - 2,
                        mouseY: e.clientY - 4,
                    });
                }}
                onClick={() => {
                    //if (ins.children.length > 0) setOpen(!open);
                    select.setSelectIndex(v);
                    //setCurrentDoc(v);
                    runtime.parentDoc.current = parent;
                    history.push(`/edit/${v}`);
                    if (listRT.onSelectDocCallback)
                        listRT.onSelectDocCallback();
                }}
                selected={select.selectIndex === v}
            >
                {isOver && canDrop && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: 4,
                            width: "100%",
                            zIndex: 1,
                            opacity: 0.5,
                            backgroundColor: "#0066FF",
                        }}
                    />
                )}
                <div style={{ width: 10 * deepth }} />
                <Grid container alignItems="center">
                    <Grid item>
                        <ListItemIcon>
                            {deepth == 0 ? (
                                <InsertDriveFileTwoToneIcon />
                            ) : (
                                <InsertDriveFileOutlinedIcon />
                            )}
                        </ListItemIcon>
                    </Grid>
                    <Grid item>
                        <ListItemText
                            primary={displayTitle}
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                width: 120,
                            }}
                        />
                    </Grid>
                </Grid>

                <Box
                    style={{
                        position: "absolute",
                        right: 15,
                        top: 5,
                    }}
                >
                    {!!ins && ins.children.length > 0 ? (
                        open ? (
                            <ExpandLess
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (ins.children.length > 0) {
                                        ins.open = !open;
                                        setOpen(!open);
                                        saveFCB();
                                    }
                                }}
                            />
                        ) : (
                            <ExpandMore
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (ins.children.length > 0) {
                                        ins.open = !open;
                                        setOpen(!open);
                                        saveFCB();
                                    }
                                }}
                            />
                        )
                    ) : null}
                </Box>
            </ListItem>

            {!!ins && ins.children.length ? (
                <Collapse in={open} timeout={"auto"} unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                        className="drawer-link"
                    >
                        {ins.children
                            .filter((vv) =>
                                findSearchResult(
                                    vv,
                                    listRT.searchResult.current
                                )
                            )
                            .map((vv) => (
                                <PageListItem
                                    setCurrentDoc={setCurrentDoc}
                                    v={vv}
                                    key={vv}
                                    select={select}
                                    createDocCallback={createDocCallback}
                                    deepth={deepth + 1}
                                    runtime={runtime}
                                    listRT={listRT}
                                    parent={v}
                                    trace={[...trace, vv]}
                                />
                            ))}
                    </List>
                </Collapse>
            ) : null}

            <Menu
                keepMounted
                open={clickState.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    clickState.mouseY !== null && clickState.mouseX !== null
                        ? { top: clickState.mouseY, left: clickState.mouseX }
                        : undefined
                }
            >
                <MenuItem
                    onClick={async () => {
                        await createDocCallback(v);
                        handleClose();
                        update.update();
                    }}
                >
                    创建子页
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                        deleteDialogControl.setOpen(true);
                    }}
                >
                    删除
                </MenuItem>
            </Menu>
            <DeleteDialog control={deleteDialogControl} runtime={runtime} />
        </div>
    );
};

const initialState = {
    mouseX: null,
    mouseY: null,
};
