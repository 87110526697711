import { Editor, Transforms, Text, Node, Range } from "slate";
import { combineOneLine } from "../EditorMethods";
import { ReactEditor } from "slate-react";


/**
 * 用于处理数学公式显示的插件，提供基础显示支持
 * @param editor
 */
export const withMath = (editor: ReactEditor) => {
  const { isVoid, isInline, insertData, normalizeNode, insertText } = editor;

  // 用isVoid禁止编辑，表明这是个不可编辑对象，例如图片
  editor.isVoid = (e) => {
    return (
      (e.type === "math-line" && e.isShow === true) ||
      (e.type === "math-inline" && e.isShow === true) ||
      isVoid(e)
    );
  };

  // 为inline公式提供支持
  editor.isInline = (e) => {
    return e.type === "math-inline" || isInline(e);
  };

  editor.insertData = (data) => {
    const [node] = Editor.nodes(editor, {
      match: (n) => n.type === "math-line" || n.type === "math-inline",
    });
    if (node) {
      let fragment = data.getData("application/x-slate-fragment");
      if (fragment) {
        let decoded = decodeURIComponent(window.atob(fragment));
        let parsed = JSON.parse(decoded);
        Transforms.insertText(editor, combineOneLine(parsed));
        return;
      }
    }
    insertData(data);
  };

  editor.normalizeNode = (entry) => {
    const [nodeE] = Editor.nodes(editor, {
      match: (n) => n.type === "math-inline",
    });
    if (nodeE) {
      const [node, path] = nodeE;
      //console.log(node.children)
      for (let i = 0; i < node.children.length; i++) {
        const element: Node = node.children[i];
        if (element.children) {
          Transforms.setNodes(
            editor,
            { text: combineOneLine(element.children || []) },
            { at: [...path, i] }
          );
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  editor.insertText = (text) => {
    // 使行内公式末尾保持一个空格 #101
    const [nodeE] = Editor.nodes(editor, {
      match: (n) => n.type === "math-inline",
    });
    if (nodeE && !endsWith(text, " ")) {
      const selection = editor.selection;
      if (selection) {
        const [node, np] = Editor.node(editor, selection);
        if (selection.focus.offset === node.text.length) {
          if (!endsWith(node.text, " ")) {
            // 插入一个带空格的字符串，并将光标往前移一格，保持在空格前面
            Transforms.insertText(editor, text + " ", { at: selection });
            Transforms.move(editor, {
              distance: 1,
              unit: "offset",
              reverse: true,
            });
            return;
          }
        }
      }
    }
    insertText(text);
  };

  return editor;
};
/**
 * 一些控制数学公式的方法，需要调用才起作用
 */
export const MathPlugin = {
  /**
   * 切换数学公式与显示和编辑状态
   */
  toggleMath: (editor: Editor) => {
    const isShow = MathPlugin.isShowMathBlock(editor);
    Transforms.setNodes(
      editor,
      { isShow: isShow ? false : true },
      { match: (n) => n.type === "math-line" || n.type === "math-inline" }
    );
    const { myIpcRenderer } = window;
    if(!isShow) myIpcRenderer?.send("APP_MATH_END");
    else myIpcRenderer?.send("APP_MATH_BEGIN")
  },

  /**
   * 判断当前选中的math是否在显示状态
   */
  isShowMathBlock: (editor: Editor) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.isShow === true,
      mode: "all",
    });
    return !!match;
  },
  isText: (editor: Editor) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => Text.isText(n),
      mode: "all",
    });
    return !!match;
  },
  isMath: (n: any) => {
    return n.type === "math-line" || n.type === "math-inline";
  },
  insertMathLine: (editor: Editor) => {
    const text = { text: "" };
    const node = { type: "math-line", children: [text] };
    Transforms.setNodes(editor, node);
  },
  insertMathInLine: (editor: Editor) => {
    const text = { text: " " };
    const node = { type: "math-inline", children: [text] };
    Transforms.setNodes(editor, node);
  },
};

function endsWith(str: string, suffix: string) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}
