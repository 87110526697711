import React, { useReducer, useContext, useState, useCallback } from "react";

export interface Qkeypoint {
  meet: number;
  right: number;
  wrong: number;
  confuse: number;
}

function newQKeypoint() {
  return {
    meet: 0,
    right: 0,
    wrong: 0,
    confuse: 0,
  };
}

export function toQkeypoint<T>(old: any): T {
  return { ...newQKeypoint(), ...old };
}

export const CollectKeyword = React.createContext({
  keywords: new Set<string>(),
  addKeywords: undefined,
  enable: false,
} as {
  keywords: Set<string>;
  addKeywords?: React.Dispatch<{
    value: string;
    action: "add" | "remove";
  }>;
  enable: boolean;
});

/**
 * 设计为收集所有被选中的关键词，需要在关键词点击时调用addKeywords
 * */
export const useCollectKeyword = () => {
  const [keywords, addKeywords] = useReducer(
    (
      state: Set<string>,
      action: { value: string; action: "add" | "remove" }
    ) => {
      switch (action.action) {
        case "add":
          state.add(action.value);
          break;
        case "remove":
          state.delete(action.value);
          break;
        default:
          break;
      }
      return state;
    },
    new Set<string>()
  );
  return { keywords, addKeywords };
};

export const useCollectKeywordContext = () => {
  const [selected, _setSelected] = useState(false);
  const context = useContext(CollectKeyword);
  const setSelect = useCallback((k: string, v: boolean) => {
    if (context.enable) {
      _setSelected(v);
      if (v) {
        context.addKeywords?.({ value: k, action: "add" });
      } else {
        context.addKeywords?.({ value: k, action: "remove" });
      }
    }
  }, [context.addKeywords, _setSelected, context.enable]);
  return {
    selected, setSelect
  };
};
