import {
  useCallback,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import {
  useLearnApp,
  setLearnStatueSubject,
} from "../../useLearnAppState";
import {
  QuizCardData,
  QuizCardUserData,
  QuizCardCollection,
} from "../../../../elements/plugins/withQuiz";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../../../lib/Database/Database";
import { toQkeypoint } from "./Quantitative";

interface usePracticeHook {
  /**
   * 下一张卡
   */
  next: MutableRefObject<() => boolean>;
  /**
   * 当前是否在读取中
   */
  loading: boolean;
  /**
   * 题目数据
   */
  quiz: QuizCardData | undefined;
  /**
   * 题目用户数据
   */
  userData: QuizCardUserData | undefined;
  /**
   * 保存用户数据
   */
  saveUserData: (newdata: QuizCardUserData) => void;
  /**
   * 当前题目ID
   */
  currentCardUUID: string;
  /**
   * 当前集合ID
   */
  currentCardCollection: string;
  /**
   * 所有题目是否做完
   */
  isComplete: boolean;
  /**
   * 当前题目在数组中的索引
   */
  Index: number;
  /**
   * 结束答题
   */
  end: () => void;
}

interface QuizIns {
  carddata: QuizCardData;
  uuid: string;
}

export const usePractice = (): usePracticeHook => {
  const learnApp = useLearnApp();
  const plan =
    learnApp.plan &&
    learnApp.state &&
    learnApp.plan.planList[learnApp.state.currentSubjectIndex];
  const statue = learnApp.state.learnSubject;

  let uuid = plan?.uuid;
  const { Index, currentCardUUID } = statue;
  const [isComplete, setComplete] = useState(false);
  const userDataCache = useRef(new Map<string, QuizCardUserData>());
  const { data: allQuiz } = useRequest(
    async () => {
      /**
       * TODO: 在此函数中读取所有关联卡片，生成将要练习的数组
       */
      const db = await getDatabase();
      let all: QuizIns[] = [];
      if (plan) {
        for (let i = 0; i < plan.quizsCollectionUUID.length; i++) {
          const element = plan.quizsCollectionUUID[i];
          const col: QuizCardCollection = await (
            await getObjectStore(db, StoreName.quiz_collection)
          ).get(element);
          if (col === undefined) continue;

          for (const ind in col.children) {
            let id = col.children[ind];
            let quizcard: QuizCardData = await (
              await getObjectStore(db, StoreName.quiz_card)
            ).get(id);
            let userdata: QuizCardUserData = toQkeypoint(
              await (
                await getObjectStore(db, StoreName.user_quiz_card_data)
              ).get(id)
            );
            if (!userdata.uuid) userdata.uuid = id;
            userDataCache.current.set(userdata.uuid, userdata);
            let q: QuizIns = {
              carddata: quizcard,
              uuid: id,
            };
            all.push(q);
          }
        }
      }
      return all;
    },
    { refreshDeps: [uuid] }
  );

  const next = useRef((() => {
    return false;
  }) as any);
  next.current = () => {
    //useCallback(
    // TODO: 根据集合、知识点掌握程度和阶段选择下一张卡，设置其ID
    let test = allQuiz?.[Index + 1];
    if (!test) {
      if (allQuiz) {
        // 如果下一张卡为undefined且卡片数组存在的话，说明越界了！是最后一张
        // 因此设置完成为真
        setComplete(true);
        learnApp.func.changeState?.(
          setLearnStatueSubject({ currentCardUUID: "", Index: -1 })
        );
        return true
      }
    } else {
      // 获取下一张卡
      learnApp.func.changeState?.(
        setLearnStatueSubject({ currentCardUUID: test.uuid, Index: Index + 1 })
      );
      setComplete(false);
      return true;
    }
    return false;
  }; //, [allQuiz, Index, setComplete, update, learnApp.func.changeState, uuid]);

  const { data: quiz } = useRequest(
    async () => {
      if (currentCardUUID !== "") {
        const db = await getDatabase();
        const res: QuizCardData = await (
          await getObjectStore(db, StoreName.quiz_card)
        ).get(currentCardUUID);
        return res;
      }
    },
    {
      refreshDeps: [currentCardUUID],
    }
  );

  const { data: userData } = useRequest(
    async () => {
      if (currentCardUUID !== "") {
        if (!userDataCache.current.has(currentCardUUID)) {
          const db = await getDatabase();
          const res: QuizCardUserData = await (
            await getObjectStore(db, StoreName.user_quiz_card_data)
          ).get(currentCardUUID);
          const r = toQkeypoint<QuizCardUserData>(res);
          r.uuid = currentCardUUID;
          userDataCache.current.set(r.uuid, r);
          return r;
        } else {
          return userDataCache.current.get(currentCardUUID);
        }
      }
    },
    { refreshDeps: [currentCardUUID] }
  );

  const saveUserData = useCallback((newdata: QuizCardUserData) => {
    const save = async () => {
      const db = await getDatabase();
      await (
        await getObjectStore(db, StoreName.user_quiz_card_data, "readwrite")
      ).put(newdata);
    };
    userDataCache.current.set(newdata.uuid, newdata);
    save();
  }, []);

  const end = () => {
    learnApp.func.changeState?.(
      setLearnStatueSubject({ currentCardUUID: "", Index: -1 })
    );
  };

  return {
    next,
    quiz,
    userData,
    saveUserData,
    loading: quiz === undefined || userData === undefined,
    currentCardUUID,
    currentCardCollection: "",
    isComplete,
    Index,
    end,
  };
};
