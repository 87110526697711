import { databaseReducer, databaseInit } from "./appreducer";

export const appData = (state = {} as any, action: ReduxAction) => {
  return {
    database: databaseReducer(state.database, action)
  };
};

export const appDataInit = () => {
  return {
    database: databaseInit()
  };
};

export interface ReduxAction {
  type: string;
}

export const ActionType = {
  ChangeDB: "ChangeDB",
  ReadDB: "ReadDB",
  RefreashPageList: "RefreashPageList",
  GetTitle: "GetTitle",
  SetTitle: "SetTitle"
};
