import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Card,
  CardContent,
  GridList,
  GridListTile,
  Typography,
  Button,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Box
} from "@material-ui/core";
import { drawerHeight, ThemeContext } from "../../App";
import { useInterval } from "../../lib/CommonHook";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import { QuizCardCollection } from "../../elements/plugins/withQuiz";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import uuid from "uuid";
import { useDivices } from "../../lib/hooks/useDevices";
import { LinkButton } from "../../lib/Coms/ButtonLink";
import ScrollToTop from "../../AppCom/ScrollToTop";

export const TestCenterPage = () => {
  const [collectionsList, setcollectionList] = useState(
    [] as QuizCardCollection[]
  );

  const updateCollectionList = useCallback(async () => {
    const db = await getDatabase();
    const colls: QuizCardCollection[] = await (
      await getObjectStore(db, StoreName.quiz_collection)
    ).getAll();
    setcollectionList(colls);
  }, [setcollectionList]);

  useInterval(() => {
    updateCollectionList();
  }, 1000);

  useEffect(() => {
    updateCollectionList();
  }, [updateCollectionList]);
  const style = useTestCenterStyle();

  const mtheme = useContext(ThemeContext);
  const divice = useDivices();
  return (
    <Box >
      <ScrollToTop />
      <GridList
        cellHeight={300}
        cols={divice.select([
          [divice.whenDesktop, 3],
          [divice.whenPad, 2],
          [divice.whenMobile, 1],
        ])}
        spacing={16}
      >
        {[
          <TestCenterCreateNewCollection key="__create_new__" />,
          ...collectionsList.map((col) => (
            <GridListTile key={col.uuid}>
              <TestCenterQuizCollectionCard collection={col} />
            </GridListTile>
          )),
        ]}
      </GridList>
    </Box>
  );
};

const TestCenterCreateNewCollection = () => {
  const style = useTestCenterStyle();
  const [createNewOpen, setCreateNewOpen] = useState(false);
  const [newDocName, setNewDocName] = useState("");
  return (
    <Card className={style.card} style={{ width: 200 }}>
      <CardContent>
        <Typography
          className={style.cardtitle}
          color="textSecondary"
          gutterBottom
        >
          创建新集合
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container justify="center">
          <Grid item>
            <IconButton
              style={{ marginTop: 30 }}
              onClick={(e) => {
                setCreateNewOpen(true);
              }}
            >
              <AddIcon style={{ width: 60, height: 60 }}></AddIcon>
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <Dialog open={createNewOpen} onClose={() => setCreateNewOpen(false)}>
        <DialogTitle>创建新卡组</DialogTitle>
        <DialogContent style={{ height: 80 }}>
          <TextField
            onChange={(e) => setNewDocName(e.target.value)}
            label="新卡组名"
            variant="outlined"
            style={{ width: 265 }}
          />
        </DialogContent>
        <DialogActions style={{ marginBottom: 20, width: 300 }}>
          <Grid container justify="space-around">
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setCreateNewOpen(false)}
              >
                取消
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={async () => {
                  const db = await getDatabase();
                  let newcol: QuizCardCollection = {
                    uuid: uuid.v4(),
                    name: newDocName === "" ? "新卡组" : newDocName,
                    children: [],
                  };
                  await (
                    await getObjectStore(
                      db,
                      StoreName.quiz_collection,
                      "readwrite"
                    )
                  ).put(newcol);
                  setCreateNewOpen(false);
                }}
              >
                确认
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const TestCenterQuizCollectionCard = ({
  collection,
}: {
  collection: QuizCardCollection;
}) => {
  const style = useTestCenterStyle();
  const [editInfo, setEditInfo] = useState(false);
  const [newDocName, setNewDocName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  return (
    <Card className={style.card}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={style.cardtitle}
              color="textSecondary"
              gutterBottom
            >
              卡片集合
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setEditInfo(true)}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography component="h2" variant="h5">
          {collection.name}
        </Typography>
        <Typography
          className={style.cardtitle}
          color="textSecondary"
          gutterBottom
        >
          数量
        </Typography>
        <Typography>{collection.children.length}</Typography>
      </CardContent>
      <CardContent></CardContent>
      <CardContent>
        <Grid container justify="space-evenly" spacing={3}>
          <Grid item>
            <LinkButton to={`/quiz/${collection.uuid}/edit`}>
              <Button color="primary" variant="outlined">
                查看
              </Button>
            </LinkButton>
          </Grid>
          <Grid item>
            <LinkButton to={`/quiz/${collection.uuid}/practice`}>
              <Button color="primary" variant="outlined">
                练习
              </Button>
            </LinkButton>
          </Grid>
        </Grid>
      </CardContent>
      <Dialog open={editInfo} onClose={() => setEditInfo(false)}>
        <DialogTitle>编辑卡组信息</DialogTitle>
        <DialogContent style={{ height: 80 }}>
          <TextField
            onChange={(e) => setNewDocName(e.target.value)}
            label="新卡组名"
            variant="outlined"
            value={newDocName}
          />
        </DialogContent>
        <DialogActions style={{ marginBottom: 20, width: 300 }}>
          <Grid container justify="space-around">
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={() => {
                  setEditInfo(false);
                  setDeleteDialogOpen(true);
                }}
              >
                删除此卡组
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setEditInfo(false)}
              >
                取消
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  const db = await getDatabase();
                  let newcol: QuizCardCollection = {
                    uuid: collection.uuid,
                    name: newDocName,
                    children: collection.children,
                  };
                  await (
                    await getObjectStore(
                      db,
                      StoreName.quiz_collection,
                      "readwrite"
                    )
                  ).put(newcol);
                  setEditInfo(false);
                }}
              >
                确认
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>是否要删除此卡组</DialogTitle>
        <DialogContentText>该操作不可逆！请谨慎决定！</DialogContentText>
        <DialogActions>
          <Button
            color="secondary"
            onClick={async () => {
              const db = await getDatabase();
              await (
                await getObjectStore(db, StoreName.quiz_collection, "readwrite")
              ).delete(collection.uuid);
              setDeleteDialogOpen(false);
            }}
          >
            删除
          </Button>
          <Button onClick={() => setDeleteDialogOpen(false)}>取消</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const useTestCenterStyle = makeStyles(() =>
  createStyles({
    body: {
      minHeight: 1080,
      marginTop: drawerHeight,
      padding: 20,
    },
    card: {
      minHeight: 270,
      margin: 20,
    },
    cardtitle: {
      fontSize: 14,
    },
    linkbutton: {
      textDecoration: "none",
    },
  })
);
