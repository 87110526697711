import { openDB, IDBPDatabase } from "idb";
import { PageRawData, SavePage, PageFCB, MediaAsset } from "./DataInterface";
import { useCallback } from "react";
import { Node } from "slate";
import { Transaction } from "../Transaction";
export enum StoreName {
  /**
   * 存放每一页的文档数据、修改时间等信息
   */
  pages = "pages",
  /**
   * 存放每一页的文件结构数据，例如排序等，便于快速访问
   * 注意，是平铺的，每一页既是文档也是文件夹,children存储着子节点的uuid
   */
  fcb_pages = "fcb_pages",
  /**
   * 存放每张quiz卡片的原始数据
   */
  quiz_card = "quiz_card",
  /**
   * 存放卡片集合原始数据
   */
  quiz_collection = "quiz_collection",
  /**
   * 存放用户的卡片数据，例如这张卡的正确率
   */
  user_quiz_card_data = "user_quiz_card_data",
  /**
   * 存放用户卡片集合数据，例如排序、上次访问时间等
   */
  user_quiz_collection_data = "user_quiz_collection_data",
  config = "config",
  page_cache = "page_cache",
  media = "media",
  cards = "cards",
  knowledge = "knowledge",
  learnKeywords = "learnKeywords",
  knowledgeLink = "knowledgeLink",
  notes = "notes",
  words = "words",
  words_userdata = 'words_userdata'
}

export async function getDatabase() {
  const db = await openDB("zsdl", 22, {
    upgrade(db, o, n, trans) {
      if (!trans.db.objectStoreNames.contains(StoreName.pages)) {
        trans.db.createObjectStore(StoreName.pages, { keyPath: "uuid" });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.fcb_pages)) {
        trans.db.createObjectStore(StoreName.fcb_pages, { keyPath: "uuid" });
      }

      if (
        !trans.objectStore(StoreName.fcb_pages).indexNames.contains("isRoot")
      ) {
        trans.objectStore(StoreName.fcb_pages).createIndex("isRoot", "isRoot");
      }
      if (!trans.db.objectStoreNames.contains(StoreName.quiz_card)) {
        trans.db.createObjectStore(StoreName.quiz_card, { keyPath: "uuid" });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.quiz_collection)) {
        trans.db.createObjectStore(StoreName.quiz_collection, {
          keyPath: "uuid",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.user_quiz_card_data)) {
        trans.db.createObjectStore(StoreName.user_quiz_card_data, {
          keyPath: "uuid",
        });
      }

      if (
        !trans.db.objectStoreNames.contains(StoreName.user_quiz_collection_data)
      ) {
        trans.db.createObjectStore(StoreName.user_quiz_collection_data, {
          keyPath: "uuid",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.config)) {
        trans.db.createObjectStore(StoreName.config, {
          keyPath: "key",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.page_cache)) {
        trans.db.createObjectStore(StoreName.page_cache, {
          keyPath: "uuid",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.media)) {
        trans.db.createObjectStore(StoreName.media, {
          keyPath: "uuid",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.cards)) {
        trans.db.createObjectStore(StoreName.cards, {
          keyPath: "uuid",
        });
      }

      if (!trans.objectStore(StoreName.cards).indexNames.contains("type")) {
        trans.objectStore(StoreName.cards).createIndex("type", "type");
      }

      if (!trans.db.objectStoreNames.contains(StoreName.knowledge)) {
        trans.db.createObjectStore(StoreName.knowledge, {
          keyPath: "uuid",
        });
      }

      if (!trans.objectStore(StoreName.knowledge).indexNames.contains("type")) {
        trans.objectStore(StoreName.knowledge).createIndex("type", "type");
      }
      if (
        !trans.objectStore(StoreName.knowledge).indexNames.contains("isSubject")
      ) {
        trans
          .objectStore(StoreName.knowledge)
          .createIndex("isSubject", "isSubject");
      }

      if (!trans.db.objectStoreNames.contains(StoreName.learnKeywords)) {
        trans.db.createObjectStore(StoreName.learnKeywords, {
          keyPath: "uuid",
        });
      }

      if (
        !trans
          .objectStore(StoreName.learnKeywords)
          .indexNames.contains("keyword")
      ) {
        trans
          .objectStore(StoreName.learnKeywords)
          .createIndex("keyword", "keyword");
      }

      if (!trans.db.objectStoreNames.contains(StoreName.knowledgeLink)) {
        trans.db.createObjectStore(StoreName.knowledgeLink, {
          keyPath: "uuid",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.notes)) {
        trans.db.createObjectStore(StoreName.notes, {
          keyPath: "uuid",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.words)) {
        trans.db.createObjectStore(StoreName.words, {
          keyPath: "word",
        });
      }

      if (!trans.db.objectStoreNames.contains(StoreName.words_userdata)) {
        trans.db.createObjectStore(StoreName.words_userdata, {
          keyPath: "word",
        });
      }

      console.log("database update complete!");
    },
  });

  return db;
}

/**
 * 获取仓库
 * @param db 数据库实例
 * @param objectStoreName 仓库名
 * @param mode 只读 | 读写
 */
export const getObjectStore = async (
  db: IDBPDatabase,
  objectStoreName: string,
  mode?: "readonly" | "readwrite",
  refreash?: boolean
) => {
  let obj = await db
    .transaction(objectStoreName, mode)
    .objectStore(objectStoreName);
  if (mode === "readwrite" && refreash !== false) changeDB();
  return obj;
};

export function changeDB() {
  if ((window as any).dirtdatabase === undefined) {
    (window as any).dirtdatabase = 0;
  }
  (window as any).dirtdatabase++;
}

export const DirtDatabaseVer = () => {
  return (window as any).dirtdatabase;
};

/**
 * 从数据库中下载某一页
 * @param uuid 页的uuid
 */
export async function downloadLocalPage(
  uuid: string,
  type: "raw" | "markdown"
) {
  const db = await getDatabase();
  const page_store = await getObjectStore(db, StoreName.pages);
  const page: PageRawData = await page_store.get(uuid);

  const fcb: PageFCB = await (
    await getObjectStore(db, StoreName.fcb_pages)
  ).get(page.uuid);
  const save = {
    page: page,
    fcb: fcb,
    uuid: page.uuid,
  } as SavePage;

  if (type === "raw") {
    var eleLink = document.createElement("a");
    eleLink.download = page.uuid;
    eleLink.style.display = "none";
    // 字符内容转变成blob地址
    var blob = new Blob([JSON.stringify(save)], {
      type: "application/json,charset=UTF-8",
    });
    eleLink.href = URL.createObjectURL(blob);
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 然后移除
    document.body.removeChild(eleLink);
  }

  if (type === "markdown") {
    var eleLink2 = document.createElement("a");
    eleLink2.download = fcb.title + "_markdown.md";
    eleLink2.style.display = "none";
    // 字符内容转变成blob地址
    let cm = ConvertToMarkdown(page);
    var blob2 = new Blob([cm.md], {
      type: "text/plain,charset=UTF-8",
    });
    eleLink2.href = URL.createObjectURL(blob2);
    // 触发点击
    document.body.appendChild(eleLink2);
    eleLink2.click();
    // 然后移除
    document.body.removeChild(eleLink2);

    await downloadAllImages(cm.assets);
  }
}

const convertImage = (image: any, format: string) => {
  let canvas = document.createElement("canvas");

  let context2D = canvas.getContext("2d");
  if (context2D != null) {
    context2D.clearRect(0, 0, canvas.width, canvas.height);

    canvas.width = image.naturalWidth;

    canvas.height = image.naturalHeight;

    image.crossOrigin = "Anonymous";

    context2D.drawImage(image, 0, 0);

    return canvas.toDataURL("image/" + (format || "png"), 1);
  }
};

export const downloadMarkdown = async (filename: string, nodes: Node[]) => {
  var eleLink2 = document.createElement("a");
  eleLink2.download = filename + ".md";
  eleLink2.style.display = "none";
  // 字符内容转变成blob地址
  let cm = CovertNodesToMarkdown(nodes);
  var blob2 = new Blob([cm.md], {
    type: "text/plain,charset=UTF-8",
  });
  eleLink2.href = URL.createObjectURL(blob2);
  // 触发点击
  document.body.appendChild(eleLink2);
  eleLink2.click();
  // 然后移除
  document.body.removeChild(eleLink2);
  await downloadAllImages(cm.assets);
};

const downloadAllImages = async (assets: string[]) => {
  assets = Array.from(new Set(assets));
  const db = await getDatabase();
  for (let i = 0; i < assets.length; i++) {
    const assetID = assets[i];
    try {
      let data: MediaAsset = await (
        await getObjectStore(db, StoreName.media)
      ).get(assetID);

      if (data.type === "img" && data.url !== undefined) {
        // console.log(data);
        let img = new Image();
        //

        img.crossOrigin = "Anonymous";
        img.src = data.url;

        img.onload = () => {
          let newurl = convertImage(img, "png");
          if (newurl) {
            let eleLink = document.createElement("a");
            eleLink.download = assetID;
            eleLink.style.display = "none";
            eleLink.href = newurl;

            // 触发点击
            document.body.appendChild(eleLink);
            eleLink.click();
            // 然后移除
            document.body.removeChild(eleLink);
          } else {
            console.error("convert failed: " + assetID);
          }
          img.onload = null;
        };
      }
    } catch (error) {
      console.warn(error);
    }
  }
};

/**
 * 从文件恢复到数据库
 * @param file 读渠到的文件指针
 * @param setDoc 从数据库中读取页的回调
 */
export async function restoreLocalPage(file: File, setDoc: Function) {
  if (!!file) {
    let reader = new FileReader();
    reader.onload = async (e) => {
      let data = e.target?.result as string;
      if (!!data) {
        try {
          let obj: SavePage = JSON.parse(data);
          const db = await getDatabase();
          const store = await getObjectStore(db, StoreName.pages, "readwrite");
          store.put(obj.page);
          const fcb_write = await getObjectStore(
            db,
            StoreName.fcb_pages,
            "readwrite"
          );
          fcb_write.put(obj.fcb);

          setDoc(obj.uuid);
        } catch (error) {
          console.log(error);
        }
      }
    };
    reader.readAsText(file);
  }
}

/**
 * 删除某一页
 * @param docUUID 页的uuid
 */
export const useDeletePage = () => {
  const deletePage = useCallback(async (docUUID: string, parent: string) => {
    let db = await getDatabase();
    const fcb: PageFCB = await (
      await getObjectStore(db, StoreName.fcb_pages)
    ).get(docUUID);

    const parent_fcb: PageFCB = await (
      await getObjectStore(db, StoreName.fcb_pages)
    ).get(parent);
    parent_fcb.children = parent_fcb.children.filter((v) => v !== docUUID);
    await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).put(
      parent_fcb
    );

    if (!!fcb) {
      for (let i = 0; i < fcb.children.length; i++) {
        const f = fcb.children[i];
        await deletePage(f, docUUID);
      }
      if (!!fcb.parent) {
        const parent_fcb: PageFCB = await (
          await getObjectStore(db, StoreName.fcb_pages)
        ).get(fcb.parent);
        parent_fcb.children = parent_fcb.children.filter((v) => v !== docUUID);
        await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).put(
          parent_fcb
        );
      }
      let fcb_store = await getObjectStore(
        db,
        StoreName.fcb_pages,
        "readwrite"
      );
      await fcb_store.delete(docUUID);
      let page_store = await getObjectStore(db, StoreName.pages, "readwrite");
      await page_store.delete(docUUID);
    }
  }, []);
  return deletePage;
};

export function ConvertToMarkdown(data: PageRawData) {
  let doc = data.document;
  let assets: string[] = [];
  let c = _convertChildren(doc, assets);
  return { md: c, assets: assets };
}

export function CovertNodesToMarkdown(data: Node[]) {
  let assets: string[] = [];
  let c = _convertChildren(data, assets);
  return { md: c, assets: assets };
}

function _convertChildren(childs: Node[] | undefined, assetCollect: string[]) {
  if (childs === undefined) return "";
  let str = "";
  for (let i = 0; i < childs.length; i++) {
    const el = childs[i];
    str = str + _convertNode(el, assetCollect);
  }
  return str;
}

function _convertNode(node: Node, assetCollect: string[]) {
  let nodea = node as any;
  if (node.text !== undefined) {
    if (nodea.bold) return `**${node.text}**`;
    return node.text;
  }
  switch (node.type) {
    case "ph":
      return `${_convertChildren(node.children, assetCollect)}  \n`;
    case "H1":
      return `# ${_convertChildren(node.children, assetCollect)}  \n`;
    case "H2":
      return `## ${_convertChildren(node.children, assetCollect)}  \n`;
    case "H3":
      return `### ${_convertChildren(node.children, assetCollect)}  \n`;
    case "H4":
      return `#### ${_convertChildren(node.children, assetCollect)}  \n`;
    case "H5":
      return `##### ${_convertChildren(node.children, assetCollect)}  \n`;
    case "H6":
      return `###### ${_convertChildren(node.children, assetCollect)}  \n`;
    case "bulleted-list":
      return _convertChildren(node.children, assetCollect);
    case "list-item":
      return `- ${_convertChildren(node.children, assetCollect)}  \n`;
    case "check-list-item":
      if (nodea.checked) {
        return `[x] ${_convertChildren(node.children, assetCollect)}  \n`;
      } else {
        return `[ ] ${_convertChildren(node.children, assetCollect)}  \n`;
      }
    case "image":
      assetCollect.push(nodea.assetID);
      return `![image](${nodea.assetID}.png)  \n`;
    case "math-inline":
      return `$${_convertChildren(node.children, assetCollect)}$`;
    case "math-line":
      return `$$${_convertChildren(node.children, assetCollect)}$$  \n`;
    default:
      return _convertChildren(node.children, assetCollect);
  }
}

export async function FetchMedia(uuid: string) {
  const db = await getDatabase();
  let getMedia = JSON.stringify({
    media: [uuid],
  });

  let response = await fetch("https://zhishudali.ink/api/getmedia", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: getMedia,
  });
  if (response) {
    let re = await response.json();
    if (re) {
      for (let i = 0; i < re.body.media.length; i++) {
        const m = re.body.media[i];
        await (await getObjectStore(db, StoreName.media, "readwrite")).put(m);
      }
    }
  }
}

interface getKnowledgeCardEnv {}

export async function getKnowledgeCard_Read(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.knowledge);
}
export async function getKnowledgeCard_Write(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.knowledge, "readwrite");
}

export async function getNotes_Read(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.notes);
}
export async function getNotes_Write(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.notes, "readwrite");
}

export async function getWord_Read(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.words);
}
export async function getWord_Write(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.words, "readwrite");
}

export async function getWordUserdata_Read(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.words_userdata);
}
export async function getWordUserdata_Write(
  db?: IDBPDatabase<unknown>,
  env?: getKnowledgeCardEnv
) {
  if (db === undefined) db = await getDatabase();
  return await getObjectStore(db, StoreName.words_userdata, "readwrite");
}
