import React, { useState, useCallback } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";

export interface IKDialogControl {
    open: boolean;
    handleClose: Function;
    handleDelete: Function
}

export const useDeletePlanDialog = () => {
    const [open, setOpen] = useState(false)
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])
    const Open = useCallback(() => {
        setOpen(true)
    }, [setOpen])
    return {
        open,
        handleClose,
        Open
    }
}

export const DeletePlanDialog = (control: IKDialogControl) => {
    return (
        <Dialog
            open={control.open}
            onClose={e => control.handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"删除警告"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    您是否要删除此计划，此操作不可逆
        </DialogContentText>
            </DialogContent>
            <DialogActions className="drawer-link">
                <Button onClick={() => control.handleDelete()} color="secondary">
                    是的，我要删除
                </Button>
                <Button onClick={e => control.handleClose()} color="primary" autoFocus>
                    不，不要删除
                </Button>
            </DialogActions>
        </Dialog>
    );
};
