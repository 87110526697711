import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from "react";
import { useInterval, useUpdate, useUpdateI } from "../lib/CommonHook";
import { cloneDeep } from "lodash";

export interface Point {
  x?: number;
  y?: number;
}
export interface MotionValue<T> {
  key: T;
  value: Point;
}

const dt = 1
export function useMotionArrayValue<T, Q extends MotionValue<T>>(array: Q[]) {
  const _array = useRef(array);
  const [desArray, setDesArray] = useState(array);
  const [moving, setMoving] = useState(false);
  const rSpeed = useRef(0);
  const moveTo = useCallback(
    (target: Q[], speed: number) => {
      let old = cloneDeep(target);
      for (let i = 0; i < old.length; i++) {
        const e = old[i];
        const f = _array.current.find((v) => v.key === e.key);
        if (f) {
          old[i].value = f.value;
        }
      }
      setMoving(true);
      setDesArray(target);
      _array.current = old;
      rSpeed.current = speed;
    },
    [setMoving, setDesArray, _array]
  );
  const update = useUpdateI()
  useInterval(() => {
    if (moving) {
      //console.log("update");
      let newa = cloneDeep(_array.current);
      let allFin = true;
      //console.log(newa)
      //console.log(desArray)
      for (let i = 0; i < newa.length; i++) {
        const e = newa[i];
        const o = desArray[i];
        let distance = Math.sqrt(
          ((e.value.x || 0) - (o.value.x || 0)) ** 2 +
            ((e.value.y || 0) - (o.value.y || 0)) ** 2
        );
        let delta = (rSpeed.current * dt) * 5;
        let dd = distance - delta;
        if (Math.abs(distance) >= 0.1) {
          //console.log(dd);
          allFin = false;
          if (dd < 0) {
            delta = distance;
          }
          const dX = (e.value.x || 0) - (o.value.x || 0);
          const dY = (e.value.y || 0) - (o.value.y || 0);
          const tan = dY / dX;
          const cos = dX / distance;
          const sin = dY / distance;
          // l * cos = x
          const x = delta * cos;
          const y = delta * sin;
          e.value.x = (e.value.x || 0) - x;
          e.value.y = (e.value.y || 0) - y;
        }
      }
      if (!allFin) {
        _array.current = newa
        update.update()
      } else {
        setMoving(false);
      }
    }
  }, 10);
  return { moveTo, position: _array, moving, key: update.key };
  //const ret = useRef(r)
  //ret.current = r
  //return ret;
}
