import React, { useState } from "react"
import { Dialog, DialogTitle, Typography, DialogActions, Grid, Button, DialogContent, List, ListItem, ListItemText, Divider } from "@material-ui/core"
import { useRequest } from "@umijs/hooks"
import { getDatabase, getObjectStore, StoreName } from "../../../lib/Database/Database"
import { KnowledgeCard } from "../Knowledge/old_Knowledge"
import { QuizCardCollection } from "../../../elements/plugins/withQuiz"

export const FindQuizCollection = ({ open, handleClose, setResult }:
    {
        open: boolean,
        handleClose: () => void,
        setResult: (newv: { uuid: string, title: string }) => void
    }) => {
    const [selectUUID, setSelected] = useState("");
    const [selectTitle, setSelectTitle] = useState("")
    const [search, setSearch] = useState("")
    const { data } = useRequest(async () => {
        const db = await getDatabase()
        let res: QuizCardCollection[] = [];
        let cur: QuizCardCollection[] = await (await getObjectStore(db, StoreName.quiz_collection)).getAll()
        for (let i = 0; i < cur.length; i++) {
            const item = cur[i];
            if (item.name !== "" && item.name.indexOf(search) !== -1) {
                res.push(item)
            }
        }
        return res;
    }, { refreshDeps: [search] })
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ minWidth: 200 }}>
            <Typography>选取科目</Typography>
        </DialogTitle>
        <DialogContent>
            <List>
                {data && data.map((v) => {
                    return <ListItem key={v.uuid}
                        button
                        selected={v.uuid === selectUUID}
                        onClick={() => { setSelected(v.uuid); setSelectTitle(v.name) }}>
                        <ListItemText>
                            <Typography>
                                {v.name}
                            </Typography><Divider />
                        </ListItemText>

                    </ListItem>
                })}
            </List>
        </DialogContent>
        <DialogActions>
            <Grid container justify="center">
                <Grid item>
                    <Button variant="contained" disabled={selectUUID === ""} onClick={() => {
                        setResult({
                            uuid: selectUUID, title: selectTitle
                        })
                        handleClose()
                    }}>
                        确认
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
}