import { useSettingCardStyle } from "./Common";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
  getKnowledgeCard_Write,
} from "../../lib/Database/Database";
import JSZip from "jszip";
import { getFromDoc, KnowledgeCard } from "../Learn/Knowledge/old_Knowledge";
import {
  QuizCardData,
  QuizCardCollection,
} from "../../elements/plugins/withQuiz";
import { MediaAsset } from "../../lib/Database/DataInterface";
import { css } from "emotion";
import { NoteCardType } from "../Cards/CardPage";

export const ExportLearn = () => {
  const style = useSettingCardStyle();
  // const appctx = useContext(AppContext);
  // const tstyle = useStyles();
  const [back_open, setBackOpen] = useState(false);

  return (
    <Card className={style.card}>
      <CardContent>
        <Typography className={style.cardTitle}>
          导入、导出学习资料库
        </Typography>
      </CardContent>
      <CardContent>
        <Grid container>
          <Grid item>
            <Button variant="outlined" color="default">
              <Input
                className={css`
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0
                  backgroung-color: transparent;
                `}
                type="file"
                onChange={async (e: any) => {
                  let file = e.target.files[0];

                  if (!!file) {
                    setBackOpen(true);
                    //reader.onload = async (e) => {
                    //  let data = e.target?.result as string;
                    //  if (!!data) {
                    //    await LoadLearn(data);
                    //    setBackOpen(false);
                    //  }
                    //};
                    await LoadLearn(file);
                    setBackOpen(false);
                    //reader.readA(file);
                  }
                }}
              />
              导入
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={async () => {
                let file = await GetExportLearn();
                downloadKnowledge("learn", file);
              }}
            >
              导出
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Backdrop
        open={back_open}
        style={{
          zIndex: 999999,
          color: "#fff",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
};

export async function GetExportLearn(root = "root") {
  const db = await getDatabase();
  const quizCols = await (
    await getObjectStore(db, StoreName.quiz_collection)
  ).getAll();
  const quizs: QuizCardData[] = await (
    await getObjectStore(db, StoreName.quiz_card)
  ).getAll();

  let knowMap = new Map<string, KnowledgeCard>();

  const getK = async (id: string) => {
    if (!knowMap.has(id)) {
      const rootCard: KnowledgeCard = await (
        await getKnowledgeCard_Read(db)
      ).get(id);
      knowMap.set(id, rootCard);
      for (let i = 0; i < rootCard.children.length; i++) {
        const e = rootCard.children[i];
        await getK(e);
      }
    }
  };

  await getK(root);

  let knowledges: KnowledgeCard[] = [];
  if (root !== "root")
    knowledges.push({
      uuid: "root",
      children: [root],
      type: NoteCardType.collection,
    } as any);
  let ro = knowMap.get(root);
  if (ro) ro.parent = "root";
  knowMap.forEach((v) => {
    knowledges.push(v);
  });

  let medias = new Set<string>();
  for (let i = 0; i < quizs.length; i++) {
    const quiz = quizs[i];
    if (quiz.assets) {
      for (let j = 0; j < quiz.assets.length; j++) {
        const a = quiz.assets[j];
        medias.add(a);
      }
    }
  }
  for (let i = 0; i < knowledges.length; i++) {
    const kno = knowledges[i];
    if (kno.doc) {
      getFromDoc(kno.doc.document, { media: medias });
    }
  }
  let mediaArray: MediaAsset[] = [];
  let me = [...medias];
  for (let i = 0; i < me.length; i++) {
    const m = me[i];
    let data = await (await getObjectStore(db, StoreName.media)).get(m);
    mediaArray.push(data);
  }

  let zip = new JSZip();
  zip.file("quizCol.database", JSON.stringify(quizCols));
  zip.file("quizs.database", JSON.stringify(quizs));
  zip.file("knowledges.database", JSON.stringify(knowledges));
  zip.file("medias.database", JSON.stringify(mediaArray));
  let d: Blob = await zip.generateAsync({ type: "blob" });
  return d;
}

export async function LoadLearn(data: string | Blob) {
  let zip = new JSZip();
  await zip.loadAsync(data);
  const db = await getDatabase();
  let quizCol: QuizCardCollection[] = JSON.parse(
    await zip.files["quizCol.database"].async("text")
  );
  let quizs: QuizCardData[] = JSON.parse(
    await zip.files["quizs.database"].async("text")
  );
  let knows: KnowledgeCard[] = JSON.parse(
    await zip.files["knowledges.database"].async("text")
  );
  let medias: MediaAsset[] = JSON.parse(
    await zip.files["medias.database"].async("text")
  );

  for (let i = 0; i < quizCol.length; i++) {
    const e = quizCol[i];
    await (
      await getObjectStore(db, StoreName.quiz_collection, "readwrite")
    ).put(e);
  }
  for (let i = 0; i < quizs.length; i++) {
    const e = quizs[i];
    await (await getObjectStore(db, StoreName.quiz_card, "readwrite")).put(e);
  }
  for (let i = 0; i < knows.length; i++) {
    const e = knows[i];
    if (e.uuid !== "root") {
      await (await getKnowledgeCard_Write(db)).put(e);
    } else {
      let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        "root"
      );
      if (root === undefined) root = { children: [], uuid: "root" } as any;
      let array = [...root.children, ...e.children];
      let child: string[] = [];
      for (let i = 0; i < array.length; i++) {
        const e = array[i];
        if (child.indexOf(e) === -1) child.push(e);
      }
      root.children = child;
      //console.log(child)
      await (await getKnowledgeCard_Write(db)).put(root);
    }
  }
  for (let i = 0; i < medias.length; i++) {
    const e = medias[i];
    await (await getObjectStore(db, StoreName.media, "readwrite")).put(e);
  }
  return knows.find((v) => v.uuid === "root")?.children[0];
}

export const downloadKnowledge = (filename: string, context: Blob) => {
  var eleLink2 = document.createElement("a");
  eleLink2.download = filename + ".databackup";
  eleLink2.style.display = "none";

  eleLink2.href = URL.createObjectURL(context);
  // 触发点击
  document.body.appendChild(eleLink2);
  eleLink2.click();
  // 然后移除
  document.body.removeChild(eleLink2);
};
