import { makeStyles, createStyles, Theme } from "@material-ui/core";
import JSZip from "jszip";
import {
  PageRawData,
  PageFCB,
  MediaAsset,
  PageCache,
} from "../../lib/Database/DataInterface";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import md5 from "blueimp-md5";
import { createClient } from "webdav/web";
import { NoteCardRecord } from "../Cards/CardPage";
import { GetExportLearn } from "./ExportLearn";

export const useSettingCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: 10,
    },
    card: {
      [theme.breakpoints.up("sm")]: {
        margin: 20,
        width: 400,
      },
      [theme.breakpoints.down("sm")]: {
        margin: 10,
        width: 300,
      },
    },
    cardTitle: {
      fontSize: 20,
    },
    cardContent: {
      margin: 5,
    },
  })
);

export async function RecoveryData(
  account: string,
  password: string,
  config: any
) {
  let client = createClient("/jianguo", {
    username: account,
    password: password,
  });

  if (await client.exists("/ZhiShuDaLi/data.zip")) {
    //setIsLoading(true)
    //setProgress(0)
    //setLoadType("upload")

    const buff = await client.getFileContents("/ZhiShuDaLi/data.zip");
    let zip = new JSZip();
    await zip.loadAsync(buff);

    let pages: PageRawData[] = JSON.parse(
      await zip.files["pages.json"].async("text")
    );
    let fcbs: PageRawData[] = JSON.parse(
      await zip.files["fcbs.json"].async("text")
    );

    let caches: PageCache[] = [];
    try {
      caches = JSON.parse(await zip.files["cache.json"].async("text"));
    } catch (error) {
      console.warn("未找到同步文件中的caches");
    }

    const db = await getDatabase();
    await (await getObjectStore(db, StoreName.pages, "readwrite")).clear();
    for (let i = 0; i < pages.length; i++) {
      const p = pages[i];
      try {
        await (await getObjectStore(db, StoreName.pages, "readwrite")).put(p);
      } catch (error) {
        console.log(error);
      }
    }

    await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).clear();
    for (let i = 0; i < fcbs.length; i++) {
      const p = fcbs[i];
      try {
        await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).put(
          p
        );
      } catch (error) {
        console.log(error);
      }
    }

    try {
      let cards: NoteCardRecord[] = JSON.parse(
        await zip.files["cards.json"].async("text")
      );
      await (await getObjectStore(db, StoreName.cards, "readwrite")).clear();
      for (let i = 0; i < cards.length; i++) {
        const p = cards[i];
        try {
          await (await getObjectStore(db, StoreName.cards, "readwrite")).put(p);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }

    localStorage.setItem("lastUpdate", JSON.stringify(new Date()));
    localStorage.setItem("data-md5", config.config.md5);

    if (config && config.media && config.media.mediaList) {
      for (let i = 0; i < config.media.mediaList.length; i++) {
        const id = config.media.mediaList[i];
        if (!(await (await getObjectStore(db, StoreName.media)).get(id))) {
          let data = await client.getFileContents("/ZhiShuDaLi/media/" + id);

          let dec = new TextDecoder().decode(data);
          let asset = JSON.parse(dec);
          await (await getObjectStore(db, StoreName.media, "readwrite")).put(
            asset
          );
        }
      }
    }

    await (await getObjectStore(db, StoreName.page_cache, "readwrite")).clear();
    for (let i = 0; i < caches.length; i++) {
      const p = caches[i];
      try {
        await (await getObjectStore(db, StoreName.page_cache, "readwrite")).put(
          p
        );
      } catch (error) {
        console.log(error);
      }
    }

    return true;
  }
}

export function ab2str(buf: ArrayBuffer) {
  return String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));
}

export async function UploadData(
  account: string,
  password: string,
  configAddition?: any
) {
  let { setIsLoading, setProgress, setLoadType, media: oldMedia } =
    configAddition || {};
  let client = createClient("/jianguo", {
    username: account,
    password: password,
  });

  if ((await client.exists("/ZhiShuDaLi")) === false) {
    await client.createDirectory("/ZhiShuDaLi");
  }
  if ((await client.exists("/ZhiShuDaLi/media")) === false) {
    await client.createDirectory("/ZhiShuDaLi/media");
  }

  if (setIsLoading) setIsLoading(true);
  if (setProgress) setProgress(0);
  if (setLoadType) setLoadType("upload");

  let zip = new JSZip();
  const db = await getDatabase();
  const pages: PageRawData[] = await (
    await getObjectStore(db, StoreName.pages)
  ).getAll();
  const fcbs: PageFCB[] = await (
    await getObjectStore(db, StoreName.fcb_pages)
  ).getAll();
  const caches: PageCache[] = await (
    await getObjectStore(db, StoreName.page_cache)
  ).getAll();
  const cards: NoteCardRecord[] = await (
    await getObjectStore(db, StoreName.cards)
  ).getAll();
  //const learn = await GetExportLearn()

  let oldm = localStorage.getItem("data-md5");

  let ps = JSON.stringify(pages);
  let fs = JSON.stringify(fcbs);
  let cache = JSON.stringify(caches);
  let card = JSON.stringify(cards);

  let m = md5(md5(ps) + md5(fs) + md5(card));

  if (m === oldm) {
    console.log("没有更改文档");
  } else {
    zip.file("pages.json", ps);
    zip.file("fcbs.json", fs);
    zip.file("cache.json", cache);
    zip.file("cards.json", card);

    let blob = await zip.generateAsync({ type: "blob" });

    await client.putFileContents("/ZhiShuDaLi/data.zip", blob, {
      onUploadProgress: (progress: any) => {
        if (setProgress) setProgress((progress.loaded / progress.total) * 100);
      },
    });

    let allMediaS = new Set<string>();
    let allmediaKey = await (
      await getObjectStore(db, StoreName.page_cache)
    ).getAllKeys();
    for (let i = 0; i < allmediaKey.length; i++) {
      const el = allmediaKey[i].toString();
      const ass = await (await getObjectStore(db, StoreName.page_cache)).get(
        el
      );

      if (ass && ass.asset) {
        for (let k = 0; k < ass.asset.length; k++) {
          const element = ass.asset[k];
          allMediaS.add(element);
        }
      }
    }
    let allMedia = Array.from(allMediaS);

    for (let i = 0; i < allMedia.length; i++) {
      const el = allMedia[i];
      if (
        el &&
        (!oldMedia ||
          !oldMedia.mediaList ||
          oldMedia.mediaList.indexOf(el) === -1)
      ) {
        const data: MediaAsset = await (
          await getObjectStore(db, StoreName.media)
        ).get(el);
        if (data) {
          client.putFileContents(
            "/ZhiShuDaLi/media/" + el,
            new TextEncoder().encode(JSON.stringify(data)),
            {
              onUploadProgress: (progress: any) => {
                if (setProgress)
                  setProgress((progress.loaded / progress.total) * 100);
              },
            }
          );
          console.log("上传" + data.uuid);
        }
      }
    }
    let config = new JSZip();
    config.file(
      "index",
      JSON.stringify({
        lastUpdate: new Date(),
        md5: m,
      })
    );

    config.file(
      "media",
      JSON.stringify({
        mediaList: allMedia,
      })
    );

    let configBlob = await config.generateAsync({ type: "blob" });
    await client.putFileContents("/ZhiShuDaLi/index", configBlob, {
      onUploadProgress: (progress: any) => {
        if (setProgress) setProgress((progress.loaded / progress.total) * 100);
      },
    });

    localStorage.setItem("lastUpdate", JSON.stringify(new Date()));
    localStorage.setItem("data-md5", m);
  }

  if (setIsLoading) setIsLoading(false);
}

export async function SyncData(
  action?: "recovery" | "upload",
  configAdd?: any
) {
  configAdd = configAdd || {};
  let account = localStorage.getItem("webdavAccount");
  let password = localStorage.getItem("webdavPassword");

  if (account && password && account !== "" && password !== "") {
    let client = createClient("/jianguo", {
      username: account,
      password: password,
    });
    let config: any = {};
    let media: any = {};
    if (await client.exists("/ZhiShuDaLi/index")) {
      let data = await client.getFileContents("/ZhiShuDaLi/index");
      let zip = new JSZip();
      await zip.loadAsync(data);
      let con = await zip.files["index"].async("text");
      config = JSON.parse(con);
      try {
        let med = await zip.files["media"].async("text");
        media = JSON.parse(med);
      } catch (error) {
        media = undefined;
        console.log(error);
      }
    }
    configAdd.media = media;
    let last = localStorage.getItem("lastUpdate");
    let lastUpload = new Date();
    if (last) lastUpload = new Date(last);
    let latest = new Date(config.lastUpdate);
    if (action === "recovery" || !last || lastUpload < latest) {
      console.log("恢复");
      await RecoveryData(account, password, { config, media });
      return true;
    } else {
      console.log("上传");
      await UploadData(account, password, configAdd);
      return true;
    }
  }
  return false;
}

export function ArrayBuffer2String(buffer: ArrayBuffer) {
  let uint8s = new Uint8Array(buffer);
  let res = "";
  for (let i = 0; i < uint8s.length; i++) {
    const num = uint8s[i];
    res += String.fromCharCode(num);
  }
  return res;
}

export function String2ArrayBuffer(data: string) {
  let buffer = new ArrayBuffer(data.length);
  let uint8 = new Uint8Array(buffer);
  for (let i = 0; i < data.length; i++) {
    const s = data.charCodeAt(i);
    uint8[i] = s;
  }
  return buffer;
}
