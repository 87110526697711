import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  Box
} from "@material-ui/core";

import React, {
  useState,
  useCallback,
  Fragment,
  useLayoutEffect,
  useRef
} from "react";
import { Paper, Container } from "@material-ui/core";
import { TabPanel } from "../../AppCom/TabPanel";
import { BlockEditor, useBlockEditorStyle } from "../../elements/BlockEditor";
import { MindMap } from "../../elements/mindmap/MindMap";
import { cloneDeep } from "lodash";
import {
  getDatabase,
  getObjectStore,
  StoreName
} from "../../lib/Database/Database";
import { PageRawData, PageCache } from "../../lib/Database/DataInterface";
import { useInterval } from "../../lib/CommonHook";
import md5 from "blueimp-md5";
import { useParams, Redirect } from "react-router-dom";
import { useDivices } from "../../lib/hooks/useDevices";
import { BlockEditorRuntime } from "./useBlockEditor";
import { selectAllKeyline } from "../../elements/eles/KeyWorldElement";
import { EditorHelpPopover } from "./EditorHelpPopover";

async function saveDoc(cdo: any) {
  if (!!cdo.current && cdo.current.uuid !== "") {
    const db = await getDatabase();
    cdo.current.md5 = md5(JSON.stringify(cdo.current.document));
    let cache: PageCache = await (
      await getObjectStore(db, StoreName.page_cache)
    ).get(cdo.current.uuid);
    if (!cache) cache = { uuid: cdo.current.uuid };

    // 获取所有关键句
    cache.kno = selectAllKeyline(cdo.current.document);

    // 获取所有资源
    let allAssets = new Set<string>();
    const forAllNode = (nodes: any[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const el = nodes[i];
        if (el.assetID) {
          allAssets.add(el.assetID);
        }
        if (el.children) {
          forAllNode(el.children);
        }
      }
    };
    forAllNode(cdo.current.document);
    cache.asset = Array.from(allAssets);

    await (await getObjectStore(db, StoreName.page_cache, "readwrite")).put(
      cache
    );
    await (await getObjectStore(db, StoreName.pages, "readwrite", false)).put(
      cdo.current
    );
  }
}

export const EditorArea = ({
  runtime,
  classes
}: {
  runtime: BlockEditorRuntime;
  classes: any;
}) => {
  const { editor_tab_value, title } = runtime;
  const { id } = useParams();
  const divice = useDivices();

  // 储存思维导图的数据，由文档数据转化而来
  const [mind_value, setMindValue] = useState(
    PageDataToMindMap(runtime.currentDoc)
  );

  // 当文档更新后动态更新思维导图和文档数据
  const SetDocValueCallback = useCallback(
    (newvalue: PageRawData) => {
      runtime.setCurrentDoc(newvalue);
      setMindValue(PageDataToMindMap(newvalue.document));
    },
    [setMindValue, runtime]
  );

  useInterval(() => {
    saveDoc(runtime.currentDoc);
  }, 500);

  const SetValue = useCallback(
    (newvalue: any) => {
      runtime.currentDoc.current.document = newvalue.doc;
      runtime.currentDoc.current.tags = newvalue.tags;
      SetDocValueCallback(runtime.currentDoc.current);
    },
    [runtime, SetDocValueCallback]
  );

  const style = useBlockEditorStyle();
  const [mapid, setMapid] = useState("");
  let valid = useRef(true);
  useLayoutEffect(() => {
    if (id && id !== runtime.current_doc_uuid) {
      const getDoc = async () => {
        runtime.set_current_uuid(id);
        const db = await getDatabase();
        const doc_store = await getObjectStore(db, StoreName.pages);
        const doc: PageRawData = await doc_store.get(id);
        if (!!doc) {
          runtime.setCurrentDoc(doc);
          setMindValue(PageDataToMindMap(doc.document));
          setMapid(doc.uuid);
          valid.current = true;
        } else {
          runtime.set_current_uuid("");
          valid.current = false;
        }
      };
      getDoc();
    }
  }, [
    id,
    runtime.current_doc_uuid,
    runtime.setCurrentDoc,
    SetDocValueCallback,
    runtime.set_current_uuid,
    runtime
  ]);
  if (!valid.current)
    return (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    );
  return (
    <Fragment>
      <TabPanel value={editor_tab_value} index={0}>
        <div className={classes.toolbar}></div>
        <Paper
          className={
            divice.whenDesktop ? classes.content : classes.mobile_content
          }
        >
          <div className={style.editor}>
            <BlockEditor
              mvalue={runtime.currentDoc.current}
              setValue={(v: any) => {
                SetValue(v);
              }}
            />
          </div>
        </Paper>
      </TabPanel>
      <TabPanel value={editor_tab_value} index={1}>
        <div className={classes.toolbar}></div>
        <Paper
          className={`mind-map-paper ${
            divice.whenDesktop ? classes.content : classes.mobile_content
          }`}
        >
          <div className="mind-map-top">
            <Container>
              <MindMap
                value={mind_value}
                title={title}
                docuuid={runtime.current_doc_uuid}
                key={mapid}
              />
            </Container>
          </div>
        </Paper>
      </TabPanel>
      <Box style={{ position: "fixed", right: 10, bottom: 10 }}>
        <EditorHelpPopover />
      </Box>
    </Fragment>
  );
};

export function PageDataToMindMap(data: any) {
  return cloneDeep(data);
}

const useHelperEditorPageStyle = makeStyles(() =>
  createStyles({
    body: {
      minHeight: 1080
    },
    helperText: {
      color: "rgb(200,200,200)"
    }
  })
);
export const HelpEditorPage = () => {
  const style = useHelperEditorPageStyle();
  const divice = useDivices();
  return (
    <Grid
      className={style.body}
      container
      alignContent="center"
      justify="center"
    >
      <Grid item className={style.helperText}>
        {divice.whenDesktop && (
          <Typography>这里空空如也，点击左上角按钮新建文档哦~</Typography>
        )}
        {(divice.whenMobile || divice.whenPad) && (
          <Typography>这里空空如也，点击右下角按钮新建文档哦~</Typography>
        )}
      </Grid>
    </Grid>
  );
};
