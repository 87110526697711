import React, {
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useEffect,
  useState,
  useReducer,
  Fragment,
} from "react";

import { createEditor, Node } from "slate";
import { withReact, ReactEditor, Slate, Editable } from "slate-react";

import { Leaf } from "../Leaf";
import { MElement } from "../Elements";

import { EditorHelper } from "../EditorMethods";
import { withMath } from "../plugins/withMath";
import { KeyWordPlugin } from "../plugins/withKeyWord";

import "./css/MindMap.css";

import clsx from "clsx";

import {
  Card,
  CardContent,
  Collapse,
  CardActions,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { red } from "@material-ui/core/colors";
import { useSimpleEditor } from "../plugins/withBlockDefault";

interface MNodeProp {
  /**
   * 用于渲染的值
   */
  value: Node[];
  /**
   * slate子节点，显示在下一级node
   * */
  children: any[];
  /**
   * 该元素的slate的element type
   */
  type: string;
  /**
   * 用以传递父节点的引用
   */
  ref?: any;
  /**
   * 删除一根连线
   */
  addLine: Function;
  /**
   * 增加一根连线
   */
  removeLine: Function;
  docuuid: string;
}

/**
 * 查找拥有最低级标题的目标
 * @param array slate元素列表
 */
function findMin(array: any[]) {
  let min = LEVEL[array[0].type];
  for (let i = 0; i < array.length; i++) {
    const e = array[i];
    const l = LEVEL[e.type];
    if (l < min) min = l;
  }
  return min;
}

/**
 * 校验输出元素大小
 * @param node 需要校验的dom节点的Ref
 */
function parseEle(node: any) {
  if (!node || !node.current)
    return {
      offsetLeft: 0,
      offsetTop: 0,
      offsetHeight: 0,
      offsetWidth: 0,
      notvalid: true,
    };
  return node.current;
}

/**
 * 这是一个转发元素，能获取父节点和自身的dom元素，从而计算连线。
 * 且包含渲染slate的功能
 */
export const MNode
  = forwardRef(
    ({ value, children, type, addLine, removeLine, docuuid }: MNodeProp, parent_ref) => {
      /**
       * 自身的span引用
       */
      const self = useRef() as React.MutableRefObject<HTMLSpanElement>;
      // 组件的唯一标识
      const [symbol] = useState(Symbol());

      // 计算自身和父组件的大小
      const p = parseEle(parent_ref); // 父
      const s = parseEle(self); // 自己

      // 储存展开节点的状态
      const [expanded, setExpanded] = React.useState(false);

      /**
       * 处理展开回调
       */
      const handleExpandClick = useCallback(
        (e) => {
          setExpanded(!expanded);
        },
        [expanded]
      );

      /**
       * 样式
       */
      const classes = useStyles();

      // 用于处理添加、删除线
      useEffect(() => {
        addLine(symbol, {
          startref: parent_ref,
          endref: self,
          s_v: p.notvalid,
          e_v: s.notvalid,
        });
        return () => {
          removeLine(symbol);
        };
      }, [
        s.offsetTop,
        s.offsetLeft,
        p.offsetLeft,
        p.offsetTop,
        addLine,
        removeLine,
        p.notvalid,
        s.notvalid,
        parent_ref,
        self,
        symbol,
      ]);

      // 用于渲染slate元素的必要函数
      const renderElemment = useCallback((props) => <MElement {...props} />, []);
      const renderLeaf = useCallback((props) => {
        return <Leaf {...props} />;
      }, []);

      /**
       * 主节点slate editor
       * * 若存在isVoid或isInline等重写插件，需要在此绑定，否则会出现显示错误
       */
      const editor = useSimpleEditor();

      /**
       * 扩展面板的editor，只有在存在需要展开面板时才计算
       */
      const editor2 = useMemo(
        () =>
          type === "ph"
            ? (new EditorHelper(createEditor())
              .bind(withReact)
              .bind(withMath)
              .complete() as ReactEditor)
            : editor,
        [editor, type]
      );

      // 如果没有什么可以渲染的，直接返回
      //if (value === null) return null;

      // 用于处理值，从而影响渲染结果
      // 因为标题需要渲染成普通加粗文本而非文档中的标题样式
      // 普通文本则需要显示关键词
      // 因此需要在此修改样式
      const [renderValue] = useReducer(
        (s: any, ns: any) => {
          // 没有需要处理的需求
          return ns;
        },
        { value, type },
        (v) => {
          if (v.value === null) return null;
          if (v.type === "ph") {
            // 若是自然段，则选择所有关键词输出
            return KeyWordPlugin.selectKeyWorld({
              children: v.value,
              type: "",
            });
          } else if (isTitle(v.type)) {
            // 若是标题，则以节点样式输出
            return [{ type: "node-head", children: v.value }];
          } else {
            // 否则以原样输出
            return v.value;
          }
        }
      );

      // 如果没东西就直接返回（不知道这一行会是什么个情况呢？就当保险吧）
      if (renderValue === null || (renderValue.length !== undefined && renderValue.length === 0))
        return null;

      // 处理子节点的工作
      // 首先找到层次等级最低的节点，然后将所有这个层次节点之间的 节点层次更高节点 打包成最近（从上往下）的节点的children
      if (children.length > 0) {
        /**
         * 储存下一层次要显示的所有节点
         */
        let cld = [];
        const thisLevel = LEVEL[type];
        const targetLevel = findMin(children);
        let cur: any = {
          children: [],
          value: [{ type: NAME[targetLevel], text: "" }],
          hide: true,
          type: "ph",
        };

        // Leaves若本节点不在显示范围内，则不显示
        if (thisLevel === undefined) return null;

        for (let i = 0; i < children.length; i++) {
          const e = children[i];
          const et = !!e.type ? LEVEL[e.type] : 99;

          if (et <= targetLevel) {
            if (!cur.hide) {
              if (
                !isTitle(cur.type) &&
                cur.children.filter((v: any) => isTitle(v.type)).length === 0
              ) {
                let ke = 0;
                //for (let j = 0; j < cur.value.length; j++) {
                //const eee = cur.value[j];
                ke += KeyWordPlugin.selectKeyWorld({
                  children: cur.value,
                  type: "",
                }).length;
                //}
                if (ke > 0) {
                  cld.push(cur);
                }
              } else {
                cld.push(cur);
              }
            }

            cur = { children: [], value: e.children, type: e.type };
            cur.hide = false;
          } else if (et !== undefined) {
            if (cur.value[0].text === "") {
              cur.value[0] = e;
            }
            cur.children.push(e);
            cur.hide = false;
          }
        }
        cld.push(cur);
        // 显示有子节点的情形
        return (
          <span className="mind-canvas">
            <span ref={self}>
              <Card style={{ maxWidth: "300px", lineHeight: "180%" }}>
                <CardContent style={{ paddingBottom: "14px" }}>
                  <Slate
                    editor={editor}
                    value={renderValue}
                    onChange={(value) => { }}
                  >
                    <Editable
                      renderElement={renderElemment}
                      renderLeaf={renderLeaf}
                      readOnly
                    ></Editable>
                  </Slate>
                  <Divider style={{ marginTop: "8px" }} />
                </CardContent>
              </Card>
            </span>
            <span className="mind-canvas-friends">
              {cld.map((e, ind) => {
                if (e.value !== undefined && e.value !== null)
                  return (
                    // ! 这里索引可能会有问题，但暂时没遇到
                    <span key={docuuid + ind}>
                      <MNode
                        ref={self}
                        value={e.value}
                        children={e.children}
                        type={e.type}
                        addLine={addLine}
                        removeLine={removeLine}
                        docuuid={docuuid}
                        key={docuuid + ind}
                      ></MNode>
                    </span>
                  );
                return null;
              })}
            </span>
          </span>
        );
      }
      // 下面是没有子节点的情形
      else {
        return (
          <span className="mind-canvas">
            <span ref={self}>
              <Card style={{ maxWidth: "300px", lineHeight: "180%" }}>
                <Grid container justify="space-between">
                  <Grid item>
                    <CardContent style={{ paddingBottom: "14px" }}>
                      <Slate
                        editor={editor}
                        value={renderValue}
                        onChange={(value) => { }}
                      >
                        <Editable
                          renderElement={renderElemment}
                          renderLeaf={renderLeaf}
                          readOnly
                        ></Editable>
                      </Slate>
                      {type !== "ph" ? (
                        // 如果不是段落，则给这个独立节点一个下划线（好看一点）
                        <Divider style={{ marginTop: "8px" }} />
                      ) : null}
                    </CardContent>
                  </Grid>
                  {type === "ph" ? (
                    // 如果是段落，则显示展开面板，展开后显示原文
                    <Grid item>
                      <span>
                        <CardActions disableSpacing>
                          <IconButton
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </CardActions>
                      </span>
                    </Grid>
                  ) : null}
                </Grid>
                {type === "ph" ? (
                  <Fragment>
                    <Divider />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Slate
                          editor={editor2}
                          value={value}
                          onChange={(value) => { }}
                        >
                          <Editable
                            renderElement={renderElemment}
                            renderLeaf={renderLeaf}
                            readOnly
                          ></Editable>
                        </Slate>
                      </CardContent>
                    </Collapse>
                  </Fragment>
                ) : null}
              </Card>
            </span>
          </span>
        );
      }
    }
  );

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
);

const LEVEL: { [key: string]: number } = {
  title: 0,
  H1: 1,
  H2: 2,
  H3: 3,
  H4: 4,
  H5: 5,
  H6: 6,
  ph: 99,
};

const NAME: { [key: number]: string } = {
  0: "title",
  1: "H1",
  2: "H2",
  3: "H3",
  4: "H4",
  5: "H5",
  6: "H6",
  99: "ph",
};

const isTitle = (t: string) => {
  if (t[0] === "H") return true;
  return false;
};
