import {
  Fab,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useAnchorOpen } from "../../lib/hooks/useAnchorOpen";

export const EditorHelpPopover = () => {
  const anchorOpen = useAnchorOpen();
  return (
    <Fragment>
      <Fab onClick={anchorOpen.handleClick} size="medium">
        <div style={{ fontSize: 30 }}>?</div>
      </Fab>
      <Popover
        onClose={anchorOpen.handleClose}
        open={anchorOpen.open}
        anchorEl={anchorOpen.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <List>
          <ListItem>
            <ListItemText>ShortCuts</ListItemText>
            <Divider />
          </ListItem>
          <ListItem>#[空格]（或#1[空格]） 一级标题</ListItem>
          <ListItem>##[空格]（或#2[空格]） 二级标题</ListItem>
          <ListItem>……</ListItem>
          <ListItem>######[空格]（或#6[空格]） 六级标题</ListItem>
          <ListItem>*[空格] 无序列表</ListItem>
          <ListItem>-[空格] 数字列表</ListItem>
          <ListItem>+[空格] 待办事项</ListItem>
          <ListItem>$$[空格] latex公式</ListItem>
          <ListItem>```[空格] 插入代码</ListItem>
          <ListItem>@@ 呼出其他可插入组件</ListItem>
          <ListItem>
            <Divider />
          </ListItem>
        </List>
      </Popover>
    </Fragment>
  );
};
