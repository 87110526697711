import React from "react";
import "../css/NodeElement.css";

export const NodeHead = (props: any) => {
  return (
    <span {...props.attributes} className={"node-head"}>
      {props.children}
    </span>
  );
};
