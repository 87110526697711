import { Editor, Transforms, Node } from "slate";
import { PluginUtil } from "./PluginUtil";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import { toKeyLink } from "../../AppPages/Learn/Knowledge/KnowledgeLink";

/**
 * 用于关键词的操作
 */
export const KeyWordPlugin = {
  /**
   * 切换关键词状态
   * @param level 关键词重要程度，1最重要
   */
  toggleKeyWord: (docUUID: string, editor: Editor, level: number) => {
    const isKeyword = KeyWordPlugin.isKeyWord(editor);

    if (!isKeyword) {
      Transforms.wrapNodes(
        editor,
        { type: "keyword", children: [], level: level },
        { split: true }
      );
    } else {
      const kw: any = KeyWordPlugin.getKeyWord(editor);
      if (kw && kw.bind) {
        const fn = async () => {
          const db = await getDatabase();
          const kl = toKeyLink(
            kw.bind,
            await(await getObjectStore(db, StoreName.knowledgeLink)).get(
              kw.bind
            )
          );
          kl.children = kl.children.filter((v) => v !== docUUID);
          await(
            await getObjectStore(db, StoreName.knowledgeLink, "readwrite")
          ).put(kl);
        };
        fn();
      }
      Transforms.unwrapNodes(editor, {
        match: (n) => n.type === "keyword",
      });
    }
  },
  getKeyWord: (editor: Editor) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "keyword",
      mode: "all",
    });
    return match;
  },
  isKeyWord: (editor: Editor, level?: number) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "keyword",
      mode: "all",
    });
    if (!!level) {
      return !!match && match[0].level === level;
    }
    return !!match;
  },
  /**
   * 切换关键句状态
   * @param level 关键词重要程度，1最重要
   */
  toggleKeyLine: (editor: Editor) => {
    const isKeyword = KeyWordPlugin.isKeyLine(editor);

    if (!isKeyword) {
      Transforms.wrapNodes(
        editor,
        { type: "keyline", children: [] },
        { split: true }
      );
    } else {
      Transforms.unwrapNodes(editor, {
        match: (n) => n.type === "keyline",
      });
    }
  },

  isKeyLine: (editor: Editor) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "keyline",
      mode: "all",
    });
    return !!match;
  },
  /**
   * 选择一个元素上的所有关键词
   */
  selectKeyWorld: (node: Node) => {
    if (node.type === "keyword") return [node];
    let rr: Node[] = [];
    if (node.children !== undefined) {
      for (let i = 0; i < node.children.length; i++) {
        const e = node.children[i];
        const res = KeyWordPlugin.selectKeyWorld(e);
        for (let j = 0; j < res.length; j++) {
          const r = res[j];
          rr.push(r);
        }
      }
    }
    return rr;
  },
};

/**
 * 关键词的编辑器附加物
 * @param editor
 */
export const withKeyword = (editor: Editor) => {
  const { isInline, insertBreak, normalizeNode, deleteBackward } = editor;
  editor.isInline = (e) => {
    return e.type === "keyword" || e.type === "keyline" || isInline(e);
  };
  editor.insertBreak = () => {
    if (
      PluginUtil.MakeNewLineWithEmptyFormat(
        editor,
        (n) => n.type === "keyword" || n.type === "keyline"
      )
    ) {
      return;
    }

    insertBreak();
  };

  editor.normalizeNode = (entry) => {
    if (entry[0].type === "keyword" || entry[0].type === "keyline") {
      const node = entry[0];
      if (node.children.length === 1 && node.children[0].text === "") {
        Transforms.unwrapNodes(editor, {
          at: entry[1],
          match: (n) => n.type === "keyword" || n.type === "keyline",
        });
        return;
      }
    }

    normalizeNode(entry);
  };

  editor.deleteBackward = (unit) => {
    deleteBackward(unit);
  };
  return editor;
};
