import React from "react";
import { css } from "emotion";

export const Leaf = (props: any) => {
  const leaf = props.leaf;
  return (
    <span
      {...props.attributes}
      className={css`
        font-weight: ${leaf.bold && "bold"};
        font-style: ${leaf.italic && "italic"};
        text-decoration: ${leaf.underlined && "underline"};
      `}
    >
      {props.children}
    </span>
  );
};
