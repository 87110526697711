import { Editor, Transforms, createEditor, Point } from "slate";
import { useMemo, useCallback } from "react";
import { EditorHelper } from "../EditorMethods";
import { withReact, ReactEditor } from "slate-react";
import { withMath } from "./withMath";
import { MElement } from "../Elements";
import React from "react";
import { Leaf } from "../Leaf";
import { withHistory } from "slate-history";
import { withShortcuts, withInlineShortcuts } from "./withShortcuts";
import { withChecklist } from "./withChecklist";
import { withKeyword } from "./withKeyWord";
import { withQuiz } from "./withQuiz";

export const withBlockDefault = (editor: Editor) => {
  const { isVoid, normalizeNode } = editor;
  editor.isVoid = (e) => {
    return e.type === "image" || e.type === "code-area" || isVoid(e);
  };
  editor.deleteFragment = () => {
    // 重写删除片段方法，修复剪切时删除部分元素失败的问题
    if (editor.selection) Transforms.delete(editor, { at: editor.selection });
  };
  editor.normalizeNode = (entry) => {
    const path = entry[1];
    if (entry[0].children && entry[0].children.length === 0) {
      Transforms.setNodes(
        editor,
        { ...entry[0], children: [{ text: "" }] },
        { at: entry[1] }
      );
      return;
    }
    if (path[0] === editor.children.length - 1) {
      const [last_node_entry] = Editor.nodes(editor, {
        at: [editor.children.length - 1],
        match: (n) => n.type === "ph",
      });
      if (last_node_entry === undefined) {
        Transforms.insertNodes(
          editor,
          { type: "ph", children: [{ text: "" }] },
          { at: [editor.children.length] }
        );
        return;
      }
    }

    // 检查是否在列表项中存在非列表元素
    if (entry[0].type === "bulleted-list" || entry[0].type === "ol") {
      for (let i = 0; i < entry[0].children.length; i++) {
        const cn = entry[0].children[i];
        if (cn.type !== "list-item") {
          Transforms.wrapNodes(
            editor,
            { ...cn, type: "list-item" },
            { at: [...entry[1], i] }
          );
          return;
        }
      }
    }
    //console.log(entry[0]);
    //if (entry[0].type === "link-card") {
    //  let match = Editor.node(editor, [entry[1][0] + 1]);
    //  console.log(match);
    //  if (match) {
    //    let [node, path] = match;
    //
    //    if (node.type !== "ph") {
    //      Transforms.insertText(editor, "", { at: [entry[1][0] + 1] });
    //      return;
    //    }
    //  }
    //}

    //if (entry[0].type === "link-card") {
    //  Transforms.wrapNodes(
    //    editor,
    //    { type: "ph", children: [{ text: "" }, entry[0], { text: "" }] },
    //    { at: entry[1] }
    //  );
    //  return;
    //}

    normalizeNode(entry);
  };

  //const { insertFragment, insertNode } = editor;
  //editor.insertFragment = (nodes) => {
  //  insertFragment(nodes);
  //};
  //editor.insertNode = (node) => {
  //  insertNode(node);
  //};

  return editor;
};

export const newSimpleEditor = () => {
  return new EditorHelper(createEditor())
  .bind(withReact)
  .bind(withBlockDefault)
  .bind(withMath)
  .complete() as ReactEditor
}
export const useSimpleEditor = (dep?: any) => {
  if (!dep) dep = [];
  return useMemo(
    () =>
    newSimpleEditor(),
    // eslint-disable-next-line
    [...dep]
  );
};

export const makeFullEditor = () => {
  return new EditorHelper(createEditor())
    .bind(withReact)
    .bind(withHistory)
    .bind(withShortcuts)
    .bind(withInlineShortcuts)
    .bind(withBlockDefault)
    .bind(withChecklist)
    .bind(withMath)
    .bind(withKeyword)
    .bind(withQuiz)
    .complete() as ReactEditor;
};

export const useSimpleRender = () => {
  return {
    renderElement: useCallback((props) => <MElement {...props} />, []),
    renderLeaf: useCallback((props) => {
      return <Leaf {...props} />;
    }, []),
  };
};

export const isTitle = (type: string) => {
  if (
    type === "H1" ||
    type === "H2" ||
    type === "H3" ||
    type === "H4" ||
    type === "H5" ||
    type === "H6"
  )
    return true;
  return false;
};

export const isElementStart = (point: Point) => {
  const len = point.path.length;
  if (point.offset === 0) {
    for (let i = 1; i < len; i++) {
      if (point.path[i] !== 0) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const BlockEditorPlugin = {
  /**
   * 处理各种换行问题
   */
  fixNewLineWithEnter: (event: any, editor: Editor) => {
    // 这部分处理标题换行后自动将格式设置为普通文本
    const [match] = Editor.nodes(editor, {
      match: (n) => isTitle(n.type),
    });

    // 若是在标题中按下回车
    if (!!match && !!editor.selection) {
      if (isElementStart(editor.selection.focus)) {
        // 当光标在开头时，在光标处插入空白行，并将光标下移一行，达成期望目的
        Transforms.insertNodes(editor, {
          type: "ph",
          children: [{ text: "" }],
        });
        Transforms.move(editor, { distance: 1, unit: "line" });
        event.preventDefault();
        return;
      }
      // 否则断开这一行标题
      Transforms.splitNodes(editor);
      const next = Editor.after(editor, editor.selection.focus, {
        distance: 1,
        unit: "offset",
      });
      const [nextNode] = Editor.nodes(editor, {
        at: next,
        match: (n) => isTitle(n.type),
      });
      // 若断开处是在中间，则将后面那个节点设置为段落block，从而自动分段
      // 第二个条件是判断在同一行
      if (
        !!nextNode &&
        !Editor.isEnd(editor, editor.selection.focus, match[1])
      ) {
        Transforms.setNodes(
          editor,
          { ...nextNode[0], type: "ph" },
          { at: next }
        );
        console.log("inner");
        event.preventDefault();
      } else {
        const [thisNode] = Editor.nodes(editor, {
          match: (n) => isTitle(n.type),
        });
        // 若是断开在结尾，则插入一个空字符串作为新的一行
        if (!!thisNode) {
          Transforms.insertNodes(editor, {
            type: "ph",
            children: [{ text: "" }],
          });
          event.preventDefault();
        }
      }
    }
  },
  forceInsertNewLine: (event: any, editor: Editor) => {
    //Transforms.splitNodes(editor);
    //Transforms.insertNodes(editor, { type: "ph", children: [] });
    //Transforms.unwrapNodes(editor, {});
    //event.preventDefault();
  },
};
