import { ElementProp } from "../common/eleCommon";
import React, { useState, useContext, useCallback } from "react";
import { IconButton, Fade, makeStyles, createStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Transforms } from "slate";
import { ReactEditor, useEditor } from "slate-react";
import { DocContext } from "../BlockEditor";

export const ParagraphWrapper = (prop: ElementProp) => {
  const [over, setOver] = useState(false);
  const style = useParaStyle();
  const editor = useEditor();
  const docctx = useContext(DocContext);
  const openOver = useCallback(()=>{
    if (docctx.edit) setOver(true);
  }, [docctx.edit, setOver])
  const closeOver = useCallback(()=>{
    if (docctx.edit) setOver(false);
  }, [docctx.edit, setOver])
  return (
    <div
      onMouseOver={openOver}
      onMouseLeave={closeOver}
      style={{ position: "relative" }}
    >
      {docctx.edit && !docctx.noAddLineHelper && (
        <Fade in={over}>
          <div className={style.add_button} contentEditable={false}>
            <IconButton
              onClick={(e) => {
                const path = ReactEditor.findPath(editor, prop.element);
                const p = [...path];
                p[0] = p[0] + 1;
                Transforms.insertNodes(
                  editor,
                  { type: "ph", children: [{ text: "" }] },
                  { at: p }
                );
                Transforms.select(editor, p);
                setTimeout(() => {
                  if (docctx.showInsert) docctx.showInsert();
                }, 50);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </Fade>
      )}

      {prop.children}
    </div>
  );
};

const useParaStyle = makeStyles(() =>
  createStyles({
    show: { visibility: "visible" },
    hide: { visibility: "hidden" },
    add_button: {
      position: "absolute",
      left: -50,
      bottom: -10,
      userSelect: "none",
    },
  })
);
