import React, { useContext } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Hidden,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import { ElementProp } from "../common/eleCommon";
import { useRequest } from "@umijs/hooks";
import { DocContext, BlockEditor } from "../BlockEditor";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../../lib/Database/Database";
import { KnowledgeCard } from "../../AppPages/Learn/Knowledge/old_Knowledge";
import { useInterval, useUpdateI } from "../../lib/CommonHook";
import { Node } from "slate";

export const SubDocPreview = ({
  children,
  element,
  attributes,
}: ElementProp) => {
  const ctx = useContext(DocContext);
  const update = useUpdateI();
  const { data: child } = useRequest(
    async () => {
      const db = await getDatabase();
      const d: KnowledgeCard = await (
        await getKnowledgeCard_Read(db)
      ).get(ctx.uuid);
      if (d) {
        let res: { title: Node[]; id: string, ttitle: string }[] = [];
        for (let i = 0; i < d.children.length; i++) {
          const e = d.children[i];
          const t: KnowledgeCard = await (
            await getKnowledgeCard_Read(db)
          ).get(e);
          if (t && t.title !== "") {
            res.push({ title: t.doc.document[0].children, id: t.uuid, ttitle: t.title });
          }
        }
        return res;
      }
    },
    { refreshDeps: [ctx.uuid, update.key] }
  );
  useInterval(() => {
    const check = async () => {
      if (child) {
        const db = await getDatabase();
        const d: KnowledgeCard = await (
          await getKnowledgeCard_Read(db)
        ).get(ctx.uuid);
        for (let i = 0; i < d.children.length; i++) {
          const id = d.children[i];
          const c: KnowledgeCard = await (
            await getKnowledgeCard_Read(db)
          ).get(id);
          if (c.title !== child[i].ttitle || d.children.length !== child.length) {
            update.update();
            break;
          }
        }
      }
    };
    check();
  }, 1000);
  const style = useSubDocStyle();
  return (
    <div {...attributes} className={style.root}>
      <Box contentEditable={false} className={style.box}>
        <Typography className={style.title} color="textSecondary">
          子条目
        </Typography>
        <List>
          {child &&
            child.map((v) => (
              <ListItem key={v.id} className={style.item}>
                <ListItemText className={style.itemText}>
                  <BlockEditor
                    mvalue={{ document: v.title } as any}
                    setValue={() => {}}
                    readonly
                  />
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </Box>
      <div contentEditable={false} className={style.hide}>
        {children}
      </div>
    </div>
  );
};

const useSubDocStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    box: {
      userSelect: "none",
      padding: 10,
      borderRadius: 10,
      borderColor: "#e8e8e8",
      borderStyle: "solid",
      borderWidth: 1,
      boxShadow: "inset 0px 0px 6px #b1b1b1",
      backgroundColor: "#f9f9f9",
    },
    hide: {
      position: "absolute",
      top: 0,
    },
    item: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    itemText: {
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "#dfdfdf",
    },
    title: {
      fontSize: 12,
    },
  })
);
