import React, { useEffect } from "react";
import { Input, Row, Divider, List, Typography, Table } from "antd";
import { Box } from "@material-ui/core";
import { useRequest } from "@umijs/hooks";
import Latex from "react-latex";
import { useStateful } from "../lib/CommonHook";

interface showItem {
  type: "math" | "shortcut";
  show: string;
}

interface searchItem {
  shortcut: string;
  intro: string;
  show: showItem;
  key?: number;
}

function maths(shortcut: string, intro?: string): searchItem {
  return {
    shortcut,
    intro: intro || "",
    show: {
      type: "math",
      show: shortcut,
    },
  };
}

function shortcut(shortcut: string, intro?: string): searchItem {
  return {
    shortcut,
    intro: intro || "",
    show: {
      type: "shortcut",
      show: "",
    },
  };
}

export const InputHelper = () => {
  useEffect(() => {
    for (let i = 0; i < searchdata.length; i++) {
      const e = searchdata[i];
      e.key = i;
    }
  }, []);
  const search = useStateful("");
  const { data } = useRequest(
    async () => {
      return search.value === "" ? searchdata : searchInData(search.value, searchdata);
    },
    { refreshDeps: [search.value], throttleInterval: 500 }
  );
  return (
    <Box
      style={{
        width: 400,
        minHeight: 460,
        backgroundColor: "white",
        boxShadow: "0px 0px 12px 3px #b9b9b9",
      }}
    >
      <Row>
        <Input
          placeholder="搜索公式、语法"
          style={{ width: 345, margin: 10, height: 35, borderStyle: "dashed" }}
          onChange={(e) => search.set(e.target.value)}
          value={search.value}
        />
      </Row>
      <Row>
        <Table
          columns={colums}
          dataSource={data || []}
          style={{ margin: 10, width: 370, minHeight: 400 }}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
        ></Table>
      </Row>
    </Box>
  );
};

const colums = [
  {
    title: "输入",
    dataIndex: "shortcut",
  },
  {
    title: "展示",
    dataIndex: "show",
    render: (item: showItem) => {
      if (item.type === "math") return <Latex>{"$" + item.show + "$"}</Latex>;
      else if(item.type === "shortcut") return "";
      else return <Typography>{item.show}</Typography>;
    },
  },
  {
    title: "介绍",
    dataIndex: "intro",
  },
];

function  searchInData(search: string, list: searchItem[]): searchItem[] {
  let res: Set<searchItem> = new Set();
  let query = search.trim().split(" ")
  for (let i = 0; i < query.length; i++) {
    const e = query[i];
    let tr = searchOneInData(e, list)
    if(tr.length > 0){
      for (let j = 0; j < tr.length; j++) {
        const el = tr[j];
        res.add(el)
      }
    }
  }
  return Array.from(res)
}

function searchOneInData(search: string, list: searchItem[]): searchItem[] {
  let res: searchItem[] = [];
  if (search !== "") {
    for (let i = 0; i < list.length; i++) {
      const e = list[i];
      if (
        e.shortcut.indexOf(search) !== -1 ||
        e.intro.indexOf(search) !== -1 ||
        e.show.show.indexOf(search) !== -1
      )
        res.push(e);
    }
  } else {
    //for (let i = 0; i < 6; i++) {
    //  const e = list[i];
    //  if (e) res.push(e);
    //}
    return list;
  }
  return res;
}

const searchdata: searchItem[] = [
  maths("\\sin", "sin函数"),
  maths("\\cos", "cos函数"),
  maths("\\tan", "tan函数"),
  maths("\\arcsin"),
  maths("\\arccos"),
  maths("\\arctan"),
  maths("\\ln", ""),
  maths("\\log_{a}{x}", ""),
  maths("\\frac{1}{x}", "分数"),
  maths("x^2", "上标，平方，n次方使用^"),
  maths("x_1", "下标使用_"),
  maths("\\bar{x}", "平均"),
  maths("\\max H", "最大"),
  maths("\\min L", "最小"),
  maths("\\dim X", ""),
  maths("\\arg", ""),
  maths("\\deg", ""),
  maths("\\lim_{x\\to 0}{\\sin x}", "极限"),
  maths("\\infty", "无穷"),
  maths("\\to", "箭头，到"),
  maths("\\mod2.2", "取模"),
  maths("\\int_0^1 x", "积分"),
  maths("\\forall", "任意"),
  maths("\\exists", "存在"),
  maths("\\varnothing", "空集"),
  maths("\\in", "属于"),
  maths("\\ni", "属于"),
  maths("\\notin", "不属于"),
  maths("\\subset", "包含于"),
  maths("\\subseteq", "包含于"),
  maths("\\supset", "包含于"),
  maths("\\supseteq", "包含于"),
  maths("\\cap", "并集"),
  maths("\\cup", "交集"),
  maths("\\sqrt{2}", "根号"),
  maths("\\sqrt[n]{3}", "根号"),
  maths("\\sim", "相似"),
  maths("\\approx", "约等于"),
  maths("\\simeq", ""),
  maths("\\cong", ""),
  maths("\\lt", "小于"),
  maths("\\gt", "大于"),
  maths("\\le", "小于等于"),
  maths("\\ge", "大于等于"),
  maths("\\because", "因为"),
  maths("\\therefore", "所以"),
  maths("\\ne", "不等于"),
  maths("\\pm", "正负"),
  maths("\\mp", "负正"),
  maths("\\angle", "角"),
  maths("\\pi", "派，π"),
  maths("\\perp", "垂直"),
  maths("\\leftarrow", "左箭头"),
  maths("\\rightarrow", "右箭头"),
  maths("\\Leftarrow", "左箭头，推出"),
  maths("\\Rightarrow", "右箭头，推出"),
  maths("\\Leftrightarrow", "双向箭头，推出"),
  maths("x\\cdot y", "点乘"),
  maths("x\\times y", "叉乘"),
  maths("\\div", "除"),
  maths("x'", "导数"),
  maths("\\overleftarrow{a b}", "向量"),
  maths("\\overrightarrow{c d}", "向量"),
  maths("\\sum_{k=1}^N k^2", "求和"),
  maths("\\prod_{i=1}^N x_i", "求积"),
  maths("\\iint_a^b dx dy", "二重积分"),
  maths("\\iiint dxdydz", "三重积分"),
  maths("\\oint_C", "曲线积分、曲面积分"),
  maths("\\tfrac{2}{4} = 0.5", "小型分数"),
  maths("\\dbinom{n}{r}=C^n_r", "二项式系数"),
  maths("\\begin{vmatrix}x&y\n\\\\z&v\\end{vmatrix}", "行列式"),
  maths("\\begin{bmatrix}x&y\n\\\\z&v\\end{bmatrix}", "矩阵"),
  maths("f=\\begin{cases}\n1\\\\2\\end{cases}", "选择，大括号，条件"),
  maths("\\alpha", "希腊字母：阿尔法"),
  maths("\\beta", "希腊字母：贝塔β"),
  maths("\\gamma", "希腊字母：伽马γ"),
  maths("\\delta", "希腊字母：德尔塔δ"),
  maths("\\epsilon", "希腊字母：yipuxilong"),
  maths("\\zeta", "希腊字母"),
  maths("\\eta", "希腊字母：伊塔"),
  maths("\\theta", "希腊字母：西塔"),
  maths("\\rho", "希腊字母：ρ，rou"),
  maths("\\sigma", "希腊字母：西格玛"),
  maths("\\tau", "希腊字母"),
  maths("\\upsilon", "希腊字母"),
  maths("\\phi", "希腊字母：fai"),
  maths("\\chi", "希腊字母"),
  maths("\\psi", "希腊字母"),
  maths("\\omega", "希腊字母"),
  shortcut("#[空格]", "一级标题，需要在行首输入"),
  shortcut("##[空格]", "二级标题，需要在行首输入"),
  shortcut("$$[空格]", "数学公式，按Tab键切换编辑与只读模式"),
  shortcut("```[空格]", "代码编辑器，需要在行首输入"),
  shortcut("-[空格]", "有序列表，需要在行首输入"),
  shortcut("*[空格]", "无序列表，需要在行首输入"),
  shortcut("+[空格]", "待办列表，需要在行首输入"),
  shortcut("@@", "呼出组件插入选择器，需要在行首输入"),
  
];
