import React, {
  useEffect,
  useMemo,
  useContext,
  Fragment,
  useState,
} from "react";
import "../css/keyword.css";
import { ElementProp } from "../common/eleCommon";
import { useEditor, ReactEditor } from "slate-react";
import { Transforms, Node } from "slate";
import shortid from "shortid";
import uuid from "uuid";
import { combineOneLine } from "../EditorMethods";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  StoreName,
  getObjectStore,
  getKnowledgeCard_Read,
} from "../../lib/Database/Database";
import {
  LearnKeyword,
  KnowledgeCard,
  newKeywordRecord,
} from "../../AppPages/Learn/Knowledge/old_Knowledge";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  Typography,
  Divider,
  DialogContent,
} from "@material-ui/core";
import { DocContext, BlockEditor } from "../BlockEditor";
import { useCollectKeywordContext } from "../../AppPages/Learn/LearnProc/Practice/Quantitative";
import { SetThisNode } from "../../lib/SlateUtil";
import { SelectListDialog } from "../../AppCom/SelectListDialog";
import { useUpdateI } from "../../lib/CommonHook";
import { toKeyLink } from "../../AppPages/Learn/Knowledge/KnowledgeLink";
import {
  ExploreDialog,
  ExploreType,
} from "../../AppPages/Learn/Explore/KnowledgeExplore";
import { useKnowledgeContext } from "../../AppPages/Learn/Knowledge/Knowledge";

async function bindKeyLink(from: string, to: string) {
  if (from === undefined || to === undefined) return;
  const db = await getDatabase();
  const kl = toKeyLink(
    to,
    await (await getObjectStore(db, StoreName.knowledgeLink)).get(to)
  );
  if (kl.children.indexOf(from) === -1) {
    kl.children = [...new Set(kl.children).add(from)];
    await (await getObjectStore(db, StoreName.knowledgeLink, "readwrite")).put(
      kl
    );
  }
}
/**
 * 关键词
 * @param props
 */
export const KeyWorldElement = (props: ElementProp) => {
  // *************
  //  在知识环境下关键词连接
  // *************
  const keyword = useMemo(() => {
    let k = combineOneLine(props.element.children).trim();
    return k;
  }, [props.element]);
  const doc = useContext(DocContext);

  const update = useUpdateI();
  const { data: findKeywordData } = useRequest(
    async () => {
      const db = await getDatabase();
      if (doc && doc.uuid) {
        if (props.element.bind) {
          let learnkeyword: LearnKeyword = await (
            await getObjectStore(db, StoreName.learnKeywords)
          ).get(props.element.bind);
          if (learnkeyword === undefined) {
            const kno: KnowledgeCard = await (
              await getKnowledgeCard_Read(db)
            ).get(props.element.bind);
            learnkeyword = newKeywordRecord(kno.title, kno.uuid);
            await (
              await getObjectStore(db, StoreName.learnKeywords, "readwrite")
            ).put(learnkeyword);
          }
          await bindKeyLink(doc.uuid, learnkeyword.uuid);
          return learnkeyword;
        } else {
          const learnkeyword: LearnKeyword = await (
            await getObjectStore(db, StoreName.learnKeywords)
          )
            .index("keyword")
            .get(keyword);
          if (learnkeyword) {
            SetThisNode(editor, props.element, { bind: learnkeyword.uuid });
            await bindKeyLink(doc.uuid, learnkeyword.uuid);
          }
          return learnkeyword;
        }
      }
    },
    { refreshDeps: [keyword, props.element.bind, update.key] }
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const collectKey = useCollectKeywordContext();
  const [sopen, setSOpen] = useState(false);
  const editor = useEditor();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [exploreOpen, setExploreOpen] = useState(false);
  const knowledgeCtx = useKnowledgeContext();
  return (
    <span
      {...props.attributes}
      className={
        doc.hideKeyword
          ? ""
          : (findKeywordData !== undefined
              ? "keyword keyword" + props.element.level
              : "keyline") +
            " " +
            (collectKey.selected ? "keyword-highlight" : "")
      }
    >
      <span
        onContextMenu={(e) => {
          e.preventDefault();
          handleClick(e as any);
        }}
        onClick={(e) => {
          if (!doc.edit) {
            collectKey.setSelect(keyword, !collectKey.selected);
          } else {
            if (findKeywordData === undefined) {
              // 在此弹出选择框
              setSOpen(true);
            }
            if (knowledgeCtx.readonly && props.element.bind) {
              setPreviewOpen(true);
            }
          }
          if (e.shiftKey && props.element.bind) {
            knowledgeCtx.openPages?.(props.element.bind);
          }
        }}
      >
        {props.children}
      </span>
      {!doc.hideKeyword && (
        <Fragment>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>取消</MenuItem>
            {findKeywordData && (
              <MenuItem
                onClick={() => {
                  setPreviewOpen(true);
                  handleClose();
                }}
              >
                速览知识点
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setSOpen(true);
                handleClose();
              }}
            >
              修改绑定知识点
            </MenuItem>
            {props.element.bind && (
              <MenuItem
                onClick={() => {
                  if (props.element.bind) {
                    setExploreOpen(true);
                    handleClose();
                  }
                }}
              >
                探索
              </MenuItem>
            )}
            {props.element.bind && (
              <MenuItem
                onClick={() => {
                  if (props.element.bind) {
                    knowledgeCtx.openPages?.(props.element.bind);
                    handleClose();
                  }
                }}
              >
                打开
              </MenuItem>
            )}
          </Menu>
          {sopen && (
            <SelectListDialog
              defaultSearch={keyword}
              open={sopen}
              handleClose={() => setSOpen(false)}
              setResult={async (v: { uuid: string }) => {
                if (uuid !== props.element.bind) {
                  const db = await getDatabase();
                  if (props.element.bind !== undefined) {
                    const kl = toKeyLink(
                      props.element.bind,
                      await (
                        await getObjectStore(db, StoreName.knowledgeLink)
                      ).get(props.element.bind)
                    );
                    kl.children = kl.children.filter((v) => v !== doc.uuid);
                    await (
                      await getObjectStore(
                        db,
                        StoreName.knowledgeLink,
                        "readwrite"
                      )
                    ).put(kl);
                  }
                }
                SetThisNode(editor, props.element, { bind: v.uuid });
                update.update();
              }}
              title="选择知识点"
              getData={async (search: string) => {
                const db = await getDatabase();
                if (search === undefined) search = "";
                let res: KnowledgeCard[] = [];
                let cur: KnowledgeCard[] = await (
                  await getKnowledgeCard_Read(db)
                ).getAll(undefined);
                for (let i = 0; i < cur.length && res.length < 20; i++) {
                  const item = cur[i];
                  if (item.title !== "" && item.title.indexOf(search) !== -1) {
                    res.push(item);
                  }
                }
                return res;
              }}
              itemKey={(t: KnowledgeCard) => t.uuid}
              itemTitle={(t: KnowledgeCard) => t.title}
            />
          )}
        </Fragment>
      )}
      {previewOpen && findKeywordData && (
        <KeywordPreviewCard
          open={previewOpen}
          handleClose={() => setPreviewOpen(false)}
          keyword={findKeywordData}
        />
      )}
      {exploreOpen && props.element.bind && (
        <ExploreDialog
          open={exploreOpen}
          handleClose={() => {
            setExploreOpen(false);
          }}
          uuid={props.element.bind}
          type={ExploreType.findLink}
        />
      )}
    </span>
  );
};

/**
 * 关键句
 * @param props
 */
export const KeyLineElement = (props: ElementProp) => {
  const editor = useEditor();
  useEffect(() => {
    if (!!!props.element.id) {
      const path = ReactEditor.findPath(editor, props.element);
      let nn = { ...props.element, id: shortid.generate() };
      nn.id = nn.id || uuid.v4();

      Transforms.setNodes(editor, nn, { at: path });
    }
  }, [editor, props.element]);
  return (
    <span {...props.attributes} className="keyline-element">
      <span className={"keyline"}>{props.children}</span>
    </span>
  );
};

export const selectAllKeyline = (nodes: Node[]) => {
  let res_keys = new Map<string, Node>();
  function addkey(keys: Map<string, Node>, node: Node[]) {
    for (let i = 0; i < node.length; i++) {
      const e: any = node[i];
      if (e.type === "keyline") {
        keys.set(e.id, e);
      } else {
        if (e.children) {
          addkey(keys, e.children);
        }
      }
    }
  }
  addkey(res_keys, nodes);
  let res = Array.from(res_keys);
  return res;
};

interface keyline {
  /**
   * 唯一文档内标识
   */
  id: string;
}

export type keylineNode = keyline & Node;

interface keyword {
  level: number;
}

export type keywordNode = keyword & Node;

const KeywordPreviewCard = ({
  open,
  handleClose,
  keyword,
}: {
  open: boolean;
  handleClose: () => void;
  keyword: LearnKeyword;
}) => {
  const { data: doc } = useRequest(async () => {
    const db = await getDatabase();
    const d: KnowledgeCard = await (
      await getKnowledgeCard_Read(db)
    ).get(keyword.uuid);
    return d;
  });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        <Typography>{keyword.keyword}</Typography>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ minWidth: 300, minHeight: 200 }}>
        {doc && <BlockEditor readonly mvalue={doc.doc} setValue={() => {}} />}
      </DialogContent>
    </Dialog>
  );
};
