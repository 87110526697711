import { useSettingCardStyle } from "./Common";
import React, { useState, useContext, useCallback } from "react";
import { AppContext } from "../../App";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

export const PreferenceCard = () => {
  const style = useSettingCardStyle();
  const alwaysUseBrowserPdfView = useAlwaysUseBrowserPdfView();
  const appctx = useContext(AppContext);
  const tstyle = useStyles();
  return (
    <Card className={style.card}>
      <CardContent>
        <Typography className={style.cardTitle}>设置偏好</Typography>
      </CardContent>
      <CardContent>
        <Grid container className={style.cardContent} spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={alwaysUseBrowserPdfView.value}
                  onChange={(b) =>
                    alwaysUseBrowserPdfView.setValue(b.target.checked)
                  }
                  color="primary"
                />
              }
              label="总是使用浏览器默认的PDF阅读器"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

const useBooleanLocalValue = (name: string) => {
  const [value, _setValue] = useState(() => {
    let lo = localStorage.getItem(name);
    if (!lo || lo === "false") return false;
    return true;
  });

  const setValue = useCallback(
    (v: boolean) => {
      localStorage.setItem(name, v ? "true" : "false");
      _setValue(v);
    },
    [_setValue, name]
  );
  return { value, setValue };
};

export const useAlwaysUseBrowserPdfView = () => {
  return useBooleanLocalValue("alwaysUseBrowserPdfViewr");
};
