import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Button,
} from "@material-ui/core";
import React, { Fragment, useEffect, useCallback } from "react";
import { usePageStyle } from "../../PageStyle";
import {
  useLearnApp,
  LearnStep,
  changeLearnStep,
  setNewLearnSubjectState,
  newLearnSubjectState,
} from "../useLearnAppState";

export const LearnSubjectHome = () => {
  const pageStyle = usePageStyle();
  const learnApp = useLearnApp();
  const comfirmState = useCallback(() => {
    if (learnApp.state && learnApp.plan) {
      let p = learnApp.plan.planList[learnApp.state.currentSubjectIndex];
      if (p) {
        if (
          learnApp.state.processState.subjectsState.findIndex(
            (v) => v.targetSubjectUUID === p.uuid
          ) === -1
        ) {
          learnApp.func.changeState?.(
            setNewLearnSubjectState(newLearnSubjectState(p.uuid))
          );
        }
      }
    }
  }, [learnApp.state, learnApp.plan, learnApp.func.changeState]);
  useEffect(() => {
    comfirmState();
  }, [comfirmState]);

  if (learnApp.state && learnApp.plan) {
    return (
      <Fragment>
        {learnApp.state.currentSubjectIndex >=
          learnApp.plan.planList.length && (
          <Card className={pageStyle.card}>
            <CardContent>
              <Typography>已完成所有科目！</Typography>
            </CardContent>
            <CardActions>
              <Grid container justify="center">
                <Grid item>
                  <Button variant="outlined">????</Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
        {learnApp.state.currentSubjectIndex < learnApp.plan.planList.length && (
          <Card className={pageStyle.card}>
            <CardContent>
              <Typography>科目</Typography>
              <Typography style={{ fontSize: 22, fontWeight: "bold" }}>
                {
                  learnApp.plan.planList[learnApp.state.currentSubjectIndex]
                    .subjectName
                }
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container justify="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (
                        learnApp.plan.planList[
                          learnApp.state.currentSubjectIndex
                        ].subjectKnowledgeCollectionUUID
                      ) {
                        learnApp.func.changeState?.(
                          changeLearnStep(LearnStep.LearnChoice)
                        );
                      } else {
                        learnApp.func.changeState?.(
                          changeLearnStep(LearnStep.Practice)
                        );
                      }
                    }}
                  >
                    开始
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
      </Fragment>
    );
  }
  return null;
};
