import {
  Paper,
  makeStyles,
  createStyles,
  Card,
  CardContent,
  Typography,
  Fab,
  Container,
  Grid,
  ThemeProvider,
  Theme,
} from "@material-ui/core";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { drawerHeight, ThemeContext } from "../../App";
import { useInterval, useUpdateI } from "../../lib/CommonHook";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import {
  QuizCardCollection,
  QuizCardData,
} from "../../elements/plugins/withQuiz";
import { useRequest } from "@umijs/hooks";
import { QuizCoiceCardElement } from "../../elements/eles/Quiz/QuizChoice";
import { CardProp } from "../../elements/eles/Quiz/QuizCommon";
import { QuizTrueOrFalseCardElement } from "../../elements/eles/Quiz/QuizTOF";
import { QuizRememberCardElement } from "../../elements/eles/Quiz/QuizRemember";
import { QuizAnswerCardElement } from "../../elements/eles/Quiz/QuizAnswer";
import AddIcon from "@material-ui/icons/Add";
import { newcard } from "../../elements/eles/Quiz/QuizCommonMethod";
import ScrollToTop from "../../AppCom/ScrollToTop";

export const QuizCollectionDetail = () => {
  const { uuid } = useParams();
  const style = useStyle();
  const [cards, setCards] = useState([] as string[]);
  const [coll, setColl] = useState({});

  const updateList = useCallback(async () => {
    if (uuid) {
      const db = await getDatabase();
      const list: QuizCardCollection = await (
        await getObjectStore(db, StoreName.quiz_collection)
      ).get(uuid);
      setColl(list as any);
      setCards(list.children);
    }
  }, [uuid, setCards, setColl]);

  useInterval(() => {
    const savecoll = async () => {
      const db = await getDatabase();
      await (
        await getObjectStore(db, StoreName.quiz_collection, "readwrite")
      ).put(coll);
    };
    savecoll();
  }, 1000);

  useEffect(() => {
    updateList();
  }, [updateList]);

  const resetCollectionFromChild = useCallback(
    (childs: string[]) => {
      setCards(childs);
      let nc = { ...coll, children: childs };
      console.log(coll);
      setColl(nc);
    },
    [setCards, setColl, coll]
  );

  const mtheme = useContext(ThemeContext);

  return (
    <Paper
      className={style.body}
      style={{ backgroundColor: mtheme.background }}
    >
      <ScrollToTop />
      <Container>
        <Grid container justify="center">
          {cards.map((vid) => (
            <Grid item key={vid} xs={12}>
              <QuizCardDetail
                uuid={vid}
                col={uuid ? uuid : ""}
                resetCollection={resetCollectionFromChild}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <ThemeProvider theme={mtheme.headtheme}>
        <Fab
          color="secondary"
          className={style.fab}
          onClick={async () => {
            let card: QuizCardData = newcard();
            const db = await getDatabase();
            await (
              await getObjectStore(db, StoreName.quiz_card, "readwrite")
            ).put(card);
            let newchild = [...cards, card.uuid];
            let ncl = { ...coll, children: newchild };
            setColl(ncl);
            await (
              await getObjectStore(db, StoreName.quiz_collection, "readwrite")
            ).put(ncl);
            setCards(newchild);
          }}
        >
          <AddIcon color="primary" />
        </Fab>
      </ThemeProvider>
    </Paper>
  );
};

const QuizCardDetail = ({
  uuid,
  col,
  resetCollection,
}: {
  uuid: string;
  col: string;
  resetCollection: (v: string[]) => void;
}) => {
  const style = useStyle();
  const update = useUpdateI()
  const { data } = useRequest(async () => {
    const db = await getDatabase();
    let card: QuizCardData = await (
      await getObjectStore(db, StoreName.quiz_card)
    ).get(uuid);
    const cprop: CardProp = {
      collectionUUID: col,
      uuid: card.uuid,
      data: card,
      userdata: undefined,
      completeCallback: undefined,
      nextCardCallback: undefined,
      setCardsCollection: resetCollection,
      updateCallback: () => {update.update()},
      isEdit: true,
      showAns: true,
    };
    switch (card.type) {
      case "Choice":
        return <QuizCoiceCardElement {...cprop} />;
      case "TrueOrFalse":
        return <QuizTrueOrFalseCardElement {...cprop} />;
      case "Remember":
        return <QuizRememberCardElement {...cprop} />;
      case "Answer":
        return <QuizAnswerCardElement {...cprop} />;
      default:
        break;
    }
  }, {refreshDeps: [update.key]});

  return data ? (
    <div className={style.card}>{data}</div>
  ) : (
    <Card className={style.card}>
      <CardContent>
        <Typography>加载中</Typography>
      </CardContent>
    </Card>
  );
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      minHeight: 1080,
      marginTop: drawerHeight,

      [theme.breakpoints.up("sm")]: {
        padding: 20,
      },
    },
    card: {
      minHeight: 270,
      [theme.breakpoints.up("xs")]: {
        width: 500,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
      margin: 20,
    },
    cardtitle: {
      fontSize: 14,
    },
    linkbutton: {
      textDecoration: "none",
    },
    fab: {
      position: "fixed",
      bottom: 100,
      right: 50,
      zIndex: 2001,
    },
  })
);
