import { ActionType } from "./appdata";
// 如何根据对应的事件处理数据
export function databaseReducer(state: {}, action: any) {
  switch (action.type) {
    case ActionType.ChangeDB:
      return { ...state, dirt: true };
    case ActionType.ReadDB:
      return { ...state, dirt: false };
    case ActionType.RefreashPageList:
      return { ...state, page_list: action.newlist };
    default:
      return state;
  }
}

export const databaseInit = () => {
  return {
    dirt: true,
    page_list: [],
    title_cache: {},
  };
};
