import { Editor, Transforms, Node } from "slate";

export const PluginUtil = {
  toggleElement: (editor: Editor, eleType: string) => {
    const matchfunc = () => {
      const [match] = Editor.nodes(editor, {
        match: n => n.isShow === true,
        mode: "all"
      });
      return !!match;
    };
    const isShow = matchfunc();
    Transforms.setNodes(
      editor,
      {
        isShow: isShow ? false : true
      },
      { match: n => n.type === eleType }
    );
  },

  MakeNewLineWithEmptyFormat: (
    editor: Editor,
    type_exp: (n: Node) => boolean
  ) => {
    const { selection } = editor;
    if (!!selection) {
      const [match] = Editor.nodes(editor, {
        at: selection,
        match: n => type_exp(n)
      });
      if (!!match) {
        Transforms.splitNodes(editor);
        if (!!editor.selection) {
          const [nodeE] = Editor.node(editor, editor.selection);
          if (!!nodeE) {
            Transforms.setNodes(editor, { ...nodeE, type: "ph" });
            Transforms.move(editor, {
              distance: 1,
              unit: "line",
              edge: "start"
            });
            Transforms.select(editor, editor.selection.focus);
          }
        }
        return true;
      }
    }
    return false;
  }
};
