import React from "react";
import { Box } from "@material-ui/core";

export const TabPanel = (prop: TabPanelProp) => {
  return prop.index === prop.value ? <Box>{prop.children}</Box> : null;
};

interface TabPanelProp {
  index: any;
  value: any;
  children: any;
}
export const SwitchComponent = TabPanel;

interface IfComProp {
  exp: boolean;
  children: any;
}

export const If = (prop: IfComProp) => {
  return !!prop.exp ? <Box>{prop.children}</Box> : null;
};
