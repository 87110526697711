import "./ArrayUtil";
/**
 *
 * @param listA 判断两个数组是否元素相同
 * @param listB
 */
export function ArrayNotEqual(listA: string[], listB: string[]) {
  const lena = listA.length;
  if (lena !== listB.length) return true;
  for (let i = 0; i < lena; i++) {
    if (listA[i] !== listB[i]) return true;
  }
  return false;
}

/**
 * 返回数组末尾
 * @param list 数组
 */
export function last<T>(list: T[]) {
  return list[list.length - 1];
}
