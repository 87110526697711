import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import uuid from "uuid";
import {
  Card,
  CardContent,
  Grid,
  createStyles,
  makeStyles,
  Fab,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Collapse,
  Fade,
  Typography,
} from "@material-ui/core";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
  getKnowledgeCard_Write,
} from "../../../lib/Database/Database";
import { NoteCardRecord, NoteCardType } from "../../Cards/CardPage";
import {
  useLearnApp,
  IKnowledgeAppState,
  setKnowledgeRoot,
  setKnowledgeFocus,
} from "../useLearnAppState";
import { emptyValueData } from "../../../Init/initValue";
import AddIcon from "@material-ui/icons/Add";
import { useUpdateI, UpdateObj, useAlive } from "../../../lib/CommonHook";
import { BlockEditor } from "../../../elements/BlockEditor";
import md5 from "blueimp-md5";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Details from "@material-ui/icons/Details";
import { useDrop, useDrag } from "react-dnd";
import { KDeleteDialog, useKDeleteDialog } from "./DeleteDialog";
import { Node } from "slate";
import { combineOneLine } from "../../../elements/EditorMethods";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import { Qkeypoint, toQkeypoint } from "../LearnProc/Practice/Quantitative";

export interface KnowledgeProp {
  root?: string;
}

export const useKnowledgeState = (def: IKnowledgeAppState) => {
  const [root1, setRoot1] = useState(def.root1);
  const [root2, setRoot2] = useState(def.root2);
  const [root3, setRoot3] = useState(def.root3);

  const [focus1, setFocus1] = useState(def.focus1);
  const [focus2, setFocus2] = useState(def.focus2);
  const [focus3, setFocus3] = useState(def.focus3);
  return {
    root1,
    root2,
    root3,
    focus1,
    focus2,
    focus3,
    setRoot1,
    setRoot2,
    setRoot3,
    setFocus1,
    setFocus2,
    setFocus3,
  };
};

export const Knowledge = () => {
  const learnApp = useLearnApp();
  const { root1, root2, root3, focus1, focus2, focus3 } = learnApp.state
    ?.knowledge as IKnowledgeAppState;
  const setRoot1 = useCallback(
    (v: string) => learnApp.func.changeState?.(setKnowledgeRoot(1, v)),
    [learnApp.func.changeState]
  );
  const setRoot2 = useCallback(
    (v: string) => learnApp.func.changeState?.(setKnowledgeRoot(2, v)),
    [learnApp.func.changeState]
  );
  const setRoot3 = useCallback(
    (v: string) => learnApp.func.changeState?.(setKnowledgeRoot(3, v)),
    [learnApp.func.changeState]
  );
  const setFocus1 = useCallback(
    (v: string) => learnApp.func.changeState?.(setKnowledgeFocus(1, v)),
    [learnApp.func.changeState]
  );
  const setFocus2 = useCallback(
    (v: string) => learnApp.func.changeState?.(setKnowledgeFocus(2, v)),
    [learnApp.func.changeState]
  );
  const setFocus3 = useCallback(
    (v: string) => learnApp.func.changeState?.(setKnowledgeFocus(3, v)),
    [learnApp.func.changeState]
  );

  const updateRoot = useUpdateI();
  const { data: rootCol } = useRequest(
    async () => {
      const db = await getDatabase();
      let col = root1;
      let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        col
      );
      if (!root) {
        await (await getKnowledgeCard_Write(db)).put(
          newKnowledgeCollection(col, col)
        );
        root = await (await getKnowledgeCard_Read(db)).get(col);
      }
      return root;
    },
    { refreshDeps: [root1, updateRoot.key] }
  );

  const update2 = useUpdateI();
  const { data: level2Col } = useRequest(
    async () => {
      if (root2 === "") return undefined;
      const db = await getDatabase();
      let col = root2;
      let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        col
      );
      if (!root) {
        await (await getKnowledgeCard_Write(db)).put(
          newKnowledgeCollection(col, col)
        );
        root = await (await getKnowledgeCard_Read(db)).get(col);
      }
      return root;
    },
    { refreshDeps: [root2, update2.key] }
  );

  const update3 = useUpdateI();
  const { data: level3Col } = useRequest(
    async () => {
      if (root3 === "") return undefined;
      const db = await getDatabase();
      let col = root3;
      let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        col
      );
      if (!root) {
        await (await getKnowledgeCard_Write(db)).put(
          newKnowledgeCollection(col, col)
        );
        root = await (await getKnowledgeCard_Read(db)).get(col);
      }
      return root;
    },
    { refreshDeps: [root3, update3.key] }
  );

  const style = useKnowledgeStyle();

  // /////////////////////////////////////////////
  // |    |        |     |        |     |        |
  // |roo1|        |root2|        |root3|        |
  // |    |        |     |        |     |        |
  // |    |        |     |        |     |        |
  // /////////////////////////////////////////////
  const selectCol1 = useCallback(
    (id: string) => {
      setRoot2(id);
      setRoot3("");
    },
    [setRoot2, setRoot3]
  );
  const selectCol2 = useCallback(
    (id: string) => {
      setRoot3(id);
    },
    [setRoot3]
  );
  const selectCol3 = useCallback(
    (id: string) => {
      setRoot1(root2);
      setFocus1(focus2);
      setRoot2(root3);
      setFocus2(focus3);
      setRoot3(id);
    },
    [
      setRoot1,
      root2,
      setRoot2,
      root3,
      setRoot3,
      setFocus1,
      setFocus2,
      focus2,
      focus3,
    ]
  );

  const fab1coolDown = useRef(false);
  const fab2coolDown = useRef(false);
  const fab3coolDown = useRef(false);

  const updateAll = useCallback(() => {
    updateRoot.update();
    update2.update();
    update3.update();
  }, [updateRoot, update2, update3]);
  return (
    <Fragment>
      <Grid container justify="center" spacing={6}>
        {rootCol && (
          <Fragment>
            <Grid item key={1} container xs={4} alignContent="flex-start">
              <Typography>{rootCol.title}</Typography>
              {rootCol.children.map((v) => (
                <Grid xs={12} key={v} item>
                  <KnowledgeItem
                    uuid={v}
                    focus={focus1}
                    setFocus={setFocus1}
                    update={updateRoot}
                    expand={selectCol1}
                    updateALL={updateAll}
                  />
                </Grid>
              ))}
              {rootCol && (
                <Fab
                  className={style.fab1}
                  onClick={async () => {
                    if (fab1coolDown.current) return;
                    fab1coolDown.current = true;
                    let newcard = newKnowledgeCard(rootCol.uuid);
                    const db = await getDatabase();
                    await (await getKnowledgeCard_Write(db)).put(newcard);
                    rootCol.children.push(newcard.uuid);
                    await (await getKnowledgeCard_Write(db)).put(rootCol);
                    updateRoot.update();
                    fab1coolDown.current = false;
                  }}
                >
                  <AddIcon color="primary" />
                </Fab>
              )}
            </Grid>
            <Grid item key={2} container xs={4} alignContent="flex-start">
              <Typography>{level2Col?.title || ""}</Typography>
              {level2Col &&
                level2Col.children.map((v) => (
                  <Grid xs={12} key={v} item>
                    <KnowledgeItem
                      uuid={v}
                      focus={focus2}
                      setFocus={setFocus2}
                      update={update2}
                      expand={selectCol2}
                      updateALL={updateAll}
                    />
                  </Grid>
                ))}
              {level2Col && level2Col && (
                <Fab
                  className={style.fab2}
                  onClick={async () => {
                    if (fab2coolDown.current) return;
                    fab2coolDown.current = true;
                    let newcard = newKnowledgeCard(level2Col.uuid);
                    const db = await getDatabase();
                    await (await getKnowledgeCard_Write(db)).put(newcard);
                    level2Col.children.push(newcard.uuid);
                    await (await getKnowledgeCard_Write(db)).put(level2Col);
                    updateRoot.update();
                    update2.update();
                    fab2coolDown.current = false;
                  }}
                >
                  <AddIcon color="primary" />
                </Fab>
              )}
            </Grid>
            <Grid item key={3} container xs={4} alignContent="flex-start">
              <Typography>{level3Col?.title || ""}</Typography>
              {level3Col &&
                level3Col.children.map((v) => (
                  <Grid xs={12} key={v} item>
                    <KnowledgeItem
                      uuid={v}
                      focus={focus3}
                      setFocus={setFocus3}
                      update={update3}
                      expand={selectCol3}
                      updateALL={updateAll}
                    />
                  </Grid>
                ))}
              {level3Col && level3Col && (
                <Fab
                  className={style.fab3}
                  onClick={async () => {
                    if (fab3coolDown.current) return;
                    fab3coolDown.current = true;
                    let newcard = newKnowledgeCard(level3Col.uuid);
                    const db = await getDatabase();
                    await (await getKnowledgeCard_Write(db)).put(newcard);
                    level3Col.children.push(newcard.uuid);
                    await (await getKnowledgeCard_Write(db)).put(level3Col);
                    update2.update();
                    update3.update();
                    fab3coolDown.current = false;
                  }}
                >
                  <AddIcon color="primary" />
                </Fab>
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Fade in={rootCol && rootCol.uuid !== "root"}>
        <Fab
          className={style.fabBack}
          onClick={() => {
            if (!!rootCol) {
              setRoot1(rootCol.parent);
              setFocus1("");
            }

            if (!!level2Col) {
              setRoot2(level2Col.parent);
              setFocus2(focus1);
            }

            if (!!level3Col) {
              setRoot3(level3Col.parent);
              setFocus3(focus2);
            }
          }}
        >
          <Details />
        </Fab>
      </Fade>
    </Fragment>
  );
};

interface KeywordDoc {
  keywords: string[];
}

export interface KnowledgeCard extends NoteCardRecord, KeywordDoc {
  keywords: string[];
  title: string;
  parent: string;
  isSubject?: 0 | 1;
}

export const newKnowledgeCard = (
  parent: string,
  id?: string
): KnowledgeCard => {
  let d = {
    document: emptyValueData(),
    uuid: id === undefined ? uuid.v4() : id,
    lastWriteTime: new Date(),
    md5: "",
    tags: [],
  };
  return {
    uuid: d.uuid,
    title: "",
    keywords: [],
    doc: d,
    type: NoteCardType.card,
    children: [],
    parent: parent,
  } as KnowledgeCard;
};

export const newKnowledgeCollection = (parent: string, id?: string) => {
  return {
    uuid: id === undefined ? uuid.v4() : id,
    title: "",
    keywords: [],
    doc: undefined,
    type: NoteCardType.collection,
    children: [],
    parent: parent,
  };
};

export interface KnowledgeItemProp {
  uuid: string;
  setFocus: (id: string) => void;
  focus: string;
  update: UpdateObj;
  expand: (id: string) => void;
  updateALL: () => void;
}

const KnowledgeItem = (prop: KnowledgeItemProp) => {
  const inupdate = useUpdateI();

  const [doc, setDoc]: [
    KnowledgeCard | undefined,
    (n: KnowledgeCard) => void
  ] = useState();

  const alive = useAlive();
  const { data } = useRequest(
    async () => {
      const db = await getDatabase();
      let docc: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        prop.uuid
      );
      if (alive.current) {
        setDoc(docc as any);
      }
      return docc;
    },
    { refreshDeps: [prop.update.key] }
  );

  const [, setBackOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setBackOpen(false);
  };

  //let mmd5 = mvalue?.md5 || "";
  useEffect(() => {
    const save = async () => {
      let db = await getDatabase();
      if (doc) {
        let oldK: KnowledgeCard = await (await getKnowledgeCard_Write(db)).get(
          doc.uuid
        );
        await (await getKnowledgeCard_Write(db)).put({
          ...doc,
          children: oldK.children,
        });
      }
    };
    if (!!doc) save();
  }, [inupdate.key, doc, setDoc]);

  const style = useKnowledgeItemStyle();

  // ------------------
  //     drag drop
  const [, drag] = useDrag({
    item: {
      type: "card",
      uuid: prop.uuid,
      update: prop.updateALL,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const deleteDialog = useKDeleteDialog();

  return (
    <Fragment>
      <Card
        style={{ position: "relative", overflow: "visible" }}
        className={
          prop.focus === prop.uuid ? style.selected + " " : "" + style.card
        }
        onClick={() => prop.setFocus(prop.uuid)}
      >
        <div
          ref={drag}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: 30,
            width: "100%",
          }}
        ></div>

        <Box style={{ position: "absolute", right: 0, top: 0 }}>
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        </Box>
        {doc && doc.type === NoteCardType.collection && (
          <IconButton
            style={{ position: "absolute", right: 0, top: "40%" }}
            onClick={() => prop.expand(prop.uuid)}
          >
            <Details />
          </IconButton>
        )}
        <CardContent>
          <Collapse in={!!doc?.doc}>
            {doc?.doc && (
              <Box
                className={
                  doc && doc.type === NoteCardType.collection
                    ? style.collection
                    : ""
                }
              >
                <BlockEditor
                  key={prop.uuid}
                  mvalue={doc.doc}
                  setValue={(v: any) => {
                    let tmvalue = doc.doc;
                    let newvalue: Node[] = v.doc;
                    let mm = md5(JSON.stringify(v.doc));
                    if (mm !== tmvalue.md5) {
                      let keys: string[] = [];
                      getFromDoc(newvalue, { keywords: keys });
                      let newdoc = {
                        ...doc,
                        doc: {
                          document: v.doc,
                          uuid: prop.uuid,
                          lastWriteTime: new Date(),
                          md5: mm,
                          tags: v.tags,
                        },
                        title: combineOneLine(newvalue[0].children).trim(),
                        keywords: [...new Set(keys)],
                      };
                      setDoc(newdoc);
                    }
                  }}
                />
              </Box>
            )}
          </Collapse>
        </CardContent>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>取消</MenuItem>
          <MenuItem
            onClick={() => {
              deleteDialog.Open();
              handleClose();
            }}
          >
            删除
          </MenuItem>
          {doc && doc.type === NoteCardType.card && (
            <MenuItem
              onClick={() => {
                doc.type = NoteCardType.collection;
                inupdate.update();
                handleClose();
              }}
            >
              转换为集合
            </MenuItem>
          )}
          {doc &&
            doc.type === NoteCardType.collection &&
            doc.children.length === 0 && (
              <MenuItem
                onClick={() => {
                  doc.type = NoteCardType.card;
                  inupdate.update();
                  handleClose();
                }}
              >
                转换为卡
              </MenuItem>
            )}

          {doc && !doc.isSubject && (
            <MenuItem
              onClick={() => {
                handleClose();
                setDoc({ ...doc, isSubject: 1 });
              }}
            >
              设置为学科
            </MenuItem>
          )}
          {doc && !!doc.isSubject && (
            <MenuItem
              onClick={() => {
                handleClose();
                setDoc({ ...doc, isSubject: 0 });
              }}
            >
              取消此学科
            </MenuItem>
          )}
        </Menu>
        <KDeleteDialog
          handleClose={deleteDialog.handleClose}
          open={deleteDialog.open}
          handleDelete={async () => {
            if (doc) {
              let db = await getDatabase();
              await (await getKnowledgeCard_Write(db)).delete(prop.uuid);
              let parent: KnowledgeCard = await (
                await getKnowledgeCard_Read(db)
              ).get(doc.parent);
              parent.children = parent.children.filter((v) => v !== prop.uuid);
              await (await getKnowledgeCard_Write(db)).put(parent);
              prop.update.update();
            }
          }}
        />
      </Card>
      <DropInsert upUUID={prop.uuid} />
    </Fragment>
  );
};

export interface DropInsert {
  upUUID: string;
}
export interface DropData {
  uuid: string;
  type: string;
  update: () => void;
}
const DropInsert = (prop: DropInsert) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "card",
    drop: async (data: DropData) => {
      const db = await getDatabase();

      const myid = data.uuid;
      // des parent -> upId.parent
      let that: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        data.uuid
      );
      let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        that.parent
      );
      root.children = root.children.filter((a) => a !== data.uuid);

      let up: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        prop.upUUID
      );

      if (up.parent !== that.parent) {
        // 组间移动
        let upParent: KnowledgeCard = await (
          await getKnowledgeCard_Read(db)
        ).get(up.parent);
        let upid = upParent.children.indexOf(up.uuid) + 1;
        upParent.children.splice(upid, 0, data.uuid);
        that.parent = upParent.uuid;

        if (upParent.uuid === myid) return;
        const upUpParent: KnowledgeCard = await (
          await getKnowledgeCard_Read(db)
        ).get(upParent.parent);
        if (upUpParent.uuid === myid) return;
        const upUpUpParent: KnowledgeCard = await (
          await getKnowledgeCard_Read(db)
        ).get(upUpParent.parent);
        if (upUpUpParent.uuid === myid) return;
        await (await getKnowledgeCard_Write(db)).put(upParent);
      } else {
        // 组内移动
        let upid = root.children.indexOf(up.uuid) + 1;
        root.children.splice(upid, 0, data.uuid);
      }

      await (await getKnowledgeCard_Write(db)).put(root);
      await (await getKnowledgeCard_Write(db)).put(that);

      data.update();
    },
    collect: (mon) => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop(),
    }),
    canDrop: (item: DropData) => {
      return item.uuid !== prop.upUUID;
    },
  });
  return (
    <div
      ref={drop}
      style={{
        height: 30,
        backgroundColor: canDrop && isOver ? "#66ccff" : "",
      }}
    ></div>
  );
};

const useKnowledgeItemStyle = makeStyles(() =>
  createStyles({
    card: {
      minWidth: 100,
    },
    selected: {
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#66ccff",
    },
    collection: {
      paddingRight: 20,
    },
  })
);

const useKnowledgeStyle = makeStyles(() =>
  createStyles({
    fab3: {
      position: "fixed",
      bottom: 100,
      right: "5%",
      zIndex: 2001,
    },
    fab2: {
      position: "fixed",
      bottom: 100,
      right: "35%",
      zIndex: 2001,
    },
    fab1: {
      position: "fixed",
      bottom: 100,
      right: "65%",
      zIndex: 2001,
    },
    fabBack: {
      position: "fixed",
      bottom: "50%",
      left: 10,
      zIndex: 2001,
    },
  })
);

export interface LearnKeyword extends Qkeypoint {
  keyword: string;
  uuid: string;
}

export function newKeywordRecord(
  keyword: string,
  cardID: string
): LearnKeyword {
  return toQkeypoint({
    keyword,
    uuid: cardID,
  });
}

export function getFromDoc(
  line: Node[],
  inject: { keywords?: string[]; media?: Set<string> }
) {
  if (line === undefined) return;
  for (let i = 0; i < line.length; i++) {
    const one = line[i];
    if (inject.keywords && one.type === "keyword") {
      let getkey = one.bind ? one.bind : combineOneLine(one.children);
      inject.keywords.push(getkey.trim());
    } else if (inject.media && one.assetID !== undefined) {
      inject.media.add(one.assetID);
    } else if (one.children !== undefined) {
      getFromDoc(one.children, inject);
    }
  }
}
