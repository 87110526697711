import {
  makeStyles,
  createStyles,
  Grid,
  Fade,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import React, { useState, useContext, useLayoutEffect } from "react";
import { useUpdate } from "../../lib/CommonHook";
import { useEditor, ReactEditor } from "slate-react";
import { DocContext } from "../BlockEditor";
import { SetThisNode } from "../../lib/SlateUtil";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Editor } from "slate";

const useHeadStyle = makeStyles(() =>
  createStyles({
    show: { visibility: "visible" },
    hide: { visibility: "hidden" },
    tag: { marginLeft: 2 },
    head_tip: {
      position: "absolute",
      left: -35,
      bottom: 10,
      userSelect: "none",
      backgroundColor: "#fffbf0",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d3b17d",
      borderRadius: 3,
      paddingLeft: 4,
      paddingRight: 4,
      color: "#d3b17d",
      fontSize: 14,
    },
  })
);

export const HeadElement = ({ children, element }: any) => {
  const [addtagDialogOpen, setAddTagDialogOpen] = useState(false);
  const thisTagsList = (element.tags ? element.tags : []) as string[];
  const update = useUpdate();
  const editor = useEditor();
  const doc = useContext(DocContext);
  const [over, setOver] = useState(false);
  const style = useHeadStyle();
  const [selectOn, setSelectOn] = useState(false);
  useLayoutEffect(() => {
    const select = editor.selection;
    const focus = select?.focus;
    if (focus) {
      const [match] = Editor.nodes(editor, {
        at: focus,
        match: (n) => isHead(n.type),
      });
      if (match) {
        const targetPath = match[1];
        const thisPath = ReactEditor.findPath(editor, element);
        if (isSamePath(targetPath, thisPath)) {
          setSelectOn(true);
        } else {
          setSelectOn(false);
        }
      } else {
        //setSelectOn(false);
      }
    }
  }, [editor.selection, setSelectOn, editor, element]);
  return (
    <div
      onPointerOver={() => setOver(true)}
      onPointerLeave={() => setOver(false)}
      style={{ position: "relative" }}
    >
      <Grid container alignItems="flex-end">
        <Grid item>
          <Fade in={over || selectOn}>
            <div className={style.head_tip} contentEditable={false}>
              {element.type}
            </div>
          </Fade>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

const AddTagDialog = ({
  open,
  onClose,
  onCancel,
}: {
  open: boolean;
  onClose: (v: string) => void;
  onCancel: Function;
}) => {
  const [selectedTag, setSelectedTag] = useState("");
  const doc = useContext(DocContext);
  return (
    <Dialog open={open} onClose={() => onCancel()}>
      <DialogTitle>选择或添加标签</DialogTitle>
      <TextField
        variant="outlined"
        onChange={(v) => setSelectedTag(v.target.value)}
        size="small"
      />
      <List>
        {doc.tags &&
          doc.tags.map((v) => (
            <ListItem
              key={v}
              button
              onClick={() => {
                setSelectedTag(v);
              }}
              selected={v === selectedTag}
            >
              <ListItemText>{v}</ListItemText>
            </ListItem>
          ))}
      </List>
      <Button
        onClick={() => {
          if (selectedTag && selectedTag !== "") {
            if (doc.tags.indexOf(selectedTag) === -1) {
              doc.tags.push(selectedTag);
            }
            onClose(selectedTag);
          } else {
            onCancel();
          }
        }}
      >
        确认
      </Button>
      <Button onClick={() => onCancel()}>取消</Button>
    </Dialog>
  );
};

function isHead(type: string) {
  return !!HEAD_N[type];
}

const HEAD_N: { [key: string]: boolean } = {
  H1: true,
  H2: true,
  H3: true,
  H4: true,
  H5: true,
  H6: true,
};

function isSamePath(pathA: number[], pathB: number[]) {
  if (pathA.length !== pathB.length) return false;
  for (let i = 0; i < pathA.length; i++) {
    if (pathA[i] !== pathB[i]) return false;
  }
  return true;
}

/**删除的tag */
//{doc.edit && (
//  <Grid
//    item
//    contentEditable={false}
//    style={{
//      userSelect: "none",
//      marginLeft: 20,
//    }}
//  >
//    <Fade in={over}>
//      <IconButton onClick={() => setAddTagDialogOpen(true)}>
//        <AddCircleOutlineIcon />
//      </IconButton>
//    </Fade>
//  </Grid>
//)}
//
//<Grid
//  item
//  contentEditable={false}
//  style={{ userSelect: "none", paddingBottom: 10 }}
//>
//  {thisTagsList.map((v) => (
//    <Chip
//      className={style.tag}
//      variant="outlined"
//      size="small"
//      onDelete={() => {
//        SetThisNode(editor, element, {
//          tags: thisTagsList.filter((k) => k !== v),
//        });
//        update.update();
//      }}
//      label={v}
//      key={v}
//    />
//  ))}
//</Grid>
//<AddTagDialog
//open={addtagDialogOpen}
//onClose={(v: string) => {
//  SetThisNode(editor, element, {
//    tags: [...new Set([...thisTagsList, v])],
//  });
//  setAddTagDialogOpen(false);
//}}
//onCancel={() => setAddTagDialogOpen(false)}
///>
