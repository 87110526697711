import {
  Typography,
  Card,
  CardContent,
  Paper,
  makeStyles,
  createStyles,
  Grid,
  Button,
  Theme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMTheme } from "../../lib/MTheme";
import { drawerHeight } from "../../App";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import {
  QuizCardData,
  QuizCardUserData,
} from "../../elements/plugins/withQuiz";
import { CardProp, UserAnswerLevel } from "../../elements/eles/Quiz/QuizCommon";
import { QuizCoiceCardElement } from "../../elements/eles/Quiz/QuizChoice";
import { QuizTrueOrFalseCardElement } from "../../elements/eles/Quiz/QuizTOF";
import { QuizRememberCardElement } from "../../elements/eles/Quiz/QuizRemember";
import { QuizAnswerCardElement } from "../../elements/eles/Quiz/QuizAnswer";
import { LinkButton } from "../../lib/Coms/ButtonLink";

export const QuizCollectionPractice = () => {
  const { uuid } = useParams();
  const style = useQuizPracticeStyle();
  const mtheme = useMTheme();
  const { data: collection } = useRequest(async () => {
    const db = await getDatabase();
    const col = await (await getObjectStore(db, StoreName.quiz_collection)).get(
      uuid as string
    );
    return col;
  });
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  return (
    <Paper
      className={style.body}
      style={{ backgroundColor: mtheme.theme.background }}
    >
      <Grid container justify="center">
        <Grid item>
          {collection &&
          collection.children.length > 0 &&
          currentCardIndex < collection.children.length ? (
            <QuizCardAnswer
              uuid={collection.children[currentCardIndex]}
              col={uuid as string}
              nextCard={() => setCurrentCardIndex(currentCardIndex + 1)}
              key={(uuid as string) + currentCardIndex}
            />
          ) : (
            <Card className={style.card}>
              <CardContent>
                <Typography>您已完成所有卡片的练习~</Typography>
              </CardContent>
              <CardContent>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <LinkButton to="/learn">
                      <Button variant="outlined">返回</Button>
                    </LinkButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export const QuizCardAnswer = ({
  uuid,
  col,
  nextCard,
  user_data,
  set_user_data
}: {
  uuid: string;
  col: string;
  nextCard: () => void;
  user_data?: QuizCardUserData;
  set_user_data?: (u: QuizCardUserData) => void
}) => {
  const style = useQuizPracticeStyle();
  const { data } = useRequest(async () => {
    const db = await getDatabase();
    let card: QuizCardData = await (
      await getObjectStore(db, StoreName.quiz_card)
    ).get(uuid);
    const cprop: CardProp = {
      collectionUUID: col,
      uuid: card.uuid,
      data: card,
      userdata: user_data,
      completeCallback: async (userdata: QuizCardUserData) => {
        if (userdata && set_user_data) {
          set_user_data(userdata)
        }
      },
      nextCardCallback: nextCard,
      setCardsCollection: undefined,
      updateCallback: () => {},
      isEdit: false,
      showAns: false,
    };
    switch (card.type) {
      case "Choice":
        return <QuizCoiceCardElement {...cprop} />;
      case "TrueOrFalse":
        return <QuizTrueOrFalseCardElement {...cprop} />;
      case "Remember":
        return <QuizRememberCardElement {...cprop} />;
      case "Answer":
        return <QuizAnswerCardElement {...cprop} />;
      default:
        break;
    }
  });

  return data ? (
    <div>{data}</div>
  ) : (
    <Card className={style.card}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography>……</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useQuizPracticeStyle = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      minHeight: 1080,
      marginTop: drawerHeight,

      [theme.breakpoints.up("sm")]: {
        padding: 20,
      },
    },
    card: {
      minHeight: 700,
      [theme.breakpoints.up("xs")]: {
        width: 500,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
    },
    cardtitle: {
      fontSize: 14,
    },
    linkbutton: {
      textDecoration: "none",
    },
  })
);
