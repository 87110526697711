import React, { Fragment } from "react";
import {
  Box,
  TextField,
  List,
  makeStyles,
  createStyles,
  Backdrop,
  IconButton,
  Fade,
} from "@material-ui/core";
import { SelectListItem } from "../../../AppCom/SelectListDialog";

import { KnowledgeCard } from "./old_Knowledge";
import { useStateful } from "../../../lib/CommonHook";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../../../lib/Database/Database";
import Search from "@material-ui/icons/Search";
import "./KnowledgeCSS.css";
import { Button } from "antd";

const SearchInputWidth = 290;

export const KnowledgeSearch = ({
  onSelect,
}: {
  onSelect: (id: string) => void;
}) => {
  const search = useStateful("");
  const { data: datalist } = useRequest(
    async () => {
      if (search.value === "") return undefined;
      const db = await getDatabase();
      let res: KnowledgeCard[] = [];
      let cur: KnowledgeCard[] = await (
        await getKnowledgeCard_Read(db)
      ).getAll();
      for (let i = 0; i < cur.length && res.length < 20; i++) {
        const item = cur[i];
        if (item.title !== "" && item.title.indexOf(search.value) !== -1) {
          res.push(item);
        }
      }
      return res;
    },
    { refreshDeps: [search.value], throttleInterval: 500 }
  );
  const searchOpen = useStateful(false);
  const style = useStyle();
  return (
    <Box
      style={{
        pointerEvents: "none",
      }}
    >
      <Fade in={!searchOpen.value}>
        <Button
          onClick={() => searchOpen.set(true)}
          size="large"
          shape="circle"
          style={{ pointerEvents: "auto" }}
        >
          <Search />
        </Button>
      </Fade>
      <Box
        style={{
          position: "fixed",
          top: 60,
          left: `calc(50% - ${SearchInputWidth / 2}px)`,
        }}
      >
        <Fade in={searchOpen.value}>
          {(searchOpen.value && (
            <Box
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "#b1b1b1",
                marginLeft: "auto",
                marginRight: "auto",
                width: SearchInputWidth + 10,
                padding: 5,
                boxShadow: "rgb(144 144 144) 0px 0px 2px inset",
                pointerEvents: "auto",
              }}
            >
              <SearchInput value={search.value} setValue={search.set} />
            </Box>
          )) || <div></div>}
        </Fade>
        <Fade in={!!(searchOpen.value && datalist)}>
          {(datalist && searchOpen.value && datalist.length > 0 && (
            <Box
              style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}
            >
              <SearchResultList
                datalist={datalist}
                onSelect={(id) => {
                  onSelect(id);
                  searchOpen.set(false);
                }}
              />
            </Box>
          )) || <div></div>}
        </Fade>
      </Box>

      {searchOpen.value && (
        <Backdrop
          style={{ pointerEvents: "auto" }}
          open={searchOpen.value}
          onClick={() => {
            //search.set("");
            searchOpen.set(false);
          }}
        />
      )}
    </Box>
  );
};

const SearchInput = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (s: string) => void;
}) => {
  return (
    <TextField
      style={{ minWidth: SearchInputWidth }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="outlined"
      color="primary"
      autoFocus={true}
      placeholder={"搜索"}
    />
  );
};

const SearchResultList = ({
  datalist,
  onSelect,
}: {
  datalist: KnowledgeCard[];
  onSelect: (id: string) => void;
}) => {
  return (
    <Box style={{ backgroundColor: "white", padding: 6, borderRadius: 6 }}>
      <List>
        {datalist.map((v) => {
          return (
            <SelectListItem
              key={v.uuid}
              selectUUID={""}
              value={v}
              itemKey={(k) => k.uuid}
              itemTitle={(k) => k.title}
              setSelectTitle={() => {}}
              setSelected={() => {
                onSelect(v.uuid);
              }}
            />
          );
        })}
      </List>
    </Box>
  );
};

const useStyle = makeStyles(() =>
  createStyles({
    seachButtonNormal: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      backgroundColor: "white",
      boxShadow: "0px 0px 6px #b1b1b1 inset",
      pointerEvents: "auto",
    },
    seachButtonHover: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      backgroundColor: "white",
      boxShadow: "0px 0px 12px #b1b1b1 inset",
      pointerEvents: "auto",
    },
  })
);
