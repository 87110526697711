import React from "react";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../lib/Database/Database";
import { KnowledgeCard } from "../AppPages/Learn/Knowledge/old_Knowledge";
import { Breadcrumbs, Link, Typography, makeStyles, createStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const KnowledgeBreadcrumbs = ({ value }: { value: KnowledgeCard }) => {
  const { data: path } = useRequest(async () => {
    const db = await getDatabase();
    let avalue = value;
    let p: string[] = [];
    if (avalue.parent && avalue.parent !== "root") {
      const parent = await (await getKnowledgeCard_Read(db)).get(
        avalue.parent
      );
      p.push(parent.title);
      if (parent.parent && parent.parent !== "root") {
        const pparent = await (
          await getKnowledgeCard_Read(db)
        ).get(parent.parent);
        p.push(pparent.title);
        //if (pparent.parent && pparent.parent !== "root") {
        //  const ppparent = await (
        //    await getKnowledgeCard_Read(db)
        //  ).get(pparent.parent);
        //  p.push(ppparent.title);
        //}
      }
    }
    return p;
  });
  const style = useBreadStyle()
  return path && path.length > 0 ? (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {path.length > 1 && <Typography className={style.bread} color="inherit">{path[1]}</Typography>}
      {path.length > 0 && <Typography className={style.bread} color="inherit">{path[0]}</Typography>}
      {path.length > 0 && <Typography className={style.bread} color="textPrimary">{value.title}</Typography>}
    </Breadcrumbs>
  ) : null;
};


const useBreadStyle = makeStyles(() =>
  createStyles({
    bread: {
        fontSize: 14
    }
  })
);
