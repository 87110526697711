import { BlockEditorRuntime } from "../AppPages/Editor/useBlockEditor";
import { useDeletePage } from "../lib/Database/Database";
import React, { useState, useCallback, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { Link } from "react-router-dom";

export interface IDialogControl {
  open: boolean;
  setOpen: Function;
  handleClose: Function;
  handleClick: Function;
  deleteID: string;
  setDeleteID: Function;
  parnetID: string;
  setParentID: Function;
}

export const useDeleteDialog = (uuid: string, parent: string) => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const [deleteID, setDeleteID] = useState(uuid);
  const [parnetID, setParentID] = useState(parent);

  useEffect(() => {
    setDeleteID(uuid);
    setParentID(parent);
  }, [uuid, setDeleteID, parent, setParentID]);

  return {
    open,
    setOpen,
    handleClose,
    handleClick,
    deleteID,
    setDeleteID,
    parnetID,
    setParentID
  } as IDialogControl;
};

export const DeleteDialog = ({
  runtime,
  control
}: {
  runtime: BlockEditorRuntime;
  control: IDialogControl;
}) => {
  const deletePage = useDeletePage();

  const handleDelete = useCallback(async () => {
    if (control.deleteID === runtime.current_doc_uuid) {
      runtime.set_current_uuid("");
      runtime.parentDoc.current = "root";
    }

    deletePage(control.deleteID, control.parnetID);
    control.setOpen(false);
  }, [deletePage, runtime, control]);

  return (
    <Dialog
      open={control.open}
      onClose={e => control.handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"删除警告"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          您是否要删除此文档，此操作不可逆
        </DialogContentText>
      </DialogContent>
      <DialogActions className="drawer-link">
        <Link to={"/doc"}>
          <Button onClick={handleDelete} color="secondary">
            是的，我要删除
          </Button>
        </Link>
        <Button onClick={e => control.handleClose()} color="primary" autoFocus>
          不，不要删除
        </Button>
      </DialogActions>
    </Dialog>
  );
};
