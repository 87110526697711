import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../../lib/Database/Database";
import {
  QuizCardCollection,
  QuizChoiceCard,
  QuizCardData,
  QuizTOFCard,
  QuizRememberCard,
  QuizAnswerCard,
} from "../../plugins/withQuiz";
import uuid from "uuid";
import { Node } from "slate";

export async function MakeNewCard(collUUID: string, cardUUID?: string) {
  const db = await getDatabase();

  const ncd = newcard();

  const col = await getObjectStore(db, StoreName.quiz_collection);

  const col_ins: QuizCardCollection = await col.get(collUUID);
  const ind = !!cardUUID ? col_ins.children.indexOf(cardUUID) : -1;
  col_ins.children.splice(ind + 1, 0, ncd.uuid);

  const card = await getObjectStore(db, StoreName.quiz_card, "readwrite");
  await card.put(ncd);
  await (await getObjectStore(db, StoreName.quiz_collection, "readwrite")).put(
    col_ins
  );
  return col_ins.children;
}

// TODO 有时候删不掉卡
export async function DeleteCurrentCard(collUUID: string, cardUUID: string) {
  const db = await getDatabase();
  const card_s = await getObjectStore(db, StoreName.quiz_card, "readwrite");
  await card_s.delete(cardUUID);
  const col_s = await getObjectStore(db, StoreName.quiz_collection);
  let col_i: QuizCardCollection = await col_s.get(collUUID);
  col_i.children = col_i.children.filter((v) => v !== cardUUID);
  await (await getObjectStore(db, StoreName.quiz_collection, "readwrite")).put(
    col_i
  );
  return col_i.children;
}

export const newcard = (): QuizCardData => {
  return {
    uuid: uuid.v4(),
    type: "Choice",
    card: {
      helper: [{ type: "ph", children: [{ text: "题干" }] }] as Node[],
      choices: [
        [{ type: "ph", children: [{ text: "A" }] }] as Node[],
        [{ type: "ph", children: [{ text: "B" }] }] as Node[],
        [{ type: "ph", children: [{ text: "C" }] }] as Node[],
        [{ type: "ph", children: [{ text: "D" }] }] as Node[],
      ],
      answer: [0],
      explain: [{ type: "ph", children: [{ text: "解析" }] }],
      exp_choices: [
        [{ type: "ph", children: [{ text: "解析A" }] }] as Node[],
        [{ type: "ph", children: [{ text: "解析B" }] }] as Node[],
        [{ type: "ph", children: [{ text: "解析C" }] }] as Node[],
        [{ type: "ph", children: [{ text: "解析D" }] }] as Node[],
      ],
    },
    keywords: [],
    assets: [],
  };
};

export const CARD_INIT = {
  Choice: () => {
    return {
      helper: [{ type: "ph", children: [{ text: "题干" }] }] as Node[],
      choices: [
        [{ type: "ph", children: [{ text: "A" }] }] as Node[],
        [{ type: "ph", children: [{ text: "B" }] }] as Node[],
        [{ type: "ph", children: [{ text: "C" }] }] as Node[],
        [{ type: "ph", children: [{ text: "D" }] }] as Node[],
      ],
      answer: [0],
    } as QuizChoiceCard;
  },
  TrueOrFalse: () => {
    return {
      helper: [{ type: "ph", children: [{ text: "题干" }] }],
      answer: false,
      explain: [{ type: "ph", children: [{ text: "解析" }] }],
    } as QuizTOFCard;
  },
  Remember: () => {
    return {
      helper: [{ type: "ph", children: [{ text: "题干" }] }],
      explain: [{ type: "ph", children: [{ text: "解析" }] }],
    } as QuizRememberCard;
  },
  Answer: () => {
    return {
      helper: [{ type: "ph", children: [{ text: "题干" }] }],
      answer: [{ type: "ph", children: [{ text: "答案" }] }],
      explain: [{ type: "ph", children: [{ text: "解析" }] }],
    } as QuizAnswerCard;
  },
};
