import { BlockEditorRuntime } from "./useBlockEditor";
import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  getDatabase,
  getObjectStore,
  StoreName
} from "../../lib/Database/Database";
import { PageFCB } from "../../lib/Database/DataInterface";
import { useDeleteDialog, DeleteDialog } from "../../AppCom/DeleteDialog";
import { ThemeContext } from "../../App";
import { useDivices } from "../../lib/hooks/useDevices";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import {
  Grid,
  Tabs,
  Tab,
  Container,
  Typography,
  TextField,
  IconButton,
  ThemeProvider
} from "@material-ui/core";
import { ShareDialog } from "./ShareDialog";

export const EditorAppbar = ({
  runtime,
  editable
}: {
  runtime: BlockEditorRuntime;
  editable?: boolean;
}) => {
  useEffect(() => {
    const settitle = async () => {
      if (runtime.current_doc_uuid !== "") {
        let db = await getDatabase();
        let fcb_store = await getObjectStore(db, StoreName.fcb_pages);
        let fcb: PageFCB = await fcb_store.get(runtime.current_doc_uuid);
        if (!!fcb) runtime.setTitle(fcb.title, "justSet");
      }
    };
    settitle();
  }, [runtime.current_doc_uuid, runtime]);

  const DeleteDialogControl = useDeleteDialog(
    runtime.current_doc_uuid,
    runtime.parentDoc.current
  );
  const [shareDialogOpen, setShareDialog] = useState(false);

  const mtheme = useContext(ThemeContext);
  const divice = useDivices();
  return (
    <Grid
      wrap="nowrap"
      item
      container
      spacing={divice.whenDesktop ? 6 : 1}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item md={5} sm={6}>
        <Tabs
          value={runtime.editor_tab_value}
          onChange={(e, v) => {
            runtime.setEditorTabValue(v);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="文档"></Tab>
          <Tab label="导图"></Tab>
        </Tabs>
      </Grid>
      {(divice.whenMobile || divice.whenPad) && (
        <Grid item sm={4} zeroMinWidth>
          <Container>
            <Typography noWrap color="primary">
              {runtime.title}
            </Typography>
          </Container>
        </Grid>
      )}
      {divice.whenDesktop && (
        <Fragment>
          <Grid item md={4}>
            <TextField
              label={runtime.current_doc_uuid === "" ? "文档名" : runtime.title}
              variant="filled"
              size="small"
              onChange={e => {
                runtime.setTitle(e.target.value);
              }}
              onKeyDown={() => {}}
              style={{ width: "300px" }}
              key={runtime.current_doc_uuid}
              InputProps={{
                readOnly: !editable
              }}
            ></TextField>
          </Grid>
        </Fragment>
      )}
      {editable && divice.whenDesktop ? (
        <Grid item sm={1}>
          <IconButton
            onClick={() => {
              setShareDialog(true);
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        </Grid>
      ) : (
        <Grid item sm={1}>
          <IconButton
            onClick={() => {
              setShareDialog(true);
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        </Grid>
      )}
      <ThemeProvider theme={mtheme.theme}>
        <ShareDialog
          open={shareDialogOpen}
          setOpen={setShareDialog}
          runtime={runtime}
          DeleteDialogControl={DeleteDialogControl}
        ></ShareDialog>
        <DeleteDialog runtime={runtime} control={DeleteDialogControl} />
      </ThemeProvider>
    </Grid>
  );
};
