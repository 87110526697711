import {
  Card,
  Container,
  CardContent,
  Button,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Select,
  MenuItem,
  Divider
} from "@material-ui/core";
import React, { useState, useEffect, useCallback, useRef } from "react";

import uuid from "uuid";
import { useAlive, useUpdate } from "../../lib/CommonHook";

import { ElementProp } from "../common/eleCommon";
import { If } from "../../AppCom/TabPanel";
import {
  getDatabase,
  getObjectStore,
  StoreName
} from "../../lib/Database/Database";

import { ReactEditor, useEditor } from "slate-react";
import {
  QuizCardUserData,
  QuizCardData,
  QuizCardCollection
} from "../plugins/withQuiz";
import { Transforms } from "slate";
import { MakeNewCard, newcard } from "./Quiz/QuizCommonMethod";
import { CardProp } from "./Quiz/QuizCommon";
import { QuizCoiceCardElement } from "./Quiz/QuizChoice";
import { QuizTrueOrFalseCardElement } from "./Quiz/QuizTOF";
import { QuizRememberCardElement } from "./Quiz/QuizRemember";
import { QuizAnswerCardElement } from "./Quiz/QuizAnswer";

export const QuizCardElement = (prop: ElementProp) => {
  const [allCards, setAllCards]: [string[], Function] = useState([]);
  const allCardsRef = useRef([] as string[]); // 用于某些需要实时获取集合长度的时候
  allCardsRef.current = allCards;

  const [index, setIndex]: [number, Function] = useState(
    prop.element.uuid === "" ? -1 : 0
  );
  const [currentCard, setCurrentCard] = useState();

  const alive = useAlive();
  const loadingCol = useRef(false);
  const update = useUpdate();
  useEffect(() => {
    // 读取卡片集合中的所有卡
    const coll = prop.element.uuid;

    const loadCollection = async () => {
      loadingCol.current = true;
      const db = await getDatabase();
      const collection: QuizCardCollection = await (
        await getObjectStore(db, StoreName.quiz_collection)
      ).get(coll);

      //const collectionUserData: QuizCardCollectionUserData = await (
      //  await getObjectStore(db, StoreName.user_quiz_collection_data)
      //).get(coll);

      if (alive.current) {
        if (!!collection) {
          loadingCol.current = false;
          setAllCards(collection.children);
          setIndex(0);
        }
      }
    };
    if (coll !== undefined) loadCollection();
  }, [prop.element.uuid, alive, setAllCards, setIndex]);

  // 答对、统计、写入数据库、下一页
  const completeAndNext = useCallback(() => {
    // TODO 具体功能
    setIndex(index + 1);
  }, [index]);
  const makeCardLock = useRef(false);
  useEffect(() => {
    if (
      index === -1 ||
      index === -2 ||
      prop.element.uuid === "" ||
      loadingCol.current
    )
      return;
    if (index >= allCards.length) {
      if (allCards.length > 0) {
        setIndex(allCards.length - 1);
        return;
      } else {
        if (makeCardLock.current === false) {
          const make = async () => {
            makeCardLock.current = true;
            const c = await MakeNewCard(prop.element.uuid);
            if (alive.current) {
              setAllCards(c);
              setIndex(0);
              makeCardLock.current = false;
            }
          };
          setIndex(-2);
          make();
        }

        return;
      }
    }
    // 读取当前指向卡片
    const loadCard = async () => {
      const db = await getDatabase();
      const card: QuizCardData = await (
        await getObjectStore(db, StoreName.quiz_card)
      ).get(allCards[index]);
      const card_userdata: QuizCardUserData = await (
        await getObjectStore(db, StoreName.user_quiz_card_data)
      ).get(allCards[index]);
      if (alive.current) {
        // TODO 根据卡片种类渲染卡
        const cprop: CardProp = {
          collectionUUID: prop.element.uuid,
          uuid: card.uuid,
          data: card,
          userdata: card_userdata,
          completeCallback: completeAndNext,
          nextCardCallback: (i?: number) => {
            let next = index + (i === undefined ? 1 : i);
            if (next >= allCardsRef.current.length)
              next = allCardsRef.current.length - 1;
            setIndex(next >= 0 ? next : 0);
          },
          setCardsCollection: setAllCards,
          updateCallback: update.update
        };
        switch (card.type) {
          case "Choice":
            setCurrentCard(<QuizCoiceCardElement {...cprop} />);
            break;
          case "TrueOrFalse":
            setCurrentCard(<QuizTrueOrFalseCardElement {...cprop} />);
            break;
          case "Remember":
            setCurrentCard(<QuizRememberCardElement {...cprop} />);
            break;
          case "Answer":
            setCurrentCard(<QuizAnswerCardElement {...cprop} />);
            break;
          default:
            break;
        }
      }
    };
    loadCard();
  }, [index, allCards, completeAndNext, alive, prop.element.uuid, update]);

  const editor = useEditor();

  if (index === -1) {
    return (
      <Container {...prop.attributes} data-slate-editor contentEditable={false}>
        <QuizInitCard
          setNewCollection={newid => {
            const path = ReactEditor.findPath(editor, prop.element);

            Transforms.setNodes(
              editor,
              { ...prop.element, uuid: newid },
              { at: path }
            );
            setIndex(0);
          }}
        ></QuizInitCard>
        {prop.children}
      </Container>
    );
  }
  return (
    <Container data-slate-editor contentEditable={false}>
      <If exp={index >= 0}>{!!currentCard ? currentCard : <LoadingCard />}</If>
      {prop.children}
    </Container>
  );
};

const useQuizInitCardStyle = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 22
    },
    Button: {
      height: 48,
      marginLeft: 20
    },
    menuitem: {
      minWidth: 219,
      height: 48
    }
  })
);

const QuizInitCard = ({
  setNewCollection
}: {
  setNewCollection: (x: string) => void;
}) => {
  const style = useQuizInitCardStyle();
  const [selectColl, setSelectColl] = useState("-");
  const [inputCollName, setInputCollName] = useState("");
  return (
    <Card>
      <CardContent>
        <Typography className={style.header}> 请创建或选择集合</Typography>
      </CardContent>
      <Divider></Divider>
      <CardContent>
        <Container>
          <TextField
            label="集合名称"
            variant="filled"
            size="small"
            onChange={e => {
              setInputCollName(e.target.value);
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            className={style.Button}
            disabled={inputCollName === ""}
            onClick={async () => {
              const db = await getDatabase();
              const store = await getObjectStore(
                db,
                StoreName.quiz_collection,
                "readwrite"
              );
              const nkd = newcard();
              const newcoll = {
                uuid: uuid.v4(),
                children: [nkd.uuid],
                name: inputCollName
              } as QuizCardCollection;
              await store.put(newcoll);
              const cardstore = await getObjectStore(
                db,
                StoreName.quiz_card,
                "readwrite"
              );
              await cardstore.put(nkd);
              setNewCollection(newcoll.uuid);
            }}
          >
            创建新集合
          </Button>
        </Container>
      </CardContent>
      <CardContent>
        <Container>
          <Typography>或</Typography>
        </Container>
      </CardContent>
      <CardContent>
        <Container>
          <Select
            variant="outlined"
            className={style.menuitem}
            value={selectColl}
            onChange={v => setSelectColl(v.target.value as string)}
          >
            <MenuItem value={"-"}>无集合</MenuItem>
            <MenuItem value={"a"}>a</MenuItem>
          </Select>
          <Button
            variant="outlined"
            color="primary"
            className={style.Button}
            disabled={selectColl === "-"}
          >
            选择该集合
            {
              // TODO 此处未完成
            }
          </Button>
        </Container>
      </CardContent>
    </Card>
  );
};

export const LoadingCard = () => {
  return (
    <Card>
      <CardContent>读取中...</CardContent>
    </Card>
  );
};
