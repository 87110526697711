import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import { createStore } from "redux";
import { appData, appDataInit } from "./lib/appdata";
import { useCheckVersion } from "./VersionUpdate";
import { RegSplitText } from "./ElectronAddon/SplitText";
import { notification } from "antd";

let store = createStore(appData, appDataInit());

function wrapPromise(cb: any) {
  return new Promise((resolve, reject) => cb(resolve, reject));
}
/**
 * 用户授权。申请持久存储.
 */
function grantPersist() {
  return wrapPromise((resolve: any, reject: any) => {
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then((granted) => {
        // granted: true/false
        resolve(granted);
      });
    } else {
      reject("navigator.storage.persist: the browser is not supported");
    }
  });
}

async function checkPersist() {
  try {
    let res = await grantPersist();
    console.log(res ? "持久化存储模式" : "临时存储模式");
  } catch (error) {
    console.log(error);
  }
}

checkPersist();

const AppCheck = () => {
  const ready = useCheckVersion();
  useEffect(() => {
    if (navigator.serviceWorker) {
      const updateComplete = () => {
        notification.open({
          message: "新版本准备就绪",
          description: "新版本下载完成！当您重启应用后，将自动生效！",
          onClick: () => {},
          duration: 3,
        });
      };
      navigator.serviceWorker.addEventListener(
        "message",
        updateComplete
      );
      return navigator.serviceWorker.removeEventListener(
        "message",
        updateComplete
      );
    }
  }, []);
  return <HashRouter>{ready && <App />}</HashRouter>;
};

ReactDOM.render(<AppCheck />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

const { myIpcRenderer } = window;

if (myIpcRenderer) {
  myIpcRenderer.on("APP_log", (data) => {
    console.log(data);
  });
  myIpcRenderer.on("APP_warn", (data) => {
    console.warn(data);
  });
  myIpcRenderer.on("APP_error", (data) => {
    console.error(data);
  });
  RegSplitText();
}
