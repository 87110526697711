import React from "react";
import { CodeElement } from "./eles/CodeElement";
import { CheckListItemElement } from "./eles/CheckListItemElement";
import { MathLineElement, MathInlineElement } from "./eles/MathLineElement";
import { KeyWorldElement, KeyLineElement } from "./eles/KeyWorldElement";
import { NodeHead } from "./eles/NodeElement";
import { ImageElement } from "./eles/ImageElement";
import { QuizCardElement } from "./eles/QuizElement";
import { Divider } from "@material-ui/core";
import { HeadElement } from "./eles/HeadElement";
import { LinkCardElement } from "./eles/LinkCardElement";
import { ParagraphWrapper } from "./eles/ParagraphWrapper";
import { CanvasElement } from "./eles/CanvasElement";
import { SubDocPreview } from "./eles/SubDocPreview";

interface ElementArgs {
  attributes: any;
  children: Node[];
  element: any;
}

// 在这里根据type渲染组件，可以用任意react组件。
export const MElement = ({ attributes, children, element }: ElementArgs) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "ol":
      return <ol {...attributes}>{children}</ol>;
    case "H1":
      return (
        <div>
          <HeadElement {...{ attributes, element }}>
            <h1 className="h1-s">{children}</h1>
          </HeadElement>
          <Divider
            contentEditable={false}
            style={{ userSelect: "none" }}
          ></Divider>
        </div>
      );
    case "H2":
      return (
        <HeadElement {...{ attributes, element }}>
          <h2 className="h2-s" {...attributes}>
            {children}
          </h2>
        </HeadElement>
      );
    case "H3":
      return (
        <HeadElement {...{ attributes, element }}>
          <h3 className="h3-s" {...attributes}>
            {children}
          </h3>
        </HeadElement>
      );
    case "H4":
      return (
        <HeadElement {...{ attributes, element }}>
          <h4 className="h4-s" {...attributes}>
            {children}
          </h4>
        </HeadElement>
      );
    case "H5":
      return (
        <HeadElement {...{ attributes, element }}>
          <h5 className="h5-s" {...attributes}>
            {children}
          </h5>
        </HeadElement>
      );
    case "H6":
      return (
        <HeadElement {...{ attributes, element }}>
          <h6 className="h6-s" {...attributes}>
            {children}
          </h6>
        </HeadElement>
      );
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "code-area":
      return <CodeElement {...{ attributes, children, element }}></CodeElement>;
    case "check-list-item":
      return <CheckListItemElement {...{ attributes, children, element }} />;
    case "math-line":
      return (
        <ParagraphWrapper {...{ attributes, element }}>
          <MathLineElement {...{ attributes, children, element }} />
        </ParagraphWrapper>
      );
    case "math-inline":
      return <MathInlineElement {...{ attributes, children, element }} />;
    case "keyword":
      return <KeyWorldElement {...{ attributes, children, element }} />;
    case "keyline":
      return <KeyLineElement {...{ attributes, children, element }} />;
    case "node-head":
      return <NodeHead {...{ attributes, children, element }} />;
    case "image":
      return (
        <ParagraphWrapper {...{ attributes, element }}>
          <ImageElement {...{ attributes, children, element }} />
        </ParagraphWrapper>
      );
    case "quiz-card":
      return <QuizCardElement {...{ attributes, children, element }} />;
    case "link":
      return <span {...attributes}>{children}</span>;
    case "link-card":
      return (
        <ParagraphWrapper {...{ attributes, element }}>
          <LinkCardElement {...{ attributes, children, element }} />
        </ParagraphWrapper>
      );
      case "sub-preview":
      return (
        <ParagraphWrapper {...{ attributes, element }}>
          <SubDocPreview {...{ attributes, children, element }} />
        </ParagraphWrapper>
      );
    case "canvas":
      return (
        <ParagraphWrapper {...{ attributes, element }}>
          <CanvasElement {...{ attributes, children, element }} />
        </ParagraphWrapper>
      );
    default:
      return (
        <div className="pag">
          <ParagraphWrapper {...{ attributes, element }}>
            {children}
          </ParagraphWrapper>
        </div>
      );
  }
};
