import React, { useState, useEffect } from "react";
import Latex from "react-latex";
import "katex/dist/katex.min.css";
import { useSelected, useFocused, useEditor } from "slate-react";
import { ElementProp } from "../common/eleCommon";
import "../css/MathPlugin.css";
import { Transforms } from "slate";

/**
 * 一行的数学公式
 * @param param0
 */
export const MathLineElement = ({
  attributes,
  children,
  element
}: ElementProp) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {element.isShow ? (
        <div
          contentEditable={false}
          style={{
            position: "relative",
            boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none"
          }}
        >
          <Latex
            displayMode={true}
            style={{
              display: selected && focused ? "none" : "block",
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              cursor: "cell",
              zIndex: 1
            }}
          >
            {"$$" + combind(element) + "$$"}
          </Latex>
          {children}
        </div>
      ) : (
        <div>
          <div
            /**
             * ! HACK:
             * 解决不可编辑的区域点击报错：
             * style={{userSelect: "none"}}
             * contentEditable={false}
             */
            style={{
              position: "relative",
              boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
              userSelect: "none"
            }}
            contentEditable={false}
          >
            <Latex
              displayMode={true}
              style={{
                display: selected && focused ? "none" : "block",
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                cursor: "cell",
                zIndex: 1
              }}
            >
              {"$$" + combind(element) + "$$"}
            </Latex>
          </div>
          <div className="math-editor-area">
            <span
              contentEditable={false}
              className="math-editor-area-inline-tips"
              style={{ userSelect: "none" }}
            >
              Ctrl+M
            </span>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

function endsWith(str: string, suffix: string) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function combind(params: any) {
  let str = "";
  if (params.text !== undefined) str = params.text;
  if (params.children !== undefined) {
    for (let i = 0; i < params.children.length; i++) {
      let e = params.children[i];
      str = str + combind(e);
    }
  }
  return str;
}

/**
 * 行内数学公式
 * @param param0
 */
export const MathInlineElement = ({
  attributes,
  children,
  element
}: ElementProp) => {
  const selected = useSelected();
  const focused = useFocused();
  const [content, setContent] = useState(combind(element));
  const editor = useEditor();

  // 若为空字符串或结尾不为空格，则插入空格，以防无法编辑
  //useEffect(() => {
  //  const newcontent = combind(element);
  //  if (newcontent === "") {
  //    Transforms.insertText(editor, " ");
  //    setContent(" ");
  //  } else if (!endsWith(newcontent, " ")) {
  //    const { selection } = editor;
  //    if (selection) {
  //      const range = { anchor: selection.focus, focus: selection.anchor };
  //      //Transforms.setSelection(editor, range);
  //      Transforms.insertText(editor, " ", { at: range });
  //      setContent(newcontent + " ");
  //    }
  //  } else {
  //    setContent(newcontent);
  //  }
  //}, [element, editor]);

  return (
    <span {...attributes}>
      {element.isShow ? (
        <span
          contentEditable={false}
          style={{
            position: "relative",
            boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none"
          }}
        >
          <Latex
            style={{
              display: selected && focused ? "none" : "block",
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              cursor: "cell",
              zIndex: 1
            }}
          >
            {"$" + combind(element) + "$"}
          </Latex>
          {children}
        </span>
      ) : (
        <span>
          <span
            style={{
              position: "relative",
              boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
              userSelect: "none"
            }}
            contentEditable={false}
          >
            <Latex
              style={{
                display: selected && focused ? "none" : "block",
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                cursor: "cell",
                zIndex: 1
              }}
            >
              {"$" + combind(element) + "$"}
            </Latex>
          </span>
          <span className="math-editor-area-inline">
            <div
              contentEditable={false}
              className="math-editor-area-inline-tips"
              style={{ userSelect: "none" }}
            >
              Ctrl+M
            </div>
            {children}
          </span>
        </span>
      )}
    </span>
  );
};
