import React, { useRef, useCallback } from "react";
import { ReactEditor } from "slate-react";

export const useEditorFocusControl = () => {
  const Ref = useRef<ReactEditor>();
  const Focus = useCallback(() => {
    if (Ref.current && !ReactEditor.isFocused(Ref.current))
      ReactEditor.focus(Ref.current);
  }, []);
  return {
    editorRef: Ref,
    manualFocus: Focus,
  };
};
