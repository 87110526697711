import React, { useRef, useEffect, useState, useCallback } from "react";
import * as d3 from "d3";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  StoreName,
  getObjectStore,
  getKnowledgeCard_Read,
} from "../../../lib/Database/Database";
import { KnowledgeCard } from "../Knowledge/old_Knowledge";
import { useUpdateI, useAlive } from "../../../lib/CommonHook";
import { toKeyLink } from "../Knowledge/KnowledgeLink";
import { useKnowledgeContext } from "../Knowledge/Knowledge";
import { BlockEditor } from "../../../elements/BlockEditor";

let simulation;
interface KDode {
  index: number;
  uuid: string;
  size?: number;
}
async function getChildrenData(uuid: string | undefined) {
  const db = await getDatabase();
  let tarid = uuid || "root";
  let nodes: KDode[] = [];
  let edges: { source: number; target: number }[] = [];
  let index = { index: 0 };
  let linkpair: { from: number; to: string }[] = [];
  // let ei = {index: 0}
  const getall = async (id: string) => {
    const doc: KnowledgeCard = await (
      await getKnowledgeCard_Read(db)
    ).get(id);
    if (doc) {
      let mi = index.index;
      const lk = toKeyLink(
        id,
        await (await getObjectStore(db, StoreName.knowledgeLink)).get(id)
      );
      nodes.push({ index: mi, uuid: id, size: lk.children.length });
      index.index += 1;
      for (let i = 0; i < doc.children.length; i++) {
        const e = doc.children[i];
        let ti = await getall(e);
        if (ti) edges.push({ source: mi, target: ti });
      }
      for (let i = 0; i < doc.keywords.length; i++) {
        const e = doc.keywords[i];
        linkpair.push({ from: mi, to: e });
      }

      return mi;
    }
  };

  await getall(tarid);
  for (let i = 0; i < linkpair.length; i++) {
    const e = linkpair[i];
    let fi = nodes.findIndex((v) => v.uuid === e.to);
    if (fi !== -1) {
      edges.push({ source: e.from, target: fi });
    }
  }
  return {
    nodes,
    edges,
  };
}

async function getLinkData(uuid: string | undefined) {
  const db = await getDatabase();
  let tarid = uuid || "root";
  let nodes: KDode[] = [];
  let edges: { source: number; target: number }[] = [];
  let index = { index: 0 };
  let linkpair: { from: number; to: string }[] = [];
  // let ei = {index: 0}
  const getall = async (id: string) => {
    const doc = toKeyLink(
      id,
      await (await getObjectStore(db, StoreName.knowledgeLink)).get(id)
    );
    let mi = index.index;
    nodes.push({ index: mi, uuid: id, size: doc.children.length });
    index.index += 1;

    for (let i = 0; i < doc.children.length; i++) {
      const e = doc.children[i];
      let ti = nodes.findIndex((v) => v.uuid === e);
      if (ti === -1) {
        ti = (await getall(e)) || -1;
      }
      if (ti !== -1) edges.push({ source: mi, target: ti });
    }
    const mdoc: KnowledgeCard = await (
      await getKnowledgeCard_Read(db)
    ).get(id);
    for (let i = 0; i < mdoc.keywords.length; i++) {
      const e = mdoc.keywords[i];
      linkpair.push({ from: mi, to: e });
    }

    return mi;
  };

  await getall(tarid);
  for (let i = 0; i < linkpair.length; i++) {
    const e = linkpair[i];
    let fi = nodes.findIndex((v) => v.uuid === e.to);
    if (fi !== -1) {
      edges.push({ source: e.from, target: fi });
    } else {
      const edoc = toKeyLink(
        e.to,
        await (await getObjectStore(db, StoreName.knowledgeLink)).get(e.to)
      );
      nodes.push({
        index: index.index,
        uuid: e.to,
        size: edoc.children.length,
      });
      edges.push({ source: e.from, target: index.index });
      index.index += 1;
    }
  }
  return {
    nodes,
    edges,
  };
}

export const KnowledgeExplore = ({
  uuid,
  exploreType,
}: {
  uuid?: string;
  exploreType: ExploreType;
}) => {
  const theChart = useRef<HTMLDivElement | null>(null);
  const [expType, setExptype] = useState(exploreType);

  const { data } = useRequest(
    async () => {
      switch (expType) {
        case ExploreType.findChildren:
          return await getChildrenData(uuid);
        case ExploreType.findLink:
          return await getLinkData(uuid);
        default:
          break;
      }
    },
    { refreshDeps: [expType] }
  );

  const update = useUpdateI();
  const trans = useRef({ k: 1, x: 0, y: 0 });
  const alive = useAlive();
  const svg = useRef<d3.Selection<
    SVGSVGElement,
    unknown,
    HTMLElement,
    any
  > | null>(null);
  const g = useRef<d3.Selection<SVGGElement, unknown, HTMLElement, any> | null>(
    null
  );
  const gl = useRef<d3.Selection<
    SVGGElement,
    unknown,
    HTMLElement,
    any
  > | null>(null);
  useEffect(() => {
    let chart = theChart.current;
    if (chart && (svg.current === null || g.current === null)) {
      const WIDTH = chart.clientWidth;
      const HEIGHT = chart.clientHeight;

      const onZoomStart = (d: any) => {
        // console.log('start zoom');
      };
      const zooming = (d: any) => {
        // 缩放和拖拽整个g
        //console.log(d3.event.transform);
        g.current?.attr("transform", d3.event.transform); // 获取g的缩放系数和平移的坐标值。
        gl.current?.attr("transform", d3.event.transform); // 获取g的缩放系数和平移的坐标值。

        if (alive.current) {
          trans.current = d3.event.transform;
          update.ref.current();
        }
      };
      const onZoomEnd = () => {
        // console.log('zoom end');
      };
      const zoom = d3
        .zoom()
        // .translateExtent([[0, 0], [WIDTH, HEIGHT]]) // 设置或获取平移区间, 默认为[[-∞, -∞], [+∞, +∞]]
        .scaleExtent([1 / 10, 10]) // 设置最大缩放比例
        .on("start", onZoomStart)
        .on("zoom", zooming)
        .on("end", onZoomEnd);

      svg.current = d3
        .select("#theChart")
        .append("svg") // 在id为‘theChart’的标签内创建svg
        .style("width", WIDTH)
        .style("height", HEIGHT)
        .on("click", () => {})
        .call(zoom as any); // 缩放

      gl.current = svg.current.append("g").attr("id", "link-g");
      g.current = svg.current.append("g").attr("id", "node-g"); // 则svg中创建g
    }
  }, [theChart, svg, g]);

  useEffect(() => {
    let chart = theChart.current;
    if (data && chart && svg.current) {
      const WIDTH = chart.clientWidth;
      const HEIGHT = chart.clientHeight;
      let edgesLine = svg.current
        .select("#link-g")
        .selectAll(".line")
        .data(data.edges) // 绑定数据
        .enter() // 为数据添加对应数量的占位符
        .append("path") // 在占位符上面生成折线（用path画
        .attr("class", "line")
        .attr("d", (d: any) => {
          return (
            d &&
            "M " +
              (d.source.x || 0) +
              " " +
              (d.source.y || 0) +
              " L " +
              (d.target.x || 0) +
              " " +
              (d.target.y || 0)
          );
        }) //遍历所有数据。d表示当前遍历到的数据，返回绘制的贝塞尔曲线
        .attr("id", (d: any, i: any) => {
          return i && "edgepath" + i;
        }) // 设置id，用于连线文字
        .attr("marker-end", "url(#arrow)") // 根据箭头标记的id号标记箭头
        .style("stroke", "#000") // 颜色
        .style("stroke-width", 1); // 粗细

      svg.current
        .select("#link-g")
        .selectAll(".line")
        .data(data.edges)
        .exit()
        .remove();

      let nodesCircle = svg.current
        .select("#node-g")
        .selectAll("circle")
        .data(data.nodes)
        .enter()
        .append("circle") // 创建圆
        .attr("r", (d) => (d.uuid === uuid ? 20 : 10 + (d.size || 0) * 2)) // 半径
        .style("fill", "rgb(210 210 210)") // 填充颜色
        .style("stroke", "rgb(86 86 86)") // 边框颜色
        .style("stroke-width", 2) // 边框粗细
        .on("click", (node) => {
          // 点击事件
          console.log("click");
        });

      svg.current
        .select("#node-g")
        .selectAll("circle")
        .data(data.nodes)
        .exit()
        .remove();

      //.call(drag); // 拖拽单个节点带动整个图

      const simulation = d3
        .forceSimulation(data.nodes) // 指定被引用的nodes数组
        .force(
          "link",
          d3
            .forceLink(data.edges)
            .id((d) => (d.index || 0).toString())
            .distance(150)
        )
        .force("collision", d3.forceCollide(1).strength(0.1))
        .force("center", d3.forceCenter(WIDTH / 2, HEIGHT / 2))
        .force("charge", d3.forceManyBody().strength(-1000).distanceMax(500));

      simulation.on("tick", () => {
        // 更新节点坐标
        nodesCircle.attr("transform", (d: any) => {
          return d && "translate(" + d.x + "," + d.y + ")";
        });
        // 更新节点文字坐标
        //nodesTexts.attr('transform', (d) => {
        //    return 'translate(' + (d.x) + ',' + d.y + ')';
        //});
        // 更新连线位置
        edgesLine.attr("d", (d: any) => {
          const path =
            "M " +
            d.source.x +
            " " +
            d.source.y +
            " L " +
            d.target.x +
            " " +
            d.target.y;
          return path;
        });
        if (alive.current) update.ref.current();
        // 更新连线文字位置
        //edgesText.attr('transform', (d, i) => {
        //    return 'rotate(0)';
        //});
      });
    }
  }, [data, theChart, svg]);

  const nodeRoot = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setExptype(event.target.value as any);
    },
    []
  );
  return (
    <Box style={{ position: "relative", height: "100%", overflow: "hidden" }}>
      <div
        className="theChart"
        id="theChart"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        ref={theChart}
      ></div>
      <div
        style={{
          overflow: "visible",
          transform: `translate(${
            -((nodeRoot.current && nodeRoot.current.clientWidth) || 0) / 2
          }px,${
            -((nodeRoot.current && nodeRoot.current.clientHeight) || 0) / 2
          }px)`,
          //pointerEvents: "none",
        }}
      >
        <div
          ref={nodeRoot}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "visible",
            transform: `translate(${trans.current.x}px,${trans.current.y}px) scale(${trans.current.k})`,
            transformOrigin: "center",
          }}
        >
          {data &&
            data.nodes.map((v: any, i) => {
              return (
                <div
                  key={v.uuid}
                  style={{
                    position: "absolute",
                    transform: `translate(${
                      v.x +
                      ((nodeRoot.current && nodeRoot.current.clientWidth) ||
                        0) /
                        2 +
                      15
                    }px,${
                      v.y +
                      ((nodeRoot.current && nodeRoot.current.clientHeight) ||
                        0) /
                        2 -
                      10
                    }px)`,
                    display: "block",
                    userSelect: "none",
                  }}
                >
                  <ExploreNode uuid={v.uuid} />
                </div>
              );
            })}
        </div>
      </div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>模式</InputLabel>
        <Select value={expType} onChange={handleChange} label="Age">
          <MenuItem value={ExploreType.findLink}>知识链接</MenuItem>
          <MenuItem value={ExploreType.findChildren}>子级关系</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const ExploreNode = React.memo(({ uuid }: { uuid: string }) => {
  const { data } = useRequest(async () => {
    const db = await getDatabase();
    const doc: KnowledgeCard = await (
      await getKnowledgeCard_Read(db)
    ).get(uuid);
    return doc;
  });
  const knowledgeCtx = useKnowledgeContext();

  return (
    <Button
      onClick={() => {
        knowledgeCtx.openPages?.(uuid);
      }}
    >
      {(data && data.title) || ""}
    </Button>
  );
});

export const ExploreDialog = ({
  open,
  handleClose,
  uuid,
  type,
}: {
  open: boolean;
  handleClose: () => void;
  uuid: string;
  type: ExploreType;
}) => {
  const { data } = useRequest(async () => {
    const db = await getDatabase();
    return (await (await getKnowledgeCard_Read(db)).get(
      uuid
    )) as KnowledgeCard;
  });
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xl"}>
      <DialogTitle>
        <Typography color="textSecondary" style={{ fontSize: 14 }}>
          探索
        </Typography>
        {data && (
          <BlockEditor
            mvalue={{ document: data.doc.document[0].children } as any}
            setValue={() => {}}
            readonly
          />
        )}
      </DialogTitle>
      <DialogContent style={{ height: "80vh" }}>
        <KnowledgeExplore uuid={uuid} exploreType={type} />
      </DialogContent>
    </Dialog>
  );
};

export enum ExploreType {
  findChildren,
  findLink,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 100,
      position: "absolute",
      top: 0,
      right: 130,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
