import { createMuiTheme } from "@material-ui/core";
import { red, teal } from "@material-ui/core/colors";
import { useState, useCallback } from "react";

const red_theme = {
  theme: createMuiTheme({
    palette: {
      type: "light",
      primary: { main: red[300] },
      secondary: { main: teal[300] },

    }
  }),
  headtheme: createMuiTheme({
    palette: {
      type: "dark",
      primary: { main: red[100] },
      secondary: { main: teal[200] }
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 15
    }
  }),
  background: "rgb(238, 228, 228 )",
  background_dark: "rgb(243, 129, 129)",
  background_drawer: "#FFF7F7"
};
const normal_theme = {
  theme: createMuiTheme({
    palette: {
      type: "light"
    }
  }),
  headtheme: createMuiTheme({
    palette: {
      type: "light"
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 15
    }
  }),
  background: "rgb(238, 238, 238)",
  background_dark: "rgb(245, 245, 245)",
  background_drawer: "rgb(250, 250, 250)"
};

const mtheme_list = new Map([
  ["red", red_theme],
  ["normal", normal_theme]
]);

export const useMTheme = () => {
  let [mtheme, setMtheme] = useState(() => {
    let t = localStorage.getItem("theme");
    if (t && mtheme_list.has(t)) return mtheme_list.get(t);
    return normal_theme;
  });
  if (!mtheme) mtheme = normal_theme;

  const refreashTheme = useCallback(() => {
    let t = localStorage.getItem("theme");

    if (t && mtheme_list.has(t)) {
      setMtheme(mtheme_list.get(t));
      return;
    }
    setMtheme(normal_theme);
  }, [setMtheme]);

  return { theme: mtheme, refreash: refreashTheme };
};
