/**
 * @file 提供知识页面的主要功能。
 */
import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import {
  Box,
  Grid,
  Paper,
  makeStyles,
  createStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Fade,
} from "@material-ui/core";
import "./KnowledgeCSS.css";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  StoreName,
  getObjectStore,
  getKnowledgeCard_Read,
  getKnowledgeCard_Write,
  getNotes_Read,
} from "../../../lib/Database/Database";
import {
  KnowledgeCard,
  newKnowledgeCard,
  DropData,
  getFromDoc,
  LearnKeyword,
  newKeywordRecord,
} from "./old_Knowledge";
import { BlockEditor } from "../../../elements/BlockEditor";
import { useMotionArrayValue } from "../../../Motion/useMotionValue";
import { cloneDeep } from "lodash";
import { PageRawData } from "../../../lib/Database/DataInterface";
import {
  useAlive,
  useUpdateI,
  useStateful,
  Stateful,
  useUpdate,
} from "../../../lib/CommonHook";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import DragHandle from "@material-ui/icons/DragHandle";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { useDrop, useDrag } from "react-dnd";
import { useKDeleteDialog, KDeleteDialog } from "./DeleteDialog";
import { NoteCardType } from "../../Cards/CardPage";
import { combineOneLine } from "../../../elements/EditorMethods";
import { comfirmKeyLink } from "./KnowledgeLink";
import { ExploreDialog, ExploreType } from "../Explore/KnowledgeExplore";
import md5 from "blueimp-md5";
import Close from "@material-ui/icons/Close";
import { last } from "../../../lib/ArrayUtil";
import {
  GetExportLearn,
  downloadKnowledge,
} from "../../SettingPage/ExportLearn";
import { useEditorFocusControl } from "../../../elements/EditorFocusControl";
import { useDivices } from "../../../lib/hooks/useDevices";
import { useApp } from "../../../App";
import { useLocalStorage } from "../../../lib/hooks/useLocalStorage";
import { NoteDragData, NoteData } from "./NotePanel";

const titleWidth = 40;
let contentWidth = 900;

export interface KnowledgeRef {
  openPages: (id: string) => void;
}

interface openPageProp {
  toRoot?: boolean;
  append?: boolean;
  rootIndex?: number;
  close?: boolean;
  isFirst?: boolean;
}

interface PageItem {
  pages: string[];
}

function PageFlat(list: PageItem[]) {
  let pages: string[] = [];
  for (let i = 0; i < list.length; i++) {
    const e = list[i];
    const re = cloneDeep(e.pages).reverse();
    for (let j = re.length - 1; j >= 0; j--) {
      const p = re[j];
      if (pages.indexOf(p) === -1) pages.push(p);
    }
  }
  return pages; //.reverse();
}

function PageFlatWithIndex(list: PageItem[]) {
  let pages: { id: string; rootIndex: number; innerIndex: number }[] = [];
  for (let i = 0; i < list.length; i++) {
    const e = list[i];
    const re = cloneDeep(e.pages).reverse();
    let ii = 0;
    for (let j = re.length - 1; j >= 0; j--) {
      const p = re[j];
      if (pages.findIndex((k) => k.id === p) === -1) {
        pages.push({ id: p, rootIndex: i, innerIndex: ii });
        ii += 1;
      }
    }
  }
  return pages; //.reverse();
}

/**
 * 知识上下文，提供子组件共享状态
 */
const KnowledgeContext = React.createContext({ openPages: undefined } as {
  openPages?: (s: string, args?: openPageProp) => Promise<void>;
  pages: string[];
  setLock: (l: Set<string>) => void;
  lock: React.MutableRefObject<Set<string>>;
  readonly?: boolean;
  //closeCard: (id: string) => void;
  //openCard: (id: string) => void;
  //theOpenedCards: Map<string, number>
});

export const useKnowledgeContext = () => {
  return useContext(KnowledgeContext);
};

//@note 知识根节点
/**
 * 知识组件，包含多个知识页。
 */
export const Knowledge = ({
  root,
  readonly,
  Ref,
}: {
  root?: string;
  readonly?: boolean;
  Ref?: React.MutableRefObject<KnowledgeRef | null>;
}) => {
  if (!root) root = "root";
  useEffect(() => {
    comfirmKeyLink();
  }, []);
  const app = useApp();

  const clientWidth = app.clientWidth;
  const screenW = clientWidth - 40;

  const pageRefKey = useUpdate();

  const divice = useDivices();

  contentWidth = useMemo(
    () =>
      clientWidth *
      divice.select([
        [divice.whenDesktop, 0.5],
        [divice.whenPad, 0.8],
        [divice.whenMobile, 0.8],
      ]),
    [clientWidth, divice.whenDesktop, divice.whenPad, divice.whenMobile]
  );
  const { state: pages, setState: setPages } = useLocalStorage<PageItem[]>(
    "knowledgeList_0",
    [{ pages: [root] }]
  );
  const { state: focus, setState: setFocusIndex } = useLocalStorage(
    "knowledgeFocusPage_0",
    0
  );

  const lock = useRef<Set<string>>(new Set<string>());
  const setLock = useCallback(
    (l: Set<string>) => {
      lock.current = l;
    },
    [lock]
  );

  const motion = useMotionArrayValue(
    PageFlatWithIndex(pages).map((v, i) => {
      let l = 0;
      let count = PageFlatWithIndex(pages).length - 1;
      if (count === 0) count = 1;
      if (focus >= i) {
        l = (i * (screenW - contentWidth)) / count;
      } else {
        l = ((i - 1) * (screenW - contentWidth)) / count + contentWidth;
      }
      return {
        key: v.id,
        value: { x: l },
        rootIndex: v.rootIndex,
        innerIndex: v.innerIndex,
        focus: undefined,
      } as {
        key: string;
        value: { x: number };
        rootIndex: number;
        innerIndex: number;
        focus: string | undefined;
      };
    })
  );

  //@note ----聚焦
  const onFocus = useCallback(
    (i: number, focusDoc?: string) => {
      let newpos = cloneDeep(motion.position.current);
      let n2 = newpos.map((vv, ii) => {
        let l = 0;
        let count = PageFlatWithIndex(pages).length - 1;
        if (count === 0) count = 1;
        if (i >= ii) {
          l = (ii * (screenW - contentWidth)) / count;
        } else {
          l = ((ii - 1) * (screenW - contentWidth)) / count + contentWidth;
        }
        return {
          key: vv.key,
          value: { x: l },
          rootIndex: vv.rootIndex,
          innerIndex: vv.innerIndex,
          focus: i === ii ? focusDoc : undefined,
        };
      });
      motion.moveTo(n2, 10);
      setFocusIndex(i);
    },
    [motion, focus, pages, screenW, setFocusIndex]
  );
  const onFocusRef = useRef(onFocus);
  onFocusRef.current = onFocus;
  useEffect(() => {
    pageRefKey.update();
    onFocusRef.current(focus);
  }, [clientWidth]);
  useEffect(() => {
    setTimeout(() => onFocus(focus), 1000);
  }, []);

  //@note ----openPage
  const openPages = useCallback(
    async (newpage: string, args?: openPageProp) => {
      let newpages: PageItem[] = [];
      const db = await getDatabase();
      let focusPage = newpage;
      if (!args?.close) {
        if (args?.toRoot) {
          let flatpages: string[] = [];
          const getd = async (id: string) => {
            const d: KnowledgeCard = await (
              await getKnowledgeCard_Read(db)
            ).get(id);
            flatpages.push(d.uuid);
            if (flatpages[flatpages.length - 1] !== root) await getd(d.parent);
          };
          await getd(newpage);
          flatpages.reverse();
          let pp = cloneDeep(pages);
          if (args?.append) {
            // 附加模式
            pp.push({ pages: flatpages });
          } else if (args.rootIndex !== undefined) {
            // 重置当前根节点
            pp[args.rootIndex] = { pages: flatpages };
          }
          newpages = pp;
        } else {
          newpages = [...pages, { pages: [newpage] }];
        }
      } else if (args.rootIndex !== undefined) {
        if (args.isFirst) {
          // 删掉的是某一组第一张，直接删掉整个组
          for (let i = 0; i < pages.length; i++) {
            const e = pages[i];
            if (i !== args.rootIndex) {
              newpages.push(e);
            }
          }
          focusPage = last(newpages[args.rootIndex - 1].pages);
        } else {
          // 删掉的是不是第一张，只删掉后面跟着的
          newpages = cloneDeep(pages);
          let ki = newpages[args.rootIndex].pages.indexOf(newpage);
          newpages[args.rootIndex].pages = newpages[args.rootIndex].pages.slice(
            0,
            ki
          );
          focusPage = last(newpages[args.rootIndex].pages);
        }
      }

      let rp = newpages; //.reverse();
      let newarr = PageFlatWithIndex(rp);
      let newfocus = newarr.findIndex((kv) => kv.id === focusPage); //newarr.length - 1;
      let n2 = newarr.map((vv, ii) => {
        let l = 0;
        let count = newarr.length - 1;
        if (count === 0) count = 1;
        if (newfocus >= ii) {
          l = (ii * (screenW - contentWidth)) / count;
        } else {
          l = ((ii - 1) * (screenW - contentWidth)) / count + contentWidth;
        }
        return {
          key: vv.id,
          value: { x: l },
          rootIndex: vv.rootIndex,
          innerIndex: vv.innerIndex,
          focus: undefined,
        };
      });
      motion.moveTo(n2, 10);
      setFocusIndex(newfocus);
      setPages(rp);
    },
    [root, screenW, setFocusIndex, setPages, pages, motion]
  );

  if (Ref) Ref.current = { ...Ref.current, openPages: openPages };

  const openPagesRef = useRef(openPages);
  openPagesRef.current = openPages;

  const openPagesInChildren = useCallback(
    async (newpage: string, args?: openPageProp) => {
      await openPagesRef.current(newpage, args);
    },
    [openPagesRef]
  );

  const flatPages = useMemo(() => PageFlat(pages), [PageFlat, pages]);

  return (
    <Box
      style={{
        position: "relative",
        width: "97vw",
        overflowX: "hidden",
        height: "90vh",
      }}
    >
      <KnowledgeContext.Provider
        value={{
          openPages: openPagesInChildren,
          pages: flatPages,
          lock,
          setLock,
          readonly,
        }}
      >
        {motion.position.current.map((v, i) => {
          return (
            <Box
              style={{
                position: "absolute",
                transform: `translateX(${v.value.x || 0}px)`,
              }}
              key={v.key}
            >
              <OneKnowledgePage //@note ----生成每列文档
                isFocus={i === focus}
                index={i}
                uuid={v.key}
                onFocusRef={onFocusRef}
                rootIndex={v.rootIndex}
                innerIndex={v.innerIndex}
                tryFocus={v.focus}
                refreashKey={pageRefKey.key}
              />
            </Box>
          );
        })}
      </KnowledgeContext.Provider>
    </Box>
  );
};
interface OneKnowledgePageProp {
  index: number;
  uuid: string;
  onFocusRef: React.MutableRefObject<
    (i: number, focusDoc?: string | undefined) => void
  >;
  isFocus: boolean;
  rootIndex: number;
  innerIndex: number;
  tryFocus: string | undefined;
  refreashKey: number;
}
//@note 知识页
/**
 * 一页知识页，显示该知识的内容和子节点
 */
export const OneKnowledgePage = React.memo(
  (prop: OneKnowledgePageProp) => {
    const style = useOneKnowledgePaper();
    const manualUpdate = useUpdateI();
    const autoFocus = useStateful("");
    const knowledgeCtx = useKnowledgeContext();
    const path = knowledgeCtx.pages;

    const { data: doc } = useRequest(
      async () => {
        const db = await getDatabase();
        let doc: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
          prop.uuid
        );
        if (doc === undefined && prop.uuid === "root") {
          await (await getKnowledgeCard_Write(db)).put({
            keywords: [],
            title: "",
            parent: "root",
            uuid: "root",
            children: [],
            type: "collection",
          });
          doc = await (await getKnowledgeCard_Read(db)).get(prop.uuid);
        } else {
          autoFocus.set(doc.uuid);
        }

        return doc;
      },
      { refreshDeps: [manualUpdate.key] }
    );

    const focusCard = useCallback(
      //@note ----聚焦卡片
      (tryFocus, up?: boolean) => {
        //    if (tryFocus !== undefined && doc) {
        //      if (tryFocus !== "" && knowledgeCtx.lock.current.has(tryFocus)) {
        //        if (doc.children) {
        //          let ii = doc.children.indexOf(tryFocus);
        //          if (!up) {
        //            for (let i = ii; i < doc.children.length; i++) {
        //              const e = doc.children[i];
        //              if (!knowledgeCtx.lock.current.has(e)) {
        //                autoFocus.set(e);
        //                break;
        //              }
        //            }
        //          } else {
        //            for (let i = ii; i >= 0; i--) {
        //              const e = doc.children[i];
        //              if (!knowledgeCtx.lock.current.has(e)) {
        //                autoFocus.set(e);
        //                break;
        //              }
        //            }
        //          }
        //        }
        //      } else autoFocus.set(tryFocus);
        //    }
        //  },
        //  [doc, knowledgeCtx.lock, autoFocus]
      },
      []
    );

    //useEffect(() => {
    //  if(autoFocus.value !== "") focusCard(prop.tryFocus);
    //}, [prop.tryFocus, focusCard]);

    //const doc_uuid = doc?.uuid || "";
    const createNew = useCallback(
      async (after?: string) => {
        if (!knowledgeCtx.readonly && doc) {
          if (after === undefined) after = last(doc.children);
          // 保存新增加的
          if (doc) {
            let newcard = newKnowledgeCard(doc.uuid);
            const db = await getDatabase();
            await (await getKnowledgeCard_Write(db)).put(newcard);
            let old: KnowledgeCard = await (
              await getKnowledgeCard_Read(db)
            ).get(doc.uuid);
            old.children.splice(
              old.children.indexOf(after) + 1,
              0,
              newcard.uuid
            );
            focusCard(newcard.uuid);
            //old.children.push(newcard.uuid);
            old.type = NoteCardType.collection;
            await (await getKnowledgeCard_Write(db)).put(old);
            manualUpdate.update();
          }
        }
      },
      [knowledgeCtx.readonly, manualUpdate, focusCard, doc]
    );
    const openChild = useCallback(
      (id) => {
        knowledgeCtx.openPages?.(id, {
          rootIndex: prop.rootIndex,
          toRoot: true,
        });
      },
      [prop.rootIndex, knowledgeCtx.openPages]
    );

    const doNothing = useCallback(() => {}, []);
    const focusNext = useCallback(
      (id, offset) => {
        if (offset === 1) {
          focusCard(doc?.children[0] || "");
        }
      },
      [focusCard, doc]
    );

    const focusNext2 = useCallback(
      (id, offset) => {
        if (doc) {
          let currentIndex = doc.children.indexOf(id);
          let newIndex = currentIndex + offset;
          let up = offset === -1;
          if (newIndex === -1) focusCard(doc.uuid);
          else if (newIndex >= doc.children.length) focusCard("");
          else focusCard(doc.children[newIndex] || "", up);
        }
      },
      [doc, focusCard]
    );

    const backToIndex = useCallback(() => {
      if (doc) {
        let ni = prop.index - 1;
        prop.onFocusRef.current(ni < 0 ? 0 : ni, doc.uuid);
      }
    }, [doc, prop.index, prop.onFocusRef]);

    const doc_children = doc?.children || [];
    const doc_title = doc?.title || "";
    const content = useMemo(() => {
      return (
        <Grid container style={{ marginTop: 20 }}>
          <Grid item className={style.title}>
            <Typography style={{ letterSpacing: 3 }}>{doc_title}</Typography>
          </Grid>
          <Grid
            container
            item
            style={{ width: contentWidth - titleWidth, paddingTop: 30 }}
          >
            <Grid item key={prop.uuid} xs={12}>
              {prop.uuid !== "root" && (
                <OneKnowledgeCard //@note ----当前文档内容
                  uuid={prop.uuid}
                  collectionUpdateUpdate={manualUpdate.update}
                  pages={path}
                  isMaster
                  rootIndex={prop.rootIndex}
                  createNew={createNew}
                  autoFocus={autoFocus}
                  openChild={doNothing}
                  focusNext={focusNext}
                  backToPrevious={backToIndex}
                  index={-1}
                  parent=""
                />
              )}
            </Grid>
            {doc_children.map((
              v,
              i //@note ----子文档列表
            ) => (
              <Grid item key={v} xs={12}>
                <OneKnowledgeCard
                  key={v}
                  uuid={v}
                  collectionUpdateUpdate={manualUpdate.update}
                  pages={path}
                  rootIndex={prop.rootIndex}
                  createNew={createNew}
                  autoFocus={autoFocus}
                  openChild={openChild}
                  focusNext={focusNext2}
                  backToPrevious={backToIndex}
                  index={i}
                  parent={prop.uuid}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    }, [
      doc,
      path,
      style.title,
      doc_title,
      doc_children,
      prop.uuid,
      manualUpdate,
      autoFocus.value,
      autoFocus.set,
      createNew,
      prop.rootIndex,
      knowledgeCtx.openPages,
      contentWidth,
    ]);

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: ["card", "note"],
      drop: async (data: DropData | NoteDragData) => {
        const db = await getDatabase();
        const myid = data.uuid;
        if (data.type === "card") {
          // des parent -> upId.parent
          let that: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
            data.uuid
          );
          let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
            that.parent
          );
          root.children = root.children.filter((a) => a !== data.uuid);

          if (doc) {
            let up = doc;
            // 组间移动
            let upParent: KnowledgeCard = doc;
            let upid = upParent.children.indexOf(up.uuid) + 1;
            upParent.children.splice(upid, 0, data.uuid);
            that.parent = upParent.uuid;

            if (path.indexOf(myid) !== -1) return;
            await (await getKnowledgeCard_Write(db)).put(upParent);

            await (await getKnowledgeCard_Write(db)).put(root);
            await (await getKnowledgeCard_Write(db)).put(that);

            (data as DropData).update();
          }
        } else if (data.type === "note" && (data as NoteDragData).onSuccess) {
          if (doc) {
            let up = doc;
            // 组间移动
            let upParent: KnowledgeCard = doc;
            upParent.children.push(data.uuid);
            let noted = data as NoteDragData;
            let note: NoteData = await (await getNotes_Read(db)).get(
              noted.uuid
            );
            if (note.doc) {
              let newk = newKnowledgeCard(doc.parent, note.uuid);
              newk.doc = note.doc;
              await (await getKnowledgeCard_Write(db)).put(upParent);
              await (await getKnowledgeCard_Write(db)).put(newk);
              await noted.onSuccess();
            }
          }
        }
        manualUpdate.update();
      },
      collect: (mon) => ({
        isOver: !!mon.isOver(),
        canDrop: !!mon.canDrop(),
      }),
      canDrop: (item: DropData) => {
        return (
          item.uuid !== prop.uuid &&
          doc_children !== undefined &&
          doc_children.length === 0
        );
      },
    });
    const istyle = useOneKnowledgeItem();
    const theme = useTheme();
    const onClick = useCallback(() => prop.onFocusRef.current(prop.index), [
      prop.index,
      prop.onFocusRef,
    ]);
    const addNew = useCallback(() => createNew(), [createNew]);
    return (
      // @note ----渲染
      <Paper
        className={style.paper}
        style={{
          left: 0,
          zIndex: 0 + prop.index + (prop.isFocus ? 1000 : 0),
          //        width: contentWidth,
        }}
        onClick={onClick}
      >
        {prop.innerIndex === 0 && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: 2,
              backgroundColor: theme.palette.primary.main,
            }}
          ></div>
        )}
        {prop.index !== 0 && (
          <IconButton
            style={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              knowledgeCtx.openPages?.(prop.uuid, {
                close: true,
                rootIndex: prop.rootIndex,
                isFirst: prop.innerIndex === 0,
              });
            }}
          >
            <Close />
          </IconButton>
        )}

        {content}
        {!knowledgeCtx.readonly && (
          <div ref={drop} className={isOver && canDrop ? istyle.over : ""}>
            <Grid container justify="center">
              <Grid item>
                <IconButton onClick={addNew}>
                  <AddCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    );
  },
  (pre, next) => {
    //@note ----比较渲染
    let res =
      pre.index === next.index &&
      pre.innerIndex === next.innerIndex &&
      pre.isFocus === next.isFocus &&
      pre.onFocusRef.current === next.onFocusRef.current &&
      //pre.path === next.path &&
      pre.rootIndex === next.rootIndex &&
      pre.tryFocus === next.tryFocus &&
      pre.uuid === next.uuid &&
      pre.refreashKey === next.refreashKey;
    return res;
  }
);

interface OneKnowledgeCardProp {
  parent: string;
  uuid: string;
  collectionUpdateUpdate: () => void;
  pages: string[];
  isMaster?: boolean;
  rootIndex: number;
  createNew: (after: string) => void;
  autoFocus: Stateful<string>;
  openChild: (parent: string) => void;
  focusNext: (id: string, offset: 1 | -1) => void;
  backToPrevious: () => void;
  index?: number;
}

//@note 知识卡
/**
 * 显示一条知识
 */
const OneKnowledgeCard = React.memo((prop: OneKnowledgeCardProp) => {
  const alive = useAlive();
  const update = useUpdateI();
  const lastTime = useRef(new Date());

  const { data: doc } = useRequest(
    async () => {
      const db = await getDatabase();
      const doc: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        prop.uuid
      );
      if (doc) {
        setMvalue(doc.doc);
        if (doc.doc) lastTime.current = doc.doc.lastWriteTime;
      }

      return doc;
    },
    { refreshDeps: [update.key] }
  );

  const knowledgeCtx = useKnowledgeContext();
  useEffect(() => {
    if (prop.isMaster) {
      knowledgeCtx.setLock(new Set([...knowledgeCtx.lock.current, prop.uuid]));
      return () => {
        let set = new Set([...knowledgeCtx.lock.current]);
        set.delete(prop.uuid);
        knowledgeCtx.setLock(set);
      };
    }
  }, [prop.isMaster, knowledgeCtx.setLock, knowledgeCtx.lock, prop.uuid]);
  const isLock = !prop.isMaster && knowledgeCtx.lock.current.has(prop.uuid);

  useEffect(() => {
    if (isLock) {
      let timer = setInterval(() => {
        const up = async () => {
          const db = await getDatabase();
          const nd: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
            prop.uuid
          );
          if (
            nd.doc &&
            nd.doc.lastWriteTime.toString() !== lastTime.current.toString()
          ) {
            if (alive.current) {
              //console.log("read new lock");
              //@todo ----同步文档
              setMvalue(nd.doc);
              lastTime.current = nd.doc.lastWriteTime;
            }
          }
        };
        up();
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isLock, alive, prop.uuid, lastTime]);

  const [mvalue, setMvalue] = useState(doc?.doc);
  const { run: save } = useRequest(
    async (newp: PageRawData) => {
      const db = await getDatabase();
      const odoc: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        prop.uuid
      );
      const newtitle = combineOneLine(newp.document[0].children);
      let keys: string[] = [];
      getFromDoc(newp.document, { keywords: keys });
      newp.md5 = md5(JSON.stringify(newp.document));
      let t = new Date();
      newp.lastWriteTime = t;
      lastTime.current = t;
      await (await getKnowledgeCard_Write(db)).put({
        ...doc,
        children: odoc.children,
        doc: newp,
        type:
          odoc.children.length === 0
            ? NoteCardType.card
            : NoteCardType.collection,
        title: newtitle,
        keywords: keys,
      });
      let kw: LearnKeyword = await (
        await getObjectStore(db, StoreName.learnKeywords)
      ).get(prop.uuid);

      if (kw === undefined || kw.keyword !== newtitle) {
        if (kw === undefined) kw = newKeywordRecord(newtitle, prop.uuid);
        else kw.keyword = newtitle;
        await (
          await getObjectStore(db, StoreName.learnKeywords, "readwrite")
        ).put(kw);
      }
    },
    { refreshDeps: [doc], manual: true, throttleInterval: 1000 }
  );

  const setDoc = useCallback(
    async (newp: KnowledgeCard) => {
      const db = await getDatabase();
      const odoc: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
        prop.uuid
      );
      const newtitle = combineOneLine(newp.doc.document[0].children);
      let keys: string[] = [];
      getFromDoc(newp.doc.document, { keywords: keys });
      await (await getKnowledgeCard_Write(db)).put({
        ...doc,
        ...newp,
        children: odoc.children,
        doc: mvalue,
        type:
          odoc.children.length === 0
            ? NoteCardType.card
            : NoteCardType.collection,
        title: newtitle,
        keywords: keys,
      } as KnowledgeCard);

      update.update();
    },
    [doc, mvalue, prop.uuid, update]
  );

  const style = useOneKnowledgeItem();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ["card", "note"],
    drop: async (data: DropData | NoteDragData) => {
      const db = await getDatabase();

      if (data.type === "card") {
        const myid = data.uuid;
        // des parent -> upId.parent
        let that: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
          data.uuid
        );
        let root: KnowledgeCard = await (await getKnowledgeCard_Read(db)).get(
          that.parent
        );
        root.children = root.children.filter((a) => a !== data.uuid);

        if (doc) {
          let up = doc;

          if (up.parent !== that.parent) {
            // 组间移动
            let upParent: KnowledgeCard = await (
              await getKnowledgeCard_Read(db)
            ).get(up.parent);
            let upid = upParent.children.indexOf(up.uuid) + 1;
            upParent.children.splice(upid, 0, data.uuid);
            that.parent = upParent.uuid;

            if (prop.pages.indexOf(myid) !== -1) return;
            await (await getKnowledgeCard_Write(db)).put(upParent);
          } else {
            // 组内移动
            let upid = root.children.indexOf(up.uuid) + 1;
            root.children.splice(upid, 0, data.uuid);
          }

          await (await getKnowledgeCard_Write(db)).put(root);
          await (await getKnowledgeCard_Write(db)).put(that);

          (data as DropData).update();
        }
      } else if (data.type === "note") {
        let noted = data as NoteDragData;
        if (doc) {
          let pdoc = await (await getKnowledgeCard_Read(db)).get(prop.parent);
          let upid = pdoc.children.indexOf(doc.uuid) + 1;
          pdoc.children.splice(upid, 0, data.uuid);
          let note: NoteData = await (await getNotes_Read(db)).get(noted.uuid);
          if (note.doc) {
            let newk = newKnowledgeCard(doc.parent, note.uuid);
            newk.doc = note.doc;
            await (await getKnowledgeCard_Write(db)).put(pdoc);
            await (await getKnowledgeCard_Write(db)).put(newk);
            await noted.onSuccess();
     
          }
        }
      }
      prop.collectionUpdateUpdate();
    },
    collect: (mon) => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop(),
    }),
    canDrop: (item: DropData) => {
      return item.uuid !== prop.uuid && !knowledgeCtx.readonly;
    },
  });

  const [, drag] = useDrag({
    item: {
      type: "card",
      uuid: prop.uuid,
      update: prop.collectionUpdateUpdate,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const needFocus = useStateful(false);
  //useEffect(() => { //@note 自动focus
  //  if (prop.autoFocus.value === prop.uuid) {
  //    needFocus.set(true);
  //    prop.autoFocus.set("");
  //  }
  //}, [
  //  prop.autoFocus.value,
  //  needFocus,
  //  prop.autoFocus,
  //  prop.rootIndex,
  //  prop.uuid,
  //]);

  const focusControl = useEditorFocusControl();
  const editor = useMemo(() => {
    //@note ----编辑器
    //console.trace("ref mem");
    if (mvalue)
      return (
        <BlockEditor
          key={prop.uuid}
          mvalue={mvalue}
          editorRef={focusControl.editorRef}
          setValue={(tdoc: any) => {
            let nv = { ...mvalue, document: tdoc.doc, tags: tdoc.tags };
            if (alive.current) setMvalue(nv);
            save(nv);
          }}
          readonly={isLock || knowledgeCtx.readonly}
          onShiftEnter={() => {
            prop.createNew(prop.uuid);
          }}
          autoFocus={needFocus.value && !isLock}
          onCtrlRight={() => {
            prop.openChild(prop.uuid);
          }}
          onCtrlLeft={() => prop.backToPrevious()}
          onCtrlUp={() => prop.focusNext(prop.uuid, -1)}
          onCtrlDown={() => prop.focusNext(prop.uuid, 1)}
          resetAutoFocus={() => needFocus.set(false)}
        />
      );
  }, [
    mvalue,
    setMvalue,
    save,
    isLock,
    alive,
    knowledgeCtx.readonly,
    focusControl.editorRef,
    needFocus.set,
    prop.uuid,
    prop.createNew,
    prop.openChild,
    prop.backToPrevious,
    prop.focusNext,
  ]);

  const deleteDialog = useKDeleteDialog();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    //setBackOpen(false);
  }, [setAnchorEl]);

  const [exploreOpen, setExploreOpen] = useState(false);

  const doc_isSubject = doc?.isSubject;
  const menu = useMemo(
    //@note ----菜单
    () => {
      return (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>取消</MenuItem>
          {!prop.isMaster && !knowledgeCtx.readonly && (
            <MenuItem
              onClick={() => {
                deleteDialog.Open();
                handleClose();
              }}
            >
              删除
            </MenuItem>
          )}

          {doc && !doc_isSubject && !knowledgeCtx.readonly && (
            <MenuItem
              onClick={() => {
                handleClose();
                setDoc({ ...doc, isSubject: 1 });
              }}
            >
              设置为学科
            </MenuItem>
          )}
          {doc && !!doc_isSubject && !knowledgeCtx.readonly && (
            <MenuItem
              onClick={() => {
                handleClose();
                setDoc({ ...doc, isSubject: 0 });
              }}
            >
              取消此学科
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setExploreOpen(true);
              handleClose();
            }}
          >
            探索
          </MenuItem>
          <MenuItem
            onClick={() => {
              knowledgeCtx.openPages?.(prop.uuid, {
                toRoot: true,
                rootIndex: prop.rootIndex,
              });
              handleClose();
            }}
          >
            打开所有父知识
          </MenuItem>
          <MenuItem
            onClick={async () => {
              if (doc) {
                let file = await GetExportLearn(doc.uuid);
                downloadKnowledge(doc?.title || "doc", file);
              }

              handleClose();
            }}
          >
            导出
          </MenuItem>
        </Menu>
      );
    },
    [
      anchorEl,
      knowledgeCtx.readonly,
      prop.rootIndex,
      handleClose,
      doc,
      doc_isSubject,
      setDoc,
      deleteDialog.Open,
      knowledgeCtx.openPages,
      prop.isMaster,
      prop.uuid,
    ]
  );

  const dcl = doc?.children.length || 0;
  const openChildButton = useMemo(() => {
    return (
      <IconButton
        style={{ position: "absolute", right: 0, bottom: -10 }}
        onClick={(e) => {
          //prop.openChildren(prop.uuid);
          let append = false;
          if (e.shiftKey) {
            append = true;
          }
          knowledgeCtx.openPages?.(prop.uuid, {
            toRoot: true,
            append: append,
            rootIndex: prop.rootIndex,
          });
        }}
      >
        <KeyboardArrowRight
          htmlColor={dcl > 0 ? "rgba(0, 0, 0, 0.54)" : "rgb(167 167 167 / 54%)"}
        />
      </IconButton>
    );
  }, [dcl, prop.uuid, prop.rootIndex, knowledgeCtx.openPages]);
  const moreButton = useMemo(
    () => (
      <Box style={{ position: "absolute", right: 0, top: -10 }}>
        <IconButton onClick={handleClick}>
          <MoreHoriz />
        </IconButton>
      </Box>
    ),
    [handleClick]
  );
  const hover = useStateful(false);
  const dragHandleButton = useMemo(
    () => (
      <Grid container justify="center">
        <Grid item>
          <DragHandle className={style.show} />
        </Grid>
      </Grid>
    ),
    [hover.value]
  );
  const deleteDialogCom = useMemo(() => {
    return (
      <KDeleteDialog
        handleClose={deleteDialog.handleClose}
        open={deleteDialog.open}
        handleDelete={async () => {
          if (doc) {
            let db = await getDatabase();
            //await (
            //  await getKnowledgeCard_Write(db)
            //).delete(prop.uuid);
            let parent: KnowledgeCard = await (
              await getKnowledgeCard_Read(db)
            ).get(doc.parent);
            parent.children = parent.children.filter((v) => v !== prop.uuid);
            await (await getKnowledgeCard_Write(db)).put(parent);
            prop.collectionUpdateUpdate();
          }
        }}
      />
    );
  }, [
    prop.uuid,
    prop.collectionUpdateUpdate,
    deleteDialog.handleClose,
    deleteDialog.open,
    doc,
  ]);
  const onMouseEnter = useCallback(() => hover.set(true), [hover.set]);
  const onMouseLeave = useCallback(() => hover.set(false), [hover.set]);
  const tempStyle = useMemo(() => {
    return {
      backgroundColor: isLock
        ? needFocus
          ? "rgb(235 235 235)"
          : "rgb(244 244 244)"
        : "",
    };
  }, [isLock, needFocus]);
  const dragHandle = useMemo(() => {
    return (
      <div
        ref={drag}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: 30,
        }}
      >
        {!prop.isMaster && <Fade in={hover.value}>{dragHandleButton}</Fade>}
      </div>
    );
  }, [drag, prop.isMaster, hover.value, dragHandleButton]);
  if (doc && doc.doc === undefined) return null;
  return (
    <Box
      className={
        !prop.isMaster
          ? prop.pages.indexOf(prop.uuid) === -1
            ? style.card
            : style.onExpand
          : style.master
      }
      style={tempStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {dragHandle}
      {doc && editor}
      {!prop.isMaster && openChildButton}

      {moreButton}
      {Boolean(anchorEl) && menu}
      {!prop.isMaster && deleteDialogCom}
      {!prop.isMaster && (
        <div
          ref={drop}
          style={{ position: "absolute", left: 0, width: "100%", height: 30 }}
          className={isOver && canDrop ? style.over : ""}
        ></div>
      )}

      {exploreOpen && (
        <ExploreDialog
          open={exploreOpen}
          handleClose={() => {
            setExploreOpen(false);
          }}
          uuid={prop.uuid}
          type={ExploreType.findChildren}
        />
      )}
    </Box>
  );
});

const useOneKnowledgePaper = makeStyles(() =>
  createStyles({
    root: {
      width: "97vw",
      minHeight: "90vh",
    },
    paper: {
      height: "90vh",
      overflow: "auto",
      boxShadow: "0px 0px 64px #989898",
    },
    title: {
      width: titleWidth,
      writingMode: "vertical-lr",
      paddingTop: 30,
      paddingLeft: 2,
      paddingRight: 2,
    },
    content: {
      width: "100%",
    },
  })
);

const useOneKnowledgeItem = makeStyles(() =>
  createStyles({
    card: {
      padding: 8,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#e0e0e0",
      margin: 8,
      borderRadius: 10,
      position: "relative",
      boxShadow: "inset 0px 0px 6px #d4d4d4",
    },
    over: {
      backgroundColor: "#76cffb8f",
      borderRadius: 10,
    },
    onExpand: {
      padding: 8,
      margin: 8,
      borderStyle: "solid",
      borderWidth: 2,
      borderColor: "#a7a7a7",
      borderRadius: 10,
      position: "relative",
      boxShadow: "inset 0px 0px 6px #d4d4d4",
    },
    master: {
      padding: 8,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ffffff",
      margin: 8,
      borderRadius: 10,
      position: "relative",
      boxShadow: "inset 0px 0px 6px #d4d4d4",
      marginBottom: 30,
    },
    show: {
      opacity: 1,
    },
    hide: {
      opacity: 0,
    },
  })
);
