import { useStateful } from "../lib/CommonHook";
import React, { useRef } from "react";
import { useMotionArrayValue } from "../Motion/useMotionValue";

export const DragablePanel = ({
    children,
    right,
    bottom,
  }: {
    children: any;
    right: number;
    bottom: number;
  }) => {
    const draging = useStateful(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const rr = useStateful(right);
    const bb = useStateful(bottom);
    const offset = useStateful({ left: 0, top: 0 });
    const motion = useMotionArrayValue([
      { key: 0, value: { x: right, y: bottom } },
    ]);
  
    return (
      <div
        ref={ref}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          draging.set(true);
          offset.set({
            left: e.clientX - (ref.current?.offsetLeft || 0),
            top: e.clientY - (ref.current?.offsetTop || 0),
          });
        }}
        onMouseMove={(e) => {
          if (draging.value) {
            motion.moveTo([
              {
                key: 0,
                value: {
                  x: largerThenZero(
                    calcRight(e.clientX - offset.value.left) -
                      (ref.current?.offsetWidth || 0)
                  ),
                  y: largerThenZero(
                    calcBottom(e.clientY - offset.value.top) -
                      (ref.current?.offsetHeight || 0)
                  ),
                },
              },
            ], 10);
          }
        }}
        onMouseUp={(e) => {
          draging.set(false);
        }}
        style={{
          position: "fixed",
          right: motion.position.current[0].value.x,
          bottom: motion.position.current[0].value.y,
          userSelect: draging.value ? "none" : "auto",
        }}
      >
        {children}
      </div>
    );
  };
  
  function calcRight(pageX: number) {
    return window.document.documentElement.clientWidth - pageX;
  }
  function calcBottom(pageY: number) {
    return window.document.documentElement.clientHeight - pageY;
  }
  function largerThenZero(n: number) {
    return n > 0 ? n : 0;
  }
  