import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
  createStyles,
  Container,
  Button,
  TextField,
  Chip,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import {
  useLearnApp,
  setNewPlan,
  LearnPlanSubject,
  newLearnPlanSubject,
  ResetStatue,
} from "./useLearnAppState";
import { cloneDeep } from "lodash";

import CloseIcon from "@material-ui/icons/Close";
import { FindSubject } from "./Find/FindSubject";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  StoreName,
  getObjectStore,
  getKnowledgeCard_Read,
} from "../../lib/Database/Database";
import { QuizCardCollection } from "../../elements/plugins/withQuiz";
import { FindQuizCollection } from "./Find/FindQuizCollection";
import { KnowledgeCard } from "./Knowledge/old_Knowledge";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useDeletePlanDialog, DeletePlanDialog } from "./Plan/DeletePlanDialog";

export const LearnPlanPage = () => {
  const style = useCardStyle();
  const learnApp = useLearnApp();
  const [plan, setPlan] = useState(learnApp.plan);
  useEffect(() => {
    setPlan(learnApp.plan);
  }, [learnApp.plan, setPlan]);
  // const savePlan = useCallback(() => {
  //     if (plan !== undefined)
  //         learnApp.func.changePlan?.(setNewPlan(plan))
  // }, [plan, learnApp.func.changePlan])

  return (
    <Grid container spacing={1} justify="center">
      <Grid item xs={12}>
        <Card className={style.card}>
          <CardContent>
            <Typography>制定学习计划</Typography>
          </CardContent>
          <CardContent>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                learnApp.func.changeState?.(ResetStatue());
              }}
            >
              重置今日学习状态
            </Button>
          </CardContent>
        </Card>
        <div style={{ height: 30 }}></div>
      </Grid>
      {plan?.planList.map((v, i) => (
        <Grid item key={v.uuid} xs={12}>
          <PlanItem
            plan={v}
            setNewPlan={(newp) => {
              let newdata = cloneDeep(plan);
              let ni = newdata.planList.findIndex((v) => {
                return v.uuid === newp.uuid;
              });
              if (ni !== -1) {
                newdata.planList[ni] = newp;
                setPlan(newdata);
                learnApp.func.changePlan?.(setNewPlan(newdata));
              }
            }}
            up={() => {
              if (i === 0) return;
              let newdata = cloneDeep(plan);
              if (newdata) {
                newdata.planList = newdata.planList.filter(
                  (s) => s.uuid !== v.uuid
                );
                newdata.planList.splice(i - 1, 0, v);
                setPlan(newdata);
                learnApp.func.changePlan?.(setNewPlan(newdata));
              }
            }}
            down={() => {
              if (!plan || i === plan.planList.length - 1) return;
              let newdata = cloneDeep(plan);
              if (newdata) {
                newdata.planList = newdata.planList.filter(
                  (s) => s.uuid !== v.uuid
                );
                newdata.planList.splice(i + 1, 0, v);
                setPlan(newdata);
                learnApp.func.changePlan?.(setNewPlan(newdata));
              }
            }}
            deleteThis={() => {
              let newdata = cloneDeep(plan);
              if (newdata) {
                newdata.planList = newdata.planList.filter(
                  (s) => s.uuid !== v.uuid
                );
                setPlan(newdata);
                learnApp.func.changePlan?.(setNewPlan(newdata));
              }
            }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Container>
          <Button
            variant="outlined"
            className={style.addButton}
            onClick={() => {
              let newdata = cloneDeep(plan);
              if (newdata) {
                newdata.planList.push(newLearnPlanSubject());
                setPlan(newdata);
                learnApp.func.changePlan?.(setNewPlan(newdata));
              }
            }}
          >
            增加新科目
          </Button>
        </Container>
      </Grid>
    </Grid>
  );
};

interface PlanItemProp {
  plan: LearnPlanSubject;
  setNewPlan: (newp: LearnPlanSubject) => void;
  up: () => void;
  down: () => void;
  deleteThis: () => void;
}
const PlanItem = (prop: PlanItemProp) => {
  const style = useCardStyle();
  const [] = useState(new Date());
  const [findSubjectOpen, setFindSUbjectOpen] = useState(false);
  const [findQuizOpen, setFindQuizOpen] = useState(false);

  const { data: quizTitle } = useRequest(
    async () => {
      const db = await getDatabase();
      let res: QuizCardCollection[] = [];
      for (let i = 0; i < prop.plan.quizsCollectionUUID.length; i++) {
        const qid = prop.plan.quizsCollectionUUID[i];
        const quiz: QuizCardCollection | undefined = await (
          await getObjectStore(db, StoreName.quiz_collection)
        ).get(qid);
        if (quiz) {
          res.push(quiz);
        }
      }
      return res;
    },
    { refreshDeps: [prop.plan.quizsCollectionUUID] }
  );

  const [timeError, setTimeError] = useState(false);

  const { data: knowledgeCol } = useRequest(
    async () => {
      if (prop.plan.subjectKnowledgeCollectionUUID) {
        const db = await getDatabase();
        const res: KnowledgeCard | undefined = await (
          await getKnowledgeCard_Read(db)
        ).get(prop.plan.subjectKnowledgeCollectionUUID);
        return res;
      }
    },
    { refreshDeps: [prop.plan.subjectKnowledgeCollectionUUID] }
  );

  const { run: setNewTime } = useRequest(
    async (newtime: number) => {
      prop.setNewPlan({ ...prop.plan, fulltime: newtime });
    },
    {
      debounceInterval: 500,
      manual: true,
    }
  );
  const [time, setTime] = useState(prop.plan.fulltime.toString());
  const deletePlanDialog = useDeletePlanDialog();
  return (
    <Fragment>
      <Card className={style.card} style={{ position: "relative" }}>
        <Box style={{ position: "absolute", top: 10, right: 10 }}>
          <IconButton onClick={prop.up}>
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
        <Box style={{ position: "absolute", bottom: 10, right: 10 }}>
          <IconButton onClick={prop.down}>
            <ArrowDownwardIcon />
          </IconButton>
        </Box>
        <Box style={{ position: "absolute", bottom: "45%", right: 10 }}>
          <IconButton onClick={() => deletePlanDialog.Open()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <CardContent>
          <TextField
            label="科目名称"
            value={prop.plan.subjectName}
            onChange={(e) => {
              prop.setNewPlan({ ...prop.plan, subjectName: e.target.value });
            }}
          />
        </CardContent>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography className={style.titleText}>
                关联知识点集合
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => setFindSUbjectOpen(true)}
                variant="outlined"
              >
                {prop.plan.subjectKnowledgeCollectionUUID !== ""
                  ? "修改集合"
                  : "选择集合"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                {prop.plan.subjectKnowledgeCollectionUUID !== ""
                  ? "当前关联知识点集合为"
                  : "当前未选择关联知识点集合"}
              </Typography>

              <Typography className={style.secondext}>
                {prop.plan.subjectKnowledgeCollectionUUID !== ""
                  ? knowledgeCol
                    ? knowledgeCol.title
                    : "无效"
                  : "无"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography className={style.titleText}>关联题组</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={() => setFindQuizOpen(true)}>
                添加题组
              </Button>
            </Grid>
          </Grid>

          <Box>
            <Grid container>
              {quizTitle &&
                quizTitle.map((v) => (
                  <Grid item key={v.uuid}>
                    <Chip
                      label={v.name}
                      onDelete={() => {
                        prop.setNewPlan({
                          ...prop.plan,
                          quizsCollectionUUID: prop.plan.quizsCollectionUUID.filter(
                            (a) => a !== v.uuid
                          ),
                        });
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={style.titleText}>预计学习时间</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined">
                <OutlinedInput
                  error={timeError}
                  value={time}
                  onChange={(e) => {
                    let canParse = /^\d+$/.test(e.target.value);
                    let t = parseInt(e.target.value);
                    if (canParse && t >= 0) {
                      setNewTime(t);
                      setTimeError(false);
                    } else {
                      setTimeError(true);
                    }
                    setTime(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">分钟</InputAdornment>
                  }
                  labelWidth={0}
                />
                {timeError && (
                  <FormHelperText id="outlined-weight-helper-text">
                    请输入正整数
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {findSubjectOpen && (
        <FindSubject
          open={findSubjectOpen}
          setResult={(newv) => {
            prop.setNewPlan({
              ...prop.plan,
              subjectKnowledgeCollectionUUID: newv.uuid,
            });
          }}
          handleClose={() => setFindSUbjectOpen(false)}
        />
      )}
      {findQuizOpen && (
        <FindQuizCollection
          open={findQuizOpen}
          setResult={(newv) => {
            let m = {
              ...prop.plan,
              quizsCollectionUUID: [
                ...new Set([...prop.plan.quizsCollectionUUID, newv.uuid]),
              ],
            };
            prop.setNewPlan(m);
          }}
          handleClose={() => setFindQuizOpen(false)}
        />
      )}

      <DeletePlanDialog
        open={deletePlanDialog.open}
        handleClose={deletePlanDialog.handleClose}
        handleDelete={prop.deleteThis}
      />
      <div style={{ height: 30 }}></div>
    </Fragment>
  );
};

const useCardStyle = makeStyles(() =>
  createStyles({
    card: {
      minWidth: 100,
      maxWidth: 500,
      marginLeft: "auto",
      marginRight: "auto",
    },
    addButton: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
    titleText: {
      fontSize: 20,
    },
    secondext: {
      fontSize: 18,
      fontWeight: "bold",
    },
  })
);
