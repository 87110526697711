import {
  changeDB,
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import { PageFCB, PageRawData } from "../../lib/Database/DataInterface";
import { PageListItemRT } from "./PageListItem";
import { Node } from "slate";

export interface searchResult {
  uuid: string;
  path: string[];
}
export function findSearchResult(
  value: string,
  list: searchResult[] | undefined
) {
  if (!list) return true;
  for (let i = 0; i < list.length; i++) {
    const e = list[i];
    if (e.uuid === value) return true;
    if (e.path.indexOf(value) !== -1) return true;
  }
  return false;
}
export async function SearchInDatabase(
  searchText: string,
  lisrRT: PageListItemRT
): Promise<searchResult[] | undefined> {
  if (!searchText || searchText === "") {
    lisrRT.searchResult.current = undefined;
    changeDB();
    return undefined;
  }
  console.log(searchText);
  const db = await getDatabase();
  const root: PageFCB = await (
    await getObjectStore(db, StoreName.fcb_pages)
  ).get("root");

  let res: searchResult[] = [];

  async function searchDoc(
    parent: string[],
    uuid: string,
    list: searchResult[]
  ) {
    let page = await (await getObjectStore(db, StoreName.pages)).get(uuid);
    let fcb: PageFCB = await (
      await getObjectStore(db, StoreName.fcb_pages)
    ).get(uuid);
    if (fcb.title.indexOf(searchText) !== -1 || SearchText(searchText, page)) {
      list.push({ uuid: page.uuid, path: parent });
    }
    for (let j = 0; j < fcb.children.length; j++) {
      const cp = fcb.children[j];
      searchDoc([...parent, uuid], cp, list);
    }
  }
  for (let i = 0; i < root.children.length; i++) {
    const page = root.children[i];
    searchDoc(["root"], page, res);
  }

  lisrRT.searchResult.current = res;
  changeDB();
  return res;
}

export function SearchText(text: string, page: PageRawData) {
  function search(text: string, node: Node) {
    if (node.text) {
      if (node.text.indexOf(text) !== -1) {
        return true;
      } else {
        return false;
      }
    } else if (node.children && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        const cn = node.children[i];
        if (search(text, cn)) return true;
      }
    }
    return false;
  }

  for (let i = 0; i < page.document.length; i++) {
    const node = page.document[i];
    if (search(text, node)) return true;
  }
  return false;
}
