import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Grid,
} from "@material-ui/core";
import { BlockEditorRuntime } from "./useBlockEditor";
import {
  downloadLocalPage,
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import { IDialogControl } from "../../AppCom/DeleteDialog";
import { useRenameDialog, RenameDialog } from "./RenameDialog";
import { PageCache } from "../../lib/Database/DataInterface";

export interface ShareDialogProp {
  open: boolean;
  setOpen: Function;
  runtime: BlockEditorRuntime;
  DeleteDialogControl: IDialogControl;
}
export const ShareDialog = (prop: ShareDialogProp) => {
  const [shareURL, setShareURL] = useState("");
  const handleClose = useCallback(() => {
    prop.setOpen(false);
    setShareURL("");
  }, [setShareURL, prop]);
  const style = useStyles();

  const renameDialog = useRenameDialog(prop.runtime, handleClose);

  return (
    <Dialog onClose={handleClose} open={prop.open}>
      <DialogTitle>高级功能</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => prop.DeleteDialogControl.handleClick()}
            >
              删除
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                var key = prop.runtime.current_doc_uuid;
                var page = prop.runtime.currentDoc;
                const db = await getDatabase();
                const fcb = await (
                  await getObjectStore(db, StoreName.fcb_pages)
                ).get(key);
                const cache: PageCache = await (
                  await getObjectStore(db, StoreName.page_cache)
                ).get(key);

                let data = {
                  key: key,
                  fcb: fcb,
                  page: page.current,
                  media: cache.asset,
                };

                let sdata = JSON.stringify(data);
                console.log("post");
                try {
                  let resp = await fetch(
                    "https://zhishudali.ink/api/sharepage",
                    {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: sdata,
                    }
                  );

                  let response = (await resp.json()).body;
                  console.log(response.media);
                  if (response.media && response.media.length > 0) {
                    for (let i = 0; i < response.media.length; i++) {
                      const id = response.media[i];
                      let med = await (
                        await getObjectStore(db, StoreName.media)
                      ).get(id);
                      if (med) {
                        console.log("upload " + id);
                        try {
                          let upload = await fetch(
                            "https://zhishudali.ink/api/uploadmedia",
                            {
                              method: "post",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({ media: med }),
                            }
                          );
                        } catch (error) {
                          alert("上传附件失败！");
                          console.error(error);
                        }
                      }
                    }
                  }

                  setShareURL(`https://zhishudali.ink/#/share/${key}`);
                } catch (error) {
                  alert("分享失败！");
                  console.error(error);
                }
              }}
            >
              分享
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => renameDialog.setOpen(true)}
            >
              重命名
            </Button>
          </Grid>
        </Grid>

        {!!shareURL && shareURL !== "" && (
          <Typography
            className={style.button}
            style={{
              marginTop: 10,
              borderStyle: "solid",
              borderRadius: 3,
              borderWidth: 1,
              borderColor: "rgb(220,220,220)",
              padding: 5,
            }}
          >
            {shareURL}
          </Typography>
        )}
      </DialogContent>
      <DialogContent>
        <Button
          style={{ marginTop: "5px" }}
          variant="outlined"
          color="default"
          onClick={() =>
            downloadLocalPage(prop.runtime.current_doc_uuid, "markdown")
          }
        >
          获取Markdown
        </Button>
      </DialogContent>

      <DialogContent></DialogContent>
      <RenameDialog {...renameDialog} />
    </Dialog>
  );
};

/**
 * 删除的组件
 * <DialogContent>
        <Button
          style={{ marginTop: "5px" }}
          variant="outlined"
          color="default"
          className={style.button}
          onClick={() =>
            downloadLocalPage(prop.runtime.current_doc_uuid, "raw")
          }
        >
          下载
        </Button>
        <Button
          className={
            css`
              position: relative;
            ` +
            " " +
            style.button
          }
          style={{ marginTop: "5px" }}
          variant="outlined"
          color="default"
        >
          <Input
            className={css`
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0
              backgroung-color: transparent;
            `}
            type="file"
            onChange={(e: any) => {
              restoreLocalPage(e.target.files[0], prop.runtime.switchDocForece);
              e.target.value = null;
            }}
          />
          {"恢复"}
        </Button>
      </DialogContent>
 */
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    button: {
      margin: 5,
    },
    dialog: {
      minWidth: 200,
    },
  })
);
