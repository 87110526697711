import { ReactEditor } from "slate-react";
import { Transforms } from "slate";

/**
 * 设置当前节点的值
 * @param editor 
 * @param elemetn 旧的element
 * @param newvalue 新的element的部分值
 */
export function SetThisNode(editor: ReactEditor, elemetn: any, newvalue: any) {
  const path = ReactEditor.findPath(editor, elemetn);

  Transforms.setNodes(editor, { ...elemetn, ...newvalue }, { at: path });
}

export const EmptyDoc = () => [{ type: "ph", children: [{ text: "" }] }];
