import React, { useContext } from "react";
import { Paper, makeStyles, createStyles, Grid } from "@material-ui/core";
import { drawerHeight, ThemeContext } from "../../App";
import { SyncCard } from "./SyncCard";
import { ThemeCard } from "./SetThemeCard";
import { useDivices } from "../../lib/hooks/useDevices";
import ScrollToTop from "../../AppCom/ScrollToTop";
import { PreferenceCard } from "./PreferenceCard";
import { ExportLearn } from "./ExportLearn";

export const SettingPage = () => {
  const style = useSettingPageStyle();
  const mtheme = useContext(ThemeContext);
  const divice = useDivices();

  return (
    <Paper
      className={style.body}
      style={{ backgroundColor: mtheme.background }}
      onKeyUp={(e)=>{
        console.log(e.key)
        if(e.key==="F12"){
          const {myIpcRenderer} = window
          if(myIpcRenderer) myIpcRenderer.send("APP_debug", {})
        }
      }}
    >
      <ScrollToTop />
      <Grid
        container
        justify={divice.select([
          [divice.whenDesktop, "center"],
          [divice.whenPad, "space-around"],
          [divice.whenMobile, "space-around"]
        ])}
      >
        <Grid item>
          <SyncCard />
        </Grid>
        <Grid item>
          <ThemeCard />
        </Grid>
        <Grid item>
          <PreferenceCard />
        </Grid>
        <Grid item>
          <ExportLearn />
        </Grid>
      </Grid>
    </Paper>
  );
};
const useSettingPageStyle = makeStyles(() =>
  createStyles({
    body: {
      minHeight: 1080,
      marginTop: drawerHeight
    }
  })
);
