import { Transforms, Editor, Range, Point } from "slate";

export const withChecklist = (editor: Editor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: n => n.type === "check-list-item"
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          Transforms.setNodes(
            editor,
            { type: "ph" },
            { match: n => n.type === "check-list-item" }
          );
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};
