import { makeStyles, Theme, createStyles } from "@material-ui/core";import { drawerHeight } from "../App";

export const usePageStyle = makeStyles((theme: Theme) =>
createStyles({
  body: {
    minHeight: "calc(100vh - 50px)",
    marginTop: drawerHeight,
    [theme.breakpoints.up("sm")]: {
      padding: 20,
    },
  },
  card: {
    minHeight: 270,
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: 280,
    },
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 40,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 20,
  },
})
);
