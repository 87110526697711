import { useState } from "react";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "./lib/Database/Database";

const CurrentVersion = 1;

export function useCheckVersion() {
  const { data } = useRequest(async () => {
    let version = (() => {
      const vstr = window.localStorage.getItem("version");
      if (vstr === null) {
        window.localStorage.setItem("version", CurrentVersion.toString());
        return CurrentVersion;
      }
      return parseInt(vstr);
    })();
    if (version !== CurrentVersion) {
      await versionUpdate(version);
    }
    return true;
  });
  return !!data;
}

async function versionUpdate(version: number) {
  let current = CurrentVersion;
  if (version === 0 && current === 1) {
    current = await version_0_to_1();
  }
}

async function version_0_to_1() {
  //const db = await getDatabase();
  //const knowledges = await (
  //  await getKnowledgeCard_Read(db)
  //).getAll();
  //for (let i = 0; i < knowledges.length; i++) {
  //  let kno = knowledges[i];
  //  kno.parent = [kno.parent];
  //  await (await getKnowledgeCard_Write).put(kno);
  //}
  return setVersion(1)
}

function setVersion(v: number) {
  window.localStorage.setItem("version", v.toString());
  return v;
}
