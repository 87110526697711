import {
  List,
  Container,
  Divider,
  TextField,
  IconButton,
  Grid,
  Box,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import React, { useState, Fragment, useCallback, useRef } from "react";
import { PageFCB, PageRawData } from "../../lib/Database/DataInterface";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  DirtDatabaseVer,
} from "../../lib/Database/Database";
import uuid from "uuid";
import { emptyValue } from "../../Init/initValue";
import { BlockEditorRuntime } from "../Editor/useBlockEditor";
import { useInterval, useAlive } from "../../lib/CommonHook";

import { useRequest, useSet } from "@umijs/hooks";
import { connect } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import md5 from "blueimp-md5";
import { RefreashPageList } from "../../lib/appaction";
import { SearchInDatabase, findSearchResult } from "./Common";
import { usePageListItem, PageListItem } from "./PageListItem";
interface PageListProp {
  selected?: string;
  // ! ---
  runtime: BlockEditorRuntime;
  onSelectDocCallback?: () => void;
}

const useSelect = () => {
  const [selectIndex, setSelectIndex] = useState("") as [string, Function];
  return { selectIndex, setSelectIndex };
};

interface pageitem {
  uuid: string;
  order: number;
}

export const PagesList = (prop: PageListProp) => {
  //const { database, refreashList } = prop;
  const [database, refreashList] = useState([] as pageitem[])
  //const databaseRef = useRef([] as pageitem[])
  //const database = databaseRef.current
  //const refreashList = (list: pageitem[]) => {
  //  databaseRef.current = list
  //}

  // ! 页面路径列表，或许可以用于面包屑导航
  const [currentPathList]: [string[], (x: string[]) => void] = useState(
    [] as string[]
  );
  const { data, run } = useRequest(SearchInDatabase, {
    throttleInterval: 500,
    manual: true,
  });

  const select = useSelect();

  const style = useStyles();
  const listRT = usePageListItem(prop.onSelectDocCallback);
  const [dirtVersion, setDirtVersion] = useState(0);
  const alive = useAlive()

  const getDataList = useCallback(
    async (path: string[]) => {
      const db = await getDatabase();
      const objstore = await getObjectStore(
        db,
        StoreName.fcb_pages,
        "readonly"
      );
      if (path.length === 0 || path[path.length - 1] === "") {
        let root: PageFCB = await objstore.get("root");
        if (!root) {
          let p: PageFCB = {
            uuid: "root",
            children: [],
            title: "",
            isRoot: 0,
          };
          let olds: PageFCB[] = await objstore
            .index("isRoot")
            .getAll(IDBKeyRange.only(1));
          p.children = olds.map((vv) => {
            vv.isRoot = 0;
            return vv.uuid;
          });

          await (
            await getObjectStore(db, StoreName.fcb_pages, "readwrite")
          ).put(p);
        }
        let list: PageFCB = await (
          await getObjectStore(db, StoreName.fcb_pages, "readonly")
        ).get("root");

        //setListData(
        if (alive.current)
          refreashList(
            list.children
              .filter((vv) => findSearchResult(vv, data))
              .map((vi, ind) => {
                return { uuid: vi, order: ind } as pageitem;
              })
            //)
          );
      } else {
        let cur = path[path.length - 1];
        const tar: PageFCB = await objstore.get(IDBKeyRange.only(cur));
        let res: PageFCB[] = [];
        for (let i = 0; i < tar.children.length; i++) {
          const name = tar.children[i];
          res.push(await objstore.get(IDBKeyRange.only(name)));
        }

        //setListData(
        if (alive.current)
          refreashList(
            res
              .map((v) => v.uuid)
              .filter((vv) => findSearchResult(vv, data))
              .map((v, i) => {
                return { uuid: v, order: i } as pageitem;
              })
          );
        //);
      }
    },
    [data, refreashList, alive]
  );
  /**
   * 定时刷新文件列表
   */
  useInterval(() => {
    if (!listRT.lockWhenDragging.current && DirtDatabaseVer() !== dirtVersion) {
      setDirtVersion(DirtDatabaseVer());
      getDataList(currentPathList);
    }
  }, 100);

  /**
   * 定时刷新文件列表
   */
  useInterval(() => {
    if (!listRT.lockWhenDragging.current) {
      //console.log("refreash");
      getDataList(currentPathList);
    }
  }, 5000);

  /**
   * 新建文档
   */
  const CreateNewDocCallback = useCallback(async (parentDoc?: string) => {
    let fcb: PageFCB = {
      uuid: uuid.v4(),
      parent: parentDoc,
      title: "新文档",
      children: [],
      isRoot: 0,
    };
    let doc: PageRawData = {
      uuid: fcb.uuid,
      document: emptyValue(),
      lastWriteTime: new Date(),
      md5: md5(JSON.stringify(emptyValue())),
      tags: [],
    };
    const db = await getDatabase();

    const doc_store = await getObjectStore(db, StoreName.pages, "readwrite");
    await doc_store.put(doc);

    if (!!parentDoc) {
      const par_fcb_store = await getObjectStore(db, StoreName.fcb_pages);
      const par_fcb: PageFCB = await par_fcb_store.get(parentDoc);
      if (!!par_fcb) {
        par_fcb.children.push(fcb.uuid);
        const par_fcb_w = await getObjectStore(
          db,
          StoreName.fcb_pages,
          "readwrite"
        );
        par_fcb_w.put(par_fcb);
      }
    } else {
      let rootObj: PageFCB = await (
        await getObjectStore(db, StoreName.fcb_pages)
      ).get("root");
      rootObj.children.push(fcb.uuid);
      await (await getObjectStore(db, StoreName.fcb_pages, "readwrite")).put(
        rootObj
      );
    }

    const fcb_store = await getObjectStore(
      db,
      StoreName.fcb_pages,
      "readwrite"
    );
    await fcb_store.put(fcb);
  }, []);

  return (
    <Fragment>
      <Box>
        <TextField
          variant="outlined"
          onChange={(e) => run(e.target.value, listRT)}
          size="small"
          className={style.searchBar}
          helperText="搜索全文"
        ></TextField>
      </Box>
      <Divider />
      <Container>
        <Grid container justify="center">
          <Grid item>
            <IconButton
              color="primary"
              className={style.addNewDocButton}
              onClick={() =>
                CreateNewDocCallback(prop.runtime.parentDoc.current)
              }
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>

      <List className={style.list + " drawer-link"}>
        {database.map((v: any) => {
          return (
            <PageListItem
              key={v.uuid}
              v={v.uuid}
              setCurrentDoc={prop.runtime.set_current_uuid}
              select={select}
              createDocCallback={CreateNewDocCallback}
              deepth={0}
              runtime={prop.runtime}
              listRT={listRT}
              parent="root"
              trace={[v.uuid]}
            />
          );
        })}
      </List>
    </Fragment>
  );
};

function mapStateToProp(state: any, own: any) {
  return { database: state.database, ...own };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    refreashList: (newlist: []) => {
      dispatch(RefreashPageList(newlist));
    },
  };
};

//export const r_PagesList = connect(
//  mapStateToProp,
//  mapDispatchToProps
//)(_PagesList);

const useStyles = makeStyles(() =>
  createStyles({
    addNewDocButton: {},
    list: {
      overflow: "auto",
      position: "relative",
    },
    searchBar: {
      marginLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
  })
);
