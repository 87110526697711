import { css } from "emotion";
import { useSelected, useFocused, useEditor } from "slate-react";
import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import "../css/renderMarkdown.css";
import { MediaAsset } from "../../lib/Database/DataInterface";
import { Node } from "slate";

import {
  getDatabase,
  getObjectStore,
  StoreName,
  FetchMedia,
} from "../../lib/Database/Database";
import { useAlive } from "../../lib/CommonHook";

interface ImageElementNode {
  assetID: string;
}

export const ImageElement = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: any;
  element: ImageElementNode & Node;
}) => {
  const selected = useSelected();
  const focused = useFocused();
  const editor = useEditor();

  const [dataURL, setDataURL] = useState("");
  const [, setAsset]: [MediaAsset, Function] = useState({} as any);
  const alive = useAlive();

  useEffect(() => {
    const getmedia = async () => {
      const db = await getDatabase();
      if (element.assetID) {
        let img: MediaAsset = await (
          await getObjectStore(db, StoreName.media)
        ).get(element.assetID);
        if (!img) {
          await FetchMedia(element.assetID);
          img = await (await getObjectStore(db, StoreName.media)).get(
            element.assetID
          );
        }
        if (alive.current) {
          setDataURL(img.url as string);
          setAsset(img as any);
        }
      }
    };
    getmedia();
  }, [element, editor, alive]);

  //const selectCurrent = useRef(editor.selection);
  //useEffect(() => {
  //  if (editor.selection) selectCurrent.current = editor.selection;
  //});
  return (
    <div {...attributes}>
      <Container>
        <div contentEditable={false}>
          <img
            onDragStart={() => {
              //console.log(element);
            }}
            onDragEnd={(e) => {
              e.preventDefault();
              //console.log(e);
              //if (asset.url) {
              //  ImagePlugin.insertImage(
              //    editor,
              //    asset.url,
              //    asset.origin,
              //    selectCurrent.current
              //  );
              //}
            }}
            onDragEndCapture={(e) => {
              //console.log("i");
              e.preventDefault();
            }}
            src={dataURL}
            className={
              css`
                display: block;
                margin-left: auto;
                margin-right: auto;
                box-shadow: ${selected && focused
                  ? "0 0 0 3px #B4D5FF"
                  : "none"};
              ` + " image"
            }
            alt={"图片id：" + element.assetID}
          />
        </div>
      </Container>
      {children}
    </div>
  );
};
