import { MyIpcRenderer } from "../dts/electron";
export interface TaskBase<D> {
  id: number;
  data: D;
}
export interface RespBase<K> {
  id: number;
  result: K;
}

/**
 * 用于与后端传送信息，并接受Promise返回的处理器。
 * @param D 发送给后端的类型
 * @param K 后端返回给前端的类型
 */
export class TaskEvent<D, K> {
  timeout: number = 5000;
  sendEvent: string = "";
  respEvent: string = "";
  _index = 0;
  Ipc: MyIpcRenderer | undefined = undefined;
  _result: Map<number, K>;

  sendToServer(data: TaskBase<D>) {
    this.Ipc?.send(this.sendEvent, data);
  }

  receiveFromServer(resp: RespBase<K>) {
    this._result.set(resp.id, resp.result);
  }

  /**
   *
   * @param respEventName 后端接收的事件名
   * @param sendEventName 前端发送的事件名
   * @param D 发送给后端的类型
   * @param K 后端返回给前端的类型
   */
  constructor(respEventName: string, sendEventName: string, arg?: {timeout?: number}) {
    const { myIpcRenderer } = window;
    this.Ipc = myIpcRenderer;
    this._result = new Map();
    if(arg?.timeout !== undefined) this.timeout = arg.timeout

    if (this.Ipc) {
      this.Ipc.on(respEventName, this.receiveFromServer.bind(this));
      this.sendEvent = sendEventName;
    }
  }

  Call(data: D): Promise<K | undefined> {
    let task: TaskBase<D> = {
      id: this._index,
      data: data,
    };
    this._index += 1;
    this.sendToServer(task);
    let time = Date.now();
    return new Promise((resolve, reject) => {
      const check = () => {
        setTimeout(() => {
          if (this._result.has(task.id)) {
            let data = this._result.get(task.id);
            this._result.delete(task.id);
            resolve(data);
          } else {
            let newtime = Date.now();
            if (newtime - time > this.timeout)
              reject(this.respEvent + " server time out!!!");
            else setTimeout(check);
          }
        });
      };
      check();
    });
  }
}
