import React, { useContext, useState, useReducer, useEffect } from "react";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../lib/Database/Database";
import uuid from "uuid";
import { cloneDeep } from "lodash";
// ----------------------------
//            STEP
// ----------------------------
export enum LearnStep {
  Plan, // 计划制定
  Begin, // 欢迎页面
  SubjectHome, // 科目页面
  LearnChoice, // 选择学习节点
  Learning, // 沉浸式学习
  Practice, // 做题
  Rest, // 休息
}

export enum TabStep {
  Knowledge,
  Plan,
  Learn,
  Quiz,
}

export enum SubjectType {
  Normal,
  Rest,
  Simple,
}
// ----------------------------
//             STATE
// ----------------------------
interface ILeatnAppState {
  key: string;
  lastTime: Date;
  learnStep: LearnStep;
  learningRoute: string[]; // 保存当前科目到选中节点的路径
  tab: TabStep;
  currentSubjectIndex: number;
  knowledge: IKnowledgeAppState;
  processState: LearnProcessState;
  learnTopicUUID?: string;
  learnSubject: LearnStatueSubject
}

export interface LearnProcessState {
  subjectsState: LearnSubjectState[];
  currentSubjectIndex: number;
}

export interface LearnSubjectState {
  targetSubjectUUID: string;
}

export interface IKnowledgeAppState {
  root1: string;
  root2: string;
  root3: string;
  focus1: string;
  focus2: string;
  focus3: string;
}

const newKnowledge = () => {
  return {
    root1: "root",
    root2: "",
    root3: "",
    focus1: "",
    focus2: "",
    focus3: "",
  };
};

export interface IKnowledgeAppFunc {
  setRoot1: (n: string) => void;
  setRoot2: (n: string) => void;
  setRoot3: (n: string) => void;
  setFocus1: (n: string) => void;
  setFocus2: (n: string) => void;
  setFocus3: (n: string) => void;
}

export interface LearnAppBarProp {
  currentTab: TabStep;
  setCurrentTab: (x: TabStep) => void;
}

function newState(): ILeatnAppState {
  return {
    key: "learnAppState",
    lastTime: new Date(),
    learnStep: LearnStep.Begin,
    learningRoute: [],
    tab: TabStep.Knowledge,
    currentSubjectIndex: 0,
    knowledge: newKnowledge(),
    processState: newLearnProcess(),
    learnSubject: newLearnStatueSubject()
  };
}

function newLearnProcess(): LearnProcessState {
  return {
    subjectsState: [],
    currentSubjectIndex: 0,
  };
}

export function newLearnSubjectState(tarID: string): LearnSubjectState {
  return {
    targetSubjectUUID: tarID,
  };
}

export const useLearnAppBar = () => {
  const [tab, setTab] = useState(TabStep.Knowledge);
  return { currentTab: tab, setCurrentTab: setTab } as LearnAppBarProp;
};

export function useLearnAppPlan() {
  const { data } = useRequest(async () => {
    const db = await getDatabase();
    let state: LearnPlan = await (
      await getObjectStore(db, StoreName.config)
    ).get("learnAppPlan");
    if (!state) {
      await (await getObjectStore(db, StoreName.config, "readwrite")).put(
        newPlan()
      );
      state = await (await getObjectStore(db, StoreName.config)).get(
        "learnAppPlan"
      );
    }
    return state;
  });
  const [state, stateRedu] = useReducer(
    (old: undefined | LearnPlan, action: disAction): LearnPlan | undefined => {
      if (!!old) {
        switch (action.act) {
          case "replace":
            return action.value;
          default:
            break;
        }
        return {
          ...old,
        };
      }
      if (action.act === "replace") {
        return action.value;
      }
      return undefined;
    },
    undefined
  );
  useEffect(() => {
    if (data !== undefined) {
      stateRedu({ act: "replace", value: data });
    }
  }, [data, stateRedu]);

  useEffect(() => {
    const saveToDatabase = async () => {
      const db = await getDatabase();
      await (await getObjectStore(db, StoreName.config, "readwrite")).put(
        state
      );
    };
    if (state !== undefined) {
      saveToDatabase();
    }
  }, [state]);
  return {
    data: state,
    dispatch: stateRedu,
  };
}

interface disAction {
  act: string;
  value: any;
}

export function useLearnAppState() {
  const { data } = useRequest(async () => {
    const db = await getDatabase();
    let state: ILeatnAppState = await (
      await getObjectStore(db, StoreName.config)
    ).get("learnAppState");
    if (!state) {
      await (await getObjectStore(db, StoreName.config, "readwrite")).put(
        newState()
        // TODO: 编辑页
      );
      state = await (await getObjectStore(db, StoreName.config)).get(
        "learnAppState"
      );
    } else if (!isSameDay(state.lastTime, new Date())) {
      await (await getObjectStore(db, StoreName.config, "readwrite")).put(
        newState()
      );
      state = await (await getObjectStore(db, StoreName.config)).get(
        "learnAppState"
      );
    }
    return state;
  });
  const [state, stateRedu] = useReducer(
    (
      old: undefined | ILeatnAppState,
      action: disAction
    ): ILeatnAppState | undefined => {
      if (!!old) {
        switch (action.act) {
          case "replace":
            return action.value;
          case "changeLearnStep":
            return {
              ...old,
              learnStep: action.value,
            };
          case "changeSubject":
            return {
              ...old,
              currentSubjectIndex: action.value,
            };
          case "setTopicRoot":
            return {
              ...old,
              learnTopicUUID: action.value,
            };
          case "setKnowledgeRoot":
            let newo = {
              ...old,
              knowledge: { ...old.knowledge },
            };
            switch (action.value.id) {
              case 1:
                newo.knowledge.root1 = action.value.value;
                break;
              case 2:
                newo.knowledge.root2 = action.value.value;
                break;
              case 3:
                newo.knowledge.root3 = action.value.value;
                break;
              default:
                break;
            }
            return newo;
          case "setKnowledgeFocus":
            let newf = {
              ...old,
              knowledge: { ...old.knowledge },
            };
            switch (action.value.id) {
              case 1:
                newf.knowledge.focus1 = action.value.value;
                break;
              case 2:
                newf.knowledge.focus2 = action.value.value;
                break;
              case 3:
                newf.knowledge.focus3 = action.value.value;
                break;
              default:
                break;
            }
            return newf;
          case "setNewLearnSubjectState":
            let oldstate = old.processState;
            let news = action.value as LearnSubjectState;
            let ind = oldstate.subjectsState.findIndex(
              (k) => k.targetSubjectUUID === news.targetSubjectUUID
            );
            let na = cloneDeep(oldstate.subjectsState);

            if (ind !== -1) {
              na[ind] = news;
            } else {
              na.push(news);
            }
            return {
              ...old,
              processState: { ...old.processState, subjectsState: na },
            };
          case "setLearnStatueSubject":
            return {
              ...old,
              learnSubject: action.value
            }

          default:
            break;
        }
        return {
          ...old,
        };
      }
      if (action.act === "replace") {
        return action.value;
      }
      return undefined;
    },
    undefined
  );

  useEffect(() => {
    const saveToDatabase = async () => {
      const db = await getDatabase();
      await (await getObjectStore(db, StoreName.config, "readwrite")).put(
        state
      );
    };
    if (state !== undefined) {
      saveToDatabase();
    }
  }, [state]);

  useEffect(() => {
    if (data !== undefined) {
      stateRedu({ act: "replace", value: data });
    }
  }, [data, stateRedu]);
  return {
    data: state,
    dispatch: stateRedu,
  };
}

async function saveLearnState(state: ILeatnAppState) {
  const db = await getDatabase();
  await (await getObjectStore(db, StoreName.config, "readwrite")).put(state);
}

async function saveLearnPlan(plan: LearnPlan) {
  const db = await getDatabase();
  await (await getObjectStore(db, StoreName.config, "readwrite")).put(plan);
}

// ----------------------------
//            CONTEXT
// ----------------------------
export const LearnAppContext = React.createContext({
  state: {} as any,
  valid: false,
  plan: {} as any,
  func: {
    savePlan: saveLearnPlan,
    saveState: saveLearnState,
    changeState: undefined,
    changePlan: undefined,
  },
} as {
  state: ILeatnAppState;
  valid: boolean;
  plan: LearnPlan;
  func: LearnAppFunc;
});

export interface LearnAppFunc {
  saveState: (state: ILeatnAppState) => void;
  savePlan: (plan: LearnPlan) => void;
  changeState: undefined | ((action: disAction) => void);
  changePlan: undefined | ((action: disAction) => void);
}

export function getLearnFunc(): LearnAppFunc {
  return {
    savePlan: saveLearnPlan,
    saveState: saveLearnState,
    changeState: undefined,
    changePlan: undefined,
  };
}

export const useLearnApp = () => {
  return useContext(LearnAppContext);
};

// ----------------------------
//            Plan
// ----------------------------
export interface LearnPlan {
  key: string;
  planList: LearnPlanSubject[];
}

export interface LearnPlanSubject {
  uuid: string;
  fulltime: number;
  subjectName: string;
  subjectKnowledgeCollectionUUID: string | undefined;
  quizsCollectionUUID: string[];
  subjectType: SubjectType;
}

export interface LearnStatueSubject {
  currentCardUUID: string,
  Index: number
}

export function newLearnPlanSubject(): LearnPlanSubject {
  return {
    uuid: uuid.v4(),
    fulltime: 45,
    subjectName: "未命名",
    subjectKnowledgeCollectionUUID: undefined,
    quizsCollectionUUID: [],
    subjectType: SubjectType.Normal,
  };
}

export function newLearnStatueSubject(): LearnStatueSubject {
  return {
    currentCardUUID: "",
    Index: -1
  }
}

function TestNewPlan(): LearnPlan {
  return {
    key: "learnAppPlan",
    planList: [newLearnPlanSubject()],
  };
}

function newPlan(): LearnPlan {
  // ! TODO: 测试用
  return TestNewPlan();
  return {
    key: "learnAppPlan",
    planList: [],
  };
}

// ----------------------------
//          DISP
// ----------------------------

export function changeLearnStep(newstep: LearnStep): disAction {
  return {
    act: "changeLearnStep",
    value: newstep,
  };
}

export function changeTabStep(newstep: TabStep): disAction {
  return {
    act: "changeTabStep",
    value: newstep,
  };
}

export function changeSubject(next: number): disAction {
  return {
    act: "changeSubject",
    value: next,
  };
}

export function setKnowledgeRoot(id: number, value: string): disAction {
  return {
    act: "setKnowledgeRoot",
    value: { id, value },
  };
}

export function setKnowledgeFocus(id: number, value: string): disAction {
  return {
    act: "setKnowledgeFocus",
    value: { id, value },
  };
}

export function setTopicRoot(value: string | undefined): disAction {
  return {
    act: "setTopicRoot",
    value: value,
  };
}

export function ResetStatue(): disAction {
  return {
    act: "replace",
    value: newState(),
  };
}
///////////////////////////////////
export function setNewPlan(newp: LearnPlan): disAction {
  return {
    act: "replace",
    value: newp,
  };
}

export function setNewLearnSubjectState(news: LearnSubjectState): disAction {
  return {
    act: "setNewLearnSubjectState",
    value: news,
  };
}

export function setLearnStatueSubject(news: LearnStatueSubject): disAction {
  return {
    act: "setLearnStatueSubject",
    value: news
  }
}

// ----------------------------
//            HELPER
// ----------------------------
function isSameDay(a: Date, b: Date): boolean {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}
