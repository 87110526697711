import {
  Dialog,
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
  Button
} from "@material-ui/core";
import React, { useState, useCallback } from "react";
import { BlockEditorRuntime } from "./useBlockEditor";

export const useRenameDialog = (
  runtime: BlockEditorRuntime,
  hadleClose: () => void
) => {
  const [open, _setOpen] = useState(false);
  const rename = useCallback(
    (newname: string) => {
      runtime.setTitle(newname);
    },
    [runtime]
  );
  const setOpen = useCallback(
    (sta: boolean) => {
      if (sta) {
        _setOpen(sta);
      } else {
        _setOpen(sta);
        hadleClose();
      }
    },
    [_setOpen, hadleClose]
  );
  return {
    open,
    setOpen,
    rename
  };
};

export const RenameDialog = ({
  rename,
  open,
  setOpen
}: {
  rename: (v: string) => void;
  open: boolean;
  setOpen: (v: boolean) => void;
}) => {
  const [newname, setNewName] = useState("");
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>重命名</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <FormControl>
              <TextField
                onChange={e => setNewName(e.target.value)}
                value={newname}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-evenly">
          <Grid item>
            <Button onClick={() => setOpen(false)}>取消</Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                rename(newname);
                setOpen(false);
              }}
            >
              确认
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
