import { PageRawData, PageFCB } from "../../lib/Database/DataInterface";
import { useState, useRef, useCallback } from "react";
import { initValueData } from "../../Init/initValue";
import {
  getDatabase,
  getObjectStore,
  StoreName
} from "../../lib/Database/Database";

export interface BlockEditorRuntime {
  editor_tab_value: number;
  setEditorTabValue: (x: number) => void;
  title: string;
  setTitle: (x: string, y?: "justSet" | "write") => void;
  current_doc_uuid: string;
  set_current_uuid: Function;
  currentDoc: React.MutableRefObject<PageRawData>;
  setCurrentDoc: (x: PageRawData) => void;
  switchDoc: Function;
  switchDocForece: Function;
  docType: "local" | "share";
  setDocType: Function;
  parentDoc: React.MutableRefObject<string>;
}

export const useBlockEditor = () => {
  // 储存editor tab的索引
  const [editor_tab_value, setEditorTabValue] = useState(0);
  // 储存当前文档的标题
  const [title, _setTitle] = useState("文档");
  const currentDoc = useRef({
    document: initValueData(),
    uuid: "",
    lastWriteTime: new Date()
  } as PageRawData);

  const parentDoc = useRef("root");

  const setCurrentDoc = useCallback(
    (value: any) => {
      currentDoc.current = value;
    },
    [currentDoc]
  );

  const [current_doc_uuid, set_current_uuid] = useState("");

  const setTitle = useCallback(
    async (newtitle: string, mode?: "justSet" | "write") => {
      if (current_doc_uuid !== "" && mode !== "justSet") {
        const db = await getDatabase();
        const fcb_s = await getObjectStore(db, StoreName.fcb_pages);
        let fcb: PageFCB = await fcb_s.get(current_doc_uuid);

        fcb.title = newtitle;
        const fcb_w = await getObjectStore(
          db,
          StoreName.fcb_pages,
          "readwrite"
        );
        fcb_w.put(fcb);
      }

      _setTitle(newtitle);
    },
    [_setTitle, current_doc_uuid]
  );
  const switchDoc = useCallback(
    async (new_doc_uuid: string) => {
      const currentUUID = current_doc_uuid;
      let db = await getDatabase();
      // 先保存
      if (currentUUID !== "") {
        let o_fcb_store = await getObjectStore(db, StoreName.fcb_pages);
        let o_fcb: PageFCB = await o_fcb_store.get(currentUUID);
        if (o_fcb !== undefined) {
          o_fcb.title = title;
          let o_fcb_store_w = await getObjectStore(
            db,
            StoreName.fcb_pages,
            "readwrite"
          );
          await o_fcb_store_w.put(o_fcb);
          let o_page = await getObjectStore(db, StoreName.pages, "readwrite");
          await o_page.put(currentDoc.current);
        }
      }
      if (new_doc_uuid !== "") {
        // 再读取
        let fcb_store = await getObjectStore(db, StoreName.fcb_pages);
        let fcb: PageFCB = await fcb_store.get(new_doc_uuid);
        if (!!fcb) set_current_uuid(fcb.uuid);
      }
    },
    [current_doc_uuid, title]
  );
  const [keycount, setkeycount] = useState(0);
  const switchDocForece = useCallback(
    async (new_doc_uuid: string) => {
      const currentUUID = current_doc_uuid;
      let db = await getDatabase();
      // 先保存
      if (currentUUID !== "" && currentUUID !== new_doc_uuid) {
        console.log("save");
        let o_fcb_store = await getObjectStore(db, StoreName.fcb_pages);
        let o_fcb: PageFCB = await o_fcb_store.get(currentUUID);
        if (o_fcb !== undefined) {
          o_fcb.title = title;
          let o_fcb_store_w = await getObjectStore(
            db,
            StoreName.fcb_pages,
            "readwrite"
          );
          await o_fcb_store_w.put(o_fcb);
          let o_page = await getObjectStore(db, StoreName.pages, "readwrite");
          await o_page.put(currentDoc.current);
        }
      }
      if (new_doc_uuid !== "") {
        // 再读取
        let fcb_store = await getObjectStore(db, StoreName.fcb_pages);
        let fcb: PageFCB = await fcb_store.get(new_doc_uuid);
        set_current_uuid(fcb.uuid);
        setkeycount(keycount + 1);
      }
    },
    [current_doc_uuid, title, setkeycount, keycount]
  );
  const [docType, setDocType] = useState("local");
  return {
    editor_tab_value,
    setEditorTabValue,
    title,
    setTitle,
    current_doc_uuid,
    set_current_uuid,
    currentDoc,
    setCurrentDoc,
    switchDoc,
    switchDocForece,
    docType,
    setDocType,
    parentDoc
  } as BlockEditorRuntime;
};
