import React, { useCallback, useState, Fragment } from "react";
import {
  CardProp,
  useQuizCardStyleCommon,
  QuizTemplateHead,
  useQuizCardCommon,
  QuizTemplateMakeCardControlBar,
  QuizTemplateAnswerControlBar,
  UserAnswerLevel,
  QuizTemplateAfterAnswerControlBar,
  UserAssessmentLevel,
  AnswerStatue,
} from "./QuizCommon";
import {
  Card,
  CardContent,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../../lib/Database/Database";
import { getFromDoc } from "../../../AppPages/Learn/Knowledge/old_Knowledge";
import { QuizCardData, QuizCardUserData } from "../../plugins/withQuiz";
import { BlockEditor } from "../../BlockEditor";
import md5 from "blueimp-md5";
import { emptyValueData } from "../../../Init/initValue";
import { Node } from "slate";

export const QuizAnswerCardElement = (prop: CardProp) => {
  const style = useAnswerCardStyle();
  const {
    canSelect,
    setCanSelect,
    wantShowExp,
    setWantShowExp,
    editcard,
    helperData,
    expData,
    setHelperData,
    setExpData,
    card,
  } = useQuizCardCommon(prop);

  const commonStyle = useQuizCardStyleCommon(canSelect, editcard.isEdit);

  const save = useCallback(async () => {
    const db = await getDatabase();
    let mainKeywords: string[] = [];
    let medias = new Set<string>();
    getFromDoc(card.helper, { keywords: mainKeywords, media: medias });
    getFromDoc(card.explain, { keywords: mainKeywords, media: medias });
    for (let i = 0; i < card.choices.length; i++) {
      const c = card.choices[i];
      getFromDoc(c, { keywords: mainKeywords, media: medias });
    }
    for (let i = 0; i < card.exp_choices.length; i++) {
      const c = card.exp_choices[i];
      getFromDoc(c, { keywords: mainKeywords, media: medias });
    }
    let old = await (await getObjectStore(db, StoreName.quiz_card)).get(
      prop.uuid
    );
    await (await getObjectStore(db, StoreName.quiz_card, "readwrite")).put({
      ...old,
      card: card,
      keywords: [...new Set(mainKeywords)],
      assets: [...medias],
    } as QuizCardData);
  }, [card, prop.data]);

  const [answer, setAnswer] = useState(emptyValueData());
  const [assess, setAssess] = useState(emptyValueData());
  const [AnsStatue, setAnsStatue] = useState(UserAnswerLevel.Aah);
  const [isRight, setIsRight] = useState(AnswerStatue.unknown);
  return (
    <Card className={style.root + " " + commonStyle}>
      <CardContent>
        <QuizTemplateHead isEdit={editcard.isEdit} prop={prop} />
      </CardContent>
      <Grid container>
        <Grid item xs={12} md={canSelect ? 12 : 6}>
          <CardContent>
            <Typography className={style.helperTips}>
              {editcard.isEdit ? "在此输入题干" : "问题"}
            </Typography>
            <Box className={style.helperEdit}>
              <BlockEditor
                mvalue={{
                  document: helperData,
                  uuid: prop.uuid + "_ques",
                  lastWriteTime: new Date(),
                  md5: md5(JSON.stringify(helperData)),
                  tags: [],
                }}
                setValue={(v: any) => {
                  setHelperData(v.doc);
                }}
                readonly={!editcard.isEdit}
                hideKeyword={!editcard.isEdit && canSelect}
              />
            </Box>
          </CardContent>
          {!canSelect && (
            <CardContent>
              <Typography className={style.helperTips}>你的回答</Typography>
              <Box className={style.helperEdit}>
                <BlockEditor
                  mvalue={{
                    document: answer,
                    uuid: prop.uuid,
                    lastWriteTime: new Date(),
                    md5: md5(JSON.stringify(answer)),
                    tags: [],
                  }}
                  setValue={(v: any) => {
                    setAnswer(v.doc);
                  }}
                  readonly
                />
              </Box>
            </CardContent>
          )}
        </Grid>
        {!canSelect && (
          <Grid item xs={12} md={canSelect ? 12 : 6}>
            <CardContent>
              <Typography className={style.helperTips}>
                {editcard.isEdit ? "在此输入解析" : "解析"}
              </Typography>
              <Box className={style.helperEdit}>
                <BlockEditor
                  mvalue={{
                    document: expData,
                    uuid: prop.uuid + "_exp",
                    lastWriteTime: new Date(),
                    md5: md5(JSON.stringify(expData)),
                    tags: [],
                  }}
                  setValue={(v: any) => {
                    setExpData(v.doc);
                  }}
                  readonly={!editcard.isEdit}
                  hideKeyword={!editcard.isEdit && canSelect}
                />
              </Box>
            </CardContent>
          </Grid>
        )}
        {canSelect && (
          <Grid item xs={12} md={canSelect ? 12 : 6}>
            <CardContent>
              <Typography className={style.helperTips}>回答</Typography>
              <Box className={style.helperEdit}>
                <BlockEditor
                  mvalue={{
                    document: answer,
                    uuid: prop.uuid,
                    lastWriteTime: new Date(),
                    md5: md5(JSON.stringify(answer)),
                    tags: [],
                  }}
                  setValue={(v: any) => {
                    setAnswer(v.doc);
                  }}
                />
              </Box>
            </CardContent>
          </Grid>
        )}

        <Grid container item xs={12} justify="space-around">
          <CardContent style={{ width: "100%" }}>
            {editcard.isEdit ? (
              <QuizTemplateMakeCardControlBar prop={prop} save={save} />
            ) : canSelect ? (
              <QuizTemplateAnswerControlBar
                onConfirm={(level: UserAnswerLevel) => {
                  setAnsStatue(level);
                  setCanSelect(false);
                  setWantShowExp(true);
                }}
              />
            ) : (
              <Fragment>
                <Box
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderColor: "#e1e1e1",
                    padding: 10,
                    borderWidth: 1,
                    margin: 10,
                  }}
                >
                  <Typography>回答结果</Typography>
                  <Grid container item xs={12} justify="space-evenly">
                    <Grid item>
                      <Button
                        variant={
                          isRight === AnswerStatue.right
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        onClick={(e) => setIsRight(AnswerStatue.right)}
                      >
                        正确
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          isRight === AnswerStatue.wrong
                            ? "contained"
                            : "outlined"
                        }
                        color="secondary"
                        onClick={(e) => setIsRight(AnswerStatue.wrong)}
                      >
                        错误
                      </Button>
                    </Grid>
                  </Grid>
                  {isRight === AnswerStatue.unknown && (
                    <Grid container item justify="center">
                      <Grid item>
                        <Typography style={{ color: "red" }}>
                          请选择你的回答正确与否！
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <Box
                  style={{
                    padding: 10,
                    margin: 10,
                  }}
                >
                  <Typography>评价题目</Typography>
                  <QuizTemplateAfterAnswerControlBar
                    disabled={isRight === AnswerStatue.unknown}
                    onConfirm={(v: UserAssessmentLevel) => {
                      if (prop.completeCallback && prop.userdata) {
                        let u = asAnswerUserData(prop.userdata);
                        u.history.push({
                          answer: answer,
                          statue: AnsStatue,
                          assess: v,
                          think: assess
                        });
                        u.meet += 1;

                        prop.completeCallback(u);
                      }
                      if (prop.nextCardCallback) prop.nextCardCallback();
                    }}
                  />
                </Box>
              </Fragment>
            )}
            {!canSelect && (
              <Fragment>
                <Box
                  style={{
                    padding: 10,
                    margin: 10,
                  }}
                >
                  <Typography>笔记</Typography>
                  <Box
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderColor: "#e1e1e1",
                      padding: 10,
                      borderWidth: 1,
                      margin: 10,
                    }}
                  >
                    <BlockEditor
                      mvalue={{
                        document: assess,
                        uuid: prop.uuid,
                        lastWriteTime: new Date(),
                        md5: md5(JSON.stringify(assess)),
                        tags: [],
                      }}
                      setValue={(v: any) => {
                        setAssess(v.doc);
                      }}
                    ></BlockEditor>
                  </Box>
                </Box>
              </Fragment>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
interface OneAnswer {
  answer: Node[];
  statue: UserAnswerLevel;
  assess: UserAssessmentLevel;
  think: Node[]
}
interface AnswerUserData extends QuizCardUserData {
  history: OneAnswer[];
}
function asAnswerUserData(a: QuizCardUserData): AnswerUserData {
  return { history: [], ...a };
}

const useAnswerCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgb(253,253,253)",
    },

    selectItemCharacter: {
      fontSize: 22,
    },
    helperEdit: {
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 4,
      padding: 8,
      borderColor: "rgb(200,200,200)",
      backgroundColor: "white",
    },
    helperTips: {
      fontSize: 14,
      color: "rgb(180,180,180)",
    },
    choiceCard: {
      marginTop: 10,
    },
  })
);
