import { useTheme, useMediaQuery } from "@material-ui/core";
import { useCallback } from "react";

export const useDivices = () => {
  const theme = useTheme();

  const up_md = useMediaQuery(theme.breakpoints.up("md"));
  const up_sm = useMediaQuery(theme.breakpoints.up("sm"));
  const select = useCallback((list: any[][], defaultv?: any) => {
    for (let i = 0; i < list.length; i++) {
      const e = list[i];
      if (e[0]) return e[1];
    }
    return defaultv;
  }, []);

  return {
    whenMobile: !up_sm,
    whenDesktop: up_md,
    whenPad: up_sm && !up_md,
    select: select
  };
};
