import React from "react";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../../../lib/Database/Database";
import { KnowledgeCard } from "../Knowledge/old_Knowledge";
import { SelectListDialog } from "../../../AppCom/SelectListDialog";

export const FindSubject = ({
  open,
  handleClose,
  setResult,
}: {
  open: boolean;
  handleClose: () => void;
  setResult: (newv: { uuid: string; title: string }) => void;
}) => {
  return (
    <SelectListDialog
      open={open}
      handleClose={handleClose}
      setResult={(v: { uuid: string; title: string }) => {
        setResult({
          uuid: v.uuid,
          title: v.title,
        });
        handleClose();
      }}
      title="选取科目"
      getData={async (search: string) => {
        const db = await getDatabase();
        let res: KnowledgeCard[] = [];
        let cur: KnowledgeCard[] = await (
          await getKnowledgeCard_Read(db)
        )
          .index("isSubject")
          .getAll(1);
        for (let i = 0; i < cur.length; i++) {
          const item = cur[i];
          if (
            item.title !== "" &&
            !!item.isSubject &&
            item.title.indexOf(search) !== -1
          ) {
            res.push(item);
          }
        }
        return res;
      }}
      itemKey={(t: KnowledgeCard) => t.uuid}
      itemTitle={(t: KnowledgeCard) => t.title}
    />
  );
};
