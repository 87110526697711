import { Typography } from "@material-ui/core";
import React, { Fragment, useContext, useEffect } from "react";
import {
  useLearnApp,
  LearnStep,
  TabStep,
  changeTabStep,
} from "./useLearnAppState";
import { TabPanel } from "../../AppCom/TabPanel";
import { LearnBegin } from "./LearnProc/LearnBegin";
import { LearnSubjectHome } from "./LearnProc/LearnSubjectHome";
import { LearnChooseTopic } from "./LearnProc/LearnChooseTopic";
import { LearnLearning } from "./LearnProc/LearnLearning";
import { LearnPractice } from "./LearnProc/LearnPractice";
import { LearnRest } from "./LearnProc/LearnRest";
import { AppContext } from "../../App";

export const LearnProc = () => {
  const learnCtx = useLearnApp();
  const ctx = useContext(AppContext);

  const learnStep = learnCtx.state?.learnStep;
  useEffect(() => {
    if (learnCtx.state?.learnStep === LearnStep.Plan) {
      ctx.learn.setCurrentTab(TabStep.Plan);
    }
  }, [learnStep, ctx.learn, learnCtx.state]);
  return (
    <Fragment>
      <TabPanel index={learnCtx.state?.learnStep} value={LearnStep.Begin}>
        <LearnBegin />
      </TabPanel>
      <TabPanel index={learnCtx.state?.learnStep} value={LearnStep.SubjectHome}>
        <LearnSubjectHome />
      </TabPanel>
      <TabPanel index={learnCtx.state?.learnStep} value={LearnStep.LearnChoice}>
        <LearnChooseTopic />
      </TabPanel>
      <TabPanel index={learnCtx.state?.learnStep} value={LearnStep.Learning}>
        <LearnLearning />
      </TabPanel>
      <TabPanel index={learnCtx.state?.learnStep} value={LearnStep.Practice}>
        <LearnPractice />
      </TabPanel>
      <TabPanel index={learnCtx.state?.learnStep} value={LearnStep.Rest}>
        <LearnRest />
      </TabPanel>
    </Fragment>
  );
};
