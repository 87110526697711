import { useSettingCardStyle } from "./Common";
import React, { useState, useContext } from "react";
import { AppContext } from "../../App";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";

export const ThemeCard = () => {
  const style = useSettingCardStyle();
  const [theme_name, setThemeName] = useState(() => {
    let t = localStorage.getItem("theme");
    if (t) return t;
    else return "red";
  });
  const appctx = useContext(AppContext);
  const tstyle = useStyles();
  return (
    <Card className={style.card}>
      <CardContent>
        <Typography className={style.cardTitle}>修改外观与主题</Typography>
      </CardContent>
      <CardContent>
        <Grid container className={style.cardContent} spacing={2}>
          <Grid item>
            <FormControl className={tstyle.formControl}>
              <InputLabel>主题色</InputLabel>
              <Select
                style={{ width: 200 }}
                value={theme_name}
                onChange={e => {
                  let t = e.target.value as string;
                  setThemeName(t);
                  localStorage.setItem("theme", t);
                  appctx.refreashTheme();
                }}
              >
                <MenuItem value={"normal"}>常规</MenuItem>
                <MenuItem value={"red"}>粉红</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    }
  })
);
