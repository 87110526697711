import { ElementProp } from "../common/eleCommon";
import { useEditor, useReadOnly, ReactEditor } from "slate-react";
import React from "react";
import { css } from "emotion";
import { Transforms } from "slate";

export const CheckListItemElement = ({
  attributes,
  children,
  element,
}: ElementProp) => {
  const editor = useEditor();
  const readOnly = useReadOnly();
  let { checked } = element;
  if (checked === undefined) checked = false;
  return (
    <div
      {...attributes}
      className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        & + & {
          margin-top: 0;
        }
      `}
    >
      <span
        contentEditable={false}
        className={css`
          margin-right: 0.75em;
          user-select: none;
        `}
      >
        <input
          type="checkbox"
          checked={checked}
          contentEditable={false}
          className={css`
            user-select: none;
          `}
          onChange={(event) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              { checked: event.target.checked },
              { at: path }
            );
          }}
        />
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className={css`
          flex: 1;
          opacity: ${checked ? 0.666 : 1};
          text-decoration: ${!checked ? "none" : "line-through"};
          &:focus {
            outline: none;
          }
          font-size: 20px;
        `}
      >
        {children}
      </span>
    </div>
  );
};
