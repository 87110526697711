import { Editor, Transforms, Node } from "slate";
import { Qkeypoint } from "../../AppPages/Learn/LearnProc/Practice/Quantitative";

export const QuizPlugin = {
  insertQuizCard: (editor: Editor) => {
    Transforms.setNodes(editor, {
      type: "quiz-card",
      uuid: "",
      children: [{ text: "" }],
    });
  },
};

export const withQuiz = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (n) => n.type === "quiz-card" || isVoid(n);
  return editor;
};

const QuizChoiceCard = () => {};

/**
 * 卡片数据
 */
export interface QuizCardData {
  uuid: string;
  type: "Choice" | "TrueOrFalse" | "Remember" | "Answer";
  card: QuizChoiceCard | QuizTOFCard | QuizRememberCard | QuizAnswerCard;
  keywords: [],
  assets: []
}

/**
 * 卡片用户数据
 */
export interface QuizCardUserData extends Qkeypoint {
  uuid: string;
}

/**
 * 卡片集合
 */
export interface QuizCardCollection {
  uuid: string;
  name: string;
  children: string[];
}

/**
 * 选择题
 */
export interface QuizChoiceCard {
  /**
   * 题干
   */
  helper: Node[];
  choices: Array<Node[]>;
  answer: number[];
  explain: Node[];
  exp_choices: Array<Node[]>;
}

/**
 * 判断题
 */
export interface QuizTOFCard {
  /**
   * 题干
   */
  helper: Node[];
  answer: boolean;
  explain: Node[];

}

/**
 * 记忆题
 */
export interface QuizRememberCard {
  helper: Node[];
  explain: Node[];

}

/**
 * 问答题
 */
export interface QuizAnswerCard {
  helper: Node[];
  answer: Node[];
  explain: Node[];

}
