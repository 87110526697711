import React, { useRef, useState, useEffect } from "react";

import { ElementProp } from "../common/eleCommon";
import CodeMirror from "codemirror";

import "codemirror/theme/duotone-light.css";
import "codemirror/lib/codemirror.css";

// 更多代码主题可见
// https://codemirror.net/mode/index.html
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";

import "../css/code.css";

import { Transforms } from "slate";
import { useEditor, ReactEditor } from "slate-react";
import {
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";

export const CodeElement = (props: ElementProp) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [codemirror, setCodeMirror] = useState() as [
    CodeMirror.Editor,
    Function
  ];
  const onValueChange = useRef() as any;
  const editor = useEditor();

  const [count, setCount] = useState(0);

  // 下面将codemirror中的内容保存到数据中
  useEffect(() => {
    onValueChange.current = (ins: CodeMirror.Editor) => {
      const data = ins.getValue();

      const path = ReactEditor.findPath(editor, props.element);

      Transforms.setNodes(
        editor,
        { ...props.element, data: data },
        { at: path }
      );
      setCount(count + 1);
    };

    if (!!ref.current) {
      if (!codemirror) {
        let cm = CodeMirror(ref.current, {
          theme: "duotone-light",
          lineNumbers: true,
          value: !!props.element.data ? props.element.data : "",
          mode: !!props.element.lang ? props.element.lang : "javascript"
        });
        if (!!cm) {
          cm.on("change", onValueChange.current);
          setCodeMirror(cm);
        }
      }
    }
  }, [
    count,
    codemirror,
    editor,
    onValueChange,
    props.element,
    ref,
    setCodeMirror
  ]);

  useEffect(() => {
    if (!!codemirror) codemirror.focus();
  }, [codemirror]);

  const [lang, setLang] = useState(
    !!props.element.lang && typeof props.element.lang === "string"
      ? props.element.lang
      : "javascript"
  );
  const style = useStyle();
  return (
    <div
      data-slate-editor
      className={"code-area " + style.codeRoot}
      contentEditable={false}
      style={{ userSelect: "none" }}
      {...props.attributes}
    >
      <FormControl className={style.selectLang}>
        <Select
          value={lang}
          onChange={e => {
            codemirror.setOption("mode", e.target.value);

            const path = ReactEditor.findPath(editor, props.element);

            Transforms.setNodes(
              editor,
              { ...props.element, lang: e.target.value },
              { at: path }
            );
            setLang(e.target.value as string);
          }}
        >
          <MenuItem value={"javascript"}>javascript</MenuItem>
          <MenuItem value={"css"}>css</MenuItem>
          <MenuItem value={"text/x-c++src"}>c++</MenuItem>
          <MenuItem value={"text/x-csharp"}>c#</MenuItem>
          <MenuItem value={"python"}>python</MenuItem>
        </Select>
      </FormControl>
      <div ref={ref} onChange={e => console.log(e)}></div>
      {props.children}
    </div>
  );
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    selectLang: {
      position: "absolute",
      right: 15,
      zIndex: 3,
      minWidth: 60
    },
    codeRoot: {
      position: "relative"
    }
  })
);
