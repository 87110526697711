import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Grid,
  Button,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../lib/Database/Database";
import { KnowledgeBreadcrumbs } from "./KnowledgeBreadcrumbs";
import { BlockEditor } from "../elements/BlockEditor";
import { KnowledgeCard } from "../AppPages/Learn/Knowledge/old_Knowledge";

export interface SelectListDialogProp<T> {
  open: boolean;
  handleClose: () => void;
  setResult: (newv: { uuid: string; title: string }) => void;
  title: string;
  getData: (search: string) => Promise<T[]>;
  itemKey: (t: T) => string;
  itemTitle: (t: T) => string;
  defaultSearch?: string;
}

export function SelectListDialog<T>({
  open,
  handleClose,
  setResult,
  title,
  getData,
  itemKey,
  itemTitle,
  defaultSearch,
}: SelectListDialogProp<T>) {
  const [selectUUID, setSelected] = useState("");
  const [selectTitle, setSelectTitle] = useState("");
  const [search, setSearch] = useState(defaultSearch || "");
  const { data } = useRequest(async () => getData(search), {
    refreshDeps: [search],
  });
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ minWidth: 200 }}>
        <Typography>{title}</Typography>
      </DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="搜索"
        />
      </DialogContent>
      <DialogContent>
        <List>
          {data &&
            data.map((v) => {
              return (
                <SelectListItem
                  key={itemKey(v)}
                  itemKey={itemKey}
                  itemTitle={itemTitle}
                  selectUUID={selectUUID}
                  setSelectTitle={setSelectTitle}
                  setSelected={setSelected}
                  value={v}
                />
              );
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              disabled={selectUUID === ""}
              onClick={() => {
                setResult({
                  uuid: selectUUID,
                  title: selectTitle,
                });
                handleClose();
              }}
            >
              确认
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export function SelectListItem<T>({
  itemKey,
  selectUUID,
  setSelected,
  setSelectTitle,
  itemTitle,
  value,
}: {
  itemKey: (t: T) => string;
  selectUUID: string;
  setSelected: (s: string) => void;
  setSelectTitle: (s: string) => void;
  itemTitle: (t: T) => string;
  value: T;
}) {
  const style = useSelectedItemStyle();
  const { data: dt } = useRequest(async () => {
    const db = await getDatabase();
    const doc: KnowledgeCard = await (
      await getKnowledgeCard_Read(db)
    ).get(itemKey(value));
    if (doc) {
      return doc.doc.document[0].children;
    }
  });
  return (
    <ListItem
      button
      selected={itemKey(value) === selectUUID}
      onClick={() => {
        setSelected(itemKey(value));
        setSelectTitle(itemTitle(value));
      }}
      className={style.item}
    >
      <ListItemText>
        {dt ? (
          <BlockEditor
            setValue={() => {}}
            readonly
            mvalue={{ document: dt } as any}
          />
        ) : itemTitle(value)}
        <Divider />
        <KnowledgeBreadcrumbs value={value as any} />
      </ListItemText>
    </ListItem>
  );
}
export const useSelectedItemStyle = makeStyles(() =>
  createStyles({
    item: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#b1b1b1",
      marginTop: 6,
      //padding: 6,
      borderRadius: 5,
      boxShadow: "inset 0px 0px 4px #d4d4d4",
      pointerEvents: "auto"
    },
  })
);
