import { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import { useLocation } from "react-router-dom";

export const ScrollMem = () => {
  const appctx = useContext(AppContext);
  const { pathname } = useLocation();
  //console.log(`${pathname} ${window.scrollY}`);
  useEffect(() => {
    let top = appctx.routeScroll.current.get(pathname) || 0;

    window.scrollTo(0, top);
  }, [pathname, appctx.routeScroll]);

  useEffect(() => {
    const updateY = () => {
      let y = window.scrollY;
      appctx.routeScroll.current.set(pathname, y);
      //console.log(`${pathname} ${y}`);
    };
    window.addEventListener("scroll", updateY);
    return () => window.removeEventListener("scroll", updateY);
  }, [pathname, appctx.routeScroll]);
  return null;
};
