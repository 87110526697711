import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  Fragment,
} from "react";
import { BlockEditor } from "../../BlockEditor";
import {
  CardProp,
  QuizTemplateMakeCardControlBar,
  useEditQuiz,
  QuizTemplateHead,
  QuizTemplateAnswerControlBar,
  UserAnswerLevel,
  QuizTemplateAfterAnswerControlBar,
  UserAssessmentLevel,
  useQuizCardStyle,
  useQuizCardStyleCommon,
  useQuizCardCommon,
} from "./QuizCommon";
import {
  QuizChoiceCard,
  QuizCardData,
  QuizCardUserData,
} from "../../plugins/withQuiz";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../../lib/Database/Database";
import { useInterval } from "../../../lib/CommonHook";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Checkbox,
  makeStyles,
  createStyles,
  Divider,
  Theme,
} from "@material-ui/core";
import md5 from "blueimp-md5";
import { EmptyDoc } from "../../../lib/SlateUtil";
import { Node } from "slate";
import { getFromDoc } from "../../../AppPages/Learn/Knowledge/old_Knowledge";
import {
  useCollectKeyword,
  CollectKeyword,
} from "../../../AppPages/Learn/LearnProc/Practice/Quantitative";
import { emptyValueData } from "../../../Init/initValue";

export const QuizCoiceCardElement = (prop: CardProp) => {
  const card = prop.data.card as QuizChoiceCard;
  card.exp_choices = card.exp_choices || [];

  const {
    canSelect,
    setCanSelect,
    wantShowExp,
    setWantShowExp,
    editcard,
    helperData,
    expData,
    setHelperData,
    setExpData,
  } = useQuizCardCommon(prop);

  const style = useChioceCardStyle();
  const quizCardStyle = useQuizCardStyle();
  /**
   * 设置选中与否
   */
  const [selectItem, selectItemDis] = useReducer(
    (state: Map<number, boolean>, action: [number, boolean] | "init") => {
      if (action !== "init") {
        const [k, v] = action;

        let newstate = new Map<number, boolean>(state);
        newstate.set(k, v);
        return newstate;
      } else {
        let map = new Map<number, boolean>();
        card.answer.forEach((n) => {
          map.set(n, editcard.isEdit);
        });
        return map;
      }
    },
    card.answer,
    (init) => {
      let map = new Map<number, boolean>();
      init.forEach((n) => {
        map.set(n, true);
      });
      return map;
    }
  );

  useEffect(() => {
    setHelperData(card.helper);
    setExpData(card.explain);
    selectItemDis("init");
  }, [prop.uuid, card.helper, card.explain]);

  const save = useCallback(async () => {
    const db = await getDatabase();

    let ans: number[] = [];
    selectItem.forEach((v, k) => {
      if (!!v) ans.push(k);
    });
    card.answer = ans;
    let mainKeywords: string[] = [];
    let medias = new Set<string>();
    getFromDoc(card.helper, { keywords: mainKeywords, media: medias });
    getFromDoc(card.explain, { keywords: mainKeywords, media: medias });
    for (let i = 0; i < card.choices.length; i++) {
      const c = card.choices[i];
      getFromDoc(c, { keywords: mainKeywords, media: medias });
    }
    for (let i = 0; i < card.exp_choices.length; i++) {
      const c = card.exp_choices[i];
      getFromDoc(c, { keywords: mainKeywords, media: medias });
    }
    let old = await (await getObjectStore(db, StoreName.quiz_card)).get(
      prop.uuid
    );
    await (await getObjectStore(db, StoreName.quiz_card, "readwrite")).put({
      ...old,
      card: card,
      keywords: [...new Set(mainKeywords)],
      assets: [...medias],
    } as QuizCardData);
  }, [selectItem, card, prop.data]);

  /**
   * 定时保存
   */
  useInterval(() => {
    if (editcard.isEdit) {
      save();
    }
  }, 1000);

  const [checkAnswer, setCheckAnswer] = useState(new Map<number, boolean>());
  const [assess, setAssess] = useState(emptyValueData());
  const [AnsStatue, setAnsStatue] = useState(UserAnswerLevel.Aah);

  const commonStyle = useQuizCardStyleCommon(canSelect, editcard.isEdit);

  const collectKeyword = useCollectKeyword();
  return (
    <Card className={style.root + " " + commonStyle}>
      <CardContent>
        <QuizTemplateHead isEdit={editcard.isEdit} prop={prop} />
      </CardContent>
      <Grid container>
        <Grid item xs={12} md={!canSelect || editcard.isEdit ? 6 : 12}>
          <CardContent>
            <Typography className={style.helperTips}>
              {editcard.isEdit ? "在此输入题干" : "问题"}
            </Typography>
            <Box className={style.helperEdit}>
              <CollectKeyword.Provider
                value={{
                  keywords: collectKeyword.keywords,
                  addKeywords: collectKeyword.addKeywords,
                  enable: !canSelect && !editcard.isEdit,
                }}
              >
                <BlockEditor
                  mvalue={{
                    document: helperData,
                    uuid: prop.uuid + "_ques",
                    lastWriteTime: new Date(),
                    md5: md5(JSON.stringify(helperData)),
                    tags: [],
                  }}
                  setValue={(v: any) => {
                    card.helper = v.doc;
                    setHelperData(v.doc);
                  }}
                  readonly={!editcard.isEdit}
                  hideKeyword={!editcard.isEdit && canSelect}
                />
              </CollectKeyword.Provider>
            </Box>
          </CardContent>
          <CardContent>
            <Typography className={style.helperTips}>
              {editcard.isEdit ? "在此编辑选项" : "选项"}
            </Typography>
            {card.choices.map((v, i: number) => (
              <Card key={i} className={style.choiceCard}>
                <CardContent>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item sm={1}>
                      <Checkbox
                        color={
                          canSelect
                            ? "default"
                            : checkAnswer.get(i)
                            ? "primary"
                            : selectItem.get(i)
                            ? "default"
                            : "secondary"
                        }
                        checked={
                          canSelect
                            ? !!selectItem.get(i)
                            : !!selectItem.get(i) ||
                              card.answer.indexOf(i) !== -1
                        }
                        onChange={() => {
                          if (canSelect)
                            selectItemDis([i, !!!selectItem.get(i)]);
                        }}
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <Typography
                        className={style.selectItemCharacter}
                        component="span"
                      >
                        {ChoiceMap[i]}
                      </Typography>
                    </Grid>
                    <Grid item sm>
                      <Typography component="span">
                        <QuizChoiceItem
                          uuid={prop.uuid + "_" + i}
                          value={v}
                          setValue={(vl: any) => {
                            card.choices[i] = vl;
                          }}
                          isEdit={editcard.isEdit}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Grid>
        <Grid item xs={12} md={!canSelect || editcard.isEdit ? 6 : 12}>
          {(prop.showAns || wantShowExp) && (
            <Fragment>
              <Divider />
              <CardContent>
                <Typography className={style.helperTips}>
                  {editcard.isEdit ? "在此输入解析" : "解析"}
                </Typography>
                <Box className={style.helperEdit}>
                  <CollectKeyword.Provider
                    value={{
                      keywords: collectKeyword.keywords,
                      addKeywords: collectKeyword.addKeywords,
                      enable: !canSelect && !editcard.isEdit,
                    }}
                  >
                    <BlockEditor
                      mvalue={{
                        document: expData || EmptyDoc(),
                        uuid: prop.uuid + "_head_e",
                        lastWriteTime: new Date(),
                        md5: md5(JSON.stringify(expData)),
                        tags: [],
                      }}
                      setValue={(v: any) => {
                        card.explain = v.doc;
                        setExpData(v.doc);
                      }}
                      readonly={!editcard.isEdit}
                    />
                  </CollectKeyword.Provider>
                </Box>
              </CardContent>
              <CardContent>
                <Typography className={style.helperTips}>
                  {editcard.isEdit ? "在此编辑选项解析" : "选项解析"}
                </Typography>
                {card.choices.map((v, i: number) => (
                  <Card key={"exp" + i} className={style.choiceCard}>
                    <CardContent>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item sm={1}>
                          <Checkbox
                            color={
                              canSelect
                                ? "default"
                                : checkAnswer.get(i)
                                ? "primary"
                                : selectItem.get(i)
                                ? "default"
                                : "secondary"
                            }
                            checked={
                              canSelect
                                ? !!selectItem.get(i)
                                : !!selectItem.get(i) ||
                                  card.answer.indexOf(i) !== -1
                            }
                          />
                        </Grid>
                        <Grid item sm={1}>
                          <Typography
                            className={style.selectItemCharacter}
                            component="span"
                          >
                            {ChoiceMap[i]}
                          </Typography>
                        </Grid>
                        <Grid item sm>
                          <Typography component="span">
                            <QuizChoiceItem
                              uuid={prop.uuid + "_e_" + i}
                              value={card.exp_choices[i] || EmptyDoc()}
                              setValue={(vl: any) => {
                                card.exp_choices[i] = vl;
                              }}
                              isEdit={editcard.isEdit}
                              isExplain
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </CardContent>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <CardContent>
            {editcard.isEdit ? (
              <QuizTemplateMakeCardControlBar prop={prop} save={save} />
            ) : canSelect ? (
              <QuizTemplateAnswerControlBar
                onConfirm={(level: UserAnswerLevel) => {
                  setAnsStatue(level);
                  setCanSelect(false);
                  setWantShowExp(true);

                  let judge = JudgeChoiceRight(card.answer, selectItem);
                  setCheckAnswer(judge);
                }}
              />
            ) : (
              <QuizTemplateAfterAnswerControlBar
                disabled={false}
                onConfirm={(v: UserAssessmentLevel) => {
                  if (prop.completeCallback && prop.userdata) {
                    let u = asChoiceUserData(prop.userdata);
                    let choose: number[] = [];
                    selectItem.forEach((v, k) => {
                      if (v) choose.push(k);
                    });
                    u.history.push({
                      answer: choose,
                      statue: AnsStatue,
                      assess: v,
                      think: assess,
                    });
                    u.meet += 1;

                    prop.completeCallback(u);
                  }
                  if (prop.nextCardCallback) prop.nextCardCallback();
                }}
              />
            )}
            {!canSelect && (
              <Fragment>
                <Box
                  style={{
                    padding: 10,
                    margin: 10,
                  }}
                >
                  <Typography>笔记</Typography>
                  <Box
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderColor: "#e1e1e1",
                      padding: 10,
                      borderWidth: 1,
                      margin: 10,
                    }}
                  >
                    <BlockEditor
                      mvalue={{
                        document: assess,
                        uuid: prop.uuid,
                        lastWriteTime: new Date(),
                        md5: md5(JSON.stringify(assess)),
                        tags: [],
                      }}
                      setValue={(v: any) => {
                        setAssess(v.doc);
                      }}
                    ></BlockEditor>
                  </Box>
                </Box>
              </Fragment>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

interface OneChoice {
  answer: number[];
  statue: UserAnswerLevel;
  assess: UserAssessmentLevel;
  think: Node[];
}

interface ChoiceUserData extends QuizCardUserData {
  history: OneChoice[];
}

function asChoiceUserData(a: QuizCardUserData): ChoiceUserData {
  return { history: [], ...a };
}

function JudgeChoiceRight(answer: number[], userAnswer: Map<number, boolean>) {
  let result = new Map<number, boolean>();
  userAnswer.forEach((v, k) => {
    if (v && answer.indexOf(k) !== -1) result.set(k, true);
    else if (!v && answer.indexOf(k) === -1) result.set(k, true);
    else result.set(k, false);
  });
  return result;
}

const useEditItemStyle = makeStyles(() =>
  createStyles({
    editarea: {},
  })
);
export const QuizChoiceItem = ({
  uuid,
  value,
  setValue,
  isEdit,
  isExplain,
}: {
  uuid: string;
  value: Node[];
  setValue: Function;
  isEdit: boolean;
  isExplain?: boolean;
}) => {
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [uuid, setLocalValue, value]);
  const style = useEditItemStyle();

  return (
    <span className={style.editarea}>
      <BlockEditor
        mvalue={{
          document: localValue,
          uuid: uuid,
          lastWriteTime: new Date(),
          md5: JSON.stringify(localValue),
          tags: [],
        }}
        setValue={(v: any) => {
          setLocalValue(v.doc);
          setValue(v.doc);
        }}
        readonly={!isEdit}
        hideKeyword={!isExplain && !isEdit}
      />
    </span>
  );
};

/**
 * 将数字与字母对应
 */
const ChoiceMap = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
];

const useChioceCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgb(253,253,253)",
    },

    selectItemCharacter: {
      fontSize: 22,
    },
    helperEdit: {
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 4,
      padding: 8,
      borderColor: "rgb(200,200,200)",
      backgroundColor: "white",
    },
    helperTips: {
      fontSize: 14,
      color: "rgb(180,180,180)",
    },
    choiceCard: {
      marginTop: 10,
    },
  })
);
