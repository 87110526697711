import { SyncData } from "../../AppPages/SettingPage/Common";
import { useRequest } from "@umijs/hooks";

export const useAutoSync = () => {
  const { loading, run, cancel } = useRequest(async () => {
    let autos = localStorage.getItem("auto-sync");
    if (autos === "true") {
      SyncData();
    }
  }, {
    pollingInterval: 60000,
    pollingWhenHidden: false
  })
};
