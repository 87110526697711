import React, { useContext, useCallback, useRef, useState } from "react";
import { Card, Button, Typography, Row, message, notification } from "antd";
import {
  Grid,
  createStyles,
  makeStyles,
  Fade,
  Input,
  Container,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useRequest } from "@umijs/hooks";
import { usePageStyle } from "../PageStyle";
import { ThemeContext } from "../../App";
import { useStateful, useUpdateI, useAlive } from "../../lib/CommonHook";
import {
  CheckOutlined,
  QuestionOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import TweenOne from "rc-tween-one";
import {
  getWordUserdata_Write,
  getWord_Write,
  getWord_Read,
  getDatabase,
  getWordUserdata_Read,
} from "../../lib/Database/Database";
import { css } from "emotion";
import { Button as MButton } from "@material-ui/core";

export interface WordData {
  word: string;
  exp: string;
  pron: string;
  feq?: number;
}

interface WordDataRT extends WordData {
  count: number;
  maxCount: number;
  feq: number;
}

export interface WordUserData {
  word: string;
  nextTIme: Date;
  type?: "never";
}

function toWordUserData(
  word: string,
  data: WordUserData | undefined
): WordUserData {
  if (data) return data;
  return {
    word,
    nextTIme: new Date(),
  };
}

const WordContext = React.createContext(
  {} as { completeCard: React.MutableRefObject<Function | undefined> }
);

export const EnglishWordPage = () => {
  const docUpdate = useUpdateI();
  const alive = useAlive();
  const { data: words } = useRequest(
    async () => {
      setBackOpen(true);
      const db = await getDatabase();
      let dd = await (await getWord_Read(db)).getAllKeys();
      for (let i = 0; i < dd.length; i++) {
        const e = dd[i];
        let ano = Math.floor(Math.random() * dd.length);
        let _t = dd[ano];
        dd[ano] = e;
        dd[i] = _t;
      }
      let firstshow: WordDataRT[] = [];
      for (let i = 0; i < dd.length; i++) {
        if (firstshow.length > 400) break;
        const key = dd[i];
        let word: WordData = await (await getWord_Read(db)).get(key);
        let u = await (await getWordUserdata_Read(db)).get(word.word);
        let user = toWordUserData(word.word, u);
        if (
          user.type !== "never" &&
          (user.nextTIme.getTime() - Date.now()) / (24 * 1000 * 3600) < 0.5
        ) {
          firstshow.push({
            word: word.word,
            exp: word.exp,
            pron: word.pron,
            count: 1,
            maxCount: 1,
            feq: word.feq || 0,
          });
        }
      }
      firstshow.sort((a, b) => a.feq - b.feq);
      for (let i = 0; i < firstshow.length; i++) {
        const random = Math.floor(Math.random() * firstshow.length);
        let t = firstshow[i];
        firstshow[i] = firstshow[random];
        firstshow[random] = t;
      }
      let d = firstshow.slice(0, totalShow);
      let o = firstshow.slice(totalShow, firstshow.length);
      setBackOpen(false);

      return {
        data: d,
        others: o,
      };
    },
    { refreshDeps: [docUpdate.key] }
  );
  const paper_style = usePageStyle();
  const mtheme = useContext(ThemeContext);

  const completeCardRef = useRef<undefined | Function>();
  const update = useUpdateI();
  const level = useRef(0);
  const totalShow = 16;
  const rightCount = useStateful(0);

  const completeCard = useCallback(
    (index: number, arg?: { never?: boolean; dontknow?: boolean }) => {
      if (words) {
        let old = words.data[index];
        level.current++;
        if (!old) return;
        if (arg) {
          if (arg.dontknow) {
            old.count += 2;
          }
        }
        if (old.count > old.maxCount) old.maxCount = old.count;

        old.count -= 1;
        if (old.count >= 4) old.count = 4;

        if (old.count > 0) {
          let ran = Math.floor(Math.random() * Math.min(30, words.others.length));
          words.others.splice(ran, 0, old);
        }
        let showcount = words.data.reduce((p, c) => {
          if (c.word) return p + 1;
          else return p;
        }, 0);

        words.data[index] = {} as any;

        if (
          (showcount === totalShow && words.others.length > 0) ||
          showcount === 1
        ) {
          let f = words.others.shift();
          if (f) {
            (f as any).level = level.current;
            if (showcount === 1) {
              words.data[Math.floor(totalShow / 2)] = f as any;
            } else {
              words.data[index] = f as any;
            }
          }
        }

        const savethis = async () => {
          let day = 1;
          if (old.maxCount < 2) {
            day = 3;
          } else if (old.maxCount < 4) {
            day = 1;
          }
          let userdata: WordUserData = {
            word: old.word,
            nextTIme: new Date(new Date().getTime() + day * 1000 * 3600 * 24),
          };
          if (arg && arg.never) userdata.type = "never";
          await (await getWordUserdata_Write()).put(userdata);
        };
        if (old.count < 1) {
          savethis();
          if (alive.current) rightCount.set(rightCount.value + 1);
        }
        update.update();
      }
    },
    [update.update, words, rightCount]
  );
  completeCardRef.current = completeCard;
  const [back_open, setBackOpen] = useState(false);
  const { run: LOG } = useRequest(
    async (data) => {
      console.log(data);
    },
    { manual: true, throttleInterval: 1000 }
  );
  return (
    <div
      className={paper_style.body}
      style={{ backgroundColor: mtheme.background }}
    >
      <WordContext.Provider value={{ completeCard: completeCardRef }}>
        <Container>
          <MButton variant="outlined">
            <Input
              className={css`
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0
                  backgroung-color: transparent;
                `}
              type="file"
              onChange={async (e: any) => {
                let file = e.target.files[0];

                if (!!file) {
                  let reader = new FileReader();
                  reader.onload = async (e) => {
                    let data = e.target?.result as string;
                    if (data) {
                      let html = new DOMParser().parseFromString(
                        data,
                        "text/html"
                      );
                      let trs = html.body.querySelectorAll("tr");
                      for (let i = 0; i < trs.length; i++) {
                        const element = trs[i];
                        let word = element.children[1].textContent;
                        let pron = element.children[2].textContent;
                        let exp = element.children[3].textContent;
                        if (word !== "单词") {
                          await (await getWord_Write()).put({
                            word,
                            pron,
                            exp,
                          });
                        }
                      }
                      notification.open({
                        message: "导入成功",
                        description: "",
                        onClick: () => {},
                        duration: 3,
                      });
                      docUpdate.update();
                    }
                  };
                  reader.readAsText(file);
                  //setBackOpen(false);
                  //reader.readA(file);
                }
              }}
            />
            从欧路单词生词本导入Html
          </MButton>
          <MButton
            variant="outlined"
            onClick={async () => {
              setBackOpen(true);
              let resp = await fetch(
                `https://zhishudali.ink/file/ANC_%E8%80%83%E7%A0%94_5937.txt`
              );
              let data = await resp.text();
              let lines = data.split("\n");
              const db = await getDatabase();
              for (let i = 1; i < lines.length; i++) {
                let line = lines[i].split("\t").filter((v) => v !== "");
                try {
                  let wd: WordData = {
                    word: line[3],
                    exp: line[5],
                    pron: line[4],
                  };
                  (wd as any).feq = Number(line[2]);
                  await (await getWord_Write(db)).put(wd);
                } catch (error) {
                  console.log(error);
                  console.log(line);
                }
                LOG(((i / lines.length) * 100).toString() + " %");
              }
              docUpdate.update();
              setBackOpen(false);
            }}
          >
            导入考研词频库
          </MButton>
        </Container>
        <Row
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
          }}
        >{`本次掌握了${rightCount.value}个单词`}</Row>
        <Grid
          container
          spacing={2}
          style={{ paddingLeft: 200, paddingRight: 200, paddingTop: 20 }}
        >
          {words &&
            words.data.map((v, i) => {
              return (
                <Grid
                  item
                  lg={3}
                  md={3}
                  xs={6}
                  key={
                    (v &&
                      v.word &&
                      v.word + ((v as any).level || 0).toString()) ||
                    i.toString() + "____index"
                  }
                >
                  {v && v.word && <EnglishWordCard wdata={v} index={i} />}
                </Grid>
              );
            })}
        </Grid>
      </WordContext.Provider>
      <Backdrop open={back_open} style={{ zIndex: 999999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const EnglishWordCard = ({
  wdata,
  index,
}: {
  wdata: WordData;
  index: number;
}) => {
  const style = useWordStyle();
  const onHover = useStateful(false);
  const paused = useStateful(true);
  const showExp = useStateful(false);
  const disappear = useStateful(true);
  const comfirm = useRef(false);
  const alive = useAlive();
  const kctx = useContext(WordContext);
  const completeThisCard = useCallback(
    (never?: boolean, dontknow?: boolean) => {
      setTimeout(() => {
        kctx.completeCard.current?.(index, { never: never, dontknow });
      }, 500);
    },
    []
  );
  return (
    <div style={{ perspective: 500 }}>
      <TweenOne
        paused={false}
        animation={{
          rotateX: 0,
          duration: 500,
        }}
        style={{ perspective: 500, transform: "rotateX(-90deg)" }}
      >
        <TweenOne
          paused={disappear.value}
          animation={{
            rotateX: 90,
            duration: 500,
          }}
          style={{ perspective: 500 }}
        >
          {!showExp.value ? (
            <TweenOne
              paused={paused.value}
              animation={{
                rotateY: 90,
                duration: 500,
              }}
            >
              <Card
                hoverable
                className={style.card + " " + style.textArea}
                onMouseEnter={() => onHover.set(true)}
                onMouseLeave={() => onHover.set(false)}
                style={{ position: "relative" }}
                onClick={() => {
                  paused.set(false);
                  setTimeout(() => {
                    showExp.set(true);
                    setTimeout(() => {
                      if (alive.current && !comfirm.current) {
                        disappear.set(false);
                        completeThisCard(false, true);
                      }
                    }, 10 * 1000);
                  }, 500);
                }}
              >
                <div style={{ height: "100%" }}>
                  <Typography className={style.mainText}>
                    {wdata.word}
                  </Typography>
                </div>
              </Card>
            </TweenOne>
          ) : (
            <TweenOne
              paused={false}
              animation={{
                rotateY: 0,
                duration: 500,
              }}
              style={{ transform: "rotateY(-90deg)" }}
            >
              <div style={{ position: "relative" }}>
                <Card
                  hoverable
                  className={style.card + " " + style.textArea}
                  onMouseEnter={() => onHover.set(true)}
                  onMouseLeave={() => onHover.set(false)}
                >
                  <Row>
                    <Typography className={style.mainText}>
                      {wdata.word}
                    </Typography>
                  </Row>
                  <Row>
                    <Typography className={style.expText}>
                      {wdata.exp}
                    </Typography>
                  </Row>
                </Card>
                <div>
                  <Button
                    onClick={() => {
                      comfirm.current = true;
                      disappear.set(false);
                      completeThisCard(true);
                    }}
                    size="small"
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 10,
                    }}
                  >
                    不再出现
                  </Button>
                  <Button
                    shape="circle"
                    onClick={() => {
                      comfirm.current = true;
                      disappear.set(false);
                      completeThisCard();
                    }}
                    size="large"
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: "calc(50% - 60px)",
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    shape="circle"
                    onClick={() => {
                      comfirm.current = true;
                      disappear.set(false);
                      completeThisCard(false, true);
                    }}
                    size="large"
                    style={{
                      position: "absolute",
                      bottom: 10,
                      right: "calc(50% - 60px)",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              </div>
            </TweenOne>
          )}
        </TweenOne>
      </TweenOne>
    </div>
  );
};

const useWordStyle = makeStyles(() =>
  createStyles({
    card: {
      height: 170,
      borderRadius: 10,
      fontFamily: "Libre_Baskerville",
    },
    textArea: {
      overflow: "auto",
    },
    mainText: {
      fontSize: 28,
    },
    expText: {
      fontSize: 18,
    },
  })
);
