import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Button,
  Box,
  makeStyles,
  Theme,
  Snackbar,
  createStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { useLearnApp, changeLearnStep, LearnStep } from "../useLearnAppState";
import { usePractice } from "./Practice/SelectQuiz";
import { QuizCardAnswer } from "../../Quizs/QuizCollectionPractice";
import { useUpdate } from "../../../lib/CommonHook";
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const LearnPractice = () => {
  const learnApp = useLearnApp();
  const practice = usePractice();
  const update = useUpdate();
  // 第一次更新
  const [isFirst, setIsFirst] = useState(true);
  // 查找第一张卡，在加载期间不断尝试
  useEffect(() => {
    if (isFirst && practice.Index === -1) {
      const newFirst = !practice.next.current();
      if (newFirst) {
        update.update();
      } else {
        setIsFirst(newFirst);
      }
    }
  }, [practice.next, isFirst, update.key, practice.Index]);

  const style = useStyle();
  const [noTopicWarnOpen, setNoTopicWarnOpen] = useState(() =>
    learnApp.plan.planList[learnApp.state.currentSubjectIndex]
      .subjectKnowledgeCollectionUUID
      ? false
      : true
  );
  return (
    <Fragment>
      <Snackbar
        open={noTopicWarnOpen}
        autoHideDuration={3000}
        onClose={() => setNoTopicWarnOpen(false)}
      >
        <Alert onClose={() => setNoTopicWarnOpen(false)} severity="warning">
          无知识点训练，进入做题板块
        </Alert>
      </Snackbar>
      {!practice.loading && (
        <Box>
          <QuizCardAnswer
            uuid={practice.currentCardUUID}
            col={practice.currentCardCollection}
            nextCard={() => practice.next.current()}
            key={practice.currentCardUUID}
            user_data={practice.userData}
            set_user_data={practice.saveUserData}
          />
        </Box>
      )}
      {practice.isComplete && (
        <Card className={style.card}>
          <CardContent>
            <Typography>这里有好多题</Typography>
          </CardContent>
          <CardActions>
            <Grid container justify="center">
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    practice.end();
                    learnApp.func.changeState?.(
                      changeLearnStep(LearnStep.Rest)
                    );
                  }}
                >
                  学完啦
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      )}
    </Fragment>
  );
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minHeight: 300,
      [theme.breakpoints.up("xs")]: {
        width: 500,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      padding: 20,
    },
  })
);
