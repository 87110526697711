import React, { useState, useCallback, useEffect } from "react";
import { SyncData, useSettingCardStyle } from "./Common";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  LinearProgress,
} from "@material-ui/core";

export const SyncCard = () => {
  const [password, setpassword] = useState(
    localStorage.getItem("webdavPassword")
  );
  const [account, setaccount] = useState(localStorage.getItem("webdavAccount"));

  useEffect(() => {
    if (account && password && account !== "" && password !== "") {
      localStorage.setItem("webdavPassword", password);
      localStorage.setItem("webdavAccount", account);
    }
  }, [password, account]);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [, setLoadType] = useState("upload");

  const uploadCallback = useCallback(async () => {
    if (account && password && account !== "" && password !== "") {
      await SyncData("upload", {
        setIsLoading,
        setProgress,
        setLoadType,
      });
      alert("上传完成！");
    }
  }, [account, password, setIsLoading, setProgress, setLoadType]);

  const recoveryCallback = useCallback(async () => {
    console.log(account);
    console.log(password);
    if (account && password && account !== "" && password !== "") {
      if (await SyncData("recovery")) alert("恢复成功！");
    }
  }, [account, password]);

  const style = useSettingCardStyle();
  const [autoSync, setAutoSync] = useState(
    localStorage.getItem("auto-sync") === "true" ? true : false
  );
  return (
    <Card className={style.card}>
      <CardContent>
        <Typography className={style.cardTitle}>
          使用坚果云WebDAV进行备份（暂时不支持知识卡同步）
        </Typography>
      </CardContent>
      <CardContent>
        <Typography className={style.cardContent}>
          使用方式： 1. 前往坚果云注册账号。2.
          前往账户信息-安全选项-第三方应用管理。3.
          添加应用，并复制应用密码，填写到下面的密码栏内（注意，不是您的坚果云账户密码）。
        </Typography>
        <Typography className={style.cardContent}>
          点击[上传]将文档上传到坚果云，点击[恢复]使用备份文档覆盖本地文件。
        </Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <TextField
              required
              id="account"
              label="账号"
              defaultValue={account}
              variant="outlined"
              className={style.button}
              onInput={(e: any) => {
                setaccount(e.target.value);
              }}
              onChange={(e) => {
                setaccount(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="password"
              label="应用密码"
              defaultValue={password}
              variant="outlined"
              className={style.button}
              onInput={(e: any) => {
                setpassword(e.target.value);
              }}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              type="password"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={uploadCallback}
              className={style.button}
            >
              上传
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={recoveryCallback}
              className={style.button}
            >
              恢复
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={autoSync}
                  onChange={(e) => {
                    localStorage.setItem(
                      "auto-sync",
                      e.target.checked ? "true" : "false"
                    );
                    setAutoSync(e.target.checked);
                  }}
                  color="primary"
                />
              }
              label="自动上传"
            />
          </Grid>
          {false && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => SyncData()}
                className={style.button}
              >
                测试
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardContent>
        {isLoading ? (
          <LinearProgress variant="determinate" value={progress} />
        ) : null}
      </CardContent>
    </Card>
  );
};
