import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../../../lib/Database/Database";
import { KnowledgeCard } from "./old_Knowledge";

export interface KeyLink {
  uuid: string;
  children: string[];
}
export function toKeyLink(uuid: string, old: KeyLink | undefined) {
  if (old === undefined) {
    return {
      uuid,
      children: [],
    };
  } else {
    return old;
  }
}

export async function comfirmKeyLink() {
  const db = await getDatabase();
  const checkOne = async (uuid: string) => {
    const doc: KnowledgeCard = await (
      await getKnowledgeCard_Read(db)
    ).get(uuid);
    if (doc && doc.keywords) {
      for (let i = 0; i < doc.keywords.length; i++) {
        const to = doc.keywords[i];
        const kl = toKeyLink(
          to,
          await (await getObjectStore(db, StoreName.knowledgeLink)).get(to)
        );
        if (kl.children.indexOf(uuid) === -1) {
          kl.children = [...new Set(kl.children).add(uuid)];
          await (
            await getObjectStore(db, StoreName.knowledgeLink, "readwrite")
          ).put(kl);
        }
      }
    }
    if (doc && doc.children) {
      for (let i = 0; i < doc.children.length; i++) {
        const n = doc.children[i];
        await checkOne(n);
      }
    }
  };
  await checkOne("root");
}
