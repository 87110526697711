import React, { useContext } from "react";
import { Paper } from "@material-ui/core";
import { usePageStyle } from "../PageStyle";
import { ThemeContext } from "../../App";
import { useParams } from "react-router-dom";
import { useRequest } from "@umijs/hooks";
import { LoadLearn } from "../SettingPage/ExportLearn";
import { useUpdateI } from "../../lib/CommonHook";
import { Knowledge } from "../Learn/Knowledge/Knowledge";

export const Read = () => {
  const paper_style = usePageStyle();
  const mtheme = useContext(ThemeContext);
  const { path } = useParams();
  const update = useUpdateI();
  const { data } = useRequest(async () => {
    let getdata = await fetch(`https://zhishudali.ink/file/${path}.databackup`);
    let root = await LoadLearn(await getdata.blob());
    update.update();
    return root;
  });

  return (
    <Paper
      className={paper_style.body}
      style={{ backgroundColor: mtheme.background }}
    >
      {data && <Knowledge root={data} readonly/>}
    </Paper>
  );
};
