import { Editor, Transforms, Text, Node } from "slate";

interface EditorMethods {
  toggleBoldMark: Function;
  isBoldMarkActive: Function;
  toggleFormat: Function;
  isFormatActive: Function;
}

export const EditorMethods: EditorMethods = {
  isBoldMarkActive: (editor: any) => {
    const [match] = Editor.nodes(editor, {
      match: n => n.bold === true,
      universal: true
    });

    return !!match;
  },

  toggleBoldMark: (editor: Editor) => {
    const isActive = EditorMethods.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: n => Text.isText(n), split: true }
    );
  },

  toggleFormat: (editor: Editor, format: any) => {
    const isActive = EditorMethods.isFormatActive(editor, format);
    Transforms.setNodes(
      editor,
      { [format]: isActive ? null : true },
      { match: n => Text.isText(n), split: true }
    );
  },

  isFormatActive: (editor: any, format: any) => {
    const [match] = Editor.nodes(editor, {
      match: n => n[format] === true,
      universal: true
    });
    return !!match;
  }
};

export class EditorHelper {
  editor: Editor;
  constructor(editor: Editor) {
    this.editor = editor;
  }
  bind(plugin: Function): EditorHelper {
    this.editor = plugin(this.editor);
    return this;
  }
  complete(): Editor {
    return this.editor;
  }
}

export function combineOneLine(line: Node[]): string {
  let res = ""
  for (let i = 0; i < line.length; i++) {
    const one = line[i];

    if (one.text !== undefined) {
      res += one.text
    } else if (one.children !== undefined) {
      res += combineOneLine(one.children)
    }
  }
  return res
}