import React, { Fragment, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Box,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Fade,
} from "@material-ui/core";
import { useLearnApp, changeLearnStep, LearnStep } from "../useLearnAppState";
import { BlockEditor } from "../../../elements/BlockEditor";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../../../lib/Database/Database";
import { KnowledgeCard } from "../Knowledge/old_Knowledge";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowLeftRoundedIcon from "@material-ui/icons/ArrowLeftRounded";

export const LearnLearning = () => {
  const learnApp = useLearnApp();
  const ucards = useLearnCards();
  const style = useStyle();
  return (
    <Fragment>
      <Card className={style.card}>
        {ucards.cards && (
          <Fragment>
            <Box style={{ position: "absolute", top: "50%", left: -10 }}>
              <Fade in={ucards.currentIndex > 0}>
                <IconButton
                  onClick={() =>
                    ucards.setCurrentIndex(ucards.currentIndex - 1)
                  }
                >
                  <ArrowLeftRoundedIcon style={{ fontSize: 50 }} />
                </IconButton>
              </Fade>
            </Box>
            <Box style={{ position: "absolute", top: "50%", right: -10 }}>
              <Fade in={ucards.currentIndex < ucards.cards.length - 1}>
                <IconButton
                  onClick={() =>
                    ucards.setCurrentIndex(ucards.currentIndex + 1)
                  }
                >
                  <ArrowRightRoundedIcon style={{ fontSize: 50 }} />
                </IconButton>
              </Fade>
            </Box>
          </Fragment>
        )}

        <CardContent>
          {ucards.currentCard && (
            <BlockEditor
              mvalue={ucards.currentCard.doc}
              setValue={() => {}}
              readonly
            />
          )}
        </CardContent>
      </Card>
      <Box
        style={{
          position: "fixed",
          bottom: 30,
          zIndex: 3000,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() =>
                learnApp.func.changeState?.(changeLearnStep(LearnStep.Practice))
              }
            >
              开始做题
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() =>
                learnApp.func.changeState?.(
                  changeLearnStep(LearnStep.LearnChoice)
                )
              }
            >
              重新选择
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

const useLearnCards = () => {
  const learn = useLearnApp();
  const topic = learn.state.learnTopicUUID;
  const { data: allcards } = useRequest(
    async () => {
      let topicID = topic;
      if (topicID) {
        const db = await getDatabase();
        let cards: KnowledgeCard[] = [];
        const getCard = async (id: string, array: KnowledgeCard[]) => {
          const card: KnowledgeCard = await (
            await getKnowledgeCard_Read(db)
          ).get(id);
          array.push(card);
          for (let i = 0; i < card.children.length; i++) {
            const c = card.children[i];
            await getCard(c, array);
          }
        };
        await getCard(topicID, cards);
        return cards;
      }
    },
    { refreshDeps: [topic] }
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  return {
    cards: allcards,
    currentIndex,
    setCurrentIndex,
    currentCard: allcards && allcards[currentIndex],
  };
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minHeight: 300,
      [theme.breakpoints.up("xs")]: {
        width: 500,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      padding: 20,
      borderRadius: 20,
    },
  })
);
