import { useState, useRef, useEffect, useReducer } from "react";
import { ImagePlugin } from "./ImageAddition";
import { Editor, Transforms, Range } from "slate";
import { ReactEditor } from "slate-react";
import { MathPlugin } from "./withMath";
import { cloneDeep } from "lodash";
import { QuizPlugin } from "./withQuiz";
import { CanvasPlguin } from "../eles/CanvasElement";

export const withSelectInsert = {};

export const SelectInsertPlugin = {
  useSelect: (editor: ReactEditor) => {
    const [selectIndex, setSelectIndex] = useState(0);
    const [target, setTarget]: [any, Function] = useState();
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    useEffect(() => {
      if (target && SelectInsertPlugin.supportList.length > 0) {
        const el = ref.current;
        if (!!el) {
          const domRange = ReactEditor.toDOMRange(editor, target);
          const rect = domRange.getBoundingClientRect();
          el.style.top = `${rect.top + window.pageYOffset + 36}px`;
          el.style.left = `${rect.left + window.pageXOffset}px`;
        } else {
        }
      }
    }, [editor, selectIndex, target, ref]);

    const [selectItemAddition, selectItemAdditionDis] = useReducer(
      (s: Map<string, any>, act: ISelectListAction) => {
        let news = new Map<string, any>();
        s.forEach((element: any, k: string) => {
          if (k === act.type) {
            news.set(k, cloneDeep(act.newAddition));
          } else {
            news.set(k, element);
          }
        });
        return news;
      },
      new Map<string, any>(),
      (map: Map<string, any>) => {
        SelectInsertPlugin.supportList.forEach((element) => {
          map.set(element.type, cloneDeep(element.addition));
        });
        return map;
      }
    );

    return {
      selectIndex,
      setSelectIndex,
      target,
      setTarget,
      ref,
      selectItemAddition,
      selectItemAdditionDis,
    };
  },
  supportList: [
    {
      label: "图片",
      type: "image",
      insert: ImagePlugin.insertImageHelper,
      addition: { dialogOpen: false },
    },
    {
      label: "画板",
      type: "canvas",
      insert: CanvasPlguin.insertCanvasHelper,
    },
    {
      label: "链接引用",
      type: "link-card",
      insert: (editor: Editor) => {
        return { type: "link-card", newAddition: { dialogOpen: true } };
      },
      addition: { dialogOpen: false },
    },
    { label: "公式", type: "math-line", insert: MathPlugin.insertMathLine },
    //{
    //  label: "行内公式",
    //  type: "math-inline",
    //  insert: MathPlugin.insertMathInLine,
    //},
    //{
    //  label: "练习",
    //  type: "quiz-card",
    //  insert: QuizPlugin.insertQuizCard,
    //},
    {
      label: "子条目预览",
      type: "sub-preview",
      insert: (editor: Editor) => {
        const text = { text: "" };
        const node = { type: "sub-preview", children: [text] };
        Transforms.setNodes(editor, node);
      },
    },
  ],

  /**
   * 处理悬浮插入列表的键盘事件
   */
  keyboardEvents: (
    event: React.KeyboardEvent<HTMLDivElement>,
    selectPlugin: any,
    editor: Editor,
    index?: number
  ) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        const pre_inde =
          selectPlugin.selectIndex >= SelectInsertPlugin.supportList.length - 1
            ? 0
            : selectPlugin.selectIndex + 1;
        selectPlugin.setSelectIndex(pre_inde);
        break;
      case "ArrowUp":
        event.preventDefault();
        const next_inde =
          selectPlugin.selectIndex <= 0
            ? SelectInsertPlugin.supportList.length - 1
            : selectPlugin.selectIndex - 1;
        selectPlugin.setSelectIndex(next_inde);
        break;
      case "Tab":
      case "Enter":
        event.preventDefault();
        const point = selectPlugin.target.focus;
        const point0 = { ...point, offset: 0 };
        Transforms.select(editor, { anchor: point0, focus: point });
        Transforms.delete(editor);
        const act =
          SelectInsertPlugin.supportList[index || selectPlugin.selectIndex];
        if (act.addition === undefined) {
          act.insert(editor);
        } else {
          //Transforms.delete(editor);
          selectPlugin.selectItemAdditionDis(act.insert(editor, act.addition));
        }
        selectPlugin.setTarget(null);
        break;
      case "Escape":
        event.preventDefault();
        selectPlugin.setTarget(null);
        break;
      default:
        break;
    }
  },

  /**
   * 检查是否在开头出现连续的 @@ 以激活下拉选择菜单
   */
  checkIfShowSelectMenu: (editor: Editor, selectPlugin: any) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);

      const wordBefore = Editor.before(editor, start, { unit: "word" });
      const before = wordBefore && Editor.before(editor, wordBefore);

      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@@$/);
      //const after = Editor.after(editor, start);
      //const afterRange = Editor.range(editor, start, after);
      //const afterText = Editor.string(editor, afterRange);
      //const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch /* && afterMatch*/) {
        selectPlugin.setTarget(beforeRange);
        //setSearch(beforeMatch[1])
        selectPlugin.setSelectIndex(0);

        return;
      }
    }

    selectPlugin.setTarget(null);
  },
};

interface ISupportListData {
  label: string;
  type: string;
  insert: Function;
  addition?: any;
}

export interface ISelectListAction {
  type: string;
  newAddition: any;
}
