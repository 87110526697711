import {
  Card,
  createStyles,
  makeStyles,
  Typography,
  Grid,
  Button,
  CardActions,
  CardContent,
  Box,
  Divider,
} from "@material-ui/core";
import React, {  } from "react";
import { usePageStyle } from "../../PageStyle";
import {
  useLearnApp,
  changeLearnStep,
  LearnStep,
  LearnPlanSubject,
} from "../useLearnAppState";
import ScrollToTop from "../../../AppCom/ScrollToTop";

export const LearnBegin = () => {
  const pageStyle = usePageStyle();
  const learn = useLearnApp();

  return (
    <Card className={pageStyle.card}>
      <ScrollToTop />
      <CardContent>
        <Grid container justify="center" spacing={2}>
          {learn.plan &&
            learn.plan.planList.map((v) => (
              <Grid item key={v.uuid} xs={12}>
                <LearnBeginItem obj={v} />
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() =>
                learn.func.changeState?.(changeLearnStep(LearnStep.SubjectHome))
              }
            >
              开始今天的学习！
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

const LearnBeginItem = ({ obj }: { obj: LearnPlanSubject }) => {
  const style = useStyle();
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={style.normalText}>科目</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={style.titleText}>
            {obj.subjectName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={style.normalText}>预计时长</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={style.titleText}>
            {obj.fulltime.toString() + " 分钟"}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

const useStyle = makeStyles(() =>
  createStyles({
    //beginCard: {
    //    width
    //}
    titleText: {
      fontWeight: "bold",
      fontSize: 20,
    },
    normalText: {
      fontSize: 18,
    },
  })
);
