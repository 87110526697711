import { jsx } from "slate-hyperscript";

export function deserializeHTML(el: HTMLElement): any {
  if (el.nodeType === 3) {
    if (el.textContent === "\n" || el.textContent === "\n\n") return null;
    return { text: el.textContent };
  } else if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map((d) =>
    deserializeHTML(d as any)
  );
  if (children.length === 0) children = [{ text: "" }];
  //console.log(el.nodeName);
  //console.log(children);
  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "quote" }, children);
    case "P":
      return jsx("element", { type: "ph" }, children);
    case "A":
      //return jsx(
      //  "element",
      //  { type: "ph", url: el.getAttribute("href") },
      //  children
      //);
      return el.getAttribute("href");
    case "IMG":
      return jsx("element", { type: "image", url: el.getAttribute("src") }, [
        ...children,
        { text: "" },
      ]);
    case "DIV":
      return children;
    case "H1":
      return jsx("element", { type: "H1" }, children);
    case "H2":
      return jsx("element", { type: "H2" }, children);
    case "H3":
      return jsx("element", { type: "H3" }, children);
    case "H4":
      return jsx("element", { type: "H4" }, children);
    case "H5":
      return jsx("element", { type: "H5" }, children);
    case "H6":
      return jsx("element", { type: "H6" }, children);

    case "SPAN":
      return children;
    case "UL":
      return jsx("element", { type: "bulleted-list" }, children);
    case "OL":
      return jsx("element", { type: "ol" }, children);

    case "LI":
      return jsx("element", { type: "list-item" }, children);
    case "U":
      if (children.length > 0) {
        addAttr(children, "underlined");
        return children;
      } else return { text: el.textContent, underlined: true };
    case "EM":
      if (children.length > 0) {
        addAttr(children, "italic");
        return children;
      } else return { text: el.textContent, italic: true };
    case "STRONG":
      if (children.length > 0) {
        addAttr(children, "bold");
        return children;
      } else return { text: el.textContent, bold: true };
    case "math":
      return jsx("element", { type: "math-line", isShow: true }, children);
    default:
      return { text: el.textContent };
  }
}

function addAttr(node: any, attr: string) {
  if (node.length !== undefined) {
    for (let i = 0; i < node.length; i++) {
      const el = node[i];
      addAttr(el, attr);
    }
  } else if (node.text !== undefined) {
    node[attr] = true;
  }
}
