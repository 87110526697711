import React, { useMemo, useRef, useEffect, useContext } from "react";
import { Box, Fade } from "@material-ui/core";
import {
  Button,
  Card,
  Space,
  Slider,
  Tag,
  Progress,
  Row,
  List,
  Switch,
} from "antd";
import { useRequest } from "@umijs/hooks";
import {
  getNotes_Read,
  getDatabase,
  getNotes_Write,
} from "../../../lib/Database/Database";
import { PageRawData } from "../../../lib/Database/DataInterface";
import { useUpdateI, useStateful, useFirst } from "../../../lib/CommonHook";
import uuid from "uuid";
import { emptyValueData } from "../../../Init/initValue";
import { Node } from "slate";
import { PlusOutlined } from "@ant-design/icons";
import { BlockEditor } from "../../../elements/BlockEditor";
import CheckableTag from "antd/lib/tag/CheckableTag";
import InfiniteScroll from "react-infinite-scroller";
import DragHandle from "@material-ui/icons/DragHandle";
import { useDrag } from "react-dnd";
import { useLocalStorage } from "../../../lib/hooks/useLocalStorage";

export interface NoteData {
  uuid: string;
  doc?: PageRawData;
  timeoutTime: Date | null;
  timeoutType: number;
  createTime: Date;
}

function makeNoteData(): NoteData {
  const id = uuid.v4();
  return {
    uuid: id,
    doc: {
      document: emptyValueData(),
      tags: [],
      uuid: id,
      lastWriteTime: new Date(),
      md5: "",
    },
    createTime: new Date(),
    timeoutType: 3,
    timeoutTime: new Date(new Date().getTime() + 3 * 1000 * 3600 * 24),
  };
}

interface NoteList {
  uuid: "root";
  active: string[];
  hide: string[];
}

export const NotePanelContext = React.createContext({
  show: undefined,
  hide: undefined,
} as { show?: () => void; hide?: () => void });

export const useNotePanelContext = () => useContext(NotePanelContext);
export const NotePanel = () => {
  const manualUpdateList = useUpdateI();
  const notelist = useStateful<string[]>([]);
  const deletelist = useStateful<string[]>([]);
  const deleteListInf = useStateful<string[]>([]);
  const currentDeleteListIndex = useStateful(0);
  const panel_width = useLocalStorage("note_panel_size", "small");
  const Width = useStateful(400);
  useEffect(() => {
    Width.set(panel_width.state === "large" ? 600 : 400);
  }, [panel_width.state]);

  const delete_open = useStateful(false);
  const { data: notes } = useRequest(
    async () => {
      const db = await getDatabase();
      const notes: NoteList = (await (await getNotes_Read(db)).get("root")) || {
        uuid: "root",
        active: [],
        hide: [],
      };
      let res: NoteData[] = [];
      let timenow = new Date();

      let dele: string[] = [];
      for (let i = 0; i < notes.active.length; i++) {
        const one = notes.active[i];
        const thenote: NoteData = await (await getNotes_Read(db)).get(one);
        if (thenote && thenote.timeoutTime != null) {
          let delta =
            (thenote.timeoutTime.getTime() - timenow.getTime()) /
            (1000 * 3600 * 24);
          if (delta > 0) res.push(thenote);
          else dele.push(thenote.uuid);
        } else {
          res.push(thenote);
        }
      }
      for (let i = 0; i < dele.length; i++) {
        const id = dele[i];
        notes.hide.splice(0, 0, id);
        notes.active.splice(notes.active.indexOf(id), 1);
      }
      await (await getNotes_Write(db)).put(notes);
      notelist.set(res.map((v) => v.uuid));
      deletelist.set(notes.hide);

      return res;
    },
    { refreshDeps: [manualUpdateList.key] }
  );
  const { run: getMore } = useRequest(
    async () => {
      let count = 0;
      if (currentDeleteListIndex.value + 5 < deletelist.value.length) {
        count = 5;
      } else {
        count = deletelist.value.length - currentDeleteListIndex.value - 1;
      }
      let list = [...deleteListInf.value];
      for (
        let i = currentDeleteListIndex.value;
        i < currentDeleteListIndex.value + count;
        i++
      ) {
        const e = deletelist.value[i];
        list.push(e);
      }
      deleteListInf.set(list);
      currentDeleteListIndex.set(currentDeleteListIndex.value + count);
    },
    { throttleInterval: 1000, manual: true }
  );
  return (
    <Box
      style={{
        width: Width.value,
        height: 550,
        backgroundColor: "white",
        boxShadow: "0px 0px 12px 3px #b9b9b9",
        position: "relative",
      }}
    >
      <Box style={{ height: 40 }}>
        <Box
          style={{
            marginLeft: 10,
            paddingTop: 10,
            fontSize: 24,
            userSelect: "none",
          }}
        >
          便签
        </Box>
        <CheckableTag
          checked={delete_open.value}
          onChange={(v) => {
            delete_open.set(v);
            deleteListInf.set([]);
            currentDeleteListIndex.set(0);
            manualUpdateList.update();
          }}
          style={{ position: "absolute", top: 20, right: 40 }}
        >
          已过期
        </CheckableTag>
        <Switch
          checked={panel_width.state === "large"}
          onChange={(cked) => {
            if (cked) panel_width.setState("large");
            else panel_width.setState("small");
          }}
          checkedChildren="大"
          unCheckedChildren="小"
          style={{ position: "absolute", top: 20, right: 100 }}
        />
      </Box>
      <Box>
        <Button
          type="dashed"
          color="#b7b7b7"
          style={{
            width: Width.value - 55,
            margin: 10,
            marginLeft: 20,
            marginBottom: 0,
          }}
          onClick={async (e) => {
            let nd = makeNoteData();
            const db = await getDatabase();
            await (await getNotes_Write(db)).put(nd);
            const notes: NoteList = await (await getNotes_Read(db)).get("root");
            notes.active.splice(0, 0, nd.uuid);
            await (await getNotes_Write(db)).put(notes);
            manualUpdateList.update();
          }}
          disabled={delete_open.value}
        >
          <PlusOutlined style={{ color: "#b7b7b7" }} />
        </Button>
      </Box>
      <Box
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        //onMouseMove={(e) => {
        //  e.stopPropagation();
        //}}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          style={{
            width: Width.value - 20,
            height: 440,
            overflow: "auto",
            margin: 10,
          }}
        >
          {!delete_open.value ? (
            notelist.value.map((v, i) => (
              <Row key={v}>
                <OneNoteItem
                  uuid={v}
                  listupdate={manualUpdateList.update}
                  Width={Width.value}
                />
              </Row>
            ))
          ) : (
            <InfiniteScroll
              pageStart={0}
              hasMore={
                currentDeleteListIndex.value !== deletelist.value.length - 1
              }
              loadMore={() => {
                getMore();
              }}
              useWindow={false}
              initialLoad={true}
              threshold={120}
            >
              <List
                dataSource={deleteListInf.value}
                renderItem={(v) => {
                  return (
                    <Row key={v}>
                      <OneNoteItem
                        uuid={v}
                        Width={Width.value}
                        listupdate={manualUpdateList.update}
                      />
                    </Row>
                  );
                }}
              ></List>
            </InfiniteScroll>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const OneNoteItem = ({
  uuid,
  listupdate,
  Width,
}: {
  uuid: string;
  listupdate: () => void;
  Width: number;
}) => {
  const notedata = useStateful<NoteData | null>(null);
  const mvalue = useStateful<PageRawData | undefined>(undefined);
  const remainDay = useStateful<number | undefined>(undefined);
  const { data } = useRequest(async () => {
    const note = (await (await getNotes_Read()).get(uuid)) as NoteData;
    notedata.set(note);
    mvalue.set(note.doc);
    if (note?.timeoutTime) {
      remainDay.set(
        (note.timeoutTime.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
      );
    } else {
      remainDay.set(8);
    }

    return note;
  });
  const manualUpdate = useUpdateI();

  const remainTime = useMemo(() => {
    if (data && data.timeoutTime) {
      let delta =
        (data.timeoutTime.getTime() - new Date().getTime()) /
        (1000 * 3600 * 24);
      return delta;
    }
    return 8;
  }, [data, manualUpdate.key]);

  const { run: save } = useRequest(
    async () => {
      if (data) await (await getNotes_Write()).put(data);
      if ((remainTime || 0) > 0.1) {
        let notes: NoteList = await (await getNotes_Read()).get("root");
        let ind = notes.hide.indexOf(uuid);
        if (ind !== -1) {
          notes.hide.splice(ind, 1);
          notes.active.splice(0, 0, uuid);
          await (await getNotes_Write()).put(notes);
        }
      }
      manualUpdate.update();
    },
    { throttleInterval: 500, manual: true }
  );
  const hover = useStateful(false);

  return (
    <Card
      hoverable
      size="small"
      style={{
        width: Width - 55,
        marginLeft: 10,
        marginRight: 5,
        position: "relative",
        marginTop: 5,
        marginBottom: 5,
        minHeight: 100,
        paddingTop: 5,
      }}
      onMouseEnter={() => hover.set(true)}
      onMouseLeave={() => hover.set(false)}
    >
      <Fade in={hover.value}>
        <Box>
          <NoteDragArea
            uuid={uuid}
            onDragSuccess={async () => {
              let root = (await (await getNotes_Read()).get(
                "root"
              )) as NoteList;
              let index = root.active.indexOf(uuid);
              if (index !== -1) {
                root.active.splice(index, 1);
                await (await getNotes_Write()).put(root);
              } else {
                index = root.hide.indexOf(uuid);
                if (index === -1) throw "不应该发生的错误：找不到笔记卡片";
                root.hide.splice(index, 1);
                await (await getNotes_Write()).put(root);
              }
              listupdate();
            }}
          />
        </Box>
      </Fade>
      <div style={{ position: "absolute", top: 2, right: 2 }}>
        {remainTime &&
          (() => {
            if (remainTime < 0.1) return <Tag color="red">过期</Tag>;
            else if (remainTime <= 3) return <Tag color="red">即将过期</Tag>;
            else if (remainTime <= 5)
              return (
                <Tag color="orange">{`剩余${Math.floor(remainTime)}日`}</Tag>
              );
            else if (remainTime <= 7) return <Tag color="green">剩余一周</Tag>;
            else if (remainTime === 8) return <Tag>永久</Tag>;
          })()}
      </div>
      {data && data.doc && mvalue.value && (
        <BlockEditor
          noAddLineHelper
          mvalue={mvalue.value}
          setValue={({ doc, tags }: { doc: Node[]; tags: string[] }) => {
            if (data.doc) {
              let n = {
                document: doc,
                tags: tags,
                lastWriteTime: new Date(),
                md5: "",
                uuid: uuid,
              };
              mvalue.set(n);
              data.doc = n;
              save();
            }
          }}
        />
      )}
      <Box>
        {!hover.value && (
          <Progress
            percent={
              remainDay.value === -1 ? 100 : ((remainDay.value || 0) / 7) * 100
            }
            size="small"
            format={(percent?: number) => {
              return Math.floor(percent || 0) + "%";
            }}
            status={
              (remainDay.value || 0) <= 3
                ? "exception"
                : (remainDay.value || 0) <= 5
                ? "normal"
                : "success"
            }
            strokeColor={remainDay.value === 8 ? "#dadada" : undefined}
            showInfo={remainDay.value !== 8}
          />
        )}
        {hover.value && (
          <Slider
            value={remainDay.value === -1 ? 8 : remainDay.value}
            marks={(hover.value && marks) || undefined}
            min={0}
            max={8}
            style={{ margin: 10, marginTop: 15 }}
            onChange={(value: number) => {
              remainDay.set(value);
              if (data) {
                data.createTime = new Date();
                if (value !== 8) {
                  data.timeoutType = value;
                  data.timeoutTime = new Date(
                    data.createTime.getTime() + value * 1000 * 3600 * 24
                  );
                } else {
                  data.timeoutType = -1;
                  data.timeoutTime = null;
                }
                save();
              }
            }}
          />
        )}
      </Box>
    </Card>
  );
};
const marks = {
  0: "0日",
  3: "3日",
  7: "7日",
  8: "永久",
};

const NoteDragArea = ({
  uuid,

  onDragSuccess,
}: {
  uuid: string;

  onDragSuccess: () => Promise<void>;
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "note",
      uuid: uuid,
      onSuccess: onDragSuccess,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const ctx = useNotePanelContext();
  const isFirst = useFirst();
  useEffect(() => {
    if (!isFirst.current) {
      if (isDragging) {
        ctx.hide?.();
      } else {
        ctx.show?.();
      }
    }else{
      isFirst.current = false
    }
  }, [isDragging]);

  return (
    <div
      ref={drag}
      style={{ position: "absolute", top: 0, width: "100%", height: 20 }}
    >
      <DragHandle
        style={{ left: "calc(100% / 2 - 22px)", position: "absolute" }}
      />
    </div>
  );
};

export interface NoteDragData {
  type: "note";
  uuid: string;
  onSuccess: () => Promise<void>;
}
