import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import {
  useLearnApp,
  changeLearnStep,
  LearnStep,
  changeSubject,
} from "../useLearnAppState";

export const LearnRest = () => {
  const learnApp = useLearnApp();
  const style = useStyle();
  return (
    <Card className={style.card}>
      <CardContent>
        <Typography>休息一下</Typography>
      </CardContent>
      <CardActions>
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                learnApp.func.changeState?.(
                  changeLearnStep(LearnStep.SubjectHome)
                );
                if (learnApp.state?.currentSubjectIndex !== undefined)
                  learnApp.func.changeState?.(
                    changeSubject(learnApp.state.currentSubjectIndex + 1)
                  );
              }}
            >
              休息完了！
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minHeight: 300,
      [theme.breakpoints.up("xs")]: {
        width: 500,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      padding: 20,
    },
  })
);
