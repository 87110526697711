import { TaskEvent } from "./TaskEvent";

let event: TaskEvent<string, string[]>;
export function RegSplitText() {
  event = new TaskEvent<string, string[]>(
    "APP_SplitText_Result",
    "APP_SplitText"
  );
}

export function SplitText(text: string) {
  return event.Call(text);
}
