import React from "react";
import { Box, IconButton, Fade } from "@material-ui/core";
import { CloseCircleTwoTone } from "@ant-design/icons";

export const BoxWithCloseButton = ({
  children,
  onClose,
}: {
  children: any;
  onClose: () => void;
}) => {
  return (
    <Box style={{ position: "relative" }}>
      <IconButton
        style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
        onClick={() => {
          onClose();
        }}
      >
        <CloseCircleTwoTone twoToneColor="rgb(180,180,180)" />
      </IconButton>
      {children}
    </Box>
  );
};
