import React, { useMemo, useCallback, useRef, useEffect } from "react";
import { Transforms, Range, Editor } from "slate";
import { Slate, Editable, ReactEditor } from "slate-react";

import "./css/renderMarkdown.css";
import { Leaf } from "./Leaf";
import { EditorMethods } from "./EditorMethods";
import { HoveringToolbar } from "./HoverToolbar";

import { BlockEditorPlugin, makeFullEditor } from "./plugins/withBlockDefault";
import { MathPlugin } from "./plugins/withMath";
import { MElement } from "./Elements";
import { KeyWordPlugin } from "./plugins/withKeyWord";
import { PageRawData } from "../lib/Database/DataInterface";
import { SelectInsertPlugin } from "./plugins/withSelectInsert";
import { Portal } from "./components.js";
import { ImageURLDialog, ImagePlugin } from "./plugins/ImageAddition";
import { SwitchComponent } from "../AppCom/TabPanel";
import {
  createStyles,
  Theme,
  makeStyles,
  List,
  ListItem,
} from "@material-ui/core";
import { deserializeHTML } from "../lib/Database/DataDeserialize";
import { LinkCardPlugin, AddLinkCardDialog } from "./eles/LinkCardElement";
export interface BlockEditorProp {
  mvalue: PageRawData;
  setValue: Function;
  readonly?: boolean;
  share?: boolean;
  className?: string;
  hideKeyword?: boolean;
  onShiftEnter?: () => void;
  onCtrlDown?: () => void;
  onCtrlUp?: () => void;
  onCtrlLeft?: () => void;
  onCtrlRight?: () => void;
  autoFocus?: boolean;
  resetAutoFocus?: () => void;
  editorRef?: React.MutableRefObject<ReactEditor | undefined>;
  noAddLineHelper?: boolean
}

export const DocContext = React.createContext({
  tags: [],
  edit: false,
  share: false,
  hideKeyword: false,
  uuid: "",
  noAddLineHelper: false
} as {
  tags: string[];
  edit: boolean;
  share: boolean;
  hideKeyword: boolean;
  showInsert?: () => void;
  uuid: string;
  noAddLineHelper: boolean
});

export const BlockEditor: React.FC<BlockEditorProp> = ({
  mvalue,
  setValue,
  readonly,
  share,
  className,
  hideKeyword,
  onShiftEnter,
  onCtrlDown,
  onCtrlUp,
  onCtrlLeft,
  onCtrlRight,
  editorRef,
  noAddLineHelper
}) => {
  const current_uuid = useRef("");
  const tagList = useRef({ tags: [] as any[] });
  useEffect(() => {
    tagList.current = mvalue.tags ? { tags: mvalue.tags } : { tags: [] };
  }, [mvalue.tags]);
  // 创建editor
  const editor = useMemo(() => {
    current_uuid.current = mvalue.uuid;
    let e = makeFullEditor();
    if(editorRef) editorRef.current = e;
    return e;
  }, [mvalue.uuid]);

  const renderElemment = useCallback((props) => <MElement {...props} />, []);
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);
  const selectPlugin = SelectInsertPlugin.useSelect(editor);

  const selectCurrent = useRef(selectPlugin.target);
  useEffect(() => {
    if (editor.selection) selectCurrent.current = editor.selection;
  });
  //console.trace(autoFocus)
  return (
    <div className={className}>
      <DocContext.Provider
        value={{
          tags: tagList.current.tags,
          edit: !readonly,
          share: !!share,
          hideKeyword: !!hideKeyword,
          showInsert: () => {
            // TODO: 希望插入新元素将在下一行
            selectPlugin.setTarget(editor.selection);
          },
          uuid: mvalue.uuid,
          noAddLineHelper: !!noAddLineHelper
        }}
      >
        <Slate
          editor={editor}
          value={mvalue.document as any[]}
          onChange={(value: any) => {
            setValue({ doc: value, tags: tagList.current.tags });

            SelectInsertPlugin.checkIfShowSelectMenu(editor, selectPlugin);
          }}
          key={mvalue.uuid}
        >
          <HoveringToolbar />
          <Editable
            readOnly={!!readonly}
            onKeyUp={(event) => {
              if (event.ctrlKey) {
                // Ctrl +
                switch (event.key) {
                  case "ArrowUp":
                    if (onCtrlUp) {
                      onCtrlUp();
                      event.stopPropagation();
                      event.preventDefault();
                    }
                    break;
                  case "ArrowDown":
                    if (onCtrlDown) {
                      onCtrlDown();
                      event.stopPropagation();
                      event.preventDefault();
                    }
                    break;
                  case "ArrowLeft":
                    if (onCtrlLeft) {
                      onCtrlLeft();
                      event.stopPropagation();
                      event.preventDefault();
                    }
                    break;
                  case "ArrowRight":
                    if (onCtrlRight) {
                      onCtrlRight();
                      event.stopPropagation();
                      event.preventDefault();
                    }
                    break;
                  default:
                    break;
                }
              } else if (event.shiftKey) {
                switch (event.key) {
                  case "Enter":
                    if (onShiftEnter !== undefined) {
                      onShiftEnter();
                      event.preventDefault();
                    }
                    break;
                  default:
                    break;
                }
              }
            }}
            onKeyDown={(event) => {
              const domSelection = window.getSelection();
              try {
                const domRange = domSelection?.getRangeAt(0);
                const rect = domRange?.getBoundingClientRect();

                //console.log(rect);
                //console.log(window.screen.availHeight);
                if (rect) {
                  if (rect.y <= 250) {
                    //console.log("~");
                    window.scrollBy(0, -80);
                  }
                  if (window.screen.availHeight - rect.y <= 250) {
                    //console.log("!");
                    window.scrollBy(0, 80);
                  }
                }
              } catch (error) {}
              if (event.ctrlKey) {
                // Ctrl +
                switch (event.key) {
                  case "m":
                    event.preventDefault();
                    MathPlugin.toggleMath(editor);
                    break;
                  case "d":
                    event.preventDefault();
                    console.log(editor.selection);
                    break;
                  case "a":
                    event.preventDefault();
                    const { selection } = editor;
                    const focus = selection?.focus;
                    if (!!focus) {
                      Transforms.select(editor, [focus.path[0]]);
                    }
                    break;
                  case "1":
                    event.preventDefault();
                    KeyWordPlugin.toggleKeyWord(mvalue.uuid, editor, 1);
                    break;
                  case "2":
                    event.preventDefault();
                    KeyWordPlugin.toggleKeyWord(mvalue.uuid, editor, 2);
                    break;
                  case "q":
                    event.preventDefault();
                    KeyWordPlugin.toggleKeyLine(editor);
                    break;
                  case "Enter":
                    BlockEditorPlugin.forceInsertNewLine(event, editor);
                    break;
                  default:
                    break;
                }
              } else if (selectPlugin.target) {
                //
                SelectInsertPlugin.keyboardEvents(event, selectPlugin, editor);
              } else {
                // 单纯换行
                switch (event.key) {
                  case "Enter":
                    BlockEditorPlugin.fixNewLineWithEnter(event, editor);
                    break;
                  case "Tab":
                    let [ms] = Editor.nodes(editor, {
                      match: (node) =>
                        node.type === "math-inline" ||
                        node.type === "math-line",
                    });
                    if (ms) {
                      MathPlugin.toggleMath(editor);
                      Transforms.move(editor, { distance: 1 });
                      event.preventDefault();
                      break;
                    }
                    Transforms.insertText(editor, "    ");
                    event.preventDefault();
                    break;
                  default:
                    break;
                }
              }
            }}
            onDOMBeforeInput={async (event: any) => {
              switch (event.inputType) {
                case "formatBold":
                  event.preventDefault();
                  return EditorMethods.toggleFormat(editor, "bold");
                case "formatItalic":
                  event.preventDefault();
                  return EditorMethods.toggleFormat(editor, "italic");
                case "formatUnderline":
                  event.preventDefault();
                  return EditorMethods.toggleFormat(editor, "underlined");
                case "insertFromPaste":
                  if (
                    event.dataTransfer.types.indexOf(
                      "application/x-slate-fragment"
                    ) !== -1
                  ) {
                  } else if (
                    event.dataTransfer.types.indexOf("text/html") !== -1
                  ) {
                    var html = event.dataTransfer.getData("text/html");
                    var doms = new DOMParser().parseFromString(
                      html,
                      "text/html"
                    );
                    var nodes = deserializeHTML(doms.body);
                    //console.log(doms);
                    //console.log(nodes);
                    for (let i = 0; i < nodes.length; i++) {
                      const nn = nodes[i];
                      editor.insertNode(nn);
                    }
                    //editor.insertFragment(nodes);
                    event.preventDefault();
                    return;
                  } else {
                  }
              }
              const { selection } = editor;
              if (!!selection && !Range.isCollapsed(selection)) {
                // fix 这里修复选中一个元素的时候用某些不上屏的输入法导致文本插入错误
                Transforms.insertText(editor, "", { at: selection });

                if (!!editor.selection)
                  Transforms.setSelection(editor, editor.selection);

                // fix 这里修复经上面处理后若插入英文导致的崩溃
                // 初步怀疑是出发此事件前保存了editor的闭包，因此里面做修改会和外部不匹配，从而崩溃
                if (event.inputType === "insertText") {
                  Transforms.insertText(editor, event.data);
                }
                event.preventDefault();
              }
            }}
            renderElement={renderElemment}
            renderLeaf={renderLeaf}
          />
          {selectPlugin.target && SelectInsertPlugin.supportList.length > 0 && (
            <Portal>
              <List
                component="div"
                ref={selectPlugin.ref}
                style={{
                  top: "-9999px",
                  left: "-9999px",
                  position: "absolute",
                  zIndex: 10,
                  padding: "5px",
                  background: "white",
                  borderRadius: "4px",
                  boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                  minWidth: 100,
                }}
              >
                {SelectInsertPlugin.supportList.map((item, i) => (
                  <ListItem
                    button
                    key={item.type}
                    style={{
                      padding: "1px 3px",
                      borderRadius: "3px",
                      background:
                        i === selectPlugin.selectIndex
                          ? "#B4D5FF"
                          : "transparent",
                    }}
                    onClick={() => {
                      selectPlugin.setSelectIndex(i);
                      SelectInsertPlugin.keyboardEvents(
                        { key: "Enter", preventDefault: () => {} } as any,
                        selectPlugin,
                        editor,
                        i
                      );
                    }}
                  >
                    {item.label}
                  </ListItem>
                ))}
              </List>
            </Portal>
          )}
        </Slate>
        <Portal>
          <SwitchComponent
            value={selectPlugin.selectItemAddition.get("image").dialogOpen}
            index={true}
          >
            <ImageURLDialog
              open={selectPlugin.selectItemAddition.get("image").dialogOpen}
              handleClose={() =>
                selectPlugin.selectItemAdditionDis({
                  type: "image",
                  newAddition: { dialogOpen: false },
                })
              }
              confirm={(url: string, urlType: string) => {
                ImagePlugin.insertImage(
                  editor,
                  url,
                  urlType,
                  selectCurrent.current
                );
                Transforms.select(editor, selectCurrent.current);
                selectPlugin.selectItemAdditionDis({
                  type: "image",
                  newAddition: { dialogOpen: false },
                });
              }}
            />
          </SwitchComponent>
          <SwitchComponent
            value={selectPlugin.selectItemAddition.get("link-card").dialogOpen}
            index={true}
          >
            <AddLinkCardDialog
              open={selectPlugin.selectItemAddition.get("link-card").dialogOpen}
              onCancel={() =>
                selectPlugin.selectItemAdditionDis({
                  type: "link-card",
                  newAddition: { dialogOpen: false },
                })
              }
              onConfirm={(
                summary: string,
                link: string,
                detail: string,
                type: string,
                blob?: ArrayBuffer
              ) => {
                LinkCardPlugin.Insert(
                  editor,
                  summary,
                  link,
                  detail,
                  type,
                  selectCurrent.current,
                  blob
                );
                selectPlugin.selectItemAdditionDis({
                  type: "link-card",
                  newAddition: { dialogOpen: false },
                });
              }}
            />
          </SwitchComponent>
        </Portal>
      </DocContext.Provider>
    </div>
  );
};

export const useBlockEditorStyle = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      minHeight: 900,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingTop: 100,
        paddingBottom: 80,
      },
    },
    paper: {
      minHeight: 900,
    },
  })
);
