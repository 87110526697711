import React, {
  useCallback,
  useRef,
  forwardRef,
  useEffect,
  useState,
  useLayoutEffect,
  useMemo,
} from "react";

import { Node } from "slate";
import { Slate, Editable } from "slate-react";

import "./css/MindMap.css";

import clsx from "clsx";

import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Divider,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  Box,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { MElement } from "../../../elements/Elements";
import { Leaf } from "../../../elements/Leaf";
import { useSimpleEditor } from "../../../elements/plugins/withBlockDefault";
import { useRequest } from "@umijs/hooks";
import {
  getDatabase,
  getObjectStore,
  StoreName,
  getKnowledgeCard_Read,
} from "../../../lib/Database/Database";
import { KnowledgeCard } from "../Knowledge/old_Knowledge";
import { cloneDeep } from "lodash";
import { useTopicMindMap } from "./TopicMindMap";
import { BlockEditor } from "../../../elements/BlockEditor";
import LoupeIcon from "@material-ui/icons/Loupe";
import { useAlive } from "../../../lib/CommonHook";

import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";

interface MNodeProp {
  /**
   * 对应知识卡片的ID
   */
  knowledgeUUID: string;
  /**
   * 用以传递父节点的引用
   */
  ref?: any;
  depth: number;
}

/**
 * 校验输出元素大小
 * @param node 需要校验的dom节点的Ref
 */
function parseEle(node: any) {
  if (!node || !node.current)
    return {
      offsetLeft: 0,
      offsetTop: 0,
      offsetHeight: 0,
      offsetWidth: 0,
      notvalid: true,
    };
  return node.current;
}

function ToRenderTitle(nodes: Node[]) {
  let first: Node = cloneDeep(nodes[0]);

  if (first.type.startsWith("H")) {
    first.type = "ph";
  }
  return [first];
}

/**
 * 这是一个转发元素，能获取父节点和自身的dom元素，从而计算连线。
 * 且包含渲染slate的功能
 */
export const TopicMNode = forwardRef(
  ({ knowledgeUUID, depth }: MNodeProp, parent_ref) => {
    /**
     * 自身的span引用
     */
    const self = useRef() as React.MutableRefObject<HTMLSpanElement>;
    // 组件的唯一标识
    const [symbol] = useState(Symbol());
    const topicCtx = useTopicMindMap();
    const { addLine, removeLine } = topicCtx;
    // 用于处理值，从而影响渲染结果
    // 因为标题需要渲染成普通加粗文本而非文档中的标题样式
    // 普通文本则需要显示关键词
    // 因此需要在此修改样式
    const [renderValue, setRenderValue] = useState([] as Node[]);

    const alive = useAlive();

    const { data: knowledge } = useRequest(async () => {
      const db = await getDatabase();
      const doc: KnowledgeCard = await (
        await getKnowledgeCard_Read(db)
      ).get(knowledgeUUID);
      if(doc === undefined) {
        alert("找不到科目，请在计划中修改有问题的科目。")
      }
      if (alive.current) setRenderValue(ToRenderTitle(doc.doc.document));
      return doc;
    });

    // 计算自身和父组件的大小
    const p = parseEle(parent_ref); // 父
    const s = parseEle(self); // 自己

    // 储存展开节点的状态
    const [expanded, setExpanded] = React.useState(false);
    /**
     * 样式
     */
    const classes = useStyles();

    // 用于处理添加、删除线
    useLayoutEffect(() => {
      addLine(symbol, {
        startref: parent_ref,
        endref: self,
        s_v: p.notvalid,
        e_v: s.notvalid,
      });
      return () => {
        removeLine(symbol);
      };
    }, [
      s.offsetTop,
      s.offsetLeft,
      p.offsetLeft,
      p.offsetTop,
      addLine,
      removeLine,
      p.notvalid,
      s.notvalid,
      parent_ref,
      self,
      symbol,
    ]);

    // 用于渲染slate元素的必要函数
    const renderElemment = useCallback((props) => <MElement {...props} />, []);
    const renderLeaf = useCallback((props) => {
      return <Leaf {...props} />;
    }, []);

    /**
     * 主节点slate editor
     * * 若存在isVoid或isInline等重写插件，需要在此绑定，否则会出现显示错误
     */
    const editor = useSimpleEditor();
    const content = useMemo(
      () => (
        <Slate editor={editor} value={renderValue} onChange={() => {}}>
          <Editable
            renderElement={renderElemment}
            renderLeaf={renderLeaf}
            readOnly
          ></Editable>
        </Slate>
      ),
      [renderValue, editor, renderElemment, renderLeaf]
    );

    const style = useTopicItemStyle();

    // 是否打开预览详情页面
    const [expandOpen, setExpandOpen] = useState(false);

    // 是否展开子节点
    const [expandChild, setExpandChild] = useState(depth < 3);

    // 如果没有什么可以渲染的，直接返回
    //if (value === null) return null;

    // 如果没东西就直接返回（不知道这一行会是什么个情况呢？就当保险吧）
    if (
      renderValue === null ||
      (renderValue.length !== undefined && renderValue.length === 0)
    )
      return null;

    // 处理子节点的工作
    // 首先找到层次等级最低的节点，然后将所有这个层次节点之间的 节点层次更高节点 打包成最近（从上往下）的节点的children
    if (knowledge && knowledge.children.length > 0) {
      // 显示有子节点的情形
      return (
        <span className="t-mind-canvas">
          <span ref={self}>
            <Card
              style={{ maxWidth: "300px", lineHeight: "180%" }}
              onClick={() => topicCtx.setSelectKnowledge(knowledgeUUID)}
              className={
                topicCtx.selectKnowledge === knowledgeUUID
                  ? style.selected + " "
                  : "" + style.card
              }
            >
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <CardContent style={{ paddingBottom: "14px" }}>
                    {content}
                    <Divider style={{ marginTop: "8px" }} />
                  </CardContent>
                </Grid>
                <Grid item>
                  {knowledge && knowledge.doc.document.length > 1 ? (
                    // 如果是段落，则显示展开面板，展开后显示原文
                    <Grid item>
                      <CardActions disableSpacing>
                        <IconButton
                          onClick={() => {
                            setExpandOpen(true);
                          }}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <LoupeIcon />
                        </IconButton>
                      </CardActions>
                      <KnowledgePreviewCard
                        open={expandOpen}
                        handleClose={() => setExpandOpen(false)}
                        doc={knowledge}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          </span>
          <Box style={{ right: -10, top: "50%" }}>
            {expandChild ? (
              <IconButton onClick={() => setExpandChild(!expandChild)}>
                <RemoveCircleOutline />
              </IconButton>
            ) : (
              <IconButton onClick={() => setExpandChild(!expandChild)}>
                <AddCircleOutline />
              </IconButton>
            )}
          </Box>

          <span className="t-mind-canvas-friends">
            {expandChild &&
              knowledge.children.map((e) => {
                return (
                  <TopicMNode
                    ref={self}
                    knowledgeUUID={e}
                    key={e}
                    depth={depth + 1}
                  ></TopicMNode>
                );
              })}
          </span>
        </span>
      );
    }
    // 下面是没有子节点的情形
    else {
      return (
        <span className="t-mind-canvas">
          <span ref={self}>
            <Card
              style={{ maxWidth: "300px", lineHeight: "180%" }}
              onClick={() => topicCtx.setSelectKnowledge(knowledgeUUID)}
              className={
                topicCtx.selectKnowledge === knowledgeUUID
                  ? style.selected + " "
                  : "" + style.card
              }
            >
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <CardContent style={{ paddingBottom: "14px" }}>
                    {content}
                  </CardContent>
                </Grid>
                {knowledge && knowledge.doc.document.length > 1 ? (
                  // 如果是段落，则显示展开面板，展开后显示原文
                  <Grid item>
                    <CardActions disableSpacing>
                      <IconButton
                        onClick={() => {
                          setExpandOpen(true);
                        }}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <LoupeIcon />
                      </IconButton>
                    </CardActions>
                    <KnowledgePreviewCard
                      open={expandOpen}
                      handleClose={() => setExpandOpen(false)}
                      doc={knowledge}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Card>
          </span>
        </span>
      );
    }
  }
);

const KnowledgePreviewCard = ({
  open,
  handleClose,
  doc,
}: {
  open: boolean;
  handleClose: () => void;
  doc: KnowledgeCard;
}) => {
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        <Typography>{doc.title}</Typography>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ minWidth: 300, minHeight: 200 }}>
        <BlockEditor readonly mvalue={doc.doc} setValue={() => {}} />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      marginTop: "auto",
      marginBottom: "auto",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
);

const LEVEL: { [key: string]: number } = {
  title: 0,
  H1: 1,
  H2: 2,
  H3: 3,
  H4: 4,
  H5: 5,
  H6: 6,
  ph: 99,
};

const useTopicItemStyle = makeStyles(() =>
  createStyles({
    card: {},
    selected: {
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#66ccff",
      boxSizing: "border-box",
    },
  })
);
