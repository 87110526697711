import React, { useRef } from "react";
import { Box, Fade, IconButton } from "@material-ui/core";
import { Knowledge, KnowledgeRef } from "./Knowledge";
import { InputHelper } from "../../../AppCom/InputHelper";
import { BoxWithCloseButton } from "../../../AppCom/BoxWithCloseButton";
import { useStateful } from "../../../lib/CommonHook";
import { Button } from "antd";
import { QuestionOutlined, EditOutlined } from "@ant-design/icons";
import { KnowledgeSearch } from "./KnowledgeSearch";
import { DragablePanel } from "../../../AppCom/DragablePanel";
import { NotePanel, NotePanelContext } from "./NotePanel";

export const KnowledgePage = () => {
  const helper_open = useStateful(false);
  const note_open = useStateful(false);
  const knowledgeMain = useRef<KnowledgeRef | null>(null);

  return (
    <Box>
      <Knowledge Ref={knowledgeMain} />
      <Fade in={!helper_open.value}>
        <Button
          shape="circle"
          onClick={() => helper_open.set(true)}
          style={{ position: "fixed", right: 30, bottom: 30 }}
          size="large"
        >
          <QuestionOutlined style={{ fontSize: 24 }} />
        </Button>
      </Fade>
      <Fade in={helper_open.value}>
        <Box>
          <DragablePanel right={10} bottom={10}>
            <BoxWithCloseButton onClose={() => helper_open.set(false)}>
              <InputHelper />
            </BoxWithCloseButton>
          </DragablePanel>
        </Box>
      </Fade>
      <Box
        style={{
          position: "fixed",
          right: 90,
          bottom: 30,
        }}
      >
        <KnowledgeSearch
          onSelect={(id) => knowledgeMain.current?.openPages(id)}
        />
      </Box>
      <Fade in={!note_open.value}>
        <Button
          shape="circle"
          onClick={() => note_open.set(true)}
          style={{ position: "fixed", right: 150, bottom: 30 }}
          size="large"
        >
          <EditOutlined style={{ fontSize: 24 }} />
        </Button>
      </Fade>
      <Fade in={note_open.value}>
        <Box>
          <DragablePanel right={10} bottom={10}>
            <BoxWithCloseButton onClose={() => note_open.set(false)}>
              <NotePanelContext.Provider
                value={{
                  show: () => note_open.set(true),
                  hide: () => note_open.set(false),
                }}
              >
                <NotePanel />
              </NotePanelContext.Provider>
            </BoxWithCloseButton>
          </DragablePanel>
        </Box>
      </Fade>
    </Box>
  );
};
