import {
  QuizCardData,
  QuizCardUserData,
  QuizChoiceCard,
} from "../../plugins/withQuiz";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Menu,
  makeStyles,
  createStyles,
  Theme,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useState, useCallback } from "react";
import { MakeNewCard, DeleteCurrentCard, CARD_INIT } from "./QuizCommonMethod";
import {
  getDatabase,
  getObjectStore,
  StoreName,
} from "../../../lib/Database/Database";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useDivices } from "../../../lib/hooks/useDevices";

export enum UserAnswerLevel {
  DontKnow = "DontKnow",
  Aah = "Aah",
  IKnow = "IKnow",
}

export enum UserAssessmentLevel {
  DontKnow = "DontKnow",
  NowKnow = "NowKnow",
  AlreadyKnow = "AlreadyKnow",
}

export interface CardProp {
  collectionUUID: string;
  uuid: string;
  data: QuizCardData;
  userdata?: QuizCardUserData;
  completeCallback?: (u: QuizCardUserData) => void;
  nextCardCallback?: Function;
  setCardsCollection?: Function;
  updateCallback: Function;
  isEdit?: boolean;
  showAns?: boolean;
}

interface type_name_map {
  [key: string]: string;
}

export const TYPE_NAME_MAP: type_name_map = {
  Choice: "选择题",
  TrueOrFalse: "判断题",
  Remember: "记忆题",
  Answer: "简答题",
};

export const useEditQuiz = (ised: boolean | undefined) => {
  const [isEdit, setIsEdit] = useState(!!ised);
  return { isEdit, setIsEdit };
};

export const QuizTemplateHead = ({
  isEdit,
  prop,
}: {
  isEdit: boolean;
  prop: CardProp;
}) => {
  const uuid = prop.uuid;
  const reload = prop.updateCallback;
  const type = prop.data.type;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return isEdit ? (
    <Grid container alignItems="center">
      <Grid container item justify="space-between">
        <Grid item>
          <Typography>{`${TYPE_NAME_MAP[type]} - 编辑中  `}</Typography>
        </Grid>
        <Grid item>
          <QuizTemplateChooseCardType uuid={uuid} type={type} reload={reload} />
        </Grid>
        <Grid item>
          <IconButton onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>取消</MenuItem>
        <MenuItem
          onClick={async () => {
            const ncol = await DeleteCurrentCard(
              prop.collectionUUID,
              prop.uuid
            );
            handleClose();
            if (prop.setCardsCollection) prop.setCardsCollection(ncol);
          }}
        >
          删除
        </MenuItem>
      </Menu>
    </Grid>
  ) : (
    <Grid container>
      <Grid item>
        <Typography>{TYPE_NAME_MAP[type]}</Typography>
      </Grid>
    </Grid>
  );
};

export const QuizTemplateMakeCardControlBar = ({
  prop,
  save,
}: {
  prop: CardProp;
  save: Function;
}) => {
  if (
    prop.nextCardCallback !== undefined &&
    prop.setCardsCollection !== undefined
  ) {
    return (
      <Grid container item justify="space-evenly">
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              save();
              if (prop.nextCardCallback) prop.nextCardCallback(-1);
            }}
          >
            上一张
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              save();
              if (prop.nextCardCallback) prop.nextCardCallback();
            }}
          >
            下一张
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={async (e) => {
              let newcard = await MakeNewCard(prop.collectionUUID, prop.uuid);
              save();
              if (prop.setCardsCollection) prop.setCardsCollection(newcard);
              if (prop.nextCardCallback) prop.nextCardCallback();
            }}
          >
            新建
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={async () => {
              const ncol = await DeleteCurrentCard(
                prop.collectionUUID,
                prop.uuid
              );
              if (prop.setCardsCollection) prop.setCardsCollection(ncol);
            }}
          >
            {"删除"}
          </Button>
        </Grid>
      </Grid>
    );
  }
  return null;
};

export const QuizTemplateChooseCardType = ({
  uuid,
  type,
  reload,
}: {
  uuid: string;
  type: string;
  reload: Function;
}) => {
  const handleChange = useCallback(
    async (e, v) => {
      const db = await getDatabase();
      let card: QuizCardData = await (
        await getObjectStore(db, StoreName.quiz_card)
      ).get(uuid);
      card.type = v;
      card.card = CARD_INIT[card.type]();
      await (await getObjectStore(db, StoreName.quiz_card, "readwrite")).put(
        card
      );
      reload();
    },
    [uuid, reload]
  );
  return (
    <RadioGroup value={type} row onChange={handleChange}>
      <FormControlLabel
        value="Choice"
        control={<Radio color="primary" />}
        label="选择题"
      />
      <FormControlLabel
        value="Answer"
        control={<Radio color="primary"/>}
        label="简答题"
      />
      <FormControlLabel
        value="TrueOrFalse"
        control={<Radio color="primary" disabled/>}
        label="判断题"
      />
      <FormControlLabel
        value="Remember"
        control={<Radio color="primary" disabled/>}
        label="记忆题"
      />
      
    </RadioGroup>
  );
};

export const QuizTemplateAnswerControlBar = ({
  onConfirm,
}: {
  onConfirm: (v: UserAnswerLevel) => void;
}) => {
  return (
    <Grid container item justify="space-evenly">
      <Grid item>
        <Button
          onClick={() => onConfirm(UserAnswerLevel.DontKnow)}
          variant="outlined"
        >
          <Typography>完全不会</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onConfirm(UserAnswerLevel.Aah)}
          variant="outlined"
        >
          <Typography>不是很确定</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onConfirm(UserAnswerLevel.IKnow)}
          variant="outlined"
        >
          <Typography>肯定的回答</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const QuizTemplateAfterAnswerControlBar = ({
  onConfirm,
  disabled,
}: {
  onConfirm: (v: UserAssessmentLevel) => void;
  disabled: boolean;
}) => {
  return (
    <Grid container justify="space-evenly">
      <Grid item>
        <Button
          onClick={() => onConfirm(UserAssessmentLevel.DontKnow)}
          variant="outlined"
          disabled={disabled}
        >
          <Typography>没有搞懂</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onConfirm(UserAssessmentLevel.NowKnow)}
          variant="outlined"
          disabled={disabled}
        >
          <Typography>现在搞懂了</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => onConfirm(UserAssessmentLevel.AlreadyKnow)}
          variant="outlined"
          disabled={disabled}
        >
          <Typography>我很熟练</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const useQuizCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    xs_card: {
      minHeight: 700,
      width: 300,
      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
    },
    md_card: {
      minHeight: 700,
      width: 500,
      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
    },
    expand_card: {
      minHeight: 700,
      width: 1000,

      margin: 20,
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

// 提供基于屏幕宽度和答题状态的卡片状态调整
export const useQuizCardStyleCommon = (canSelect: boolean, isEdit: boolean) => {
  const divice = useDivices();
  const style = useQuizCardStyle();
  return divice.select([
    [divice.whenMobile, style.xs_card],
    [divice.whenDesktop && (!canSelect || isEdit), style.expand_card],
    [!(divice.whenDesktop && (!canSelect || isEdit)), style.md_card],
  ]);
};

export const useQuizCardCommon = (prop: CardProp) => {
  const card = prop.data.card as QuizChoiceCard;

  const [canSelect, setCanSelect] = useState(true);
  const [wantShowExp, setWantShowExp] = useState(false);
  const editcard = useEditQuiz(prop.isEdit);
  const [helperData, setHelperData] = useState(prop.data.card.helper);
  const [expData, setExpData] = useState(prop.data.card.explain);
  return {
    canSelect,
    setCanSelect,
    wantShowExp,
    setWantShowExp,
    editcard,
    helperData,
    expData,
    setHelperData,
    setExpData,
    card,
  };
};

export enum AnswerStatue {
  unknown,
  right,
  wrong,
}
